import {
  PerformanceFilters,
  PerformanceFilterState
} from "@/app/performance/PerformanceFilters";
import { PerformanceMoreActions } from "@/app/performance/PerformanceMoreActions";
import { PerformanceTemplateFragment } from "@/app/performance/PerformanceTemplate";
import { usePerformanceContext } from "@/app/performance/state/PerformanceProvider";
import { FIFTEEN_MINUTES_IN_MS } from "@/constants";
import { graphql, readFragment } from "@/lib/data/graphql";
import { Divider } from "@/ui/display/divider";
import { Alert } from "@/ui/feedback/alert";
import { LoadingSpinner } from "@/ui/loading-spinner";
import { useQuery } from "@apollo/client";
import { formatISO } from "date-fns";
import { getRangeForFilter } from "./messages-log/utils";
import { PerformanceChart, PerformanceChartFragment } from "./PerformanceChart";
import PerformanceChartToolbar from "./PerformanceChartToolbar";
import { AdjustTemplateDialog } from "./template-management/AdjustTemplateDialog";

export const PerformanceChartContainerQuery = graphql(
  `
    query PerformanceChartContainer($where: PerformancePlantsWhereInput!) {
      performancePlants(where: $where) {
        ...PerformanceChart
      }
    }
  `,
  [PerformanceChartFragment]
);

export type PerformanceChartContainerProps = {
  loading?: boolean;
};

export const PerformanceChartContainer: React.FC<
  PerformanceChartContainerProps
> = ({ loading: outsideLoading }) => {
  const {
    state: {
      template: templateData,
      filters,
      formatters,
      adjustTemplateDialog
    },
    dispatch
  } = usePerformanceContext();

  const template = readFragment(PerformanceTemplateFragment, templateData);
  const plantIds = template?.plants.map((plant) => plant.id) ?? [];
  const { data, loading, previousData } = useQuery(
    PerformanceChartContainerQuery,
    {
      variables: {
        where: {
          plantIds,
          startDate: formatISO(filters.startDate),
          endDate: formatISO(filters.endDate),
          aggregation: formatters.aggregation
        }
      },
      fetchPolicy: "cache-and-network",
      pollInterval: FIFTEEN_MINUTES_IN_MS
    }
  );

  const handleMessageLogTickClick = (date: string) => {
    const { startDate, endDate } = getRangeForFilter(
      date,
      formatters.aggregation
    );

    dispatch({
      type: "SET_MESSAGES_LOG_FILTERS",
      payload: { startDate, endDate }
    });
    dispatch({ type: "OPEN_MESSAGES_LOG_DRAWER", payload: true });
  };

  const handleFiltersChange = ({
    startDate,
    endDate
  }: PerformanceFilterState) => {
    dispatch({
      type: "SET_MESSAGES_LOG_FILTERS",
      payload: { startDate, endDate }
    });
  };

  const handleAdjustTemplateDialogClose = () => {
    dispatch({ type: "OPEN_ADJUST_TEMPLATE_DIALOG", payload: false });
  };

  return (
    <>
      <div className="flex justify-between gap-2 py-2.5 px-2 md:px-1">
        <PerformanceFilters onFiltersChange={handleFiltersChange} />
        <PerformanceMoreActions
          data={data?.performancePlants}
          className="max-md:hidden"
        />
      </div>
      <div className="mb-2.5 max-md:mx-2">
        <Divider />
      </div>

      <div className="h-full">
        {(data?.performancePlants || previousData?.performancePlants) &&
        template ? (
          <PerformanceChart
            data={
              data?.performancePlants ?? previousData?.performancePlants ?? []
            }
            loading={loading || outsideLoading}
            onMessageLogTickClick={handleMessageLogTickClick}
          />
        ) : loading || outsideLoading ? (
          <div className="flex mt-14 items-center justify-center">
            <LoadingSpinner />
          </div>
        ) : (
          <Alert type="info" title="No data" />
        )}
      </div>

      <PerformanceChartToolbar
        className="md:hidden"
        endSlot={<PerformanceMoreActions data={data?.performancePlants} />}
      />

      <AdjustTemplateDialog
        templateId={template?.id.toString() ?? ""}
        isOpen={adjustTemplateDialog.open}
        onClose={handleAdjustTemplateDialogClose}
      />
    </>
  );
};
