import { RolePermissionsPageQuery } from "@/app/permissions/RolePermissionsPage";
import { graphql } from "@/lib/data/graphql";
import { useMutation } from "@apollo/client";

const CreateResourceMutation = graphql(`
  mutation AddResource($name: String!) {
    addResource(name: $name) {
      id
      name
    }
  }
`);

export const useAddResource = () => {
  const [addResource, { error, loading, data }] = useMutation(
    CreateResourceMutation,
    {
      update: (cache, { data }) => {
        if (!data?.addResource) {
          return;
        }

        const existingData = cache.readQuery({
          query: RolePermissionsPageQuery
        });

        // update the cache with new user
        cache.writeQuery({
          query: RolePermissionsPageQuery,
          data: {
            ...existingData,
            adminResources: [
              ...(existingData?.adminResources ?? []),
              data.addResource
            ]
          }
        });
      }
    }
  );

  return { addResource, error, data, loading };
};
