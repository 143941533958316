import clsx from "clsx";
import React from "react";

export const styles = {
  "1": ["text-heading-md sm:text-heading-lg"],
  "2": ["text-heading-sm sm:text-heading-md"],
  "3": ["text-heading-sm"]
};

export type HeadingProps = {
  display?: boolean;
  level?: 1 | 2 | 3;
} & React.ComponentPropsWithoutRef<"h1" | "h2" | "h3"> & {
    className?: string;
  };

export const Heading = ({
  className,
  display = false,
  level = 1,
  ...props
}: HeadingProps) => {
  const Element: `h${typeof level}` = `h${level}`;

  if (display) {
    return (
      <h1
        {...props}
        className={clsx(
          className,
          "text-display-sm md:text-display-md lg:text-display-lg text-content-primary"
        )}
      >
        {props.children}
      </h1>
    );
  }

  return (
    <Element
      {...props}
      className={clsx(className, styles[level], "text-content-primary")}
    />
  );
};

export const Subheading = ({
  className,
  level = 2,
  ...props
}: HeadingProps) => {
  const Element: `h${typeof level}` = `h${level}`;

  return (
    <Element
      {...props}
      className={clsx(
        className,
        "text-base/7 font-semibold text-content-primary sm:text-sm/6"
      )}
    />
  );
};
