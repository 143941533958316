import { PerformanceChartFragment } from "@/app/performance/PerformanceChart";
import { PerformanceTemplateFragment } from "@/app/performance/PerformanceTemplate";
import { usePerformanceContext } from "@/app/performance/state/PerformanceProvider";
import { downloadCsv } from "@/app/performance/utils/downloadCSV";
import { FragmentOf, readFragment } from "@/lib/data/graphql";
import { useTranslation } from "@/lib/i18n";
import { formatDate } from "@/lib/utils/date";
import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownMenu
} from "@/ui/form/dropdown";
import { EllipsisHorizontalIcon } from "@heroicons/react/16/solid";
import { ArrowDownTrayIcon, DocumentIcon } from "@heroicons/react/24/outline";

export const PerformanceMoreActions: React.FC<{
  data?: FragmentOf<typeof PerformanceChartFragment>[];
  className?: string;
}> = ({ data, className }) => {
  const { t } = useTranslation();
  const { state } = usePerformanceContext();
  const { filters } = state;
  const { startDate, endDate } = filters;

  if (!state.template) {
    return null;
  }

  const template = readFragment(PerformanceTemplateFragment, state.template);

  const handleDownloadCsv = () => {
    if (data) {
      downloadCsv(data);
    }
  };

  return (
    <div className={className}>
      <Dropdown>
        <DropdownButton plain aria-label={t("users.listing.actions.label")}>
          <EllipsisHorizontalIcon />
        </DropdownButton>
        <DropdownMenu>
          <DropdownItem onClick={handleDownloadCsv} disabled={!data}>
            <ArrowDownTrayIcon />
            {t("performance.moreActions.downloadCsv.label")}
          </DropdownItem>

          <DropdownItem
            href={`/documents?dateFrom=${formatDate(startDate)}&dateTo=${formatDate(endDate)}&plant=${template?.plants.map((p) => p.id).join(",")}`}
          >
            <DocumentIcon />
            {t("performance.moreActions.browseDocs.label")}
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};
