import {
  PaymentSettingsFormFragment,
  PaymentSettingsFormValues
} from "@/app/settings/PaymentSettingsForm";
import { PlantIbansField } from "@/app/settings/PlantIbansField";
import { graphql } from "@/lib/data/graphql";
import { useTranslation } from "@/lib/i18n";
import { Button } from "@/ui/button/button";
import { Alert } from "@/ui/feedback/alert";
import { Dialog, DialogActions, DialogTitle } from "@/ui/feedback/dialog";
import { toaster } from "@/ui/feedback/toaster";
import { Field, Label } from "@/ui/form/fieldset";
import { SwitchControlled } from "@/ui/form/SwitchControlled";
import { Text } from "@/ui/typography/text";
import { useMutation } from "@apollo/client";
import clsx from "clsx";
import React from "react";
import { Control, useForm } from "react-hook-form";

const UpdateIbansMutation = graphql(`
  mutation UpdateIbans($userId: String!, $input: UpdatePlantIbansInput!) {
    updatePlantIbans(userId: $userId, updatePlantIbansInput: $input) {
      plant {
        id
        name
      }
      iban
    }
  }
`);

export type ConfigureIbansDialogFormValues = {
  ibans: {
    plantName: string;
    plantId: number;
    iban: string;
  }[];
};

type ConfigureIbansDialogProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  paymentSettingsFormControl: Control<PaymentSettingsFormValues, any>;
  plantIbans: { plantId: number; plantName: string; iban: string }[];
  onClose: () => void;
  isOpen: boolean;
  enableIndividualIbanEnabled: boolean;
  userId: string;
  userUid: string;
};

export const ConfigureIbansDialog: React.FC<ConfigureIbansDialogProps> = ({
  paymentSettingsFormControl,
  plantIbans,
  onClose,
  isOpen,
  userId,
  userUid,
  enableIndividualIbanEnabled
}) => {
  const { t } = useTranslation();

  const noPlantsAvailable = plantIbans?.length === 0;
  const { control, handleSubmit, reset } =
    useForm<ConfigureIbansDialogFormValues>({
      defaultValues: {
        ibans: plantIbans ?? []
      }
    });

  const [updateIbans, { loading: mutationLoading }] =
    useMutation(UpdateIbansMutation);

  const handleUpdateIbans = (formData: ConfigureIbansDialogFormValues) => {
    if (!enableIndividualIbanEnabled) {
      //don't save ibans as they are not validated if toggle is off
      reset();
      onClose();
      return;
    }

    if (userUid && formData.ibans.length > 0) {
      updateIbans({
        variables: {
          userId: userUid, // TODO(#262): should be id
          input: {
            plantIbans: formData.ibans.map(({ plantId, iban }) => ({
              plantId,
              iban
            }))
          }
        },
        onCompleted: () => {
          toaster.success({
            title: t("settings.payment.configureIbans.actions.submit.success")
          });
          onClose();
        },
        onError: () => {
          toaster.error({
            title: t("settings.payment.configureIbans.actions.submit.error")
          });
        },
        // TODO: test with real data
        update: (cache, { data }) => {
          if (data?.updatePlantIbans) {
            const existingPaymentSettings = cache.readFragment({
              id: `User:${userId}`,
              fragment: PaymentSettingsFormFragment
            });

            if (existingPaymentSettings?.paymentDetails) {
              cache.writeFragment({
                id: `User:${userId}`,
                fragment: PaymentSettingsFormFragment,
                data: {
                  ...existingPaymentSettings,
                  paymentDetails: {
                    ...existingPaymentSettings.paymentDetails,
                    plantIbans: data.updatePlantIbans
                  }
                }
              });
            }
          }
        }
      });
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={() => (noPlantsAvailable ? onClose() : null)}
      size="2xl"
    >
      <DialogTitle>{t("settings.payment.configureIbans.title")}</DialogTitle>

      <Field className="flex gap-3 items-center">
        <SwitchControlled
          id="enableIndividualIban"
          name="enableIndividualIban"
          defaultChecked={true}
          control={paymentSettingsFormControl}
          disabled={noPlantsAvailable}
          optional
        />
        <div className="flex flex-col">
          <Label htmlFor="enableIndividualIban" className="font-medium">
            {t("settings.payment.fields.enableIndividualIban.label")}
          </Label>
          <Text className="text-content-secondary">
            {t("settings.payment.fields.enableIndividualIban.hintText")}
          </Text>
        </div>
      </Field>

      {noPlantsAvailable ? (
        <Alert
          type="warning"
          title={t("settings.payment.configureIbans.noPlants")}
          className="mt-4"
        />
      ) : (
        <form onSubmit={handleSubmit(handleUpdateIbans)} className="mt-6">
          <div className={clsx({ "opacity-40": !enableIndividualIbanEnabled })}>
            <PlantIbansField
              control={control}
              disabled={!enableIndividualIbanEnabled}
            />
          </div>

          <DialogActions>
            <Button outline onClick={onClose}>
              {t("settings.payment.configureIbans.actions.cancel.label")}
            </Button>
            <Button loading={mutationLoading} type="submit">
              {t("settings.payment.configureIbans.actions.submit.label")}
            </Button>
          </DialogActions>
        </form>
      )}
    </Dialog>
  );
};
