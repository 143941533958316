import { useTranslation } from "@/lib/i18n";
import { Divider } from "@/ui/display/divider";
import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownMenu
} from "@/ui/form/dropdown";
import { PageWrapper } from "@/ui/layouts/page-wrapper";
import {
  Sidebar,
  SidebarBody,
  SidebarItem,
  SidebarLabel,
  SidebarSection
} from "@/ui/layouts/sidebar";
import { Heading } from "@/ui/typography/heading";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import {
  CreditCardIcon,
  EnvelopeIcon,
  UserIcon
} from "@heroicons/react/24/outline";
import clsx from "clsx";
import { Outlet, useLocation } from "react-router-dom";

const subNavItems = [
  {
    href: "/settings",
    key: "account",
    icon: UserIcon
  },
  {
    href: "/settings/notifications",
    key: "notifications",
    icon: EnvelopeIcon
  },
  {
    href: "/settings/payment",
    key: "payment",
    icon: CreditCardIcon
  }
] as const;

export const SettingsPage = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const currentActiveKey =
    subNavItems.find((item) => item.href === pathname)?.key || "account";

  return (
    <PageWrapper>
      <div className="flex justify-between items-center gap-2">
        <Heading>{t("settings.title")}</Heading>
        <Dropdown>
          <DropdownButton
            plain
            className="w-52 flex justify-between gap-2 !px-4 !py-3 lg:hidden"
            title={t(`settings.navigation.${currentActiveKey}`)}
          >
            <span className="text-ellipsis overflow-hidden whitespace-nowrap">
              {t(`settings.navigation.${currentActiveKey}`)}
            </span>
            <ChevronDownIcon className="size-5" />
            <span
              className={clsx(
                "absolute inset-x-2 -bottom-0.5 h-0.5 rounded-full bg-zinc-950 dark:bg-white"
              )}
            />
          </DropdownButton>
          <DropdownMenu>
            {subNavItems.map(({ key, href }) => (
              <DropdownItem key={key} href={href}>
                <div className="flex gap-2">
                  {t(`settings.navigation.${key}`)}
                </div>
              </DropdownItem>
            ))}
          </DropdownMenu>
        </Dropdown>
      </div>
      <Divider />

      <div className="flex gap-6">
        <Sidebar className="hidden lg:flex grow-0">
          <SidebarBody>
            <SidebarSection>
              {subNavItems.map(({ href, key, icon: NavIcon }) => (
                <SidebarItem key={key} href={href} current={pathname === href}>
                  <NavIcon className="!fill-none" />
                  <SidebarLabel>{t(`settings.navigation.${key}`)}</SidebarLabel>
                </SidebarItem>
              ))}
            </SidebarSection>
          </SidebarBody>
        </Sidebar>

        <div className="hidden lg:block w-0.25 border-r border-border-primary/70" />

        <Outlet />
      </div>
    </PageWrapper>
  );
};
