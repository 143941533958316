import { cn } from "@/ui/utils";
import { Dialog, DialogPanel } from "@headlessui/react";
import clsx from "clsx";
import { forwardRef, ReactNode } from "react";

interface PerformanceTooltipOverlayProps {
  open: boolean;
  children?: ReactNode;
  className?: string;
}

export const PerformanceTooltipOverlay = forwardRef<
  HTMLElement,
  PerformanceTooltipOverlayProps
>(({ open, children, className }, ref) => {
  return (
    <Dialog open={open} onClose={() => null}>
      <div className={clsx("fixed inset-0 top-0 pt-safe py-safe")}>
        <DialogPanel
          ref={ref}
          className={cn("w-screen", className)}
          style={{
            // 197px is the height of AppHeader, PerformanceTemplateNavigation and PerformanceFilters on mobile screensizes.
            // It is hardcoded to ensure flawless alignment.
            maxHeight: "197px",
            height: "197px"
          }}
        >
          <div
            className={cn(
              "h-full mt-2 mx-2",
              "bg-bg-secondary/30 backdrop-blur-md",
              "border border-border-primary rounded-lg ring-1 ring-border-primary shadow-lgx"
            )}
          >
            {children}
          </div>
        </DialogPanel>
      </div>
    </Dialog>
  );
});

PerformanceTooltipOverlay.displayName = "PerformanceTooltipOverlay";
