import {
  PerformanceTemplateForm,
  PerformanceTemplateFormValues
} from "@/app/performance/template-management/PerformanceTemplateForm";
import { graphql, readFragment } from "@/lib/data/graphql";
import { useTranslation } from "@/lib/i18n";
import { Drawer } from "@/ui/Drawer";
import { Button } from "@/ui/button/button";
import { toaster } from "@/ui/feedback/toaster";
import { useLazyQuery, useMutation } from "@apollo/client";
import { TrashIcon } from "@heroicons/react/16/solid";
import { useEffect } from "react";
import { useForm } from "react-hook-form";

export const PerformanceTemplateFormFragment = graphql(`
  fragment PerformanceTemplateForm on PerformanceTemplate {
    id
    name
    generatedRevenue
    generatedRevenueTarget
    producedElectricity
    producedElectricityTarget
    selfConsumptionRate
    plants {
      id
    }
  }
`);

const Query = graphql(
  `
    query UpdatePerformanceTemplateDrawer($id: Int!) {
      performanceTemplate(performanceTemplateId: $id) {
        ...PerformanceTemplateForm
      }
    }
  `,
  [PerformanceTemplateFormFragment]
);

const UpdatePerformanceTemplateMutation = graphql(
  `
    mutation UpdatePerformanceTemplate(
      $templateId: Int!
      $input: PerformanceTemplateInput!
    ) {
      updatePerformanceTemplate(
        templateId: $templateId
        performanceTemplateInput: $input
      ) {
        ...PerformanceTemplateForm
      }
    }
  `,
  [PerformanceTemplateFormFragment]
);

const DeletePerformanceTemplateMutation = graphql(`
  mutation DeletePerformanceTemplate($id: Int!) {
    deletePerformanceTemplate(performanceTemplateId: $id) {
      id
    }
  }
`);

type Props = {
  templateId: string;
  isOpen: boolean;
  onClose: () => void;
};

export const UpdatePerformanceTemplateDrawer: React.FC<Props> = ({
  templateId,
  isOpen,
  onClose
}) => {
  const { t } = useTranslation();
  const [getTemplate, { data }] = useLazyQuery(Query);

  const [deletePerformanceTemplate, { loading: loadingDelete }] = useMutation(
    DeletePerformanceTemplateMutation
  );
  const [updatePerformanceTemplate, { loading }] = useMutation(
    UpdatePerformanceTemplateMutation
  );

  const form = useForm<PerformanceTemplateFormValues>();
  const {
    formState: { isValid: isFormValid }
  } = form;

  // Everytime the drawer closes, reset the form
  useEffect(() => {
    if (!isOpen) {
      form.reset();
    }
  }, [isOpen, form]);

  // Fetch template data only when drawer opens
  useEffect(() => {
    if (isOpen && templateId) {
      getTemplate({
        variables: { id: parseInt(templateId, 10) }
      });
    }
  }, [isOpen, templateId, getTemplate]);

  // Update form values when data is available
  useEffect(() => {
    if (data?.performanceTemplate) {
      const {
        name,
        generatedRevenue,
        generatedRevenueTarget,
        producedElectricity,
        producedElectricityTarget,
        selfConsumptionRate,
        plants
      } = readFragment(
        PerformanceTemplateFormFragment,
        data.performanceTemplate
      );

      form.reset({
        name,
        generatedRevenue,
        generatedRevenueTarget,
        producedElectricity,
        producedElectricityTarget,
        selfConsumptionRate,
        plants: plants.map((plant) => plant.id.toString()) ?? []
      });
    }
  }, [data, form]);

  const handleSubmit = ({
    name,
    generatedRevenue,
    generatedRevenueTarget,
    producedElectricity,
    producedElectricityTarget,
    selfConsumptionRate,
    plants
  }: PerformanceTemplateFormValues) => {
    updatePerformanceTemplate({
      variables: {
        templateId: parseInt(templateId, 10),
        input: {
          name,
          generatedRevenue,
          generatedRevenueTarget,
          producedElectricity,
          producedElectricityTarget,
          selfConsumptionRate,
          plantIds: plants.map((p) => parseInt(p, 10))
        }
      },
      onCompleted: () => {
        onClose();
      },
      onError: () => {
        toaster.error({
          title: t("performanceTemplates.form.actions.update.error")
        });
        form.setError("root", {
          type: "api",
          message: t("performanceTemplates.form.actions.update.error")
        });
      }
    });
  };

  const handleDelete = () => {
    if (!templateId) return;

    deletePerformanceTemplate({
      variables: { id: parseInt(templateId, 10) },
      onCompleted: () => {
        onClose();
      },
      onError: () => {
        toaster.error({
          title: t("performanceTemplates.form.actions.delete.error")
        });
      }
    });
  };

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      title={t("performanceTemplates.form.actions.update.title")}
      actions={
        <>
          <Button outline onClick={onClose}>
            {t("performanceTemplates.form.actions.close.label")}
          </Button>
          <div className="flex items-center justify-center gap-2">
            <Button onClick={handleDelete} outline loading={loadingDelete}>
              <TrashIcon className="fill-content-danger" />
            </Button>
            <Button
              onClick={form.handleSubmit(handleSubmit)}
              disabled={!isFormValid}
              loading={loading}
            >
              {t("performanceTemplates.form.actions.update.label")}
            </Button>
          </div>
        </>
      }
    >
      <div className="pt-2.5 pb-4">
        <PerformanceTemplateForm onSubmit={handleSubmit} form={form} />
      </div>
    </Drawer>
  );
};
