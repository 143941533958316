import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Transition,
  TransitionChild
} from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import { PropsWithChildren, ReactNode } from "react";

export const Accordion: React.FC<
  PropsWithChildren<{
    defaultOpen?: boolean;
    trigger: ReactNode;
    maxHeightClassName?: string;
  }>
> = ({
  children,
  defaultOpen,
  trigger,
  maxHeightClassName = "max-h-screen"
}) => {
  return (
    <Disclosure as="div" defaultOpen={defaultOpen}>
      {/* custom handling of opening to achieve max-height animation */}
      {({ open }) => (
        <>
          <DisclosureButton
            as="div"
            className="group flex w-full items-center justify-between cursor-pointer"
          >
            {trigger}
            <ChevronDownIcon className="size-5 fill-content-secondary group-hover-[hover]:fill-content-secondary/70 group-data-[open]:rotate-180" />
          </DisclosureButton>
          <Transition show={open}>
            <TransitionChild
              enter="transition-[max-height] duration-200 ease-in"
              enterFrom="max-h-0"
              enterTo={maxHeightClassName}
              leave="transition-[max-height] duration-200 ease-out"
              leaveFrom={maxHeightClassName}
              leaveTo="max-h-0"
            >
              <div className="overflow-hidden">
                <DisclosurePanel
                  transition
                  className="origin-top transition duration-300 data-[closed]:-translate-y-4 data-[closed]:opacity-0"
                >
                  <div className={clsx(maxHeightClassName, "overflow-y-auto")}>
                    {children}
                  </div>
                </DisclosurePanel>
              </div>
            </TransitionChild>
          </Transition>
        </>
      )}
    </Disclosure>
  );
};
