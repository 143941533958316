import { PlantsFilter } from "@/app/plants/PlantsFilter";
import { graphql } from "@/lib/data/graphql";
import { useTranslation } from "@/lib/i18n";
import { Button } from "@/ui/button/button";
import { DatePresetsDropdown } from "@/ui/date/date-presets-dropdown";
import { DateInput } from "@/ui/form/DateInput";
import { Field, Label } from "@/ui/form/fieldset";
import { Input, InputGroup } from "@/ui/form/input";
import { Select } from "@/ui/form/select";
import { TableFilterWrapper } from "@/ui/table/TableFilterWrapper";
import { MagnifyingGlassIcon } from "@heroicons/react/16/solid";
import { ArrowUturnLeftIcon } from "@heroicons/react/24/outline";
import React from "react";

// yup, this is a scalar type, and you cannot expose it as enum (map fce) with values from gql.tada
export type DocumentType = ReturnType<typeof graphql.scalar<"DocumentType">>;
export const DOC_TYPE: DocumentType[] = [
  "BILL",
  "CONTRACT",
  "TAX_RECEIPT"
] as const;

type FiltersFields = {
  plantIds: string[];
  name: string;
  type: string;
  dateFrom: Date | null;
  dateTo: Date | null;
};

type Props = {
  filters: FiltersFields;
  setFilters: React.Dispatch<React.SetStateAction<FiltersFields>>;
  isAnyFilterActive: boolean;
  resetFilters: VoidFunction;
};

// TODO: react-hook-form
export const DocumentsFiltersForm: React.FC<Props> = ({
  filters,
  setFilters,
  isAnyFilterActive,
  resetFilters
}) => {
  const { t } = useTranslation();

  return (
    <TableFilterWrapper>
      <Field className="min-w-36">
        <Label>{t("documents.listing.filters.name.label")}</Label>
        <InputGroup>
          <MagnifyingGlassIcon />
          <Input
            name="search"
            placeholder={t("documents.listing.filters.name.placeholder")}
            aria-label={t("documents.listing.filters.name.placeholder")}
            value={filters.name}
            onChange={(e) =>
              setFilters((prev) => ({ ...prev, name: e.target.value }))
            }
            onClear={() => setFilters((prev) => ({ ...prev, name: "" }))}
          />
        </InputGroup>
      </Field>

      <Field className="min-w-60">
        <Label>{t("documents.listing.filters.plant.label")}</Label>
        <PlantsFilter
          className="mt-2"
          onChange={(items) => {
            setFilters((prev) => ({
              ...prev,
              plantIds: items.map((item) => item.value)
            }));
          }}
          defaultSelectedValues={filters.plantIds}
        />
      </Field>

      <Field className="min-w-36 xl:mr-auto">
        <Label>{t("documents.listing.filters.type.label")}</Label>
        <Select
          name="type"
          value={filters.type}
          onChange={(e) =>
            setFilters((prev) => ({
              ...prev,
              type: e.target.value as DocumentType
            }))
          }
        >
          <option value="all">
            {t("documents.listing.filters.type.options.all")}
          </option>
          {DOC_TYPE.map((Document) => (
            <option key={Document} value={Document}>
              {t(`documents.listing.filters.type.options.${Document}`)}
            </option>
          ))}
        </Select>
      </Field>

      <div className="flex flex-wrap gap-4">
        <Field className="min-w-10">
          <Label>{t("documents.listing.filters.dateFrom.label")}</Label>
          <DateInput
            value={filters.dateFrom}
            onChange={(date) =>
              setFilters((prev) => ({ ...prev, dateFrom: date }))
            }
            className="mt-3"
            prefix={t("documents.listing.filters.dateFrom.prefix")}
            placeholder={t("documents.listing.filters.dateFrom.placeholder")}
          />
        </Field>

        <Field className="min-w-10">
          <Label>{t("documents.listing.filters.dateTo.label")}</Label>
          <DateInput
            value={filters.dateTo}
            minDate={filters.dateFrom ?? undefined}
            onChange={(date) =>
              setFilters((prev) => ({ ...prev, dateTo: date }))
            }
            className="mt-3"
            prefix={t("documents.listing.filters.dateTo.prefix")}
            placeholder={t("documents.listing.filters.dateTo.placeholder")}
          />
        </Field>

        {/* TODO(UX): maybe better styling, position for mobile ? */}
        <Field className="mt-auto">
          <DatePresetsDropdown
            setDateTo={(date) =>
              date && setFilters((prev) => ({ ...prev, dateTo: date }))
            }
            setDateFrom={(date) =>
              date && setFilters((prev) => ({ ...prev, dateFrom: date }))
            }
          />
        </Field>

        <Field className="mt-auto hidden sm:block">
          <Button outline disabled={!isAnyFilterActive} onClick={resetFilters}>
            <ArrowUturnLeftIcon />
            <span className="sm:hidden">
              {t("documents.listing.filters.actions.reset")}
            </span>
          </Button>
        </Field>
      </div>
    </TableFilterWrapper>
  );
};
