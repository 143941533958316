/* eslint-disable */
import { demoRoleAtom } from "@/app/users/atoms/demoRoleAtom";
import { logger } from "@/lib/logger";
import { updateTechnicalLogMessages } from "@/mocks/factories/technicalLogMessagesFactory";
import { Observable, Operation, RequestHandler } from "@apollo/client";
import {
  compareAsc as compareDateAsc,
  compareDesc as compareDateDesc
} from "date-fns";
import { useAtom } from "jotai";
import AdminMeQuery from "./admin-me.json";
import CommunityImpactPage from "./community-impact-page.json";
import DocumentsPageQuery from "./documents.json";
import { performanceValuesFactory } from "./factories/performanceValuesFactory";
import InvestorDashboard from "./investor-dashboard.json";
import InvestorMeQuery from "./investor-me.json";
import MeQuery from "./me.json";
import MyImpactCalculatorCardQuery from "./my-impact-calculation.json";
import NotificationsPageQuery from "./notifications.json";
import OwnerDashboard from "./owner-dashboard.json";
import OwnerMeQuery from "./owner-me.json";
import PerformanceTemplate1Query from "./performance-template-1.json";
import PerformanceTemplate2Query from "./performance-template-2.json";
import PerformanceTemplate3Query from "./performance-template-3.json";
import PerformanceTemplate4Query from "./performance-template-4.json";
import PerformanceTemplate5Query from "./performance-template-5.json";
import PerformancePageQuery from "./performance-templates.json";
import PlantDetail from "./plant-detail.json";
import PlantsFilterQuery from "./plants-filter.json";
import PerformancePlantsFieldQuery from "./plants.json";
import ProjectOverviewQuery from "./project-overviews.json";
import TechnicalLogMessagesList from "./technical-log-messages-list.json";
import UsersPageQuery from "./users.json";

const responses = {
  ProjectOverviewQuery,
  PerformancePageQuery,
  PerformanceTemplate1Query,
  PerformanceTemplate2Query,
  PerformanceTemplate3Query,
  PerformanceTemplate4Query,
  PerformanceTemplate5Query,
  PlantsFilterQuery,
  PerformancePlantsFieldQuery,
  UsersPageQuery,
  MeQuery: {
    data: {
      me: MeQuery
    }
  },
  NotificationSettingsForm: {
    data: {
      me: MeQuery
    }
  },
  AccountSettingsPageQuery: {
    data: {
      me: MeQuery
    }
  },
  ActivateUser: {
    data: {
      activateUser: MeQuery
    }
  },
  DocumentsPageQuery,
  NotificationsPageQuery,
  RegisterDevice: {
    data: {
      registerDevice: {
        success: true,
        message: "Device registered"
      }
    }
  },
  ProducedElectricityCardQuery: {
    data: {
      myImpactProduction: 100
    }
  },
  MyImpactCalculatorCardQuery,
  InvestorDashboard,
  OwnerDashboard,
  PlantsMapDetail: {
    data: {
      plant: InvestorDashboard.data.plants[0]
    }
  },
  ElectricityProductionCardQuery: {
    data: {
      portfolioPerformance: [] as unknown[]
    }
  },
  CommunityImpactPage,
  PerformanceChartContainer: { data: { performancePlants: [] as unknown[] } },
  PlantDetail,
  PlantPerformanceCard: { data: { performancePlants: [] as unknown[] } },
  TechnicalLogMessagesList,
  MessageLogAxis: { data: { technicalLogMessages: {} as unknown } },
  AdjustPerformanceTemplate: {
    data: {
      updatePerformanceTemplate: {} as unknown
    }
  }
};

export const useMockRequest = () => {
  const [demoRole] = useAtom(demoRoleAtom);

  const mockRequest: RequestHandler = (operation: Operation) => {
    return new Observable((observer) => {
      let response =
        responses[operation.operationName as keyof typeof responses];
      let timeout = 500;

      switch (operation.operationName) {
        case "NotificationSettingsPage":
        case "AccountSettingsPage":
        case "PaymentSettings":
        case "Me": {
          timeout = 50;
          response = {
            data: {
              me:
                demoRole === "owner"
                  ? OwnerMeQuery
                  : demoRole === "admin"
                    ? AdminMeQuery
                    : InvestorMeQuery
            }
          };
          break;
        }

        case "DeleteProject": {
          const { plantId, year, quarter } = operation.variables.input;

          const project =
            responses.ProjectOverviewQuery.data.adminProjectsOverview.projects.find(
              (p) => p.plant.id === plantId
            );

          response = {
            data: {
              // @ts-ignore
              deleteProjectBill: {
                ...project,
                name: `${project?.plant.name}_${year}_00${quarter}`,
                status: "NOT_APPROVED"
              }
            }
          };
          break;
        }

        case "RefreshProject": {
          const { plantId, year, quarter, status } = operation.variables.input;

          const project =
            responses.ProjectOverviewQuery.data.adminProjectsOverview.projects.find(
              (p) => p.plant.id === plantId
            );

          response = {
            data: {
              // @ts-ignore
              refreshProjectBill: {
                ...project,
                name: `${project?.plant.name}_${year}_00${quarter}`,
                status: status,
                document: {
                  __typename: "Document",
                  id: "1",
                  name: `${project?.plant.name}_${year}_00${quarter} bill`,
                  type: "PROJECT_DOCUMENT",
                  url: "https://storage.googleapis.com/sol-tmp/sample-invoice.pdf"
                }
              }
            }
          };
          break;
        }

        case "PerformancePlantsField": {
          response = responses.PerformancePlantsFieldQuery;
          break;
        }

        case "SubmitProjectsOverview": {
          const { year, quarter, projects, paymentDate } =
            operation.variables.input;

          response = {
            data: {
              // @ts-ignore
              submitProjectsOverview: {
                ...responses.ProjectOverviewQuery.data.adminProjectsOverview,
                paymentDate: paymentDate,
                projects: projects.map((inputProject: any) => {
                  const existingProject =
                    responses.ProjectOverviewQuery.data.adminProjectsOverview.projects.find(
                      (project: any) =>
                        project.plant.id === inputProject.plantId
                    );
                  return {
                    ...existingProject,
                    name: `${existingProject?.plant.name}_${year}_00${quarter}`,
                    status: inputProject.status,
                    plantId: inputProject.plantId,
                    document: {
                      __typename: "Document",
                      id: "1",
                      name: `${existingProject?.plant.name}_${year}_00${quarter} bill`,
                      type: "PROJECT_DOCUMENT",
                      url: "https://storage.googleapis.com/sol-tmp/sample-invoice.pdf"
                    }
                  };
                })
              }
            }
          };
          break;
        }

        case "ProjectOverviewPage": {
          const { year, quarter } = operation.variables.where;

          response = {
            ...responses.ProjectOverviewQuery,
            data: {
              ...responses.ProjectOverviewQuery.data,
              adminProjectsOverview: {
                ...responses.ProjectOverviewQuery.data.adminProjectsOverview,
                projects:
                  responses.ProjectOverviewQuery.data.adminProjectsOverview.projects.map(
                    (project) => ({
                      ...project,
                      name: `${project.plant.name}_${year} _00${quarter}`
                    })
                  )
              }
            }
          };
          break;
        }

        case "UsersPage": {
          response = responses.UsersPageQuery;
          break;
        }

        case "ActivateUser": {
          response = responses.ActivateUser;
          break;
        }

        case "RegisterDevice": {
          response = responses.RegisterDevice;
          break;
        }

        case "PerformancePage": {
          response = responses.PerformancePageQuery;
          break;
        }

        case "UpdatePerformanceTemplateDrawer":
        case "PerformanceTemplate": {
          const id = operation.variables.id as number;

          const templates = [
            responses.PerformanceTemplate1Query,
            responses.PerformanceTemplate2Query,
            responses.PerformanceTemplate3Query,
            responses.PerformanceTemplate4Query,
            responses.PerformanceTemplate5Query
          ];

          response =
            templates.find((t) => t.data.performanceTemplate.id === id) ??
            templates[0]!;
          break;
        }

        case "PerformanceChartContainer": {
          const { where } = operation.variables;

          const plants = responses.PerformancePlantsFieldQuery.data.plants;

          const performancePlants = where.plantIds.map(
            (id: number, i: number) => ({
              __typename: "PerformancePlant",
              id: i,
              plant: plants.find((p) => p.id === id),
              values: performanceValuesFactory(
                where.startDate,
                where.endDate,
                where.aggregation,
                i
              )
            })
          );

          response = {
            data: {
              performancePlants
            }
          };
          break;
        }

        case "OwnerDashboard": {
          response = OwnerDashboard;
          break;
        }

        case "NotificationsPage":
        case "NotificationsBadge":
        case "NotificationsNavItem": {
          const { take, skip, where, orderBy } = operation.variables;

          const tmp = [...NotificationsPageQuery.data.notifications.items];
          const orderedItems = orderBy?.createdAt
            ? tmp.sort((a, b) => {
                if (orderBy.createdAt === "ASC") {
                  return compareDateAsc(
                    new Date(a.createdAt),
                    new Date(b.createdAt)
                  );
                } else {
                  return compareDateDesc(
                    new Date(a.createdAt),
                    new Date(b.createdAt)
                  );
                }
              })
            : NotificationsPageQuery.data.notifications.items;

          const filteredItemsByText = where?.text
            ? orderedItems.filter((item) =>
                item.text.toLowerCase().includes(where.text.toLowerCase())
              )
            : orderedItems;

          const filteredItemsByReadStatus = where?.read
            ? filteredItemsByText.filter((item) => item.read === where?.read)
            : filteredItemsByText;

          response = {
            ...NotificationsPageQuery,
            data: {
              ...NotificationsPageQuery.data,
              notifications: {
                ...NotificationsPageQuery.data.notifications,
                items: filteredItemsByReadStatus.slice(skip, skip + take),
                totalCount: filteredItemsByReadStatus.length
              }
            }
          };
          break;
        }

        case "DocumentsPage": {
          const { take, skip, where, orderBy } = operation.variables;

          // TODO: not implemented filter by date in mocks

          // TODO: orderBy mocked only for name
          const tmp = [...DocumentsPageQuery.data.documents.items];
          const orderedItems = orderBy?.name
            ? tmp.sort((a, b) => {
                if (orderBy.name === "ASC") {
                  return a.name.localeCompare(b.name);
                } else {
                  return b.name.localeCompare(a.name);
                }
              })
            : DocumentsPageQuery.data.documents.items;

          const filteredItemsByName = where?.name
            ? orderedItems.filter((item) =>
                item.name.toLowerCase().includes(where.name.toLowerCase())
              )
            : orderedItems;

          const filteredItemsByType = filteredItemsByName.filter((item) =>
            where?.type ? item.type === where.type : true
          );

          const filteredItemsByPlant = filteredItemsByType.filter((item) =>
            where?.plantIds?.length
              ? where?.plantIds.includes(item.plant.id.toString())
              : true
          );

          response = {
            ...DocumentsPageQuery,
            data: {
              ...DocumentsPageQuery.data,
              documents: {
                ...DocumentsPageQuery.data.documents,
                items: filteredItemsByPlant.slice(skip, skip + take),
                totalCount: filteredItemsByPlant.length
              }
            }
          };
          break;
        }

        case "ProducedElectricityCard": {
          const { period } = operation.variables as { period: string };
          const periods: Record<string, number> = {
            TOTAL: 100,
            YEAR: 50,
            MONTH: 12,
            TODAY: 1,
            WEEK: 7
          };

          response = {
            data: {
              myImpactProduction: periods[period as string] ?? 0
            }
          };
          break;
        }

        case "MyImpactCalculatorCard": {
          response = responses.MyImpactCalculatorCardQuery;

          break;
        }

        case "ElectricityProductionCard": {
          const { where } = operation.variables;
          response = {
            data: {
              portfolioPerformance: performanceValuesFactory(
                where.startDate,
                where.endDate,
                where.aggregation,
                20
              )
            }
          };

          break;
        }

        case "PlantPerformanceCard": {
          const { where } = operation.variables;

          response = {
            data: {
              performancePlants: [
                {
                  id: where.plantIds[0],
                  values: performanceValuesFactory(
                    where.startDate,
                    where.endDate,
                    where.aggregation,
                    20
                  )
                }
              ]
            }
          };

          break;
        }

        case "TechnicalLogMessagesList":
        case "MessageLogAxis": {
          const { where } = operation.variables;

          const items = updateTechnicalLogMessages(
            responses.TechnicalLogMessagesList.data.technicalLogMessages
              .items as never,
            where.startDate,
            where.endDate
          ).filter((item) => where.plantIds.includes(item.plant.id));

          response = {
            data: {
              technicalLogMessages: {
                __typename: "TechnicalLogMessagePagedList",
                totalCount: items.length,
                items
              }
            }
          };

          break;
        }

        case "AdjustPerformanceTemplate": {
          const id = operation.variables.templateId as number;

          const templates = [
            responses.PerformanceTemplate1Query,
            responses.PerformanceTemplate2Query,
            responses.PerformanceTemplate3Query,
            responses.PerformanceTemplate4Query,
            responses.PerformanceTemplate5Query
          ];

          const template =
            templates.find((t) => t.data.performanceTemplate.id === id) ??
            templates[0]!;

          response = {
            data: {
              updatePerformanceTemplate: template.data.performanceTemplate
            }
          };

          break;
        }

        default:
          if (!response) {
            const error = "Mock not available for following operation";
            logger.error(error, operation);
          }
          break;
      }

      setTimeout(() => {
        observer.next(response);
        observer.complete();
      }, timeout);
    });
  };

  return { mockRequest };
};
