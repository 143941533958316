import { useUpdateReadStatus } from "@/app/notifications/hooks/useUpdateReadStatus";
import {
  NotificationItem,
  NotificationItemFragment
} from "@/app/notifications/NotificationItem";
import { NotificationsBadgeQuery } from "@/app/notifications/NotificationsBadge";
import { NotificationsNavItemQuery } from "@/app/notifications/NotificationsNavItem";
import { useSession } from "@/lib/auth";
import { FragmentOf, readFragment } from "@/lib/data/graphql";
import { useOrderBy } from "@/lib/data/hooks/useOrderBy";
import { useTranslation } from "@/lib/i18n";
import { toaster } from "@/ui/feedback/toaster";
import { Checkbox } from "@/ui/form/checkbox";
import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownMenu
} from "@/ui/form/dropdown";
import { LoadingSpinner } from "@/ui/loading-spinner";
import {
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow
} from "@/ui/table/table";
import { TableHeaderSortable } from "@/ui/table/TableHeaderSortable";
import { TableLoader } from "@/ui/table/TableLoader";
import { Text } from "@/ui/typography/text";
import { EllipsisHorizontalIcon } from "@heroicons/react/16/solid";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";

type Props = {
  notifications?: FragmentOf<typeof NotificationItemFragment>[];
  loading?: boolean;
  orderBy?: Partial<Record<string, "ASC" | "DESC" | null>> | null;
  setOrderBy: (
    updateFn: (
      prev: Partial<Record<string, "ASC" | "DESC" | null>> | null
    ) => Partial<Record<string, "ASC" | "DESC" | null>> | null
  ) => void;
  selectedIds: number[];
  onSingleCheck: (id: number, checked: boolean) => void;
  onMultiCheck: (notifications: { id: number; checked: boolean }[]) => void;
};

export const NotificationsTable: React.FC<Props> = ({
  notifications,
  loading,
  orderBy,
  setOrderBy,
  selectedIds,
  onSingleCheck,
  onMultiCheck
}) => {
  const { t } = useTranslation();
  const { onOrderBy } = useOrderBy(setOrderBy);
  const { updateReadStatus, loading: multiOperationLoading } =
    useUpdateReadStatus();

  const session = useSession();
  const ability = session?.ability;
  const ableToUpdateStatus = ability?.can("updateStatus", "notifications"); // TODO(BE): condition if could read this notification?

  const handleUpdateReadStatus = () => {
    if (selectedIds.length === 0) {
      return;
    }

    updateReadStatus({
      variables: {
        input: {
          notifications: selectedIds.map((notificationId) => ({
            id: notificationId,
            read: true
          }))
        }
      },
      onCompleted: () => {
        toaster.success({
          title: t(
            "notifications.listing.actions.markAsRead.success.description"
          )
        });
        onMultiCheck([]);
      },
      onError: () => {
        toaster.error({
          title: t("notifications.listing.actions.markAsRead.error.description")
        });
      },
      refetchQueries: [NotificationsBadgeQuery, NotificationsNavItemQuery]
    });
  };

  if (!loading && (!notifications || notifications.length === 0)) {
    return <Text>{t("notifications.listing.noData")}</Text>;
  }

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableHeader className="max-w-10">
            <Checkbox
              onChange={(checked) => {
                const notificationsWihStatus =
                  notifications?.map((not) => {
                    const { id } = readFragment(NotificationItemFragment, not);
                    return { id, checked };
                  }) ?? [];
                onMultiCheck(notificationsWihStatus);
              }}
            />
          </TableHeader>
          <TableHeaderSortable
            column="createdAt"
            label={t("notifications.listing.fields.createdAt")}
            orderBy={orderBy}
            onOrderBy={onOrderBy}
          />
          <TableHeader>{t("notifications.listing.fields.text")}</TableHeader>
          <TableHeader>
            <Dropdown>
              <DropdownButton
                plain
                aria-label={t("users.listing.actions.label")}
                disabled={!ableToUpdateStatus}
                className={clsx({ "cursor-not-allowed": !ableToUpdateStatus })}
                onClick={
                  ((e) =>
                    e.stopPropagation()) as React.MouseEventHandler<HTMLButtonElement>
                }
              >
                <EllipsisHorizontalIcon />
              </DropdownButton>
              <DropdownMenu>
                {ableToUpdateStatus ? (
                  <DropdownItem
                    onClick={handleUpdateReadStatus}
                    disabled={selectedIds.length === 0}
                    className={clsx({
                      "pointer-events-none opacity-50": multiOperationLoading
                    })}
                  >
                    {loading ? (
                      <div className="pr-2">
                        <LoadingSpinner size="sm" />
                      </div>
                    ) : (
                      <CheckCircleIcon />
                    )}
                    {t(
                      "notifications.listing.actions.markSelectedAsRead.label"
                    )}
                  </DropdownItem>
                ) : null}
              </DropdownMenu>
            </Dropdown>
          </TableHeader>
        </TableRow>
      </TableHead>

      <TableBody
        className={loading ? "opacity-40 pointer-events-none" : undefined}
      >
        {notifications ? (
          notifications.map((notification) => {
            const { id } = readFragment(NotificationItemFragment, notification);
            return (
              <NotificationItem
                key={id}
                data={notification}
                onCheck={onSingleCheck}
                checked={selectedIds.includes(id)}
              />
            );
          })
        ) : loading ? (
          <TableLoader colSpan={5} />
        ) : null}
      </TableBody>
    </Table>
  );
};
