import { FragmentOf, graphql, readFragment } from "@/lib/data/graphql";
import { useTranslation } from "@/lib/i18n";
import { Avatar } from "@/ui/avatar";
import { Card, CardBody, CardHeader } from "@/ui/display/card";
import { Divider } from "@/ui/display/divider";
import { Section, SectionBody, SectionHeader } from "@/ui/display/section";
import { Alert } from "@/ui/feedback/alert";
import { Flash } from "@carbon/icons-react";
import clsx from "clsx";
import { FC } from "react";

export const PlantDetailsCardFragment = graphql(`
  fragment PlantDetailsCard on Plant {
    id
    name
    photoUrl
    panelsTotal
    panelsOwned
    peakPower
    status
    todayPerformance {
      id
      pYield
      pConsumption
      pOwnConsumption
      pOwnConsumptionPercentage
    }
    allTimePerformance {
      id
      pYield
      pConsumption
      pOwnConsumption
      pOwnConsumptionPercentage
    }
  }
`);

export const PlantDetailsCard: FC<{
  plant?: FragmentOf<typeof PlantDetailsCardFragment>;
}> = ({ plant: data }) => {
  const { t } = useTranslation();
  const plant = readFragment(PlantDetailsCardFragment, data);

  if (!plant) {
    return (
      <Card className="bg-bg-white">
        <CardBody>
          <Alert title={t("error.generic.description")} type="warning" />
        </CardBody>
      </Card>
    );
  }

  const { todayPerformance, allTimePerformance } = plant;

  return (
    <Card className="dark:bg-black">
      <CardHeader
        title={plant.name}
        end={
          <PlantStatus
            className="max-sm:hidden"
            label={t("plantDetail.status")}
            status={plant.status}
          />
        }
      />
      <CardBody>
        <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-6 sm:gap-8">
          {/* Avatar Column */}
          <div className="flex flex-col justify-between gap-4">
            <div className="flex sm:m-auto gap-4">
              <Avatar
                src={plant.photoUrl}
                className="size-20 sm:size-32 lg:size-40"
              />
              <div className="my-auto sm:hidden">
                <PlantStatus
                  label={t("plantDetail.status")}
                  status={plant.status}
                />
              </div>
            </div>
          </div>

          {/* Plant Details Column */}
          <Section>
            <SectionHeader>
              {t("plantDetail.cards.details.sections.data.title")}
            </SectionHeader>
            <Divider className="xl:hidden" />
            <SectionBody>
              <div className="space-y-4">
                <PerformanceField
                  label={t("plantDetail.cards.details.sections.data.peakPower")}
                  value={plant.peakPower}
                  units={t("common.units.kWh")}
                />
                <PerformanceField
                  label={t("plantDetail.cards.details.sections.data.panels")}
                  value={`${plant.panelsOwned} / ${plant.panelsTotal}`}
                />
              </div>
            </SectionBody>
          </Section>

          {/* Today's Performance Column */}
          <Section>
            <SectionHeader icon={<Flash />}>{t("common.today")}</SectionHeader>
            <Divider className="xl:hidden" />
            <SectionBody>
              <div className="space-y-4 xl:ml-1">
                <PerformanceField
                  label={t(
                    "plantDetail.cards.details.sections.todayPerformance.production"
                  )}
                  value={todayPerformance.pYield.toFixed(2)}
                  units={t("common.units.kWh")}
                />
                <PerformanceField
                  label={t(
                    "plantDetail.cards.details.sections.todayPerformance.consumption"
                  )}
                  value={todayPerformance.pConsumption.toFixed(2)}
                  units={t("common.units.kWh")}
                />
                <PerformanceField
                  label={t(
                    "plantDetail.cards.details.sections.todayPerformance.ownConsumption"
                  )}
                  value={todayPerformance.pOwnConsumption.toFixed(2)}
                  units={`${t("common.units.kWh")} (${todayPerformance.pOwnConsumptionPercentage}%)`}
                />
              </div>
            </SectionBody>
          </Section>

          {/* All-time Performance Column */}
          <Section>
            <SectionHeader icon={<Flash />}>
              {t("plantDetail.cards.details.sections.allTimePerformance.title")}
            </SectionHeader>
            <Divider className="xl:hidden" />
            <SectionBody>
              <div className="space-y-4 xl:ml-1">
                <PerformanceField
                  label={t(
                    "plantDetail.cards.details.sections.allTimePerformance.production"
                  )}
                  value={allTimePerformance.pYield.toFixed(2)}
                  units={t("common.units.kWh")}
                />
                <PerformanceField
                  label={t(
                    "plantDetail.cards.details.sections.allTimePerformance.consumption"
                  )}
                  value={allTimePerformance.pConsumption.toFixed(2)}
                  units={t("common.units.kWh")}
                />
                <PerformanceField
                  label={t(
                    "plantDetail.cards.details.sections.allTimePerformance.ownConsumption"
                  )}
                  value={allTimePerformance.pOwnConsumption.toFixed(2)}
                  units={`${t("common.units.kWh")} (${allTimePerformance.pOwnConsumptionPercentage}%)`}
                />
              </div>
            </SectionBody>
          </Section>
        </div>
      </CardBody>
    </Card>
  );
};

const PerformanceField: FC<{
  label: string;
  value: React.ReactNode;
  units?: string;
}> = ({ label, value, units }) => {
  return (
    <div className="space-y-1">
      <div className="text-content-secondary text-sm">{label}</div>
      <div className="font-medium text-sm">
        {value} {units ? units : null}
      </div>
    </div>
  );
};

const PlantStatus: FC<{
  status: ReturnType<typeof graphql.scalar<"PlantStatus">>;
  label?: string;
  className?: string;
}> = ({ status, label, className }) => {
  const { t } = useTranslation();

  const getStatusClass = () => {
    switch (status) {
      case "OK":
        return "bg-green-500";
      case "OFFLINE":
        return "bg-red-500";
      default:
        return "bg-content-secondary";
    }
  };

  return (
    <div className={clsx("space-y-1", className)}>
      {label ? (
        <div className="text-content-secondary text-sm">{label}</div>
      ) : null}
      <div className="flex items-center gap-2">
        <div className={`size-2 rounded-full ${getStatusClass()}`} />
        <span className="text-sm">{t(`enums.plantStatus.${status}`)}</span>
      </div>
    </div>
  );
};
