import { useTranslation } from "@/lib/i18n";
import { APIProvider } from "@vis.gl/react-google-maps";
import { FC, PropsWithChildren } from "react";

export const MapProvider: FC<PropsWithChildren> = ({ children }) => {
  const { i18n } = useTranslation();

  return (
    <APIProvider
      apiKey={import.meta.env.VITE_GOOGLE_MAPS_API_KEY}
      language={i18n.language}
    >
      {children}
    </APIProvider>
  );
};
