import {
  DOC_TYPE,
  DocumentsFiltersForm,
  DocumentType
} from "@/app/documents/DocumentsFiltersForm";
import { graphql } from "@/lib/data/graphql";
import { useTranslation } from "@/lib/i18n";
import { formatDate, getDateISO, parseDateFromUrl } from "@/lib/utils/date";
import { ActionDialogToggle } from "@/ui/action-dialog/ActionDialogToggle";
import { Button } from "@/ui/button/button";
import { ResponsiveWrapper } from "@/ui/layouts/responsive-wrapper";
import { compareAsc } from "date-fns";
import React, { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

type DocumentsWhereInput = ReturnType<
  typeof graphql.scalar<"DocumentsWhereInput">
>;
export type SetDocumentsWhere = (where: DocumentsWhereInput) => void;

type Props = {
  setWhere: SetDocumentsWhere;
};

export const DocumentsFilters: React.FC<Props> = ({ setWhere }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();

  const [filters, setFilters] = useState({
    plantIds: searchParams.get("plant")?.split(",") ?? [],
    name: searchParams.get("name") ?? "",
    type: searchParams.get("type") ?? "",
    dateFrom: parseDateFromUrl(searchParams, "dateFrom"),
    dateTo: parseDateFromUrl(searchParams, "dateTo")
  });

  // sync with url
  useEffect(() => {
    const updatedParams = new URLSearchParams(searchParams);
    const filtersToParams = {
      plant: filters.plantIds.join(","),
      name: filters.name,
      type: filters.type,
      dateFrom: filters.dateFrom ? formatDate(filters.dateFrom) : null,
      dateTo: filters.dateTo ? formatDate(filters.dateTo) : null
    };

    // Iterate through all filters and update search params
    Object.entries(filtersToParams).forEach(([key, value]) => {
      if (!value || value === "all") {
        updatedParams.delete(key);
      } else if (value) {
        updatedParams.set(key, value.toString());
      }
    });

    setSearchParams(updatedParams);
  }, [filters, searchParams, setSearchParams]);

  const isAnyFilterActive = Boolean(
    filters.name ||
      filters.plantIds.length > 0 ||
      filters.type !== "all" ||
      filters.dateFrom ||
      filters.dateTo
  );

  const resetFilters = useCallback(() => {
    setFilters({
      plantIds: [],
      type: "all",
      name: "",
      dateFrom: null,
      dateTo: null
    });
  }, []);

  useEffect(() => {
    if (
      filters.dateFrom &&
      filters.dateTo &&
      compareAsc(filters.dateFrom, filters.dateTo) === 1
    ) {
      setFilters((prev) => ({ ...prev, dateTo: null }));
    }
  }, [filters.dateFrom, filters.dateTo]);

  // update query vars
  useEffect(() => {
    const { name, plantIds, type, dateFrom, dateTo } = filters;

    const timerId = setTimeout(() => {
      setWhere({
        dateFrom: getDateISO(dateFrom),
        dateTo: getDateISO(dateTo),
        name: name || null,
        plantIds: plantIds.length > 0 ? plantIds : null,
        type:
          type === "all"
            ? null
            : DOC_TYPE.includes(type as DocumentType)
              ? (type as DocumentType)
              : null
      });
    }, 200);
    return () => clearTimeout(timerId);
  }, [filters, setWhere]);

  const ActionDialog = (
    <ActionDialogToggle
      renderTriggerButton={(openModal) => (
        <Button onClick={openModal} outline>
          {t("documents.listing.filters.actions.open")}
        </Button>
      )}
      title={t("documents.listing.filters.title")}
      renderActions={(closeModal) => (
        <>
          <Button
            outline
            onClick={() => {
              resetFilters();
              closeModal();
            }}
          >
            {t("documents.listing.filters.actions.reset")}
          </Button>
          <Button onClick={closeModal}>
            {t("documents.listing.filters.actions.showResults")}
          </Button>
        </>
      )}
    ></ActionDialogToggle>
  );

  return (
    <ResponsiveWrapper wrapper={ActionDialog}>
      <DocumentsFiltersForm
        filters={filters}
        setFilters={setFilters}
        isAnyFilterActive={isAnyFilterActive}
        resetFilters={resetFilters}
      />
    </ResponsiveWrapper>
  );
};
