import {
  PerformanceTemplatesNavigation,
  PerformanceTemplatesNavigationFragment,
  URL_PERF_TEMPLATE_KEY
} from "@/app/performance/PerformanceTemplatesNavigation";
import { graphql } from "@/lib/data/graphql";
import { Divider } from "@/ui/display/divider";
import { PageWrapper } from "@/ui/layouts/page-wrapper";
import { Heading } from "@/ui/typography/heading";
import { useQuery } from "@apollo/client";
import { t } from "i18next";
import { useSearchParams } from "react-router-dom";
import { PerformanceTemplate } from "./PerformanceTemplate";
import { PerformanceProvider } from "./state/PerformanceProvider";

export const PerformancePageQuery = graphql(
  `
    query PerformancePage {
      performanceTemplates {
        ...PerformanceTemplatesNavigation
      }
    }
  `,
  [PerformanceTemplatesNavigationFragment]
);

export const PerformancePage = () => {
  const { data, loading } = useQuery(PerformancePageQuery);

  const [params] = useSearchParams();
  const activeTemplateId = params.get(URL_PERF_TEMPLATE_KEY);

  return (
    <PageWrapper className="p-0">
      <Heading className="max-md:hidden">{t("performance.title")}</Heading>
      <Divider className="max-md:hidden" />
      <PerformanceProvider>
        <div className="flex flex-col h-full">
          <PerformanceTemplatesNavigation
            data={data?.performanceTemplates}
            loading={loading}
          />
          {activeTemplateId ? (
            <PerformanceTemplate templateId={activeTemplateId} />
          ) : null}
        </div>
      </PerformanceProvider>
    </PageWrapper>
  );
};
