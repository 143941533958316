import { graphql } from "@/lib/data/graphql";
import { getDateISO } from "@/lib/utils/date";
import { ActionDialogToggle } from "@/ui/action-dialog/ActionDialogToggle";
import { Button } from "@/ui/button/button";
import { DateTimeRangeForm } from "@/ui/date/date-time-range-form";
import { DateInput } from "@/ui/form/DateInput";
import { Field, Label } from "@/ui/form/fieldset";
import { FabButton } from "@/ui/interactions/fab-button";
import { ResponsiveWrapper } from "@/ui/layouts/responsive-wrapper";
import { Capacitor } from "@capacitor/core";
import { Filter } from "@carbon/icons-react";
import clsx from "clsx";
import { t } from "i18next";
import { useCallback, useEffect, useState } from "react";
import { PlantsFilter } from "../plants/PlantsFilter";
import { TechnicalLogMessagesTypesListbox } from "./TechnicalLogMessagesTypesListbox";

export type TechnicalLogMessaagesListFilterWhereInput = ReturnType<
  typeof graphql.scalar<"TechnicalLogMessagesWhereInput">
>;

type Props = {
  defaultFilter?: TechnicalLogMessaagesListFilterWhereInput;
  onFilterChange?: (where: TechnicalLogMessaagesListFilterWhereInput) => void;
};

export const TechnicalLogMessagesListFilter: React.FC<Props> = ({
  defaultFilter,
  onFilterChange
}) => {
  const [filters, setFilters] =
    useState<TechnicalLogMessaagesListFilterWhereInput>({
      plantIds: defaultFilter?.plantIds ?? [],
      type: defaultFilter?.type ?? null,
      startDate: defaultFilter?.startDate,
      endDate: defaultFilter?.endDate
    });

  // update query vars
  useEffect(() => {
    const { plantIds, startDate, endDate } = filters;

    const timeout = setTimeout(() => {
      onFilterChange?.({
        startDate,
        endDate,
        plantIds
      });
    }, 200);
    return () => clearTimeout(timeout);
  }, [filters, onFilterChange]);

  const reset = useCallback(() => {
    setFilters({
      plantIds: [],
      type: null,
      startDate: null,
      endDate: null
    });
  }, []);

  const ActionDialog = (
    <ActionDialogToggle
      renderTriggerButton={(openModal) => (
        <FabButton onClick={openModal} bottom="3.5rem">
          <Filter className="text-xl h-5 w-5" />
        </FabButton>
      )}
      title={t("technicalLogMessages.filter.title")}
      renderActions={(closeModal) => (
        <>
          <Button
            outline
            onClick={() => {
              reset();
              closeModal();
            }}
          >
            {t("technicalLogMessages.filter.reset")}
          </Button>
          <Button onClick={closeModal}>
            {t("technicalLogMessages.filter.apply")}
          </Button>
        </>
      )}
      // Workaround: iOS has a bug where the datepicker is clipped when the action dialog is open
      bodyClassName={clsx({
        "[--footer-offset:56px] max-md:[&_.react-datepicker]:bottom-[calc(env(safe-area-inset-bottom)+var(--footer-offset))]":
          Capacitor.getPlatform() === "ios"
      })}
    />
  );

  return (
    <ResponsiveWrapper wrapper={ActionDialog}>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 sm:gap-x-3 sm:gap-y-0 w-full">
        <Field>
          <Label className="sm:hidden">
            {t("technicalLogMessages.filter.fields.solarPlants")}
          </Label>
          <PlantsFilter
            onChange={(items) => {
              setFilters((prev) => ({
                ...prev,
                plantIds: items.map((item) => Number(item.value))
              }));
            }}
            defaultSelectedValues={filters.plantIds?.map(String) ?? []}
          />
        </Field>
        <Field>
          <Label className="sm:hidden">
            {t("technicalLogMessages.filter.fields.type")}
          </Label>
          <TechnicalLogMessagesTypesListbox
            value={filters.type}
            onChange={(value) => {
              setFilters((prev) => ({ ...prev, type: value }));
            }}
          />
        </Field>

        <div className="max-md:hidden mt-2">
          <Field>
            <DateInput
              showTimeInput
              value={filters.startDate ? new Date(filters.startDate) : null}
              onChange={(date) =>
                setFilters((prev) => ({ ...prev, startDate: getDateISO(date) }))
              }
              dateFormat="dd.MM.yyyy HH:mm"
            />
          </Field>
        </div>
        <div className="max-md:hidden mt-2">
          <Field>
            <DateInput
              minDate={
                filters.startDate ? new Date(filters.startDate) : undefined
              }
              showTimeInput
              value={filters.endDate ? new Date(filters.endDate) : null}
              onChange={(date) =>
                setFilters((prev) => ({ ...prev, endDate: getDateISO(date) }))
              }
              dateFormat="dd.MM.yyyy HH:mm"
            />
          </Field>
        </div>

        <div className="md:hidden">
          <DateTimeRangeForm
            startDate={new Date(filters.startDate ?? "")}
            endDate={new Date(filters.endDate ?? "")}
            onChange={(start, end) => {
              setFilters((prev) => ({
                ...prev,
                startDate: getDateISO(start),
                endDate: getDateISO(end)
              }));
            }}
            startDateLabel={t("technicalLogMessages.filter.fields.dateFrom")}
            endDateLabel={t("technicalLogMessages.filter.fields.dateTo")}
            className="!mt-0"
          />
        </div>
      </div>
    </ResponsiveWrapper>
  );
};
