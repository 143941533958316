import { readFragment } from "@/lib/data/graphql";
import {
  addMinutes,
  endOfDay,
  endOfHour,
  endOfMonth,
  format,
  formatISO,
  startOfDay,
  startOfHour,
  startOfMonth
} from "date-fns";
import { AggregationType } from "../utils/format-utilities";
import {
  MessageLogAxisItem,
  MessageLogAxisItemFragment
} from "./useMessageLogAxisQuery";

/**
 * Returns a range of dates for a given date and aggregation type. This used when clicked on MessageLogTick,
 */
export function getRangeForFilter(
  date: string,
  aggregation: AggregationType
): {
  startDate: Date;
  endDate: Date;
  date: string;
} {
  switch (aggregation) {
    case "FIVE_MINUTES":
    case "FIFTEEN_MINUTES": {
      const dateObj = new Date(date);
      const minutes = dateObj.getMinutes();
      const startMinutes = minutes < 30 ? 0 : 30;
      const startDate = new Date(dateObj);
      startDate.setMinutes(startMinutes, 0, 0);
      const endDate = addMinutes(startDate, 30);
      return { startDate, endDate, date };
    }
    case "HOUR": {
      const startDate = startOfHour(new Date(date));
      const endDate = endOfHour(startDate);
      return { startDate, endDate, date };
    }
    case "DAY": {
      const startDate = startOfDay(new Date(date));
      const endDate = endOfDay(startDate);
      return { startDate, endDate, date };
    }
    case "MONTH": {
      const startDate = startOfMonth(new Date(date));
      const endDate = endOfMonth(startDate);
      return { startDate, endDate, date };
    }
    default:
      throw Error(`Unknown aggregation type: ${aggregation}`);
  }
}

/**
 * Groups dates by time periods based on the aggregation type.
 * Returns a record where keys are the ISO string of each period's start date
 * and values are arrays of dates that fall within that period.
 */
export function group(
  dates: string[],
  aggregation: AggregationType
): Record<string, string[]> {
  if (!dates || dates.length === 0) return {};

  const result: Record<string, string[]> = {};

  // First sort dates chronologically
  const sortedDates = [...dates].sort();

  sortedDates.forEach((dateStr) => {
    const date = new Date(dateStr);

    // Calculate the group start date based on aggregation
    let groupStartDate: Date;
    switch (aggregation) {
      case "FIVE_MINUTES":
      case "FIFTEEN_MINUTES": {
        const minutes = date.getMinutes();
        const startMinutes = minutes < 30 ? 0 : 30;
        groupStartDate = new Date(date);
        groupStartDate.setUTCMinutes(startMinutes, 0, 0);
        break;
      }
      case "HOUR":
        groupStartDate = startOfHour(date);
        break;
      case "DAY":
        groupStartDate = startOfDay(date);
        break;
      case "MONTH":
        groupStartDate = startOfMonth(date);
        break;
      default:
        throw Error(`Unknown aggregation type: ${aggregation}`);
    }

    // Use ISO string as key
    const groupKey = formatISO(groupStartDate);

    // Add date to its group
    if (!result[groupKey]) {
      result[groupKey] = [];
    }
    result[groupKey].push(dateStr);
  });

  return result;
}

export function formatDrawerTitle(
  startDate: Date,
  endDate: Date,
  aggregation: AggregationType
): string {
  switch (aggregation) {
    case "FIVE_MINUTES":
    case "FIFTEEN_MINUTES":
    case "HOUR":
      // example: 1.12.2025 12:00 - 12:30
      return `${format(startDate, "d.M.yyyy HH:mm")} - ${format(endDate, "HH:mm")}`;
    case "DAY":
      // example: Sat, 1.12.2025
      return format(startDate, "EEE, d.M.yyyy");
    case "MONTH":
      // example: January 2025
      return format(startDate, "MMMM yyyy");
    default:
      throw Error(`Unknown aggregation type: ${aggregation}`);
  }
}

export function generateTicks(
  messages: MessageLogAxisItem[] | undefined,
  aggregation: AggregationType
): string[] {
  // Array with empty string is a trick to make recharts to render no ticks at all.
  // Empty array or null value would populate x axis with so many ticks...
  if (!messages || messages.length === 0) return [""];

  const dates = messages.map((message) => {
    const { createdAt } = readFragment(MessageLogAxisItemFragment, message);

    return createdAt;
  });

  const groupedDates = group(dates, aggregation);

  const ticks = Object.keys(groupedDates);

  return ticks;
}
