import { Heading } from "@/ui/typography/heading";
import clsx from "clsx";
import React from "react";

type BaseCardProps = {
  className?: string;
  children: React.ReactNode;
};

export type CardProps = {
  /**
   * If true, the card will have a fancy background.
   */
  fancyBackground?: boolean;
} & BaseCardProps;

export const Card: React.FC<CardProps> = ({
  fancyBackground,
  className,
  children
}) => {
  return (
    <div
      className={clsx(
        "w-full rounded-2xl border border-border-primary",
        fancyBackground &&
          [
            "bg-[linear-gradient(90deg,_var(--color-gradient-primary)_0%,_#ffffff_35%)]",
            "dark:bg-[linear-gradient(90deg,_var(--color-gradient-primary)_0%,_#000000_35%)]"
          ].join(" "),
        className
      )}
    >
      {children}
    </div>
  );
};

export type CardHeaderProps = {
  title: string | React.ReactNode;
  end?: React.ReactNode;
  border?: boolean;
  headingLevel?: 1 | 2 | 3;
  wrapperClassName?: string;
} & Omit<BaseCardProps, "children">;

export const CardHeader: React.FC<CardHeaderProps> = ({
  title,
  end,
  border = true,
  headingLevel = 2,
  wrapperClassName,
  className
}) => {
  return (
    <div
      data-slot="header"
      className={clsx(
        "flex flex-col p-4 sm:p-6 [&+[data-slot=body]]:pt-0",
        className
      )}
    >
      <div
        className={clsx(
          "flex max-sm:gap-4 items-center justify-between pb-4 sm:pb-6",
          {
            "border-b border-border-primary": border
          },
          wrapperClassName
        )}
      >
        {typeof title === "string" ? (
          <Heading level={headingLevel} className="tracking-wider">
            {title}
          </Heading>
        ) : (
          title
        )}
        {end ? end : null}
      </div>
    </div>
  );
};

export const CardBody: React.FC<BaseCardProps> = ({ className, children }) => {
  return (
    <div
      data-slot="body"
      className={clsx(
        "p-4 sm:p-6 [&:has(+[data-slot=footer])]:pb-0",
        className
      )}
    >
      {children}
    </div>
  );
};

export const CardFooter: React.FC<BaseCardProps> = ({
  className,
  children
}) => {
  return (
    <div data-slot="footer" className={clsx("p-4 sm:p-6", className)}>
      <div className="flex justify-between gap-4">{children}</div>
    </div>
  );
};
