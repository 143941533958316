import clsx from "clsx";
import { FC } from "react";

export type YAxisLabelProps = {
  className?: string;
  children?: React.ReactNode;
} & {
  viewBox?: { x?: number; y?: number; height?: number; width?: number };
  offset?: number;
};

export const YAxisLabel: FC<YAxisLabelProps> = ({
  className,
  children,
  ...props
}) => {
  const { viewBox } = props;

  // https://gaurav5430.medium.com/exploring-recharts-using-foreignobject-to-render-custom-html-5c6b75d6207e
  return (
    <foreignObject x={Math.abs(viewBox?.x ?? 0)} className="overflow-visible">
      <div
        className={clsx(
          "w-fit",
          "text-[10px] leading-none text-content-secondary text-center",
          className
        )}
      >
        {children}
      </div>
    </foreignObject>
  );
};
