import { useTranslation } from "@/lib/i18n";
import { ActionDialogToggle } from "@/ui/action-dialog/ActionDialogToggle";
import { Button } from "@/ui/button/button";
import { DateTimeRangeForm } from "@/ui/date/date-time-range-form";
import { TimeScaleKeyType } from "@/ui/date/date-utils";
import { Accordion } from "@/ui/display/accordion";
import { FunnelIcon } from "@heroicons/react/24/outline";
import { DatePresets } from "../../ui/date/date-presets";
import { PerformanceFilterState } from "./PerformanceFilters";

interface Props {
  startDate: Date;
  endDate: Date;
  quickSelectorKey: TimeScaleKeyType;
  onPresetChange: (value: PerformanceFilterState) => void;
  onDateRangeChange: (startDate: Date, endDate: Date) => void;
}

export const PerformanceFiltersActionDialog: React.FC<Props> = ({
  startDate,
  endDate,
  quickSelectorKey,
  onPresetChange,
  onDateRangeChange
}) => {
  const { t } = useTranslation();

  return (
    <ActionDialogToggle
      renderTriggerButton={(openModal) => (
        <Button onClick={openModal} outline>
          <FunnelIcon />
        </Button>
      )}
      title={t("performance.filters.actionDialogTitle")}
    >
      <div className="flex flex-col gap-6 pb-2">
        <DatePresets
          activeSelector={quickSelectorKey}
          onChange={onPresetChange}
          showMorePresets={false}
        />

        <Accordion
          trigger={
            <span className="text-content-secondary">
              {t("performance.filters.customDateRange")}
            </span>
          }
        >
          <DateTimeRangeForm
            startDate={startDate}
            endDate={endDate}
            onChange={(start, end) => onDateRangeChange(start, end)}
          />
        </Accordion>
      </div>
    </ActionDialogToggle>
  );
};
