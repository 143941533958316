import { usePerformanceContext } from "@/app/performance/state/PerformanceProvider";
import { getFormatters } from "@/app/performance/utils/format-utilities";
import { DateNavigation } from "@/ui/date/date-navigation";
import { DatePresets } from "@/ui/date/date-presets";
import { DateTimeRange } from "@/ui/date/date-time-range";
import {
  getIntervalDates,
  navigateInterval,
  TimeScaleKeyType
} from "@/ui/date/date-utils";
import { endOfDay, startOfDay } from "date-fns";
import React, { useEffect, useState } from "react";
import { PerformanceFiltersActionDialog } from "./PerformanceFiltersActionDialog";

export type PerformanceFilterState = {
  startDate: Date;
  endDate: Date;
  quickSelectorKey: TimeScaleKeyType;
};

export type PerformanceFiltersProps = {
  onFiltersChange?: (filters: PerformanceFilterState) => void;
};

export const PerformanceFilters: React.FC<PerformanceFiltersProps> = ({
  onFiltersChange
}) => {
  const { dispatch } = usePerformanceContext();

  const [filters, setFilters] = useState<PerformanceFilterState>({
    startDate: startOfDay(new Date()),
    endDate: endOfDay(new Date()),
    quickSelectorKey: "day"
  });

  useEffect(() => {
    dispatch({
      type: "SET_FILTERS",
      payload: {
        startDate: filters.startDate,
        endDate: filters.endDate
      }
    });
    dispatch({
      type: "SET_FORMATTERS",
      payload: {
        ...getFormatters(filters.startDate, filters.endDate)
      }
    });

    onFiltersChange?.(filters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, filters]);

  const handleDateNavigate = (direction: "back" | "forward") => {
    const newDate = navigateInterval(
      direction,
      filters.startDate,
      filters.quickSelectorKey
    );
    const dates = getIntervalDates(newDate, filters.quickSelectorKey);
    setFilters((prev) => ({
      ...prev,
      startDate: dates.start,
      endDate: dates.end
    }));
  };

  const handlePresetChange = (value: PerformanceFilterState) => {
    setFilters((prev) => ({ ...prev, ...value }));
  };

  const handleCustomDateRangeChange = (startDate: Date, endDate: Date) => {
    setFilters({ startDate, endDate, quickSelectorKey: "custom" });
  };

  return (
    <>
      <div className="flex flex-row w-full sm:flex-row sm:items-center gap-4">
        <DatePresets
          className="max-md:hidden"
          activeSelector={filters.quickSelectorKey}
          onChange={handlePresetChange}
        />

        <div className="flex w-full md:w-auto md:ml-2 justify-between gap-2">
          <DateNavigation
            {...filters}
            onNavigate={handleDateNavigate}
            disabled={filters.quickSelectorKey === "custom"}
          />

          <div className="md:hidden">
            <PerformanceFiltersActionDialog
              {...filters}
              onPresetChange={handlePresetChange}
              onDateRangeChange={(startDate, endDate) =>
                setFilters({
                  startDate,
                  endDate,
                  quickSelectorKey: "custom"
                })
              }
            />
          </div>
        </div>

        <span className="hidden md:block self-stretch flex-1 max-w-0.5 my-2 bg-border-primary" />

        <DateTimeRange
          className="max-md:hidden"
          startDate={filters.startDate}
          endDate={filters.endDate}
          onChange={handleCustomDateRangeChange}
        />
      </div>
    </>
  );
};
