import { useTranslation } from "@/lib/i18n";
import { Segment } from "@/ui/form/segment";
import { useCallback } from "react";

export type ApprovalStatus =
  | "NOT_APPROVED"
  | "PROVISIONALLY_APPROVED"
  | "DEFINITELY_APPROVED";

type Props = {
  layoutId: string;
  value: ApprovalStatus;
  onChange: (value: ApprovalStatus) => void;
};

export const ApprovalStatusSegments: React.FC<Props> = ({
  layoutId,
  value,
  onChange
}) => {
  const { t } = useTranslation();

  const handleChange = useCallback(
    (value: string) => {
      onChange(value as ApprovalStatus);
    },
    [onChange]
  );

  return (
    <Segment
      layoutId={layoutId}
      value={value}
      onChange={handleChange}
      options={(
        [
          "NOT_APPROVED",
          "PROVISIONALLY_APPROVED",
          "DEFINITELY_APPROVED"
        ] as const
      ).map((value) => ({
        value,
        label: t(`project.status.${value}`)
      }))}
    />
  );
};
