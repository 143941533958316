import { FragmentOf, graphql, readFragment } from "@/lib/data/graphql";
import { useTranslation } from "@/lib/i18n";
import { Card, CardBody, CardHeader } from "@/ui/display/card";
import { Progress } from "@/ui/feedback/progress";
import { Text } from "@/ui/typography/text";
import { FC } from "react";

export const Co2EquivalentCalculatorCardFragment = graphql(`
  fragment Co2EquivalentCalculatorCard on Query {
    communityCo2Equivalents {
      key
      currentValue
      milestoneValue
      unit
    }
  }
`);

export const Co2EquivalentCalculatorCard: FC<{
  data?: FragmentOf<typeof Co2EquivalentCalculatorCardFragment>;
}> = ({ data }) => {
  const { t } = useTranslation();
  const values = readFragment(Co2EquivalentCalculatorCardFragment, data);

  if (!values?.communityCo2Equivalents) return null;

  return (
    <Card className="bg-bg-white">
      <CardHeader
        title={t("communityImpact.co2Calculator.title")}
        headingLevel={2}
      />
      <CardBody>
        <div className="space-y-6">
          {values.communityCo2Equivalents.map((equivalent) => (
            <Metric
              key={equivalent.key}
              label={t(
                `communityImpact.co2Calculator.metrics.${equivalent.key}`
              )}
              currentValue={equivalent.currentValue}
              milestoneValue={equivalent.milestoneValue}
              unit={equivalent.unit}
            />
          ))}
        </div>
      </CardBody>
    </Card>
  );
};

type MetricProps = {
  label: string;
  currentValue: number;
  milestoneValue: number;
  unit: string | null;
};

const Metric: FC<MetricProps> = ({
  label,
  currentValue,
  milestoneValue,
  unit
}) => {
  const { t } = useTranslation();
  const progress = (currentValue / (currentValue + milestoneValue)) * 100;

  return (
    <div className="space-y-2 !font-medium">
      <Text className="!text-base">
        {label} {unit ? `• ${unit}` : ""}
      </Text>
      <Progress color="primary" value={progress} />
      <div className="flex justify-between text-xs">
        <div>
          {t("communityImpact.co2Calculator.labels.currentValue")}:{" "}
          {currentValue} {unit}
        </div>
        <div>
          {t("communityImpact.co2Calculator.labels.milestone")}:{" "}
          {milestoneValue} {unit}
        </div>
      </div>
    </div>
  );
};
