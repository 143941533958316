import {
  AggregationType,
  getFormatters,
  TIME_SCALE,
  TimeScaleType
} from "@/app/performance/utils/format-utilities";
import { FragmentOf } from "@/lib/data/graphql";
import { endOfDay, startOfDay } from "date-fns";
import { PerformanceTemplateFragment } from "../PerformanceTemplate";

// Define State Type
export type State = {
  selectedTemplateId: string | null;
  template: FragmentOf<typeof PerformanceTemplateFragment> | null | undefined;

  config: {
    breakpoint: string;
  };

  filters: {
    startDate: Date;
    endDate: Date;
  };

  formatters: {
    aggregation: AggregationType;
    timeScale: TimeScaleType;
    formatXAxis: (date: Date) => string;
    daysDiff: number;
    monthsDiff: number;
  };

  messagesLogDrawer: {
    open: boolean;
  };
  messagesLogFilter: {
    startDate: Date;
    endDate: Date;
  };

  addTemplateDrawer: {
    open: boolean;
  };

  updateTemplateDrawer: {
    open: boolean;
  };

  adjustTemplateDialog: {
    open: boolean;
  };
};

// Define Current Date Constants
const currentDate = new Date();
const startDate = startOfDay(currentDate);
const endDate = endOfDay(currentDate);

// Get Formatters
const formatters = getFormatters(startDate, endDate);

// Define Initial State
export const initialState: State = {
  selectedTemplateId: null,
  template: null,

  config: {
    breakpoint: "md"
  },

  filters: {
    startDate,
    endDate
  },

  formatters: {
    aggregation: "FIFTEEN_MINUTES", // Ensure correct type
    timeScale: TIME_SCALE.HOURS,
    formatXAxis: formatters.formatXAxis,
    daysDiff: formatters.daysDiff,
    monthsDiff: formatters.monthsDiff
  },

  messagesLogDrawer: {
    open: false
  },
  messagesLogFilter: {
    startDate,
    endDate
  },

  addTemplateDrawer: {
    open: false
  },
  updateTemplateDrawer: {
    open: false
  },
  adjustTemplateDialog: {
    open: false
  }
};
