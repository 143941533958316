import { graphql } from "@/lib/data/graphql";
import { useTranslation } from "@/lib/i18n";
import { PageHeading } from "@/ui/layouts/page-heading";
import { PageWrapper } from "@/ui/layouts/page-wrapper";
import { useQuery } from "@apollo/client";
import {
  Co2EquivalentCalculatorCard,
  Co2EquivalentCalculatorCardFragment
} from "./Co2EquivalentCalculatorCard";
import {
  CommunityKeyFiguresCard,
  CommunityKeyFiguresCardFragment
} from "./CommunityKeyFiguresCard";
import {
  SwissSolarExpansionCard,
  SwissSolarExpansionCardFragment
} from "./SwissSolarExpansionCard";

const CommunityImpactPageQuery = graphql(
  `
    query CommunityImpactPage {
      ...CommunityKeyFiguresCard
      ...Co2EquivalentCalculatorCard
      ...SwissSolarExpansionCard
    }
  `,
  [
    CommunityKeyFiguresCardFragment,
    Co2EquivalentCalculatorCardFragment,
    SwissSolarExpansionCardFragment
  ]
);

export const CommunityImpactPage = () => {
  const { t } = useTranslation();
  const { data } = useQuery(CommunityImpactPageQuery);

  return (
    <PageWrapper>
      <PageHeading title={t("communityImpact.title")} />
      <div className="grid grid-cols-1 lg:grid-cols-2 pt-1 gap-6">
        <div className="space-y-6">
          <CommunityKeyFiguresCard data={data} />
          <SwissSolarExpansionCard data={data} />
        </div>
        <Co2EquivalentCalculatorCard data={data} />
      </div>
    </PageWrapper>
  );
};
