import { useTranslation } from "@/lib/i18n";
import { DateInput } from "@/ui/form/DateInput";
import { Field, Label } from "@/ui/form/fieldset";
import { Input } from "@/ui/form/input";
import clsx from "clsx";
import React, { useState } from "react";

type DateTimeRangeFormProps = {
  startDate: Date;
  endDate: Date;
  onChange: (startDate: Date, endDate: Date) => void;
  startDateLabel?: string;
  endDateLabel?: string;
  className?: string;
};

export const DateTimeRangeForm: React.FC<DateTimeRangeFormProps> = ({
  startDate: defaultStartDate,
  endDate: defaultEndDate,
  onChange,
  startDateLabel,
  endDateLabel,
  className
}) => {
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(defaultEndDate);

  const mergeDateAndTime = (date: Date, time: string) => {
    const [hours, minutes] = time.split(":").map(Number);
    const newDate = new Date(date);
    newDate.setHours(hours ?? 0, minutes ?? 0, 0, 0);
    return newDate;
  };

  const updateStartDate = (date: Date) => {
    setStartDate(date);
    onChange(date, endDate);
  };

  const updateEndDate = (date: Date) => {
    setEndDate(date);
    onChange(startDate, date);
  };

  const handleStartDateChange = (date: Date | null) => {
    if (date) {
      updateStartDate(
        mergeDateAndTime(date, startDate?.toTimeString().slice(0, 5) || "00:00")
      );
    }
  };

  const handleStartTimeChange = (time: string) => {
    if (startDate) {
      updateStartDate(mergeDateAndTime(startDate, time));
    }
  };

  const handleEndDateChange = (date: Date | null) => {
    if (date) {
      updateEndDate(
        mergeDateAndTime(date, endDate?.toTimeString().slice(0, 5) || "00:00")
      );
    }
  };

  const handleEndTimeChange = (time: string) => {
    if (endDate) {
      updateEndDate(mergeDateAndTime(endDate, time));
    }
  };

  return (
    <div className={clsx("flex flex-col gap-4 mt-4", className)}>
      <Field>
        <Label className="block mb-2">
          {startDateLabel ?? t("dateFilter.startDate.label")}
        </Label>
        <div className="flex gap-2">
          <DateInput
            value={startDate}
            onChange={handleStartDateChange}
            maxDate={endDate}
          />
          <Input
            value={startDate?.toTimeString().slice(0, 5) || ""}
            onChange={(e) => handleStartTimeChange(e.target.value)}
            type="time"
            className="max-w-32"
          />
        </div>
      </Field>

      <Field>
        <Label className="block mb-2">
          {endDateLabel ?? t("dateFilter.endDate.label")}
        </Label>
        <div className="flex gap-2">
          <DateInput
            value={endDate}
            onChange={handleEndDateChange}
            minDate={startDate}
          />
          <Input
            value={endDate?.toTimeString().slice(0, 5) || ""}
            onChange={(e) => handleEndTimeChange(e.target.value)}
            type="time"
            className="max-w-32"
          />
        </div>
      </Field>
    </div>
  );
};
