import { AdjustPlantsField } from "@/app/performance/template-management/AdjustPlantsField";
import { graphql, readFragment } from "@/lib/data/graphql";
import { useTranslation } from "@/lib/i18n";
import { Accordion } from "@/ui/display/accordion";
import { toaster } from "@/ui/feedback/toaster";
import { CheckboxControlled } from "@/ui/form/CheckboxControlled";
import { Field, Label } from "@/ui/form/fieldset";
import { FormFieldCard } from "@/ui/form/formFieldCard";
import { SwitchControlled } from "@/ui/form/SwitchControlled";
import { useMutation } from "@apollo/client";
import * as Headless from "@headlessui/react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { PerformanceTemplateFragment } from "../PerformanceTemplate";
import { State } from "../state/state";

export type AdjustTemplateFormValues = {
  generatedRevenue: boolean;
  generatedRevenueTarget: boolean;
  producedElectricity: boolean;
  producedElectricityTarget: boolean;
  selfConsumptionRate: boolean;
  plants: string[];
};

const AdjustPerformanceTemplateMutation = graphql(`
  mutation AdjustPerformanceTemplate(
    $templateId: Int!
    $input: PerformanceTemplateInput!
  ) {
    updatePerformanceTemplate(
      templateId: $templateId
      performanceTemplateInput: $input
    ) {
      id
      generatedRevenue
      generatedRevenueTarget
      producedElectricity
      producedElectricityTarget
      selfConsumptionRate
      plants {
        id
      }
    }
  }
`);

type AdjustTemplateFormProps = {
  templateId: string;
  template: State["template"];
  onCompleted?: () => void;
  onSubmitting?: (isSubmitting: boolean) => void;
};

export const AdjustTemplateForm: React.FC<AdjustTemplateFormProps> = ({
  templateId,
  template,
  onCompleted,
  onSubmitting
}) => {
  const { t } = useTranslation();
  const templateData = readFragment(PerformanceTemplateFragment, template);
  const form = useForm<AdjustTemplateFormValues>({
    mode: "onChange",
    defaultValues: {
      generatedRevenue: templateData?.generatedRevenue,
      generatedRevenueTarget: templateData?.generatedRevenueTarget,
      producedElectricity: templateData?.producedElectricity,
      producedElectricityTarget: templateData?.producedElectricityTarget,
      selfConsumptionRate: templateData?.selfConsumptionRate,
      plants: templateData?.plants.map((plant) => plant.id.toString())
    }
  });
  const { control, setValue, watch, handleSubmit } = form;

  const [adjustPerformanceTemplate] = useMutation(
    AdjustPerformanceTemplateMutation
  );

  // Watch for changes and submit form automatically
  useEffect(() => {
    const subscription = watch(() => handleSubmit(onSubmit)());
    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleSubmit, watch]);

  const onSubmit = (values: AdjustTemplateFormValues) => {
    if (!templateData) return;

    onSubmitting?.(true);

    adjustPerformanceTemplate({
      variables: {
        templateId: parseInt(templateId, 10),
        input: {
          // Keep original name from the template data
          name: templateData.name,
          ...values,
          plantIds: values.plants.map((p) => parseInt(p, 10))
        }
      },
      onCompleted: () => {
        onCompleted?.();
        onSubmitting?.(false);
      },
      onError: () => {
        toaster.error({
          title: t("performanceTemplates.form.actions.update.error")
        });
      }
    });
  };

  return (
    <form className="flex flex-col gap-2">
      <Field className="flex flex-col gap-2">
        <FormFieldCard>
          <Accordion
            maxHeightClassName="max-h-[60px]"
            trigger={
              <Headless.Field className="flex items-center gap-3">
                <SwitchControlled
                  id="generatedRevenue"
                  name="generatedRevenue"
                  defaultChecked={true}
                  control={control}
                  optional
                />
                <Label htmlFor="generatedRevenue" className="cursor-pointer">
                  {t("performanceTemplates.form.fields.generatedRevenue.label")}
                </Label>
              </Headless.Field>
            }
          >
            <CheckboxControlled
              label={t(
                "performanceTemplates.form.fields.generatedRevenueTarget.label"
              )}
              id="generatedRevenueTarget"
              name="generatedRevenueTarget"
              control={control}
              optional
              className="ml-11 mt-2" // size-8 of switch box + gap 3
            />
          </Accordion>
        </FormFieldCard>

        <FormFieldCard>
          <Accordion
            maxHeightClassName="max-h-[60px]"
            trigger={
              <Headless.Field className="flex items-center gap-3">
                <SwitchControlled
                  id="producedElectricity"
                  name="producedElectricity"
                  defaultChecked={true}
                  control={control}
                  optional
                />
                <Label htmlFor="producedElectricity" className="cursor-pointer">
                  {t(
                    "performanceTemplates.form.fields.producedElectricity.label"
                  )}
                </Label>
              </Headless.Field>
            }
          >
            <CheckboxControlled
              label={t(
                "performanceTemplates.form.fields.producedElectricityTarget.label"
              )}
              id="producedElectricityTarget"
              name="producedElectricityTarget"
              control={control}
              optional
              className="ml-11 mt-2" // size-8 of switch box + gap 3
            />
          </Accordion>
        </FormFieldCard>

        <FormFieldCard>
          <Headless.Field className="flex items-center gap-3">
            <SwitchControlled
              id="selfConsumptionRate"
              name="selfConsumptionRate"
              defaultChecked={true}
              control={control}
              optional
            />
            <Label htmlFor="selfConsumptionRate" className="cursor-pointer">
              {t("performanceTemplates.form.fields.selfConsumptionRate.label")}
            </Label>
          </Headless.Field>
        </FormFieldCard>
      </Field>

      <AdjustPlantsField
        id="plants"
        name="plants"
        control={control}
        setValue={setValue}
        value={watch("plants")}
      />
    </form>
  );
};
