import { PerformanceChartFragment } from "@/app/performance/PerformanceChart";
import { FragmentOf, readFragment } from "@/lib/data/graphql";
export type PerformanceValuesByDate = {
  date: string;
  [key: string]: string | number;
};

// TODO: move this logic to BE
export function transformPerformanceData(
  performancePlantsData: FragmentOf<typeof PerformanceChartFragment>[]
): PerformanceValuesByDate[] {
  const valuesByDate = new Map<string, PerformanceValuesByDate>();

  performancePlantsData.forEach((performancePlant) => {
    const { plant, values } = readFragment(
      PerformanceChartFragment,
      performancePlant
    );
    const plantId = plant.id;

    values.forEach((value) => {
      const {
        date,
        pYield,
        pYieldTarget,
        revenue,
        revenueTarget,
        pOwnConsumptionPercentage
      } = value;

      if (!valuesByDate.has(date)) {
        valuesByDate.set(date, { date });
      }

      const dataPoint = valuesByDate.get(date)!;

      // Add plant-prefixed properties
      dataPoint[`${plantId}_producedElectricity`] = pYield;
      dataPoint[`${plantId}_producedElectricityTarget`] = pYieldTarget;
      dataPoint[`${plantId}_generatedRevenue`] = revenue;
      dataPoint[`${plantId}_generatedRevenueTarget`] = revenueTarget;
      dataPoint[`${plantId}_selfConsumptionRate`] = pOwnConsumptionPercentage;
    });
  });

  // Convert map to array and sort by date
  return Array.from(valuesByDate.values()).sort(
    (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
  );
}
