import clsx from "clsx";
import { FC } from "react";
import type { XAxisProps } from "recharts";

export type TickProps = {
  formatter?: XAxisProps["tickFormatter"];
  className?: string;
  axis: "x" | "y";
} & { x?: number; y?: number; payload?: { value: string }; index?: number };

export const Tick: FC<TickProps> = ({
  formatter,
  className,
  axis,
  ...props
}) => {
  const { x, y, payload, index } = props;

  // https://gaurav5430.medium.com/exploring-recharts-using-foreignobject-to-render-custom-html-5c6b75d6207e
  return (
    <foreignObject
      x={x}
      y={y}
      width="0"
      height="0"
      className="overflow-visible"
    >
      <div
        className={clsx(
          "w-fit",
          axis === "x"
            ? "-translate-x-1/2 translate-y-1"
            : // To avoid clashing tick labels in the 0:0 position of the chart
              "-translate-y-1/2 -translate-x-[calc(100%+theme(spacing.1))]",
          "text-xs text-content-primary text-center",
          className
        )}
      >
        <div>
          {formatter ? formatter(payload?.value, index ?? 0) : payload?.value}
        </div>
      </div>
    </foreignObject>
  );
};
