import { FragmentOf, graphql, readFragment } from "@/lib/data/graphql";
import { useCallback, useEffect, useState } from "react";

export const ProjectItemFragment = graphql(`
  fragment ProjectItem on Project {
    id
    name
    status
    document {
      id
      name
      url
    }
    plant {
      id
      name
    }
  }
`);

export type ProjectStatusType = ReturnType<
  typeof graphql.scalar<"ProjectStatus">
>;
export type ProjectData = {
  id: string;
  name: string;
  plantId: string;
  documentName?: string;
  documentUrl?: string;
  status: ProjectStatusType;
};

export const useProjectData = ({
  projects
}: {
  projects?: FragmentOf<typeof ProjectItemFragment>[];
}) => {
  const [projectData, setProjectData] = useState<ProjectData[]>([]);
  const [provisionallyApprovedPercentage, setProvisionallyApprovedPercentage] =
    useState(0);
  const [notApprovedPercentage, setNotApprovedPercentage] = useState(0);

  useEffect(() => {
    if (projects) {
      const formattedData = projects.map((projectData) => {
        const project = readFragment(ProjectItemFragment, projectData);

        return {
          id: project.id.toString(),
          name: project.name,
          plantId: project.plant.id.toString(),
          documentName: project?.document?.name,
          documentUrl: project?.document?.url,
          status: project.status
        };
      });
      setProjectData(formattedData);
    }
  }, [projects]);

  useEffect(() => {
    if (projectData.length > 0) {
      const provisionallyApproved = projectData.filter(
        (project) => project.status === "PROVISIONALLY_APPROVED"
      );
      const notApproved = projectData.filter(
        (project) => project.status === "NOT_APPROVED"
      );

      setProvisionallyApprovedPercentage(
        (provisionallyApproved.length / projectData.length) * 100
      );
      setNotApprovedPercentage((notApproved.length / projectData.length) * 100);
    }
  }, [projectData]);

  const handleStatusChange = useCallback(
    (projectId: string, status: ProjectStatusType) => {
      setProjectData((prev) =>
        prev.map((project) =>
          project.plantId === projectId ? { ...project, status } : project
        )
      );
    },
    [setProjectData]
  );

  return {
    projectData,
    handleStatusChange,
    provisionallyApprovedPercentage,
    approvedPercentage: 100 - notApprovedPercentage
  };
};
