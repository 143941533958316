import clsx from "clsx";
import React from "react";

export const ActionDialogFooter = ({
  className,
  children,
  ...props
}: {
  className?: string;
  children?: React.ReactNode;
}) => {
  return (
    <div
      {...props}
      className={clsx(
        "sticky z-10 w-full",
        "bg-bg-primary border-t border-border-primary",
        className
      )}
    >
      {children ? (
        <div className="flex justify-between p-4 gap-4">{children}</div>
      ) : null}
    </div>
  );
};
