import { Count } from "@/ui/display/count";
import { CarbonIconType } from "@carbon/icons-react";
import { FC } from "react";

export const KeyFigure: FC<{
  Icon: CarbonIconType;
  label: string;
  value?: number;
}> = ({ Icon, label, value }) => {
  if (!value) return;
  return (
    <div className="flex flex-col items-center gap-2 text-content-primary p-2">
      <div className="bg-bg-secondary dark:bg-zinc-900 p-4 rounded-full">
        <Icon className="text-content-primary" size={32} />
      </div>
      <Count className="text-4xl m-2" value={value} />
      <div className="text-sm text-center">{label}</div>
    </div>
  );
};
