import {
  Co2EquivalentCategory,
  Co2EquivalentCategoryListbox
} from "@/app/impact/calculator/Co2EquivalentCategoryListbox";
import { FragmentOf, graphql, readFragment } from "@/lib/data/graphql";
import { useTranslation } from "@/lib/i18n";
import { Card, CardBody, CardHeader } from "@/ui/display/card";
import { Count } from "@/ui/display/count";
import { LoadingSpinner } from "@/ui/loading-spinner";
import { FC, useState } from "react";

export const ImpactCardFragment = graphql(`
  fragment ImpactCard on MyImpactCalculation {
    equivalents {
      key
      value
      category
    }
  }
`);

export const ImpactCard: FC<{
  data?: FragmentOf<typeof ImpactCardFragment>;
  loading?: boolean;
}> = ({ data, loading }) => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState<Co2EquivalentCategory>("MOBILITY");

  const values = readFragment(ImpactCardFragment, data);

  const filterEquivalents = () => {
    return values?.equivalents.filter(
      (equivalent) => equivalent.category === selected
    );
  };

  return (
    <Card className="bg-bg-white">
      <CardHeader
        title={t("myImpact.cards.myImpactCalculator.cards.impact.title")}
        headingLevel={2}
        end={loading ? <LoadingSpinner size="sm" /> : null}
      />
      <CardBody>
        <div className="flex flex-col gap-8 min-h-[250px]">
          <div className="mr-auto">
            <Co2EquivalentCategoryListbox
              value={selected}
              onChange={setSelected}
              exclude={["FOSSILE_FUELS"]}
            />
          </div>
          <div className="grid 2xl:grid-cols-2 items-end gap-x-1 gap-y-6 2xl:gap-y-4">
            {filterEquivalents()?.map((item) => (
              <EquivalentField
                key={item.key}
                // @ts-expect-error dynamic translation keys are pain with TS
                label={t(`enums.co2Equivalents.${item.key}`)}
                value={item.value}
              />
            ))}
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

const EquivalentField: FC<{ label: string; value: number }> = ({
  label,
  value
}) => {
  return (
    <div className="flex flex-col gap-2 text-content-primary">
      <div className="text-sm font-extrabold">{label}</div>
      <Count className="text-4xl" value={value} />
    </div>
  );
};
