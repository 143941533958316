import { logger } from "@/lib/logger";
import {
  addDays,
  addMonths,
  addQuarters,
  addWeeks,
  addYears,
  endOfDay,
  endOfMonth,
  endOfQuarter,
  endOfWeek,
  endOfYear,
  format,
  startOfDay,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
  startOfYear,
  subMonths,
  subWeeks,
  subYears
} from "date-fns";
import { StartOfWeekOptions } from "date-fns/startOfWeek";

export type TimeScaleKeyType =
  | "day"
  | "week"
  | "month"
  | "quarter"
  | "year"
  | "custom";
export const getIntervalText = (
  dateFrom: Date,
  dateTo: Date,
  key: TimeScaleKeyType
) => {
  switch (key) {
    case "day":
      return format(dateFrom, "dd MMM yyyy");
    case "week":
      return `${format(dateFrom, "dd MMM")} - ${format(dateTo, "dd MMM yyyy")}`;
    case "month":
      return format(dateFrom, "MMMM yyyy");
    case "quarter":
      return `Q${Math.floor(dateFrom.getMonth() / 3) + 1} ${format(dateFrom, "yyyy")}`;
    case "year":
      return format(dateFrom, "yyyy");
    default:
      return `${format(dateFrom, "d.M")} - ${format(dateTo, "d.M yyyy")}`;
  }
};

export const navigateInterval = (
  direction: "back" | "forward",
  dateFrom: Date,
  key: TimeScaleKeyType
): Date => {
  const amount = direction === "back" ? -1 : 1;
  switch (key) {
    case "week":
      return addWeeks(dateFrom, amount);
    case "month":
      return addMonths(dateFrom, amount);
    case "quarter":
      return addQuarters(dateFrom, amount);
    case "year":
      return addYears(dateFrom, amount);
    case "day":
    default:
      return addDays(dateFrom, amount);
  }
};

export const WEEK_OPTIONS: StartOfWeekOptions = { weekStartsOn: 1 };
export const getIntervalDates = (
  date: Date,
  key: TimeScaleKeyType
): { start: Date; end: Date } => {
  switch (key) {
    case "day":
      return { start: startOfDay(date), end: endOfDay(date) };
    case "week":
      return {
        start: startOfWeek(date, WEEK_OPTIONS),
        end: endOfWeek(date, WEEK_OPTIONS)
      };
    case "month":
      return { start: startOfMonth(date), end: endOfMonth(date) };
    case "quarter":
      return { start: startOfQuarter(date), end: endOfQuarter(date) };
    case "year":
      return { start: startOfYear(date), end: endOfYear(date) };
    default:
      return { start: date, end: date };
  }
};

export const FILTER_OPTIONS = [
  "today",
  "thisWeek",
  "thisMonth",
  "thisQuartal",
  "thisYear",
  "lastWeek",
  "lastMonth",
  "lastQuartal",
  "lastYear"
] as const;
export type FilterOption = (typeof FILTER_OPTIONS)[number];
export function getDateRange(filterOption: FilterOption): {
  startDate: Date | null;
  endDate: Date | null;
  shortcut: TimeScaleKeyType;
} {
  const today = startOfDay(new Date());

  const filterMap: Record<
    FilterOption,
    {
      dates: () => { startDate: Date; endDate: Date };
      shortcut: TimeScaleKeyType;
    }
  > = {
    today: {
      dates: () => ({ startDate: today, endDate: endOfDay(today) }),
      shortcut: "day"
    },
    thisWeek: {
      dates: () => ({
        startDate: startOfWeek(today, { weekStartsOn: 1 }),
        endDate: endOfWeek(today, { weekStartsOn: 1 })
      }),
      shortcut: "week"
    },
    thisMonth: {
      dates: () => ({
        startDate: startOfMonth(today),
        endDate: endOfMonth(today)
      }),
      shortcut: "month"
    },
    thisQuartal: {
      dates: () => ({
        startDate: startOfQuarter(today),
        endDate: endOfQuarter(today)
      }),
      shortcut: "quarter"
    },
    thisYear: {
      dates: () => ({
        startDate: startOfYear(today),
        endDate: endOfYear(today)
      }),
      shortcut: "year"
    },
    lastWeek: {
      dates: () => ({
        startDate: startOfWeek(subWeeks(today, 1), { weekStartsOn: 1 }),
        endDate: endOfWeek(subWeeks(today, 1), { weekStartsOn: 1 })
      }),
      shortcut: "week"
    },
    lastMonth: {
      dates: () => ({
        startDate: startOfMonth(subMonths(today, 1)),
        endDate: endOfMonth(subMonths(today, 1))
      }),
      shortcut: "month"
    },
    lastQuartal: {
      dates: () => ({
        startDate: startOfQuarter(subMonths(today, 3)),
        endDate: endOfQuarter(subMonths(today, 3))
      }),
      shortcut: "quarter"
    },
    lastYear: {
      dates: () => ({
        startDate: startOfYear(subYears(today, 1)),
        endDate: endOfYear(subYears(today, 1))
      }),
      shortcut: "year"
    }
  };

  try {
    const { dates, shortcut } = filterMap[filterOption];
    const { startDate, endDate } = dates();
    return { startDate, endDate, shortcut };
  } catch (error) {
    logger.error("Invalid filter option");
    return { startDate: null, endDate: null, shortcut: "day" };
  }
}

export const DATE_QUICK_SELECTORS = [
  {
    label: "1D",
    key: "day",
    duration: () => addDays(new Date().setHours(0, 0, 0, 0), 1)
  },
  {
    label: "1W",
    key: "week",
    duration: () => addWeeks(new Date().setHours(0, 0, 0, 0), 1)
  },
  {
    label: "1M",
    key: "month",
    duration: () => addMonths(new Date().setHours(0, 0, 0, 0), 1)
  },
  {
    label: "1Q",
    key: "quarter",
    duration: () => addQuarters(new Date().setHours(0, 0, 0, 0), 1)
  },
  {
    label: "1Y",
    key: "year",
    duration: () => addYears(new Date().setHours(0, 0, 0, 0), 1)
  }
] as const;
