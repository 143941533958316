import { graphql } from "@/lib/data/graphql";
import { useTranslation } from "@/lib/i18n";
import { Alert } from "@/ui/feedback/alert";
import { SubPageWrapper } from "@/ui/layouts/sub-page-wrapper";
import { LoadingSpinner } from "@/ui/loading-spinner";
import { useQuery } from "@apollo/client";
import {
  PaymentSettingsForm,
  PaymentSettingsFormFragment
} from "./PaymentSettingsForm";

export const PaymentSettingsQuery = graphql(
  `
    query PaymentSettings {
      me {
        ...PaymentSettingsForm
      }
    }
  `,
  [PaymentSettingsFormFragment]
);

export const PaymentSettings = () => {
  const { t } = useTranslation();
  const { data, loading } = useQuery(PaymentSettingsQuery);

  if (loading) {
    return (
      <div className="flex mt-14 items-center justify-center grow">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <SubPageWrapper>
      {data?.me ? (
        <>
          <PaymentSettingsForm data={data.me} />
        </>
      ) : (
        <Alert type="error" title={t("error.generic.description")} />
      )}
    </SubPageWrapper>
  );
};
