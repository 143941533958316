import { graphql } from "@/lib/data/graphql";
import { useTranslation } from "@/lib/i18n";
import { Button } from "@/ui/button/button";
import { Card, CardBody, CardFooter, CardHeader } from "@/ui/display/card";
import { LoadingSpinner } from "@/ui/loading-spinner";
import { useQuery } from "@apollo/client";
import { Launch } from "@carbon/icons-react";
import { PlantItemCard, PlantItemCardFragment } from "./PlantItemCard";

export const OwnerDashboardQuery = graphql(
  `
    query OwnerDashboard {
      plants {
        id
        ...PlantItemCard
      }
    }
  `,
  [PlantItemCardFragment]
);

export const OwnerDashboard = () => {
  const { t } = useTranslation();
  const { data, loading } = useQuery(OwnerDashboardQuery);

  if (loading || !data) {
    return (
      <div className="flex mt-14 items-center justify-center">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div className="grid gap-6">
      <Card className="bg-bg-white">
        <CardHeader
          title={t("ownerDashboard.plantsCard.title")}
          border={false}
          className="[&>div]:pb-0"
        ></CardHeader>
        <CardBody>
          <div className="grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 gap-6">
            {data.plants.map((plant) => (
              <PlantItemCard key={plant.id} data={plant} />
            ))}
          </div>
        </CardBody>
      </Card>

      <Card fancyBackground>
        <CardBody>
          <div className="flex flex-col gap-y-4 sm:gap-y-2">
            <span className="text-heading-md">
              {t("ownerDashboard.ctaCard.headline")}
            </span>
            <p className="text-sm">{t("ownerDashboard.ctaCard.text")}</p>
          </div>
        </CardBody>
        <CardFooter>
          <Button
            href={`mailto:${import.meta.env.VITE_SUPPORT_EMAIL}`}
            target="_blank"
          >
            <div className="flex items-center gap-2">
              <span>{t("ownerDashboard.ctaCard.linkLabel")}</span>
              <Launch />
            </div>
          </Button>
        </CardFooter>
      </Card>
    </div>
  );
};
