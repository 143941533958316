import { Switch } from "@/ui/form/switch";
import { FieldPath, FieldValues } from "react-hook-form";
import { FormFieldController, FormFieldProps } from "./FormFieldController";

type SwitchControlledProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = { className?: string; defaultChecked?: boolean } & Omit<
  FormFieldProps<TFieldValues, TName>,
  "label"
>;

export const SwitchControlled = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  id,
  control,
  name,
  optional,
  rules,
  defaultChecked,
  ...restProps
}: SwitchControlledProps<TFieldValues, TName>) => {
  return (
    <FormFieldController
      id={id}
      control={control}
      name={name}
      optional={optional}
      rules={rules}
      render={({ field, error }) => (
        <Switch
          id={id}
          checked={field?.value}
          defaultChecked={defaultChecked}
          aria-invalid={error?.message ? "true" : "false"}
          {...field}
          {...restProps}
          name={field?.name}
        />
      )}
    />
  );
};
