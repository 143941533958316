import { DEMO_ACCOUNT } from "@/constants";
import { AuthError, useSignInWithEmailAndPassword } from "@/lib/auth";
import { useTranslation } from "@/lib/i18n";
import { logger } from "@/lib/logger";
import { Button } from "@/ui/button/button";
import { UserCredential } from "firebase/auth";
import { useAtom } from "jotai";
import { demoRoleAtom } from "./atoms/demoRoleAtom";
import { disabledAtom } from "./atoms/loginAtoms";

export type DemoLoginButtonProps = {
  disabled?: boolean;
  onError: (error: unknown) => void;
  onSuccess: (result: UserCredential) => void;
};

export const DemoLoginButton = (props: DemoLoginButtonProps) => {
  const { onError, onSuccess, disabled } = props;
  const { t } = useTranslation();
  const [, setDisabled] = useAtom(disabledAtom);
  const [, setDemoRole] = useAtom(demoRoleAtom);

  const [singIn, , loading] = useSignInWithEmailAndPassword();

  const handleClick = async (role: "owner" | "investor" | "admin") => {
    setDisabled(true);
    setDemoRole(role);
    try {
      const result = await singIn(DEMO_ACCOUNT.email, DEMO_ACCOUNT.password);
      onSuccess(result);
    } catch (error) {
      onError?.(error as AuthError);
    } finally {
      setDisabled(false);
    }
  };

  if (!DEMO_ACCOUNT.email || !DEMO_ACCOUNT.password) {
    logger.error("Demo account env is not set!");
    return null;
  }

  return (
    <div className="flex flex-col gap-4">
      <Button
        onClick={() => handleClick("investor")}
        size="lg"
        disabled={disabled || loading}
        className="w-full"
        outline
      >
        <span className="text-base">{t("login.signInWithDemoInvestor")}</span>
      </Button>
      <Button
        onClick={() => handleClick("owner")}
        size="lg"
        disabled={disabled || loading}
        className="w-full"
        outline
      >
        <span className="text-base">{t("login.signInWithDemoOwner")}</span>
      </Button>
      <Button
        onClick={() => handleClick("admin")}
        size="lg"
        disabled={disabled || loading}
        className="w-full"
        outline
      >
        <span className="text-base">{t("login.signInWithDemoAdmin")}</span>
      </Button>
    </div>
  );
};
