import {
  AccountFormFragment,
  useUpdateAccount
} from "@/app/settings/hooks/useUpdateAccount";
import { FragmentOf, readFragment } from "@/lib/data/graphql";
import { useTranslation } from "@/lib/i18n";
import { Button } from "@/ui/button/button";
import { toaster } from "@/ui/feedback/toaster";
import { InputControlled } from "@/ui/form/InputControlled";
import { useForm } from "react-hook-form";

type AccountFormValues = {
  email: string;
  firstName: string;
  lastName: string;
  additionalName?: string;
};

type AccountFormProps = {
  data: FragmentOf<typeof AccountFormFragment>;
};

export const AccountForm: React.FC<AccountFormProps> = ({ data }) => {
  const { t } = useTranslation();

  const { email, uid, additionalName, firstName, lastName } = readFragment(
    AccountFormFragment,
    data
  );

  const { control, handleSubmit } = useForm<AccountFormValues>({
    defaultValues: {
      email: email || "",
      firstName: firstName || "",
      lastName: lastName || "",
      additionalName: additionalName || ""
    }
  });

  const { updateAccount, loading } = useUpdateAccount();
  const handleUpdate = (formData: AccountFormValues) => {
    if (uid) {
      updateAccount({
        variables: {
          userId: uid, // TODO(#262): should be id
          input: {
            firstName: formData.firstName,
            lastName: formData.lastName,
            additionalName: formData.additionalName
          }
        },
        onCompleted: () => {
          toaster.success({
            title: t("settings.account.actions.save.success")
          });
        },
        onError: () => {
          toaster.error({
            title: t("settings.account.actions.save.error")
          });
        }
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(handleUpdate)} className="flex flex-col gap-6">
      <div className="flex flex-col sm:flex-row gap-6">
        <InputControlled
          label={t("settings.account.fields.firstName.label")}
          id="firstName"
          type="firstName"
          name="firstName"
          autoComplete="given-name"
          control={control}
        />

        <InputControlled
          label={t("settings.account.fields.additionalName.label")}
          id="additionalName"
          type="additionalName"
          name="additionalName"
          autoComplete="additional-name"
          control={control}
          optional
        />
      </div>

      <div className="flex flex-col sm:flex-row gap-6">
        <InputControlled
          label={t("settings.account.fields.lastName.label")}
          id="lastName"
          type="lastName"
          name="lastName"
          autoComplete="family-name"
          control={control}
        />
      </div>

      <Button
        type="submit"
        className="self-start min-w-32 mt-2 sm:mt-6"
        loading={loading}
      >
        {t("settings.account.actions.save.label")}
      </Button>
    </form>
  );
};
