import { Button } from "@/ui/button/button";
import clsx from "clsx";
import { motion } from "motion/react";
import React from "react";

type Option = {
  value: string;
  label: string;
};

type Props = {
  layoutId?: string;
  value: string;
  onChange: (value: string) => void;
  options: Option[];
  className?: string;
};

export const Segment: React.FC<Props> = ({
  layoutId,
  value,
  onChange,
  options,
  className
}) => {
  return (
    <div
      className={clsx(
        "inline-flex rounded-lg border border-border-primary self-start p-1",
        className
      )}
    >
      {options.map((option) => (
        <Button
          key={option.value}
          onClick={() => onChange(option.value)}
          outline
          className={clsx(
            "border-0 !font-semibold",
            value === option.value
              ? "!text-content-primary "
              : "!text-content-secondary"
          )}
        >
          {value === option.value ? (
            <motion.span
              layoutId={`current-indicator-${layoutId}`}
              className={clsx(
                "absolute z-20 inset-x-0  h-full  bg-zinc-950/5 dark:bg-white/5 rounded-lg"
              )}
            />
          ) : null}
          {option.label}
        </Button>
      ))}
    </div>
  );
};
