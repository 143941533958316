import { graphql } from "@/lib/data/graphql";
import { useTranslation } from "@/lib/i18n";
import { Avatar } from "@/ui/avatar";
import { Button } from "@/ui/button/button";
import { Alert } from "@/ui/feedback/alert";
import {
  Dialog,
  DialogBody,
  DialogProps,
  DialogTitle
} from "@/ui/feedback/dialog";
import { LoadingSpinner } from "@/ui/loading-spinner";
import { Text } from "@/ui/typography/text";
import { useQuery } from "@apollo/client";
import { ArrowRight } from "@carbon/icons-react";
import React, { FC } from "react";
import { useNavigate } from "react-router-dom";

export const PlantsMapDetailQuery = graphql(`
  query PlantsMapDetail($plantId: Int!) {
    plant(plantId: $plantId) {
      id
      name
      panelsOwned
      photoUrl
      peakPower
      status
      panelsTotal
    }
  }
`);

interface PlantsMapDetailProps extends DialogProps {
  plantId?: number;
}

export const PlantsMapDetail: FC<PlantsMapDetailProps> = ({
  plantId,
  ...dialogProps
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data, loading, error } = useQuery(PlantsMapDetailQuery, {
    fetchPolicy: "no-cache",
    variables: { plantId: plantId! },
    skip: !plantId
  });

  if (loading || !data?.plant) {
    return (
      <Dialog {...dialogProps} className="text-center">
        <LoadingSpinner />
      </Dialog>
    );
  }

  if (error) {
    return (
      <Dialog {...dialogProps}>
        <Alert
          title={t("dashboard.cards.plantsMap.error.plantDetails")}
          type="warning"
        />
      </Dialog>
    );
  }

  const { plant } = data;

  const getStatusClass = () => {
    switch (plant?.status) {
      case "OK":
        return "bg-green-500";

      case "OFFLINE":
        return "bg-red-500";

      default:
        return "bg-content-secondary";
    }
  };

  return (
    <Dialog {...dialogProps}>
      <div className="flex items-center justify-between">
        <DialogTitle className="!text-2xl font-semibold mb-0">
          {plant?.name}
        </DialogTitle>
        <Button plain onClick={() => navigate("/performance")}>
          <ArrowRight className="size-6" />
        </Button>
      </div>
      <DialogBody className="flex flex-col gap-4">
        <PlantsDataItem
          label={t("dashboard.cards.plantsMap.peakPower.label")}
          value={t("dashboard.cards.plantsMap.peakPower.value", {
            value: plant?.peakPower,
            totalPanels: plant?.panelsTotal
          })}
        />
        <div className="flex justify-between items-center gap-2">
          <div className="flex flex-col justify-items-start gap-4">
            <PlantsDataItem
              label={t("dashboard.cards.plantsMap.status.label")}
              value={
                <div className="flex items-center gap-2">
                  <div className={`size-2 rounded-full ${getStatusClass()}`} />
                  {plant?.status && t(`enums.plantStatus.${plant?.status}`)}
                </div>
              }
            />
            <PlantsDataItem
              label={t("dashboard.cards.plantsMap.ownedPanels.label")}
              value={t("dashboard.cards.plantsMap.ownedPanels.value", {
                value: plant?.panelsOwned
              })}
            />
          </div>
          <Avatar className="size-32" src={plant?.photoUrl} />
        </div>
      </DialogBody>
    </Dialog>
  );
};

const PlantsDataItem: FC<{
  label: React.ReactNode;
  value: React.ReactNode;
}> = ({ label, value }) => {
  return (
    <div className="flex flex-col gap-2">
      <Text className="text-xs">{label}</Text>
      <div className="!text-content-primary mb-6 font-bold">{value}</div>
    </div>
  );
};
