import { PerformancePlantBadge } from "@/app/performance/PerformancePlantBadge";
import { PerformanceTemplateFragment } from "@/app/performance/PerformanceTemplate";
import { PerformanceTooltipOverlay } from "@/app/performance/PerformanceTooltipOverlay";
import { usePerformanceContext } from "@/app/performance/state/PerformanceProvider";
import { readFragment } from "@/lib/data/graphql";
import { useTranslation } from "@/lib/i18n";
import { getTheme } from "@/lib/utils/getTheme";
import { TooltipProps } from "@/ui/display/charts/tooltip";
import { ResponsiveWrapper } from "@/ui/layouts/responsive-wrapper";
import { ModalCloseButton } from "@/ui/overlays/ModalCloseButton";
import { cn } from "@/ui/utils";
import { useClickAway } from "@uidotdev/usehooks";
import clsx from "clsx";
import { FC, ReactNode, Ref, useEffect, useState } from "react";
import type { TooltipProps as RechartsTooltipProps } from "recharts/types/component/Tooltip";

type TooltipData = {
  name: string;
  color?: string;
  [key: string]: string | undefined;
};

export type PerformanceTooltipProps = TooltipProps;

export const PerformanceTooltip: FC<PerformanceTooltipProps> = ({
  labelFormatter,
  nameFormatter,
  valueFormatter,
  className,
  active,
  payload,
  label
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [tooltipPayload, setTooltipPayload] = useState<TooltipProps["payload"]>(
    []
  );

  // Anytime recharts told us that the tooltip is active and has payload, we open it
  useEffect(() => {
    if (active && payload && payload.length > 0) {
      setIsOpen(true);
    }
  }, [active, payload]);

  // Whenever recharts tells us that the tooltip has payload, we store it in our state
  // This is used to render the tooltip content
  useEffect(() => {
    if (payload && payload.length > 0) {
      setTooltipPayload(payload);
    }
  }, [payload]);

  // Whenever the tooltip is not active, we close it and clear the data
  // Checking active state is important to prevent closing the tooltip when the user is clicking on the chart
  const ref = useClickAway<HTMLElement>(() => {
    if (!active) {
      setIsOpen(false);
      setTooltipPayload([]);
    }
  });

  const handleClose = () => {
    setIsOpen(false);
    setTooltipPayload([]);
  };

  const tableData: Record<string, TooltipData> = (tooltipPayload ?? []).reduce(
    (acc, item) => {
      if (!item.name || !item.dataKey) return acc;

      const prefix = item.name.split("-")[0]?.trim();
      if (!prefix) return acc;

      if (!acc[prefix]) {
        acc[prefix] = { name: prefix };
      }

      const cleanedDataKey =
        typeof item.dataKey === "string"
          ? item.dataKey.replace(/^\d+_/, "")
          : item.dataKey;
      const formattedValue = valueFormatter
        ? valueFormatter(item.value)
        : String(item.value ?? "");

      acc[prefix][cleanedDataKey] = item.unit
        ? `${formattedValue || 0}${item.unit === "%" ? "%" : ` ${item.unit}`}`
        : formattedValue;

      if (item.stroke !== "transparent") acc[prefix].color = item.color;

      return acc;
    },
    {} as Record<string, TooltipData>
  );

  const listData: Record<string, TooltipData> = (tooltipPayload ?? []).reduce(
    (acc, item) => {
      if (!item.name || !item.dataKey) return acc;

      const prefix = item.name.split("-")[0]?.trim();
      if (!prefix) return acc;

      if (!acc[prefix]) {
        acc[prefix] = { name: prefix };
      }

      const cleanedDataKey =
        typeof item.dataKey === "string"
          ? item.dataKey.replace(/^\d+_/, "")
          : item.dataKey;
      const formattedValue = valueFormatter
        ? valueFormatter(item.value)
        : String(item.value ?? "");

      acc[prefix][cleanedDataKey] = formattedValue;

      if (item.stroke !== "transparent") acc[prefix].color = item.color;

      return acc;
    },
    {} as Record<string, TooltipData>
  );

  return (
    <ResponsiveWrapper
      breakpoint={getTheme().screens["md"]}
      wrapper={
        <PerformanceTooltipOverlay
          ref={ref as Ref<HTMLElement>}
          open={isOpen}
        />
      }
    >
      <div className="md:hidden [--tooltip-header-height:40px] h-full">
        <TooltipHeader
          label={label}
          payload={tooltipPayload}
          labelFormatter={labelFormatter}
          className="h-[var(--tooltip-header-height)]"
          onClose={handleClose}
        />
        <TooltipList
          data={Object.values(listData)}
          nameFormatter={nameFormatter}
          payload={tooltipPayload}
          className="pt-[calc(var(--tooltip-header-height)+0.5rem)]"
        />
      </div>
      <div className="hidden md:block">
        <TooltipContent>
          <TooltipHeader
            label={label}
            payload={tooltipPayload}
            labelFormatter={labelFormatter}
            className={className}
          />
          <TooltipTable
            data={Object.values(tableData)}
            nameFormatter={nameFormatter}
            payload={tooltipPayload}
          />
        </TooltipContent>
      </div>
    </ResponsiveWrapper>
  );
};

const TOOLTIP_COLUMNS = [
  "producedElectricity",
  "producedElectricityTarget",
  "generatedRevenue",
  "generatedRevenueTarget",
  "selfConsumptionRate"
] as const;

const TooltipHeader: FC<{
  label?: string;
  payload?: RechartsTooltipProps<string, string>["payload"];
  labelFormatter?: TooltipProps["labelFormatter"];
  className?: string;
  onClose?: () => void;
}> = ({ label, payload, labelFormatter, className, onClose }) => (
  <div
    className={cn(
      "max-md:fixed max-md:inset-x-0 max-md:top-0 max-md:z-10",
      "flex items-center",
      "p-2 md:p-3",
      "text-content-primary text-center font-semibold",
      "bg-bg-primary rounded-t-lg border-b border-border-primary",
      className
    )}
  >
    <div className="flex flex-1 items-center justify-center">
      {labelFormatter && payload ? labelFormatter(label, payload) : label}
      {onClose ? (
        <div className="absolute right-2 md:right-3">
          <ModalCloseButton onClick={onClose} size="xs" plain />
        </div>
      ) : null}
    </div>
  </div>
);

const TooltipTable: FC<{
  data: TooltipData[];
  nameFormatter?: TooltipProps["nameFormatter"];
  payload?: RechartsTooltipProps<string, string>["payload"];
  className?: string;
}> = ({ data, nameFormatter, payload, className }) => {
  const { t } = useTranslation();
  const {
    state: { template: templateData }
  } = usePerformanceContext();
  const templateConfig = readFragment(
    PerformanceTemplateFragment,
    templateData
  );

  return (
    <div className={cn("overflow-y-auto h-full", className)}>
      <table className="text-left">
        <thead>
          <tr>
            <th />
            {TOOLTIP_COLUMNS.map((key) => {
              return templateConfig?.[key] ? (
                <th
                  key={key}
                  className="md:p-2 text-xs font-medium text-content-secondary"
                >
                  {t(`performance.chart.tooltip.${key}`)}
                </th>
              ) : null;
            })}
          </tr>
        </thead>
        <tbody>
          {data.map((item, i) => (
            <tr key={i}>
              <td className="px-2 whitespace-nowrap font-medium py-1.5">
                <TooltipItemName
                  item={item}
                  nameFormatter={nameFormatter}
                  payload={payload}
                />
              </td>
              {TOOLTIP_COLUMNS.map((key) => {
                return templateConfig?.[key] ? (
                  <td
                    key={key}
                    className="px-2 whitespace-nowrap text-xs text-content-primary font-medium"
                  >
                    {item[key] ?? "-"}
                  </td>
                ) : null;
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const TooltipItemName: FC<{
  item: TooltipData;
  nameFormatter?: TooltipProps["nameFormatter"];
  payload?: RechartsTooltipProps<string, string>["payload"];
}> = ({ item, nameFormatter, payload }) => (
  <PerformancePlantBadge
    colorHex={item.color ?? ""}
    name={nameFormatter ? nameFormatter(item.name, payload) : item.name}
  />
);

const TooltipList: FC<{
  data: TooltipData[];
  nameFormatter?: TooltipProps["nameFormatter"];
  payload?: RechartsTooltipProps<string, string>["payload"];
  className?: string;
}> = ({ data, nameFormatter, payload, className }) => {
  const { t } = useTranslation();

  return (
    <div
      className={cn(
        "flex flex-col gap-2",
        "overflow-y-auto h-full",
        "p-2 md:p-4",
        className
      )}
    >
      {data.map((item, i) => {
        const hasYield = !!item[TOOLTIP_COLUMNS[0]];
        const hasRevenue = !!item[TOOLTIP_COLUMNS[2]];
        const hasSelfConsumptionRate = !!item[TOOLTIP_COLUMNS[4]];

        return (
          <div key={i} className="flex flex-col mt-auto">
            <TooltipItemName
              item={item}
              nameFormatter={nameFormatter}
              payload={payload}
            />
            <div className="flex pl-1">
              <div className="w-full">
                <div className="grid grid-cols-5">
                  {hasYield ? (
                    <div className="col-span-2">
                      <span className="text-xs font-medium text-content-secondary">
                        {t("performance.chart.tooltip.list.yield")}
                      </span>
                    </div>
                  ) : null}
                  {hasRevenue ? (
                    <div className="col-span-2">
                      <span className="text-xs font-medium text-content-secondary">
                        {t("performance.chart.tooltip.list.revenue")}
                      </span>
                    </div>
                  ) : null}
                  <div className="col-span-1">
                    {hasSelfConsumptionRate ? (
                      <span className="text-xs font-medium text-content-secondary">
                        {t("performance.chart.tooltip.list.consumptionShort")}
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className="grid grid-cols-5">
                  {hasYield ? (
                    <div className="col-span-2">
                      {/* Yield */}
                      <span className="text-xs text-content-primary font-medium">
                        {item[TOOLTIP_COLUMNS[0]]}&nbsp;
                        {t("common.units.kWh")}
                      </span>
                      {/* Target yield */}
                      {item[TOOLTIP_COLUMNS[1]] ? (
                        <span className="text-xs text-content-secondary font-medium ml-0.5">
                          ({item[TOOLTIP_COLUMNS[1]]})
                        </span>
                      ) : null}
                    </div>
                  ) : null}
                  {hasRevenue ? (
                    <div className="col-span-2">
                      {/* Revenue */}
                      <span className="text-xs text-content-primary font-medium">
                        {item[TOOLTIP_COLUMNS[2]]}&nbsp;
                        {t("common.units.CHF")}
                      </span>
                      {/* Target revenue */}
                      {item[TOOLTIP_COLUMNS[3]] ? (
                        <span className="text-xs text-content-secondary font-medium ml-0.5">
                          ({item[TOOLTIP_COLUMNS[3]]})
                        </span>
                      ) : null}
                    </div>
                  ) : null}
                  {hasSelfConsumptionRate ? (
                    <div className="col-span-1">
                      <span className="text-xs text-content-primary font-medium">
                        {item[TOOLTIP_COLUMNS[4]]}%
                      </span>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

const TooltipContent: FC<{
  children?: ReactNode;
  className?: string;
}> = ({ children, className }) => (
  <div
    className={clsx(
      "min-w-fit md:min-w-60 px-3 pb-2",
      "bg-bg-primary border border-border-primary shadow-sm rounded-lg",
      className
    )}
  >
    {children}
  </div>
);
