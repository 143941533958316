import { logger } from "@/lib/logger";
import { isNative } from "@/lib/utils/isNative";
import { toaster } from "@/ui/feedback/toaster";

interface DownloadFileOptions {
  blob: Blob;
  fileName: string;
  onSuccess?: VoidFunction;
  onError?: (error: unknown) => void;
}

export function downloadFile(options: DownloadFileOptions) {
  if (isNative()) {
    downloadFileNative(options);
  } else {
    downloadFileBrowser(options);
  }
}

async function downloadFileNative({
  blob,
  fileName,
  onSuccess,
  onError
}: DownloadFileOptions) {
  try {
    // Convert Blob to Base64
    const base64Data = await convertBlobToBase64(blob);

    // Load Capacitor plugins dynamically
    const { Directory, Filesystem } = await import("@capacitor/filesystem");
    const { Share } = await import("@capacitor/share");

    // Write the file to the native filesystem
    await Filesystem.writeFile({
      path: fileName,
      data: base64Data,
      directory: Directory.Cache
    });

    // Get file URI and use Share to open dialog with save option
    const uriResult = await Filesystem.getUri({
      directory: Directory.Cache,
      path: fileName
    });

    if (!(await Share.canShare())) {
      toaster.error({ title: "Cannot share document" });
      return;
    }

    await Share.share({
      title: fileName,
      text: fileName,
      url: uriResult.uri
    });

    onSuccess?.();
  } catch (error) {
    if (error instanceof Error && error.message.includes("Share canceled")) {
      return;
    }
    logger.error("Error downloading and saving file:", error);
    onError?.(error);
  }
}

function downloadFileBrowser({
  blob,
  fileName,
  onSuccess,
  onError
}: DownloadFileOptions) {
  try {
    const anchor = document.createElement("a");
    anchor.href = URL.createObjectURL(blob);
    anchor.download = fileName;
    anchor.style.display = "none";

    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);

    URL.revokeObjectURL(anchor.href);
    onSuccess?.();
  } catch (error) {
    logger.error("Error downloading file in browser:", error);
    onError?.(error);
  }
}

async function convertBlobToBase64(blob: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(blob);
  });
}
