import { logger } from "@/lib/logger";
import { isNative } from "@/lib/utils/isNative";
import { toaster } from "@/ui/feedback/toaster";

interface DownloadFileOptions {
  url: string;
  fileName: string;
  onSuccess?: VoidFunction;
  onError?: (error: unknown) => void;
}

export function downloadExternalFile(options: DownloadFileOptions) {
  if (isNative()) {
    downloadFileNative(options);
  } else {
    downloadFileBrowser(options);
  }
}

async function downloadFileNative({
  url,
  fileName,
  onSuccess,
  onError
}: DownloadFileOptions) {
  try {
    // load capacitor plugins dynamically to split bundle and don't load them on web
    const { Share } = await import("@capacitor/share");
    if (!(await Share.canShare())) {
      toaster.error({
        title: "Cannot share document link"
      });
      // TODO: copy to clipboard?
      return;
    }

    const response = await fetch(url);

    if (!response.ok) {
      throw new Error(`Failed to fetch file. Status: ${response.status}`);
    }

    const blob = await response.blob();
    const base64Data = await convertBlobToBase64(blob);

    // load capacitor plugins dynamically to split bundle and don't load them on web
    const { Directory, Filesystem } = await import("@capacitor/filesystem");

    // write the file to the native filesystem
    await Filesystem.writeFile({
      path: fileName,
      data: base64Data,
      directory: Directory.Cache
    });

    // get file uri and use share to open dialog with save option
    const uriResult = await Filesystem.getUri({
      directory: Directory.Cache,
      path: fileName
    });

    await Share.share({
      title: fileName,
      text: fileName,
      url: uriResult.uri
    });

    onSuccess?.();
  } catch (error) {
    // skipping closing share dialog, not a error
    if (error instanceof Error && error.message.includes("Share canceled")) {
      return;
    }

    logger.error("error downloading and saving file:", error);
    onError?.(error);
  }
}

async function downloadFileBrowser({
  url,
  fileName,
  onSuccess,
  onError
}: DownloadFileOptions) {
  try {
    const response = await fetch(url);

    if (!response.ok) {
      throw new Error(`Failed to fetch file. Status: ${response.status}`);
    }

    const blob = await response.blob();

    const anchor = document.createElement("a");
    anchor.href = URL.createObjectURL(blob);
    anchor.download = fileName;
    anchor.style.display = "none";

    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);

    URL.revokeObjectURL(anchor.href);
    onSuccess?.();
  } catch (error) {
    logger.error("error downloading file in browser:", error);
    onError?.(error);
  }
}

async function convertBlobToBase64(blob: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(blob); // convert blob to base64
  });
}
