import React from "react";
import {
  CartesianGrid,
  ComposedChart as RechartsComposedChart,
  Legend as RechartsLegend,
  Tooltip as RechartsTooltip,
  ResponsiveContainer,
  XAxis,
  XAxisProps
} from "recharts";
import { BaseChartProps, ChartSerie } from "./chart";
import { Tick } from "./tick";
import { Tooltip, TooltipProps } from "./tooltip";

export type ComposedChartSeries<TData> = ChartSerie<TData>;

export type ComposedChartProps<TData> = {
  /**
   * Data to be displayed in the chart
   */
  data: TData[];
  series: ChartSerie<TData>[];
  /**
   * Data key for X axis
   */
  xAxisKey: Extract<keyof TData, string>;
  xAxisProps?: Omit<XAxisProps, "dataKey" | "tick">;
  xAxisTick?: React.ReactElement;
  /**
   * Show grid lines
   *
   * @default false
   */
  grid?: boolean;
  /**
   * Legend
   *
   * - if false or undefined, no tooltip will be rendered
   * - if true, tooltip will be rendered with tooltip props provided
   * - if React element, custom tooltip will be rendered   * @default false
   *
   */
  legend?: boolean | React.ReactElement;
  /**
   * Tooltip
   *
   * - if false or undefined, no tooltip will be rendered
   * - if true, tooltip will be rendered with tooltip props provided
   * - if React element, custom tooltip will be rendered
   *
   * @default true
   */
  tooltip?: boolean | React.ReactElement;
  tooltipProps?: TooltipProps;
  margin?: {
    top?: number;
    right?: number;
    bottom?: number;
    left?: number;
  };
  /**
   * Height of the chart.
   *
   * @default 300
   */
  height?: string | number | null;
  /**
   * Width of the chart
   *
   * @default "100%"
   */
  width?: string | number | null;
  /**
   * Width of the legend, sometimes helps if legend overflow its container.
   *
   * @default undefined
   */
  legendWidth?: number | null;
} & BaseChartProps;

export const ComposedChart = <TData,>({
  data,
  xAxisKey,
  xAxisProps,
  xAxisTick,
  grid = false,
  tooltip = true,
  legend = false,
  tooltipProps,
  series,
  height,
  width,
  className,
  children,
  ...props
}: ComposedChartProps<TData>) => {
  return (
    <ResponsiveContainer
      className={className}
      width={width ?? "100%"}
      height={height ?? 300}
    >
      <RechartsComposedChart
        data={data}
        margin={{ top: 0, right: 0, left: 0, bottom: 20, ...props.margin }}
      >
        {series.map(({ render, renderProps }, i) => {
          return render({
            ...renderProps,
            key: i
          });
        })}

        <XAxis
          stroke="var(--color-content-secondary)"
          {...xAxisProps}
          dataKey={xAxisKey}
          tick={
            xAxisTick ?? <Tick axis="x" formatter={xAxisProps?.tickFormatter} />
          }
        />

        {children}

        {/* Legend */}
        {legend && (
          <RechartsLegend
            verticalAlign="bottom"
            height={36}
            width={props.legendWidth ?? undefined}
            className="max-md:hidden text-sm"
            {...(typeof legend === "boolean" ? {} : { content: legend })}
          />
        )}

        {/* Grid */}
        {grid && (
          <CartesianGrid
            strokeDasharray="3 3"
            className="stroke-border-primary opacity-50"
          />
        )}

        {/* Tooltip */}
        {tooltip && (
          <RechartsTooltip
            isAnimationActive={false}
            {...tooltipProps}
            content={tooltip === true ? <Tooltip {...tooltipProps} /> : tooltip}
          />
        )}
      </RechartsComposedChart>
    </ResponsiveContainer>
  );
};
