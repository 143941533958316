/* eslint-disable no-restricted-imports */
import type { introspection } from "@/lib/data/graphql/graphql-env";
import { loadDevMessages, loadErrorMessages } from "@apollo/client/dev";
import { initGraphQLTada } from "gql.tada";

if (import.meta.env.DEV) {
  loadDevMessages();
  loadErrorMessages();
}

export const graphql = initGraphQLTada<{
  introspection: introspection;
  scalars: {
    DateTime: string;
    JSON: Record<string, unknown>;
  };
}>();

export { readFragment } from "gql.tada";
export type { FragmentOf, ResultOf, VariablesOf } from "gql.tada";
