import { UserRolesRadioField } from "@/app/user-management/UserRolesRadioField";
import { useTranslation } from "@/lib/i18n";
import { Button } from "@/ui/button/button";
import { Alert } from "@/ui/feedback/alert";
import { InputControlled } from "@/ui/form/InputControlled";
import { FC } from "react";
import { UseFormReturn } from "react-hook-form";

export type UserFormValues = {
  email: string;
  firstName?: string;
  lastName?: string;
  additionalName?: string;
  password: string;
  phoneNumber?: string;
  roles: string[];
};

type Props = {
  form: UseFormReturn<UserFormValues>;
  onSubmit: (values: UserFormValues) => void;
  loading: boolean;
  isEditMode?: boolean;
  onClose: VoidFunction;
};

export const UserForm: FC<Props> = ({
  onClose,
  onSubmit,
  isEditMode,
  loading,
  form
}) => {
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = form;

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">
      <InputControlled
        label={t("userManagement.editForm.fields.email.label")}
        id="email"
        type="email"
        name="email"
        control={control}
      />

      <InputControlled
        label={t("userManagement.editForm.fields.firstName.label")}
        id="firstName"
        type="firstName"
        name="firstName"
        autoComplete="given-name"
        control={control}
      />
      <InputControlled
        label={t("userManagement.editForm.fields.additionalName.label")}
        id="additionalName"
        type="additionalName"
        name="additionalName"
        autoComplete="additional-name"
        control={control}
        optional
      />
      <InputControlled
        label={t("userManagement.editForm.fields.lastName.label")}
        id="lastName"
        type="lastName"
        name="lastName"
        autoComplete="family-name"
        control={control}
      />

      {!isEditMode && (
        <InputControlled
          label={t("userManagement.editForm.fields.password.label")}
          id="password"
          type="password"
          name="password"
          control={control}
          autoComplete="new-password"
        />
      )}

      {/* TODO: We persist the phone currently only in Firebase, that was originally because of 2FA, we have to move it to DWH */}
      {/* <InputControlled
        label={t("userManagement.editForm.fields.phoneNumber.label")}
        id="phoneNumber"
        type="tel"
        name="phoneNumber"
        control={control}
      /> */}

      <UserRolesRadioField name="roles" control={control} />

      {errors.root?.message ? (
        <Alert title={errors.root?.message} type="error" />
      ) : null}

      <div className="flex justify-end gap-2">
        <Button type="button" outline onClick={onClose}>
          {t("userManagement.editForm.actions.cancel.label")}
        </Button>
        <Button type="submit" loading={loading}>
          {t(
            `userManagement.editForm.actions.${isEditMode ? "edit" : "add"}.label`
          )}
        </Button>
      </div>
    </form>
  );
};
