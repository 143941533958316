import { graphql } from "@/lib/data/graphql";
import { useTranslation } from "@/lib/i18n";
import { Listbox, ListboxLabel, ListboxOption } from "@/ui/form/listbox";

export type TimePeriod = ReturnType<typeof graphql.scalar<"TimePeriod">>;

export type TimePeriodListboxProps = {
  value: TimePeriod;
  onChange: (value: TimePeriod) => void;
};

export const TimePeriodListbox: React.FC<TimePeriodListboxProps> = ({
  value,
  onChange
}) => {
  const { t } = useTranslation();

  const periods = (["TOTAL", "TODAY", "WEEK", "MONTH", "YEAR"] as const).map(
    (period) => ({
      label: t(`enums.timePeriods.${period}`),
      value: period
    })
  );

  return (
    <Listbox
      value={value}
      onChange={(e: TimePeriod) => onChange(e)}
      className="sm:w-48"
    >
      {periods.map((period) => {
        return (
          <ListboxOption<TimePeriod> key={period.value} value={period.value}>
            <ListboxLabel>{period.label}</ListboxLabel>
          </ListboxOption>
        );
      })}
    </Listbox>
  );
};
