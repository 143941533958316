import { FragmentOf, graphql, readFragment } from "@/lib/data/graphql";
import { Card, CardBody, CardHeader } from "@/ui/display/card";
import { KeyFigure } from "@/ui/display/key-figure";
import { Link } from "@/ui/link";
import { BatteryCharging, Events, RoadWeather } from "@carbon/icons-react";
import { t } from "i18next";
import { FC } from "react";
import { CardHeaderAction } from "./CardHeaderAction";

const EQUIVALENTS_WHITELIST = [
  {
    icon: Events,
    key: "individuals"
  },
  {
    icon: RoadWeather,
    key: "kilometersByElectricCar"
  },
  {
    icon: BatteryCharging,
    key: "phoneCharges"
  }
];

export const MyImpactCardFragment = graphql(`
  fragment MyImpactCard on MyImpactCalculation {
    tier {
      level
      value
    }
    equivalents {
      key
      value
    }
  }
`);

export const MyImpactCard: FC<{
  data?: FragmentOf<typeof MyImpactCardFragment>;
}> = ({ data }) => {
  const values = readFragment(MyImpactCardFragment, data);

  const findEquivalent = (key: string) =>
    values?.equivalents?.find((e) => e.key === key);

  return (
    <Card className="bg-bg-white">
      <CardHeader
        title={t("dashboard.cards.myImpact.title")}
        headingLevel={2}
        end={
          <CardHeaderAction
            href="/my-impact?hasCalculated=false"
            label={t("dashboard.cards.myImpact.calculate")}
          />
        }
      />
      <CardBody>
        <div className="grid 2xl:grid-cols-3 gap-4 2xl:gap-8">
          <div className="text-sm my-auto">
            <p
              dangerouslySetInnerHTML={{
                __html: t("dashboard.cards.myImpact.text", {
                  value: values?.tier.value,
                  level: values?.tier.level
                })
              }}
            ></p>
            <br />
            <p>
              {t("dashboard.cards.myImpact.linkText")}{" "}
              <br className="max-2xl:hidden" />
              <Link
                href="/my-impact?hasCalculated=true"
                className="underline hover:no-underline"
              >
                <b>{t("dashboard.cards.myImpact.impactCalculator")}</b>
              </Link>
              .
            </p>
          </div>
          <div className="grid col-span-2 xl:grid-cols-3 gap-x-4 gap-y-6 xl:gap-y-4">
            {EQUIVALENTS_WHITELIST.map(({ key, icon }) => {
              const item = findEquivalent(key);
              if (!item) return;
              return (
                <KeyFigure
                  key={item.key}
                  // @ts-expect-error dynamic translation keys are pain with TS
                  label={t(`enums.co2Equivalents.${item.key}`)}
                  value={item.value}
                  Icon={icon}
                />
              );
            })}
          </div>
        </div>
      </CardBody>
    </Card>
  );
};
