import {
  ProjectData,
  ProjectItemFragment,
  ProjectStatusType
} from "@/app/project-overview/hooks/useProjectData";
import { ProjectStatus } from "@/app/project-overview/PojectStatus";
import { ProjectActions } from "@/app/project-overview/ProjectActions";
import { graphql, readFragment } from "@/lib/data/graphql";
import { useTranslation } from "@/lib/i18n";
import { toaster } from "@/ui/feedback/toaster";
import { TableCell, TableRow } from "@/ui/table/table";
import { useMutation } from "@apollo/client";

const DeleteProjectMutation = graphql(
  `
    mutation DeleteProject($input: DeleteProjectBillInput!) {
      deleteProjectBill(deleteInput: $input) {
        ...ProjectItem
      }
    }
  `,
  [ProjectItemFragment]
);

const RefreshProjectMutation = graphql(
  `
    mutation RefreshProject($input: RefreshProjectBillInput!) {
      refreshProjectBill(refreshInput: $input) {
        ...ProjectItem
      }
    }
  `,
  [ProjectItemFragment]
);

type ProjectItemProps = {
  data: ProjectData;
  onStatusChange: (projectId: string, status: ProjectStatusType) => void;
  disabled?: boolean;
  quarter: number;
  year: number;
};

export const ProjectItem: React.FC<ProjectItemProps> = ({
  data,
  onStatusChange,
  disabled,
  quarter,
  year
}) => {
  const { t } = useTranslation();

  const project = readFragment(ProjectItemFragment, data);

  const [deleteProject] = useMutation(DeleteProjectMutation);

  const handleDelete = () => {
    deleteProject({
      variables: {
        input: {
          quarter,
          year,
          plantId: parseInt(project.plantId, 10)
        }
      },
      onCompleted: () => {
        toaster.success({
          title: t("project.actions.deleteBill.success")
        });
      },
      onError: () => {
        toaster.error({
          title: t("project.actions.deleteBill.error")
        });
      }
    });
  };

  const [refreshProject, { loading: regenerateLoading }] = useMutation(
    RefreshProjectMutation
  );
  const handleRefresh = () => {
    refreshProject({
      variables: {
        input: {
          quarter,
          year,
          plantId: parseInt(project.plantId, 10),
          status: project.status
        }
      },
      onCompleted: () => {
        toaster.success({
          title: t("project.actions.regenerate.success")
        });
      },
      onError: () => {
        toaster.error({
          title: t("project.actions.regenerate.error")
        });
      }
    });
  };

  return (
    <TableRow
      key={project.id}
      className="border-b border-border-primary hover:bg-zinc-50 dark:hover:bg-zinc-900 transition-colors"
    >
      <TableCell className="font-bold max-w-60 truncate">
        {project.name}
      </TableCell>
      <TableCell>
        <ProjectStatus
          projectData={data}
          disabled={disabled}
          onChange={onStatusChange}
        />
      </TableCell>
      <TableCell>
        <div className="w-full flex justify-end">
          <ProjectActions
            onDelete={handleDelete}
            onRegenerate={handleRefresh}
            regenerateLoading={regenerateLoading}
            disabled={disabled && !project.documentUrl}
            documentName={project.documentName}
            documentUrl={project.documentUrl}
          />
        </div>
      </TableCell>
    </TableRow>
  );
};
