import { Drawer } from "@/ui/Drawer";
import { TablePagination } from "@/ui/table/TablePagination";
import { useAtom } from "jotai";
import { paginationAtom } from "./atoms/technicalLogMessagesListAtoms";
import { TechnicalLogMessagesList } from "./TechnicalLogMessagesList";
import { TechnicalLogMessaagesListFilterWhereInput } from "./TechnicalLogMessagesListFilter";

export type TechnicalLogMessagesDrawerProps = {
  title: string;
  filter?: TechnicalLogMessaagesListFilterWhereInput;
  isOpen: boolean;
  onClose: () => void;
};

export const TechnicalLogMessagesDrawer: React.FC<
  TechnicalLogMessagesDrawerProps
> = ({ title, filter, isOpen, onClose }) => {
  const [pagination] = useAtom(paginationAtom);
  const { currentPage, totalPages, loading } = pagination;

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      actions={
        <div className="grow">
          <TablePagination
            currentPage={currentPage}
            totalPages={totalPages}
            loading={loading}
          />
        </div>
      }
    >
      <div className="h-full">
        <TechnicalLogMessagesList filter={filter} />
      </div>
    </Drawer>
  );
};
