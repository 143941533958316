import {
  ProjectItemFragment,
  useProjectData
} from "@/app/project-overview/hooks/useProjectData";
import { PaymentDateDialog } from "@/app/project-overview/PaymentDateDialog";
import {
  FiltersFields,
  ProjectOverviewFilters
} from "@/app/project-overview/ProjectOverviewFilters";
import { ProjectOverviewProgress } from "@/app/project-overview/ProjectOverviewProgress";
import { ProjectsTable } from "@/app/project-overview/ProjectsTable";
import { graphql } from "@/lib/data/graphql";
import { useTranslation } from "@/lib/i18n";
import { Divider } from "@/ui/display/divider";
import { PageWrapper } from "@/ui/layouts/page-wrapper";
import { LoadingSpinner } from "@/ui/loading-spinner";
import { Heading } from "@/ui/typography/heading";
import { useQuery } from "@apollo/client";
import clsx from "clsx";
import { getQuarter, getYear } from "date-fns";
import { useState } from "react";

function getCurrentYearAndQuarter() {
  const now = new Date();
  return { year: getYear(now), quarter: getQuarter(now) };
}

export const ProjectOverviewPageQuery = graphql(
  `
    query ProjectOverviewPage($where: ProjectsOverviewWhereInput!) {
      adminProjectsOverview(where: $where) {
        id
        quarter
        year
        provisionallyApprovedLimit
        paymentDate
        projects {
          ...ProjectItem
        }
      }
    }
  `,
  [ProjectItemFragment]
);

export const ProjectOverviewPage = () => {
  const { t } = useTranslation();
  const [filters, setFilters] = useState<FiltersFields>(
    getCurrentYearAndQuarter()
  );
  const [showPaymentDateDialog, setShowPaymentDateDialog] = useState(false);

  const { data, loading } = useQuery(ProjectOverviewPageQuery, {
    variables: {
      where: {
        year: filters.year,
        quarter: filters.quarter
      }
    }
  });

  const paymentDate = data?.adminProjectsOverview.paymentDate;
  const provisionallyApprovedLimit =
    data?.adminProjectsOverview.provisionallyApprovedLimit ?? 0;
  const {
    projectData,
    handleStatusChange,
    provisionallyApprovedPercentage,
    approvedPercentage
  } = useProjectData({
    projects: data?.adminProjectsOverview.projects
  });

  const conditionsAreMet =
    approvedPercentage === 100 &&
    provisionallyApprovedPercentage <= provisionallyApprovedLimit;

  return (
    <PageWrapper>
      <Heading>{t("projectOverview.title")}</Heading>
      <Divider />

      <ProjectOverviewFilters filters={filters} setFilters={setFilters} />
      <Divider />

      {loading && (
        <div className="absolute w-full flex mt-14 items-center justify-center">
          <LoadingSpinner />
        </div>
      )}

      {projectData.length === 0 && !loading ? (
        <div className="flex justify-center items-center h-64">
          <p>{t("projectOverview.noProjects")}</p>
        </div>
      ) : (
        <div
          className={clsx("flex flex-col lg:flex-row flex-wrap gap-4", {
            "opacity-40": loading
          })}
        >
          <div className="w-full md:max-w-[360px] xl:max-w-[320px]">
            {projectData.length > 0 ? (
              <ProjectOverviewProgress
                approvedPercentage={approvedPercentage}
                provisionallyApprovedPercentage={
                  provisionallyApprovedPercentage
                }
                provisionallyApprovedLimit={provisionallyApprovedLimit}
                conditionsAreMet={conditionsAreMet}
                paymentDate={paymentDate}
                onOpenPaymentDateDialog={() => setShowPaymentDateDialog(true)}
              />
            ) : null}
          </div>
          <div className="w-full overflow-x-hidden xl:w-auto xl:flex-1">
            <div className="bg-bg-primary p-4 rounded-lg border border-border-primary">
              <ProjectsTable
                data={projectData}
                disabled={Boolean(paymentDate)}
                onStatusChange={handleStatusChange}
                year={filters.year}
                quarter={filters.quarter}
              />
            </div>
          </div>
        </div>
      )}

      <PaymentDateDialog
        isOpen={showPaymentDateDialog}
        onClose={() => setShowPaymentDateDialog(false)}
        projects={projectData}
        quarter={filters.quarter}
        year={filters.year}
      />
    </PageWrapper>
  );
};
