import { DEMO_ACCOUNT } from "@/constants";
import { MongoAbility } from "@casl/ability";
import * as Sentry from "@sentry/capacitor";
import {
  type Auth,
  multiFactor,
  onAuthStateChanged,
  type User
} from "firebase/auth";
import React, { createContext, useEffect, useState } from "react";
import { type MeFragmentType } from "./Me.fragment";

type Role = "admin" | "investor" | "owner";

export type SessionContextProps = {
  loading: boolean;
  error: Error | null;
  accessToken?: string;
  auth: Auth;
  me: MeFragmentType | null;
  setMe: (user: MeFragmentType | null) => void;
  isSignedIn: () => boolean;
  firebaseUser: User | null;
  ability: MongoAbility | null;
  setAbility: (user: MongoAbility | null) => void;
  hasRole: (role: Role) => boolean;
  isUserEnrolled: (user: User | null) => boolean;
};

export const SessionContext = createContext<SessionContextProps | undefined>(
  undefined
);

export function getSessionProvider(auth: Auth) {
  return function SessionProvider({ children }: React.PropsWithChildren) {
    const [firebaseUser, setFirebaseUser] = useState<
      SessionContextProps["firebaseUser"]
    >(auth.currentUser);
    const [me, setMe] = useState<SessionContextProps["me"]>(null);
    const [accessToken, setAccessToken] =
      useState<SessionContextProps["accessToken"]>();
    const [ability, setAbility] =
      useState<SessionContextProps["ability"]>(null);
    const [loading, setLoading] = useState<SessionContextProps["loading"]>(
      !auth.currentUser
    );
    const [error, setError] = useState<SessionContextProps["error"]>(null);

    useEffect(() => {
      const unsubscribe = onAuthStateChanged(
        auth,
        (user) => {
          setFirebaseUser(user);
          setLoading(false);
          if (user) {
            Sentry.setUser({
              id: user.uid,
              email: user.email || undefined
            });
          }
        },
        (error) => {
          setError(error);
          setLoading(false);
        }
      );

      return () => unsubscribe();
    }, []);

    useEffect(() => {
      if (firebaseUser) {
        const getIdToken = async () => {
          const token = await firebaseUser.getIdToken();
          setAccessToken(token);
        };

        getIdToken();
      } else {
        setAccessToken(undefined);
      }
    }, [firebaseUser]);

    const isUserEnrolled = (user: User | null) => {
      if (!user) {
        return false;
      }

      // If it's the demo user, consider MFA as completed
      if (user.email === DEMO_ACCOUNT.email) {
        return true;
      }

      const enrolled = multiFactor(user).enrolledFactors.length > 0;
      return enrolled;
    };

    const isSignedIn = () => !!firebaseUser;

    const hasRole = (role: Role) => !!me?.roles.find((r) => r.name === role);

    const value = {
      me,
      loading,
      error,
      accessToken,
      auth,
      setMe,
      isSignedIn,
      firebaseUser,
      ability,
      setAbility,
      hasRole,
      isUserEnrolled
    };

    return (
      <SessionContext.Provider value={value}>
        {children}
      </SessionContext.Provider>
    );
  };
}
