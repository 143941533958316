import { useTranslation } from "@/lib/i18n";
import {
  FILTER_OPTIONS,
  FilterOption,
  getDateRange,
  TimeScaleKeyType
} from "@/ui/date/date-utils";
import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownMenu
} from "@/ui/form/dropdown";
import { FunnelIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import React, { ReactNode, useCallback } from "react";

export type DatePresetsDropdownProps = {
  onChange?: (value: FilterOption) => void;
  // @deprecated
  setDateFrom?: (date: Date | null) => void;
  // @deprecated
  setDateTo?: (date: Date | null) => void;
  // @deprecated
  setActiveShortCut?: (shortcut: TimeScaleKeyType) => void;
  icon?: ReactNode;
};

export const DatePresetsDropdown: React.FC<DatePresetsDropdownProps> = ({
  onChange,
  setDateFrom,
  setDateTo,
  setActiveShortCut,
  icon
}) => {
  const { t } = useTranslation();

  const handleClick = (filterOption: FilterOption) => {
    onChange?.(filterOption);
  };

  // @deprecated
  const deprecatedHandleOnCLick = useCallback(
    (filterOption: FilterOption) => {
      const { startDate, endDate, shortcut } = getDateRange(filterOption);
      setDateFrom?.(startDate);
      setDateTo?.(endDate);
      setActiveShortCut?.(shortcut);
    },
    [setDateFrom, setDateTo, setActiveShortCut]
  );

  return (
    <Dropdown>
      <DropdownButton outline>
        {icon ?? <FunnelIcon />}
        <span className="sm:hidden text-sm font-normal">
          {t("dateFilter.quickSelector.label")}
        </span>
      </DropdownButton>
      <DropdownMenu>
        {FILTER_OPTIONS.map((filterOption) => (
          <DropdownItem
            key={filterOption}
            onClick={() => {
              handleClick(filterOption);
              deprecatedHandleOnCLick(filterOption);
            }}
            className={clsx({
              "mb-4 relative after:absolute after:content-[''] after:-bottom-2 after:left-0 after:w-full after:h-[1px] after:bg-border-primary":
                filterOption === "thisYear"
            })}
          >
            {t(`dateFilter.quickSelector.option.${filterOption}`)}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};
