import clsx from "clsx";
import { FC, PropsWithChildren } from "react";

export type FabButtonProps = {
  position?:
    | "bottom-right"
    | "bottom-center"
    | "bottom-left"
    | "top-left"
    | "top-right"
    | "top-center";
  top?: string;
  right?: string;
  bottom?: string;
  left?: string;
  onClick?: () => void;
  className?: string;
};

export const FabButton: FC<PropsWithChildren<FabButtonProps>> = ({
  children,
  position = "bottom-right",
  top = "0px",
  right = "0px",
  bottom = "0px",
  left = "0px",
  onClick,
  className
}) => {
  const positionClasses = {
    "bottom-right":
      "bottom-[calc(env(safe-area-inset-bottom)+theme(spacing.6)+var(--fab-bottom))] right-[calc(env(safe-area-inset-right)+theme(spacing.6)+var(--fab-right))]",
    "bottom-center":
      "left-1/2 bottom-[calc(env(safe-area-inset-bottom)+theme(spacing.6)+var(--fab-bottom))] -translate-x-1/2",
    "bottom-left":
      "bottom-[calc(env(safe-area-inset-bottom)+theme(spacing.6)+var(--fab-bottom))] left-[calc(env(safe-area-inset-left)+theme(spacing.6)+var(--fab-left))]",
    "top-left":
      "top-[calc(env(safe-area-inset-top)+theme(spacing.6)+var(--fab-top))] left-[calc(env(safe-area-inset-left)+theme(spacing.6)+var(--fab-left))]",
    "top-right":
      "top-[calc(env(safe-area-inset-top)+theme(spacing.6)+var(--fab-top))] right-[calc(env(safe-area-inset-right)+theme(spacing.6)+var(--fab-right))]",
    "top-center":
      "top-[calc(env(safe-area-inset-top)+theme(spacing.6)+var(--fab-top))] left-1/2 -translate-x-1/2"
  };

  return (
    <div
      style={{
        "--fab-top": top,
        "--fab-right": right,
        "--fab-bottom": bottom,
        "--fab-left": left
      }}
      className={clsx(
        "fixed",
        positionClasses[position],
        "bg-bg-primary rounded-full",
        className
      )}
    >
      <button
        className={clsx(
          "flex items-center justify-center w-14 h-14 rounded-full",
          "bg-bg-primary text-content-primary border border-border-primary shadow-lg hover:bg-bg-hover",
          "focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-content-primary",
          "transition-colors"
        )}
        onClick={onClick}
      >
        {children}
      </button>
    </div>
  );
};
