import { AuthError, useSignInWithEmailAndPassword } from "@/lib/auth";
import { useTranslation } from "@/lib/i18n";
import { Button } from "@/ui/button/button";
import { FormComponentProps } from "@/ui/form/form";
import { InputControlled } from "@/ui/form/InputControlled";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import { UserCredential } from "firebase/auth";
import { useAtom } from "jotai";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { disabledAtom } from "./atoms/loginAtoms";

export type EmailAndPasswordFormProps = FormComponentProps<
  EmailAndPasswordFormValues,
  UserCredential,
  AuthError
>;

export type EmailAndPasswordFormValues = {
  email: string;
  password: string;
};

export const EmailAndPasswordForm = (props: EmailAndPasswordFormProps) => {
  const { onError, onSuccess, onSubmit, disabled } = props;
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const [singIn, , loading] = useSignInWithEmailAndPassword();
  const [, setDisabled] = useAtom(disabledAtom);

  const { handleSubmit, control } = useForm<EmailAndPasswordFormValues>();

  const handle = async (data: EmailAndPasswordFormValues) => {
    onSubmit?.(data);
    setDisabled(true);
    try {
      const result = await singIn(data.email, data.password);
      onSuccess?.(result);
    } catch (error) {
      onError?.(error as AuthError);
    } finally {
      setDisabled(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(handle)} className="flex flex-col gap-4 mt-4">
      <InputControlled
        label={t("login.email")}
        placeholder={t("login.email")}
        id="email"
        type="email"
        name="email"
        control={control}
      />
      <div className="relative">
        <InputControlled
          label={t("login.password")}
          placeholder={t("login.password")}
          id="password"
          name="password"
          control={control}
          type={showPassword ? "text" : "password"}
        />
        <button
          disabled={loading || disabled}
          onClick={() => setShowPassword(!showPassword)}
          type="button"
          aria-label="Show/Hide password"
          className="absolute top-10 right-3"
        >
          {showPassword ? (
            <EyeSlashIcon
              className="text-gray-500 dark:text-gray-400 h-[1em] w-[1em]"
              fontSize="18"
            />
          ) : (
            <EyeIcon
              className="text-gray-500 dark:text-gray-400 h-[1em] w-[1em]"
              fontSize="18"
            />
          )}
        </button>
      </div>
      <a className="text-xs text-right hover:underline" href="/forgot-password">
        {t("login.forgotPassword")}
      </a>
      <Button
        id="submit"
        type="submit"
        size="lg"
        className="w-full mt-4"
        loading={loading}
        outline
      >
        <span className="text-base">{t("login.signIn")}</span>
      </Button>
    </form>
  );
};
