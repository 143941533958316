import { useTranslation } from "@/lib/i18n";
import { Button } from "@/ui/button/button";
import { CalendarIcon } from "@heroicons/react/16/solid";
import React, { useState } from "react";
import { DateTimeRangeDialog } from "./date-time-range-dialog";

export interface DateTimeRangeProps {
  startDate: Date;
  endDate: Date;
  onChange: (startDate: Date, endDate: Date) => void;
  className?: string;
  hideLabel?: boolean;
}

export const DateTimeRange: React.FC<DateTimeRangeProps> = ({
  startDate,
  endDate,
  onChange,
  className,
  hideLabel = false
}) => {
  const { t } = useTranslation();
  const [isCustomPickerVisible, setIsCustomPickerVisible] = useState(false);

  return (
    <>
      <Button
        className={className}
        outline
        onClick={() => setIsCustomPickerVisible(true)}
      >
        <CalendarIcon className="size-4" />
        {!hideLabel && (
          <span className="sm:hidden text-sm font-normal">
            {t("dateFilter.customSelector.label")}
          </span>
        )}
      </Button>

      <DateTimeRangeDialog
        isOpen={isCustomPickerVisible}
        onClose={() => setIsCustomPickerVisible(false)}
        startDate={startDate}
        endDate={endDate}
        onChange={onChange}
      />
    </>
  );
};
