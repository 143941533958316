import { PerformanceTemplateFragment } from "@/app/performance/PerformanceTemplate";
import { usePerformanceContext } from "@/app/performance/state/PerformanceProvider";
import { readFragment } from "@/lib/data/graphql";
import { useTranslation } from "@/lib/i18n";
import { YAxisProps } from "@/ui/display/charts/y-axis";
import { YAxisLabel } from "@/ui/display/charts/y-axis-label";
import { useMemo } from "react";

export const useChartYAxis = (): YAxisProps[] => {
  const { t } = useTranslation();
  const {
    state: { template: templateData }
  } = usePerformanceContext();
  const templateConfig = readFragment(
    PerformanceTemplateFragment,
    templateData
  );

  return useMemo(() => {
    const config: YAxisProps[] = [];
    if (
      templateConfig?.generatedRevenue ||
      templateConfig?.generatedRevenueTarget
    ) {
      config.push({
        yAxisId: "CHF",
        orientation: "right",
        label: <YAxisLabel>{t("common.units.CHF")}</YAxisLabel>
      });
    }
    if (
      templateConfig?.producedElectricity ||
      templateConfig?.producedElectricityTarget
    ) {
      config.push({
        yAxisId: "kWh",
        orientation: "left",
        label: <YAxisLabel>{t("common.units.kWh")}</YAxisLabel>
      });
    }
    if (templateConfig?.selfConsumptionRate) {
      config.push({
        yAxisId: "consumption",
        hide: true
      });
    }
    return config;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateConfig]);
};
