import clsx, { type ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";

/**
 * If Vercel does it, it must be good.
 *
 * Stolen from: https://github.com/shadcn-ui/ui/blob/main/apps/www/lib/utils.ts#L4
 */
export function cn(...classes: ClassValue[]) {
  return twMerge(clsx(classes));
}
