import {
  NotificationSettingsForm,
  NotificationSettingsFormFragment
} from "@/app/settings/NotificationSettingsForm";
import { graphql } from "@/lib/data/graphql";
import { useTranslation } from "@/lib/i18n";
import { Divider } from "@/ui/display/divider";
import { Alert } from "@/ui/feedback/alert";
import { SubPageWrapper } from "@/ui/layouts/sub-page-wrapper";
import { LoadingSpinner } from "@/ui/loading-spinner";
import { Heading } from "@/ui/typography/heading";
import { useQuery } from "@apollo/client";

export const NotificationsSettingPageQuery = graphql(
  `
    query NotificationSettingsPage {
      me {
        ...NotificationSettingsForm
      }
    }
  `,
  [NotificationSettingsFormFragment]
);

export const NotificationSettings = () => {
  const { t } = useTranslation();

  const { data, loading } = useQuery(NotificationsSettingPageQuery);

  if (loading) {
    return (
      <div className="flex mt-14 items-center justify-center grow">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <SubPageWrapper>
      <Heading level={2}>{t("settings.notifications.title")}</Heading>
      <Divider />

      {data?.me ? (
        <NotificationSettingsForm data={data.me} />
      ) : (
        <Alert type="error" title={t("error.generic.description")} />
      )}
    </SubPageWrapper>
  );
};
