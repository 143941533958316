import { DeleteUserDialog } from "@/app/user-management/DeleteUserDialog";
import {
  UpdateUserDialog,
  UpdateUserDialogFragment
} from "@/app/user-management/EditUserDialog";
import { useSession } from "@/lib/auth";
import { FragmentOf, graphql, readFragment } from "@/lib/data/graphql";
import { useTranslation } from "@/lib/i18n";
import { Badge } from "@/ui/display/badge";
import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownMenu
} from "@/ui/form/dropdown";
import { TableCell, TableRow } from "@/ui/table/table";
import { EllipsisHorizontalIcon } from "@heroicons/react/16/solid";
import clsx from "clsx";
import { useCallback, useState } from "react";

export const UserItemFragment = graphql(`
  fragment UserItem on User {
    id
    uid
    email
    firstName
    lastName
    phoneNumber # TODO(BE): not fetched?
    roles {
      id
      name
    }
  }
`);

type UserItemProps = {
  data: FragmentOf<typeof UserItemFragment>;
};

// TODO: RBAC deeply checks
export const UserItem: React.FC<UserItemProps> = ({ data }) => {
  const { id, firstName, lastName, email, roles } = readFragment(
    UserItemFragment,
    data
  );

  return (
    <TableRow
      key={id}
      className="border-b border-zinc-200 dark:border-zinc-700 hover:bg-zinc-100 dark:hover:bg-zinc-800 transition-colors"
    >
      <TableCell>{firstName + " " + lastName}</TableCell>
      <TableCell>{email}</TableCell>
      <TableCell>
        {roles.map((role) => (
          <Badge color="zinc" key={role.id} className="mr-1">
            {/* TODO: Should we care about any translations here? */}
            {role.name}
          </Badge>
        ))}
      </TableCell>

      <TableCell>
        <UserItemActions user={data} />
      </TableCell>
    </TableRow>
  );
};

type UserItemActionsProps = {
  user: FragmentOf<typeof UserItemFragment>;
};

const UserItemActions: React.FC<UserItemActionsProps> = ({ user }) => {
  const { t } = useTranslation();
  const ability = useSession().ability;
  const ableToDoAction =
    ability?.can("update", "users") || ability?.can("delete", "users");

  const [openDialogKey, setOpenDialogKey] = useState("");

  const handleCloseDialog = useCallback(() => {
    setOpenDialogKey("");
  }, []);

  return (
    <>
      <Dropdown>
        <DropdownButton
          plain
          aria-label={t("users.listing.actions.label")}
          disabled={!ableToDoAction}
          className={clsx({ "cursor-not-allowed": !ableToDoAction })}
        >
          <EllipsisHorizontalIcon />
        </DropdownButton>
        <DropdownMenu>
          {ability?.can("update", "users") ? (
            <DropdownItem onClick={() => setOpenDialogKey("editUser")}>
              {t("users.listing.actions.edit.label")}
            </DropdownItem>
          ) : null}
          {ability?.can("delete", "users") ? (
            <DropdownItem onClick={() => setOpenDialogKey("deleteUser")}>
              {t("users.listing.actions.remove.label")}
            </DropdownItem>
          ) : null}
        </DropdownMenu>
      </Dropdown>

      {ability?.can("update", "users") ? (
        <UpdateUserDialog
          isOpen={openDialogKey === "editUser"}
          data={user as unknown as FragmentOf<typeof UpdateUserDialogFragment>}
          onClose={handleCloseDialog}
        />
      ) : null}

      {ability?.can("delete", "users") ? (
        <DeleteUserDialog
          isOpen={openDialogKey === "deleteUser"}
          // TODO(BE): uid xs id
          userId={readFragment(UserItemFragment, user).uid}
          // TODO(BE): again with enabled fetching one user per id, move to fragments
          userEmail={readFragment(UserItemFragment, user).email}
          onClose={handleCloseDialog}
        />
      ) : null}
    </>
  );
};
