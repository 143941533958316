import { DEFAULT_HOUSEHOLD_CONSUMPTION } from "@/constants";
import { graphql } from "@/lib/data/graphql";
import { useQuery } from "@apollo/client";
import { FC } from "react";
import { ElectricityProductionCard } from "./ElectricityProductionCard";
import { MyImpactCard, MyImpactCardFragment } from "./MyImpactCard";
import { PlantsMapCard, PlantsMapCardFragment } from "./PlantsMapCard";
import {
  PortfolioKeyFiguresCard,
  PortfolioKeyFiguresCardFragment
} from "./PortfolioKeyFiguresCard";

export const InvestorDashboardQuery = graphql(
  `
    query InvestorDashboard($consumption: Int!) {
      myImpactCalculation(consumption: $consumption) {
        ...MyImpactCard
      }
      portfolioKeyFigures {
        ...PortfolioKeyFiguresCard
      }
      ...PlantsMapCard
    }
  `,
  [MyImpactCardFragment, PlantsMapCardFragment, PortfolioKeyFiguresCardFragment]
);

export const InvestorDashboard: FC = () => {
  const { data } = useQuery(InvestorDashboardQuery, {
    fetchPolicy: "no-cache",
    variables: { consumption: DEFAULT_HOUSEHOLD_CONSUMPTION }
  });

  return (
    <div>
      <div className="grid xl:grid-cols-3 gap-y-6 xl:gap-x-6 mb-6">
        <div className="col-span-2">
          <PlantsMapCard data={data} />
        </div>
        <div className="col-span-1">
          <ElectricityProductionCard />
        </div>
      </div>
      <div className="grid md:grid-cols-2 gap-6">
        <PortfolioKeyFiguresCard data={data?.portfolioKeyFigures} />
        <MyImpactCard data={data?.myImpactCalculation} />
      </div>
    </div>
  );
};
