import {
  PerformanceTemplateForm,
  PerformanceTemplateFormValues
} from "@/app/performance/template-management/PerformanceTemplateForm";
import { PerformanceTemplateFormFragment } from "@/app/performance/template-management/UpdatePerformanceTemplateDrawer";
import { graphql } from "@/lib/data/graphql";
import { useTranslation } from "@/lib/i18n";
import { Button } from "@/ui/button/button";
import { Drawer } from "@/ui/Drawer";
import { toaster } from "@/ui/feedback/toaster";
import { useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";

const AddPerformanceTemplateMutation = graphql(
  `
    mutation AddPerformanceTemplate($input: PerformanceTemplateInput!) {
      addPerformanceTemplate(performanceTemplateInput: $input) {
        ...PerformanceTemplateForm
      }
    }
  `,
  [PerformanceTemplateFormFragment]
);

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const AddPerformanceTemplateDrawer: React.FC<Props> = ({
  isOpen,
  onClose
}) => {
  const { t } = useTranslation();

  const form = useForm<PerformanceTemplateFormValues>({
    defaultValues: {
      name: "",
      generatedRevenue: true,
      generatedRevenueTarget: true,
      producedElectricity: false,
      producedElectricityTarget: false,
      selfConsumptionRate: true,
      plants: []
    }
  });
  const {
    formState: { isValid: isFormValid }
  } = form;

  const [addPerformanceTemplate, { loading }] = useMutation(
    AddPerformanceTemplateMutation
  );

  const handleSubmit = ({
    name,
    generatedRevenue,
    generatedRevenueTarget,
    producedElectricity,
    producedElectricityTarget,
    selfConsumptionRate,
    plants
  }: PerformanceTemplateFormValues) => {
    addPerformanceTemplate({
      variables: {
        input: {
          name,
          generatedRevenue,
          generatedRevenueTarget,
          producedElectricity,
          producedElectricityTarget,
          selfConsumptionRate,
          plantIds: plants.map((p) => parseInt(p, 10))
        }
      },
      onCompleted: () => {
        onClose();
        form.reset();
      },
      onError: () => {
        toaster.error({
          title: t("performanceTemplates.form.actions.add.error")
        });
        form.setError("root", {
          type: "api",
          message: t("performanceTemplates.form.actions.add.error")
        });
      }
    });
  };

  return (
    <Drawer
      isOpen={isOpen}
      closeOnOutsideClick={false}
      onClose={onClose}
      title={t("performanceTemplates.form.actions.add.title")}
      actions={
        <>
          <Button outline onClick={onClose}>
            {t("performanceTemplates.form.actions.close.label")}
          </Button>
          <Button
            onClick={form.handleSubmit(handleSubmit)}
            disabled={!isFormValid}
            loading={loading}
          >
            {t("performanceTemplates.form.actions.add.label")}
          </Button>
        </>
      }
    >
      <div className="pt-2.5 pb-4">
        <PerformanceTemplateForm onSubmit={handleSubmit} form={form} />
      </div>
    </Drawer>
  );
};
