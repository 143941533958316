import { MyImpactCalculatorCard } from "@/app/impact/calculator/MyImpactCalculatorCard";
import { ProducedElectricityCard } from "@/app/impact/ProducedElectricityCard";
import { useTranslation } from "@/lib/i18n";
import { PageHeading } from "@/ui/layouts/page-heading";
import { PageWrapper } from "@/ui/layouts/page-wrapper";

export const MyImpactPage = () => {
  const { t } = useTranslation();

  return (
    <PageWrapper>
      <PageHeading title={t("myImpact.title")} />
      <div className="grid pt-1 gap-6">
        <ProducedElectricityCard />

        <MyImpactCalculatorCard />
      </div>
    </PageWrapper>
  );
};
