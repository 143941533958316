import { useTranslation } from "@/lib/i18n";
import { Button } from "@/ui/button/button";
import { DateTimeRangeForm } from "@/ui/date/date-time-range-form";
import { Dialog, DialogActions, DialogTitle } from "@/ui/feedback/dialog";
import React from "react";

type DateFilterCustomDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  onChange: (startDate: Date, endDate: Date) => void;
  startDate: Date;
  endDate: Date;
};

export const DateTimeRangeDialog: React.FC<DateFilterCustomDialogProps> = ({
  startDate,
  endDate,
  isOpen,
  onClose,
  onChange
}) => {
  const { t } = useTranslation();

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>{t("dateFilter.customDates.title")}</DialogTitle>

      <DateTimeRangeForm
        startDate={startDate}
        endDate={endDate}
        onChange={onChange}
      />

      <DialogActions>
        <Button outline onClick={onClose}>
          {t("dialog.close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
