import { FragmentOf, graphql } from "@/lib/data/graphql";
import { LineChart, LineChartSeries } from "@/ui/display/charts/line-chart";
import { format } from "date-fns";
import { t } from "i18next";
import { FC } from "react";

export const ElectricityProductionChartFragment = graphql(`
  fragment ElectricityProductionChart on PerformanceValues @_unmask {
    id
    date
    pYield
  }
`);

export type ElectricityProductionChartData = FragmentOf<
  typeof ElectricityProductionChartFragment
>;

export const ElectricityProductionChart: FC<{
  data: ElectricityProductionChartData[];
}> = ({ data }) => {
  const series: LineChartSeries<ElectricityProductionChartData>[] = [
    {
      dataKey: "pYield",
      name: t("dashboard.cards.electricityProduction.tooltip.pYield"),
      renderProps: {
        stroke: "var(--color-yellow-300)",
        strokeWidth: 4
      }
    }
  ];

  return (
    <>
      <LineChart
        data={data}
        series={series}
        xAxisKey="date"
        xAxisProps={{
          ticks: [data[0]?.date ?? 0, data[data.length - 1]?.date ?? 1],
          tickCount: 2,
          tickFormatter: (value) =>
            value ? `${format(new Date(value), "HH:mm")}` : ""
        }}
        yAxisProps={{
          tickFormatter: (value) => (value ? Number(value).toFixed(2) : "")
        }}
        tooltipProps={{
          labelFormatter: (label) => format(new Date(label), "dd.M.yyyy HH:mm"),
          valueFormatter: (value) =>
            `${Number(value).toFixed(2)} ${t("common.units.kWh")}`
        }}
      />
    </>
  );
};
