import { useSession } from "@/lib/auth";
import { useTranslation } from "@/lib/i18n";
import { PageHeading } from "@/ui/layouts/page-heading";
import { PageWrapper } from "@/ui/layouts/page-wrapper";
import { InvestorDashboard } from "./InvestorDashboard";
import { OwnerDashboard } from "./OwnerDashboard";

export const DashboardPage = () => {
  const { hasRole } = useSession();
  const { t } = useTranslation();

  const Dashboard =
    hasRole("investor") || hasRole("admin")
      ? InvestorDashboard
      : OwnerDashboard;

  return (
    <PageWrapper>
      <PageHeading title={t("dashboard.title")} />
      <Dashboard />
    </PageWrapper>
  );
};
