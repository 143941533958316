import { ITEMS_PER_PAGE_OPTIONS } from "@/ui/table/TablePagination";
import { QueryHookOptions, TypedDocumentNode, useQuery } from "@apollo/client";
import type { OperationVariables } from "@apollo/client/core";
import { useEffect, useMemo, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";

type ExtractWhereVariable<T> = T extends { where?: infer U } ? U : never;
type ExtractOrderByVariable<T> = T extends { orderBy?: infer U } ? U : never;

type PaginatedQueryOptions<TResult, TVariables extends OperationVariables> = {
  query: TypedDocumentNode<TResult, TVariables>;
  initialVariables?: {
    orderBy?: ExtractOrderByVariable<TVariables>;
    where?: ExtractWhereVariable<TVariables>;
    currentPage?: number;
    itemsPerPage?: number;
  };
  queryOptions?: QueryHookOptions<TResult, TVariables>;
  extractCountFromQuery: (data: TResult) => number;
};

export const usePaginatedQuery = <
  TResult,
  TVariables extends OperationVariables
>({
  query,
  initialVariables = {},
  queryOptions,
  extractCountFromQuery
}: PaginatedQueryOptions<TResult, TVariables>) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentPage, setCurrentPage] = useState(
    initialVariables.currentPage ||
      parseInt(searchParams.get("page") ?? "1", 10)
  );
  const [where, setWhere] = useState(initialVariables.where || null);
  const [orderBy, setOrderBy] = useState(initialVariables.orderBy || null);
  const [itemsPerPage, setItemsPerPage] = useState(
    initialVariables.itemsPerPage ||
      parseInt(searchParams.get("take") ?? `${ITEMS_PER_PAGE_OPTIONS[0]}`, 10)
  );
  const previousTotalPagesRef = useRef<number>(0);

  // main data fetching query
  const queryResult = useQuery(query, {
    variables: {
      where,
      orderBy,
      skip: (currentPage - 1) * itemsPerPage,
      take: itemsPerPage
    } as unknown as TVariables,
    fetchPolicy: "cache-and-network",
    ...queryOptions
  });

  // get totalPage, extractTotalCount maps totalCount with model form gql query
  const totalPages = useMemo(() => {
    if (queryResult.loading) {
      return previousTotalPagesRef.current;
    }

    const totalCount = queryResult?.data
      ? extractCountFromQuery(queryResult.data)
      : 0;
    const calculatedTotalPages = Math.ceil(totalCount / itemsPerPage);

    previousTotalPagesRef.current = calculatedTotalPages;
    return calculatedTotalPages;
  }, [
    extractCountFromQuery,
    queryResult.data,
    queryResult.loading,
    itemsPerPage
  ]);

  // 'state' is stored in URL, sync React state with URL values
  useEffect(() => {
    const pageParam = parseInt(searchParams.get("page") || "1", 10);
    const takeParam = parseInt(
      searchParams.get("take") || `${ITEMS_PER_PAGE_OPTIONS[0]}`,
      10
    );

    // calculate total pages with the latest itemsPerPage from URL
    const totalCount = queryResult?.data
      ? extractCountFromQuery(queryResult.data)
      : 0;
    const calculatedTotalPages = Math.ceil(totalCount / takeParam);

    // sync itemsPerPage with url parameter, updating if necessary
    if (itemsPerPage !== takeParam) {
      setItemsPerPage(takeParam);

      // ensure currentPage does not exceed calculatedTotalPages
      if (calculatedTotalPages > 0 && currentPage > calculatedTotalPages) {
        setCurrentPage(calculatedTotalPages);
        setSearchParams((prev) => {
          const updatedParams = new URLSearchParams(prev);
          updatedParams.set("page", calculatedTotalPages.toString());
          return updatedParams;
        });
      }
    }

    // sync currentPage with url parameter or reset to 1 if invalid
    if (currentPage !== pageParam) {
      const validPage =
        pageParam >= 1 && pageParam <= calculatedTotalPages ? pageParam : 1;
      setCurrentPage(validPage);

      if (validPage !== pageParam) {
        // if the url param is invalid, update it to a valid page number
        setSearchParams((prev) => {
          const updatedParams = new URLSearchParams(prev);
          updatedParams.set("page", validPage.toString());
          return updatedParams;
        });
      }
    }
  }, [
    currentPage,
    itemsPerPage,
    searchParams,
    queryResult.data,
    extractCountFromQuery,
    setSearchParams
  ]);

  // first sync searchParams with currentPage and itemsPerPage if params missing
  useEffect(() => {
    const updatedParams = new URLSearchParams(searchParams);
    updatedParams.set("page", currentPage.toString());
    updatedParams.set("take", itemsPerPage.toString());

    // avoid updating searchParams if no changes
    if (
      updatedParams.get("page") !== searchParams.get("page") ||
      updatedParams.get("take") !== searchParams.get("take")
    ) {
      setSearchParams(updatedParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    queryResult,
    currentPage,
    totalPages,
    itemsPerPage,
    where,
    setWhere,
    orderBy,
    setOrderBy
  };
};
