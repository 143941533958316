import clsx from "clsx";
import React from "react";

export type DividerProps = {
  soft?: boolean;
  className?: string;
} & React.ComponentPropsWithoutRef<"hr">;

export const Divider: React.FC<DividerProps> = ({
  soft = false,
  className,
  ...props
}) => {
  return (
    <hr
      role="presentation"
      {...props}
      className={clsx(
        className,
        "w-full border-t",
        soft && "border-border-primary/70",
        !soft && "border-border-primary"
      )}
    />
  );
};
