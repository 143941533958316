import { State } from "./state";

export type Action =
  | { type: "SET_TEMPLATE"; payload: State["template"] }
  | {
      type: "SET_FILTERS";
      payload: State["filters"];
    }
  | {
      type: "SET_FORMATTERS";
      payload: State["formatters"];
    }
  | {
      type: "OPEN_MESSAGES_LOG_DRAWER";
      payload: boolean;
    }
  | {
      type: "SET_MESSAGES_LOG_FILTERS";
      payload: State["messagesLogFilter"];
    }
  | {
      type: "OPEN_ADD_TEMPLATE_DRAWER";
      payload: boolean;
    }
  | {
      type: "OPEN_UPDATE_TEMPLATE_DRAWER";
      payload: boolean;
    }
  | {
      type: "OPEN_ADJUST_TEMPLATE_DIALOG";
      payload: boolean;
    };

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "SET_TEMPLATE":
      return { ...state, template: action.payload };
    case "SET_FILTERS": {
      return {
        ...state,
        filters: action.payload
      };
    }
    case "SET_FORMATTERS": {
      return {
        ...state,
        formatters: action.payload
      };
    }
    case "OPEN_MESSAGES_LOG_DRAWER": {
      return {
        ...state,
        messagesLogDrawer: {
          ...state.messagesLogDrawer,
          open: action.payload
        }
      };
    }
    case "SET_MESSAGES_LOG_FILTERS": {
      return {
        ...state,
        messagesLogFilter: action.payload
      };
    }
    case "OPEN_ADD_TEMPLATE_DRAWER": {
      return {
        ...state,
        addTemplateDrawer: {
          ...state.addTemplateDrawer,
          open: action.payload
        }
      };
    }
    case "OPEN_UPDATE_TEMPLATE_DRAWER": {
      return {
        ...state,
        updateTemplateDrawer: {
          ...state.updateTemplateDrawer,
          open: action.payload
        }
      };
    }
    case "OPEN_ADJUST_TEMPLATE_DIALOG": {
      return {
        ...state,
        adjustTemplateDialog: {
          ...state.adjustTemplateDialog,
          open: action.payload
        }
      };
    }
    default:
      return state;
  }
};
