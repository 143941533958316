import * as Sentry from "@sentry/capacitor";

export const logger = {
  error: (message?: unknown, ...optionalParams: unknown[]) => {
    Sentry.captureException(message);
    if (!import.meta.env.PROD) {
      console.error(message, ...optionalParams);
    }
  },
  info: (message?: unknown, ...optionalParams: unknown[]) => {
    if (!import.meta.env.PROD) {
      // eslint-disable-next-line no-console
      console.info(message, ...optionalParams);
    }
  }
};
