import { usePerformanceContext } from "@/app/performance/state/PerformanceProvider";
import { ActionDialog } from "@/ui/action-dialog/ActionDialog";
import { ActionDialogBody } from "@/ui/action-dialog/ActionDialogBody";
import { ActionDialogFooter } from "@/ui/action-dialog/ActionDialogFooter";
import { ActionDialogHeader } from "@/ui/action-dialog/ActionDialogHeader";
import { useState } from "react";
import { AdjustTemplateForm } from "./AdjustTemplateForm";

type Props = {
  templateId: string;
  isOpen: boolean;
  onClose: () => void;
};

export const AdjustTemplateDialog: React.FC<Props> = ({
  templateId,
  isOpen,
  onClose
}) => {
  const {
    state: { template }
  } = usePerformanceContext();
  const [isSubmitting, setIsSubmitting] = useState(false);

  return (
    <ActionDialog open={isOpen} onClose={onClose} offsetTop="40vh">
      <ActionDialogHeader
        loading={isSubmitting}
        onClose={onClose}
        hideCloseButton
      ></ActionDialogHeader>
      <ActionDialogBody>
        {template ? (
          <AdjustTemplateForm
            templateId={templateId}
            template={template}
            onSubmitting={setIsSubmitting}
          />
        ) : null}
      </ActionDialogBody>
      <ActionDialogFooter></ActionDialogFooter>
    </ActionDialog>
  );
};
