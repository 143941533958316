import { PerformanceTemplateFormValues } from "@/app/performance/template-management/PerformanceTemplateForm";
import { PlantCheckboxGroup } from "@/app/performance/template-management/PlantCheckboxGroup";
import { graphql } from "@/lib/data/graphql";
import { useTranslation } from "@/lib/i18n";
import { Button } from "@/ui/button/button";
import { Alert } from "@/ui/feedback/alert";
import { Field, Label } from "@/ui/form/fieldset";
import { FormFieldCard } from "@/ui/form/formFieldCard";
import { FormFieldProps } from "@/ui/form/FormFieldController";
import { Input, InputGroup } from "@/ui/form/input";
import { LoadingSpinner } from "@/ui/loading-spinner";
import { Text } from "@/ui/typography/text";
import { useQuery } from "@apollo/client";
import { Transition } from "@headlessui/react";
import { MagnifyingGlassIcon } from "@heroicons/react/16/solid";
import { useEffect, useState } from "react";
import { FieldPath, UseFormSetValue } from "react-hook-form";

const MAX_PLANTS_WARNING = 5;

const PerformancePlantsFieldQuery = graphql(`
  query PerformancePlantsField {
    plants {
      id
      name
      colorHex
    }
  }
`);

type PerformancePlantsFieldProps<
  TName extends
    FieldPath<PerformanceTemplateFormValues> = FieldPath<PerformanceTemplateFormValues>
> = {
  value: string[];
  setValue: UseFormSetValue<PerformanceTemplateFormValues>;
} & FormFieldProps<PerformanceTemplateFormValues, TName>;

export const PerformancePlantsField = <
  TName extends
    FieldPath<PerformanceTemplateFormValues> = FieldPath<PerformanceTemplateFormValues>
>({
  id,
  name,
  control,
  setValue,
  value
}: PerformancePlantsFieldProps<TName>) => {
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState("");
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState("");
  const [showQueryInput, setShowQueryInput] = useState(false);

  const { data, loading } = useQuery(PerformancePlantsFieldQuery);

  useEffect(() => {
    const timerId = setTimeout(() => {
      setDebouncedSearchQuery(searchQuery);
    }, 200);

    return () => clearTimeout(timerId);
  }, [searchQuery]);

  const options =
    data?.plants.map((plant) => ({
      label: plant.name,
      value: plant.id.toString(),
      color: plant.colorHex ?? "#939393FF"
    })) || [];

  const filteredOptions = options.filter((option) =>
    option.label.toLowerCase().includes(debouncedSearchQuery.toLowerCase())
  );

  const allFilteredAreSelected =
    filteredOptions.length > 0 &&
    filteredOptions.every((option) => value?.includes(option.value)) &&
    !loading;

  return (
    <div className="flex flex-col gap-2">
      <Field className="flex items-center gap-2">
        <Label>{t("performanceTemplates.form.fields.plants.label")}</Label>
        <Button
          plain
          className="!text-xs/5 opacity-60 hover:opacity-100 transition-opacity disabled:opacity-30"
          disabled={filteredOptions.length === 0}
          onClick={() =>
            setValue(
              "plants",
              allFilteredAreSelected
                ? value.filter(
                    (v) => !filteredOptions.map((o) => o.value).includes(v)
                  )
                : filteredOptions.map((o) => o.value)
            )
          }
        >
          {t(
            `performanceTemplates.form.fields.plants.${allFilteredAreSelected ? "deselectAll" : "selectAll"}`
          )}
        </Button>

        <div className="flex ml-auto gap-1 justify-end">
          <Transition show={showQueryInput}>
            <div className="transition data-[closed]:duration-200 ease-in data-[closed]:opacity-0">
              <InputGroup>
                <MagnifyingGlassIcon />

                <Input
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  placeholder={t(
                    "performanceTemplates.form.fields.plants.searchPlaceholder"
                  )}
                  autoFocus={showQueryInput}
                  onClear={() => {
                    setSearchQuery("");
                  }}
                />
              </InputGroup>
            </div>
          </Transition>

          {!showQueryInput ? (
            <Button plain onClick={() => setShowQueryInput((p) => !p)}>
              <MagnifyingGlassIcon className="size-5" />
            </Button>
          ) : null}
        </div>
      </Field>

      <FormFieldCard>
        {loading ? (
          <div className="flex justify-center">
            <LoadingSpinner size="sm" />
          </div>
        ) : filteredOptions.length > 0 ? (
          <PlantCheckboxGroup
            id={id}
            name={name}
            control={control}
            options={filteredOptions}
          />
        ) : (
          <Text>{t("performanceTemplates.form.fields.plants.noData")}</Text>
        )}
      </FormFieldCard>

      {(value ?? []).length > MAX_PLANTS_WARNING ? (
        <Alert
          type="warning"
          className="mt-4"
          title={t("performanceTemplates.form.fields.plants.tooMany.title")}
          description={t(
            "performanceTemplates.form.fields.plants.tooMany.description"
          )}
        />
      ) : null}
    </div>
  );
};
