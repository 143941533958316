import { SERVER_ERROR_CODES } from "@/lib/api/graphqlErrorLink";
import { useTranslation } from "@/lib/i18n";
import { Alert } from "@/ui/feedback/alert";
import { ApolloError } from "@apollo/client";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import React from "react";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type GraphQLCustomErrorExtensions = Record<string, any>;

export type GraphqlErrorAlertProps = {
  error?: ApolloError;
  messageRenderer?: (
    code: string,
    context?: Record<string, never>
  ) => React.ReactNode;
  className?: string;
};

export const GraphqlErrorAlert: React.FC<GraphqlErrorAlertProps> = ({
  error,
  messageRenderer,
  className
}) => {
  const { t } = useTranslation();

  if (!error) {
    return null;
  }

  const code = parseErrorCode(error);
  const extensions = parseExtensions(error);

  if (code && messageRenderer) {
    return (
      <div className={className}>
        {messageRenderer(code, extensions?.context as never)}
      </div>
    );
  }

  if (code && SERVER_ERROR_CODES.includes(code)) {
    return (
      <Alert
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        title={t(`error.graphql.${code}.title`)}
        className={className}
      />
    );
  }

  return (
    <Alert
      title={t("error.graphql.generic.title")}
      description={t("error.graphql.generic.description")}
      type="error"
      icon={
        <ExclamationCircleIcon className="size-6 sm:size-8 text-content-danger" />
      }
      className={className}
    />
  );
};

function parseErrorCode(error: ApolloError): string | undefined {
  return (error.graphQLErrors[0]?.extensions?.code as string) || undefined;
}

function parseExtensions(
  error: ApolloError
): GraphQLCustomErrorExtensions | undefined {
  return error.graphQLErrors[0]?.extensions || undefined;
}
