import { useTranslation } from "@/lib/i18n";
import { Button } from "@/ui/button/button";
import { Card, CardBody, CardHeader } from "@/ui/display/card";
import { Input } from "@/ui/form/input";
import { useForm } from "react-hook-form";

type CalculateFormValues = {
  value?: number;
};

export type CalculateFormProps = {
  initialValues?: CalculateFormValues;
  onSubmit: (data: CalculateFormValues) => void;
  loading?: boolean;
};

export const CalculateForm: React.FC<CalculateFormProps> = ({
  initialValues,
  onSubmit,
  loading
}) => {
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { isValid }
  } = useForm<CalculateFormValues>({
    defaultValues: initialValues
  });

  return (
    <Card className="w-full md:w-1/2 xl:w-1/3">
      <CardHeader
        title={t("myImpact.cards.myImpactCalculator.calculateForm.title")}
        border={false}
      />
      <CardBody>
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-8">
          <p
            className="text-sm"
            dangerouslySetInnerHTML={{
              __html: t(
                "myImpact.cards.myImpactCalculator.calculateForm.description"
              )
            }}
          ></p>
          <div className="flex max-sm:flex-col gap-4">
            <div className="flex justify-center items-center gap-1.5 w-full sm:w-1/2">
              <Input
                {...register("value", {
                  valueAsNumber: true,
                  required: true,
                  min: 1,
                  max: 999999
                })}
                placeholder={t(
                  "myImpact.cards.myImpactCalculator.calculateForm.placeholder"
                )}
                type="number"
                disabled={loading}
              />
              <span className="text-sm">{t("common.units.kWh")}</span>
            </div>
            <Button
              type="submit"
              className="w-full sm:w-1/2"
              disabled={!isValid}
              loading={loading}
            >
              {t("myImpact.cards.myImpactCalculator.calculateForm.buttonLabel")}
            </Button>
          </div>
        </form>
      </CardBody>
    </Card>
  );
};
