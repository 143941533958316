import { useTranslation } from "@/lib/i18n";
import { ErrorMessage, Field, Label } from "@/ui/form/fieldset";
import { Text } from "@/ui/typography/text";
import React from "react";
import {
  ControllerRenderProps,
  type FieldError,
  FieldPath,
  type FieldValues,
  useController,
  UseControllerProps
} from "react-hook-form";

export type FormFieldProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = UseControllerProps<TFieldValues, TName> & {
  id: string;
  name: string;
  label?: string;
  hintText?: string;
  hideCounter?: boolean;
  optional?: boolean; // default required field
  error?: FieldError;
  defaultValue?: TFieldValues[TName];
  render?: (p: {
    field: ControllerRenderProps<TFieldValues, TName>;
    resourceId?: string;
    name: TName;
    error?: FieldError;
  }) => React.ReactNode;
  className?: string;
};

export const FormFieldController = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  id,
  label,
  hintText,
  name,
  defaultValue,
  optional,
  control,
  render,
  rules,
  className,
  hideCounter
}: FormFieldProps<TFieldValues, TName>) => {
  const { t } = useTranslation();

  const {
    field,
    fieldState: { error }
  } = useController({
    name,
    control,
    defaultValue,
    rules: {
      ...rules,
      ...(optional ? {} : { required: t("form.mandatoryField") })
    }
  });

  const maxLength = (rules?.maxLength as { value: number; message: string })
    ?.value;
  const lengthCounter = `${field.value?.length}\u00a0/\u00a0${maxLength}`;

  return (
    <Field className={className}>
      {label ? (
        <Label htmlFor={id}>
          {optional ? label + t("form.optionalSuffix") : label}
        </Label>
      ) : null}

      {render?.({ field, name, error })}

      {hintText || (maxLength && !hideCounter) ? (
        <div className="flex gap-2 justify-between w-full mt-1">
          {hintText ? (
            <Text className="!text-xs text-content-secondary">{hintText}</Text>
          ) : null}
          {maxLength && !hideCounter ? (
            <Text className="!text-xs ml-auto">{lengthCounter}</Text>
          ) : null}
        </div>
      ) : null}
      {error?.message ? <ErrorMessage>{error.message}</ErrorMessage> : null}
    </Field>
  );
};
