import { graphql } from "@/lib/data/graphql";
import { useTranslation } from "@/lib/i18n";
import { Listbox, ListboxLabel, ListboxOption } from "@/ui/form/listbox";

export type Co2EquivalentCategory = ReturnType<
  typeof graphql.scalar<"Co2EquivalentCategory">
>;

export const Co2EquivalentCategoryListbox: React.FC<{
  value: Co2EquivalentCategory;
  onChange: (value: Co2EquivalentCategory) => void;
  exclude?: Co2EquivalentCategory[];
}> = ({ value, onChange, exclude }) => {
  const { t } = useTranslation();

  const categories: Co2EquivalentCategory[] = (
    ["FOSSILE_FUELS", "MOBILITY", "PERSONAL_DEVICES", "SOCIETY"] as const
  ).filter((category) => !exclude?.includes(category));

  const options = categories.map((option) => ({
    label: t(`enums.co2EquivalentCategories.${option}`),
    value: option
  }));

  return (
    <Listbox
      value={value}
      onChange={(e: Co2EquivalentCategory) => onChange(e)}
      className="max-w-48"
    >
      {options.map((option) => {
        return (
          <ListboxOption<Co2EquivalentCategory>
            key={option.value}
            value={option.value}
          >
            <ListboxLabel>{option.label}</ListboxLabel>
          </ListboxOption>
        );
      })}
    </Listbox>
  );
};
