import { DatePresetsDropdown } from "@/ui/date/date-presets-dropdown";
import { DateQuickPresets } from "@/ui/date/date-quick-presets";
import {
  FilterOption,
  getDateRange,
  getIntervalDates,
  TimeScaleKeyType
} from "@/ui/date/date-utils";
import { EllipsisVerticalIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import { FC } from "react";
import { PerformanceFilterState } from "../../app/performance/PerformanceFilters";

export type DatePresetsProps = {
  activeSelector: TimeScaleKeyType;
  onChange: (value: PerformanceFilterState) => void;
  showMorePresets?: boolean;
  className?: string;
};

export const DatePresets: FC<DatePresetsProps> = ({
  activeSelector,
  onChange,
  showMorePresets = true,
  className
}) => {
  const handleDropdownChange = (filterOption: FilterOption) => {
    const { startDate, endDate, shortcut } = getDateRange(filterOption);

    // In case of preset time range, these will never be null
    if (startDate && endDate) {
      onChange({ startDate, endDate, quickSelectorKey: shortcut });
    }
  };

  const handleQuickSelectorChange = (selector: TimeScaleKeyType) => {
    const { start, end } = getIntervalDates(new Date(), selector);

    onChange({ startDate: start, endDate: end, quickSelectorKey: selector });
  };

  return (
    <div className={clsx("flex gap-2", className)}>
      <DateQuickPresets
        activeSelector={activeSelector}
        onChange={handleQuickSelectorChange}
      />

      {showMorePresets ? (
        <DatePresetsDropdown
          icon={<EllipsisVerticalIcon />}
          onChange={handleDropdownChange}
        />
      ) : null}
    </div>
  );
};
