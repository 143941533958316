import { Divider } from "@/ui/display/divider";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle
} from "@headlessui/react";
import clsx from "clsx";
import React, { PropsWithChildren } from "react";
import { ModalCloseButton } from "./overlays/ModalCloseButton";

export type DrawerProps = {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  actions?: React.ReactNode;
  closeOnOutsideClick?: boolean;
  className?: string;
};

export const Drawer: React.FC<PropsWithChildren<DrawerProps>> = ({
  isOpen,
  onClose,
  closeOnOutsideClick = true,
  title,
  children,
  actions,
  className
}) => {
  return (
    <Dialog
      open={isOpen}
      onClose={closeOnOutsideClick ? onClose : () => null}
      className="relative z-10"
    >
      <DialogBackdrop
        transition
        className="fixed inset-0 sfy-bg-backdrop transition-opacity duration-100 ease-out data-[closed]:opacity-0"
      />

      <div className="fixed inset-0 overflow-hidden">
        <div className="absolute inset-0 overflow-hidden">
          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full">
            <DialogPanel
              transition
              className={clsx(
                "relative w-screen max-w-md pointer-events-auto",
                "py-safe pr-safe",
                "bg-bg-primary shadow-lg ring-border-primary",
                "transition transform duration-100 ease-out data-[closed]:translate-x-full sm:duration-500"
              )}
            >
              <div className={clsx("flex h-full flex-col", className)}>
                <div
                  className={clsx(
                    "flex items-center gap-4 justify-between p-2.5 sm:p-4"
                  )}
                >
                  <DialogTitle className="text-base font-semibold text-content-primary">
                    {title}
                  </DialogTitle>
                  <ModalCloseButton outline onClick={onClose} />
                </div>

                <Divider />

                <div className="flex-1 overflow-y-auto px-[--scrollbar-gutter]">
                  {children}
                </div>

                {actions ? (
                  <>
                    <Divider />
                    <div
                      className={clsx(
                        "sticky bottom-0 z-10 w-full",
                        "flex flex-col gap-4 p-2.5 sm:p-4"
                      )}
                    >
                      <div className="flex justify-between gap-4">
                        {actions}
                      </div>
                    </div>
                  </>
                ) : null}
              </div>
            </DialogPanel>
          </div>
        </div>
      </div>
    </Dialog>
  );
};
