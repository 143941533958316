import { useTranslation } from "@/lib/i18n";
import { Alert } from "@/ui/feedback/alert";
import { useTheme } from "@/ui/Theme";
import {
  APILoadingStatus,
  Map as GoogleMap,
  MapProps as GoogleMapProps,
  Marker,
  MarkerProps,
  useApiLoadingStatus
} from "@vis.gl/react-google-maps";
import { FC } from "react";
import { getMapMarker } from "./mapMarker";
import { getMapSettings } from "./mapSettings";
import { getMapStyles } from "./mapStyles";

export interface MapProps extends GoogleMapProps {
  markers?: MapMarkerProp[];
  onMarkerClick?: (marker: MapMarkerProp) => void;
}

export interface MapMarkerProp extends MarkerProps {
  id: string;
  position: MarkerProps["position"];
}

export const Map: FC<MapProps> = (props) => {
  const { markers, onMarkerClick, style = {}, ...mapProps } = props;
  const { theme } = useTheme();
  const mapSettings = getMapSettings();
  const { t } = useTranslation();
  const apiLoadingStatus = useApiLoadingStatus();

  const elStyle = {
    height: mapSettings.height,
    ...style
  };

  if (apiLoadingStatus === APILoadingStatus.AUTH_FAILURE)
    return (
      <div className="p-4">
        <Alert
          title={t("map.error.title")}
          description={t("map.error.description")}
          type="warning"
        />
      </div>
    );

  return (
    <GoogleMap
      defaultCenter={mapSettings.center}
      defaultZoom={mapSettings.zoom}
      styles={getMapStyles(theme)}
      backgroundColor={theme === "dark" ? "black" : "white"}
      disableDefaultUI
      style={elStyle}
      {...mapProps}
    >
      {markers?.map((marker) => (
        <Marker
          key={marker.id}
          icon={getMapMarker(theme)}
          position={marker.position}
          onClick={onMarkerClick ? () => onMarkerClick(marker) : undefined}
        />
      ))}
    </GoogleMap>
  );
};
