import { DEFAULT_CONFIG } from "./config";

const MAP_SETTINGS = {
  xl: {
    ...DEFAULT_CONFIG,
    height: 560,
    zoom: 8
  },
  sm: {
    ...DEFAULT_CONFIG,
    height: 360,
    zoom: 7
  }
};

export const getMapSettings = () => {
  if (window.innerWidth < 1000) return MAP_SETTINGS.sm;
  return MAP_SETTINGS.xl;
};
