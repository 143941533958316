import { CloseButton } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { t } from "i18next";
import { FC } from "react";
import { Button, ButtonProps } from "../button/button";

export type ModalCloseButtonProps = {
  onClick?: () => void;
  className?: string;
} & Pick<ButtonProps, "size" | "plain" | "outline">;

export const ModalCloseButton: FC<ModalCloseButtonProps> = ({
  onClick,
  size,
  plain,
  outline,
  className
}) => {
  return (
    <CloseButton
      as={Button}
      outline={outline}
      plain={plain}
      size={size}
      onClick={onClick}
      aria-label={t("drawer.close")}
      className={className}
    >
      <XMarkIcon />
    </CloseButton>
  );
};
