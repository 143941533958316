import { graphql } from "@/lib/data/graphql";
import { useTranslation } from "@/lib/i18n";
import { Alert } from "@/ui/feedback/alert";
import { PageHeading } from "@/ui/layouts/page-heading";
import { PageWrapper } from "@/ui/layouts/page-wrapper";
import { LoadingSpinner } from "@/ui/loading-spinner";
import { useQuery } from "@apollo/client";
import { FC } from "react";
import { useParams } from "react-router-dom";
import { PlantDetailsCard, PlantDetailsCardFragment } from "./PlantDetailsCard";
import { PlantPerformanceCard } from "./PlantPerformanceCard";

export const PlantDetailQuery = graphql(
  `
    query PlantDetail($plantId: Int!) {
      plant(plantId: $plantId) {
        ...PlantDetailsCard
      }
    }
  `,
  [PlantDetailsCardFragment]
);

export const PlantDetailPage: FC = () => {
  const { id: plantId } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const { data, loading, error } = useQuery(PlantDetailQuery, {
    variables: { plantId: parseInt(plantId ?? "", 10) }
  });

  if (loading) {
    return (
      <PageWrapper>
        <PageHeading title={t("plantDetail.title")} back />
        <div className="flex mt-14 items-center justify-center">
          <LoadingSpinner />
        </div>
      </PageWrapper>
    );
  }

  if (error || !plantId) {
    return (
      <PageWrapper>
        <PageHeading title={t("plantDetail.title")} back />
        <Alert title={t("error.generic.description")} type="error" />
      </PageWrapper>
    );
  }

  return (
    <PageWrapper>
      <PageHeading title={t("plantDetail.title")} back />
      <div className="space-y-6">
        <PlantDetailsCard plant={data?.plant} />
        <PlantPerformanceCard plantId={plantId} />
      </div>
    </PageWrapper>
  );
};
