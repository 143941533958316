import { useSession } from "@/lib/auth";
import { Dropdown, DropdownButton } from "@/ui/form/dropdown";
import { ChevronDownIcon } from "@heroicons/react/16/solid";
import { FullName } from "./FullName";
import { UserAvatar } from "./UserAvatar";
import { UserDropdownMenu } from "./UserDropdownMenu";

export const UserDropdown: React.FC = () => {
  const { me } = useSession();

  if (!me) {
    return null;
  }

  return (
    <Dropdown>
      <DropdownButton outline>
        <UserAvatar className="size-6" />
        <span className="text-xs font-bold">
          <FullName />
        </span>
        <ChevronDownIcon />
      </DropdownButton>
      <UserDropdownMenu anchor="bottom end" />
    </Dropdown>
  );
};
