import { FragmentOf, graphql } from "@/lib/data/graphql";
import { usePaginatedQuery } from "@/lib/data/hooks/usePaginatedQuery";

type WhereInput = ReturnType<
  typeof graphql.scalar<"TechnicalLogMessagesWhereInput">
>;

export const MessageLogAxisItemFragment = graphql(`
  fragment MessageLogAxisItem on TechnicalLogMessage {
    id
    createdAt
  }
`);

export const MessagesLogAxisQuery = graphql(
  `
    query MessageLogAxis(
      $where: TechnicalLogMessagesWhereInput
      $skip: Int
      $take: Int
      $orderBy: TechnicalLogMessagesOrderByInput
    ) {
      technicalLogMessages(
        where: $where
        skip: $skip
        take: $take
        orderBy: $orderBy
      ) {
        items {
          ...MessageLogAxisItem
        }
        totalCount
      }
    }
  `,
  [MessageLogAxisItemFragment]
);

export type MessageLogAxisItem = FragmentOf<typeof MessageLogAxisItemFragment>;

export const useMessageLogAxisQuery = (initialWhere: WhereInput) => {
  const { queryResult } = usePaginatedQuery({
    query: MessagesLogAxisQuery,
    initialVariables: {
      where: initialWhere
    },
    extractCountFromQuery: (data) => data?.technicalLogMessages.totalCount
  });

  return { messages: queryResult.data?.technicalLogMessages.items };
};
