import { useTranslation } from "@/lib/i18n";
import { Button } from "@/ui/button/button";
import {
  Dialog,
  DialogActions,
  DialogDescription,
  DialogTitle
} from "@/ui/feedback/dialog";
import React from "react";

export type ConfirmDialogProps = {
  isOpen: boolean;
  title: string;
  description: string;
  onClose: () => void;
  onConfirm: () => void;
  closeOnOutsideClick?: boolean;
};

export const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
  isOpen,
  title,
  description,
  onClose,
  onConfirm,
  closeOnOutsideClick = true
}) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={isOpen}
      onClose={() => (closeOnOutsideClick ? onClose() : undefined)}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogDescription>{description}</DialogDescription>
      <DialogActions>
        <Button plain onClick={onClose}>
          {t("dialog.cancel")}
        </Button>
        <Button onClick={onConfirm}>{t("dialog.confirm")}</Button>
      </DialogActions>
    </Dialog>
  );
};
