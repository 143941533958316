import { PerformanceChartFragment } from "@/app/performance/PerformanceChart";
import { PerformanceTemplateFragment } from "@/app/performance/PerformanceTemplate";
import { usePerformanceContext } from "@/app/performance/state/PerformanceProvider";
import { PerformanceValuesByDate } from "@/app/performance/utils/transformPerformanceData";
import { FragmentOf, readFragment } from "@/lib/data/graphql";
import { useTranslation } from "@/lib/i18n";
import { renderBar, renderBarLabel } from "@/ui/display/charts/bar";
import { ChartSerie } from "@/ui/display/charts/chart";
import { renderLine } from "@/ui/display/charts/line";
import { LabelList } from "recharts";

export const useChartSeries = (
  plantValueData: FragmentOf<typeof PerformanceChartFragment>[],
  legendHiddenItems: string[]
): Array<ChartSerie<PerformanceValuesByDate> | false> => {
  const { t } = useTranslation();
  const performancePlants = readFragment(
    PerformanceChartFragment,
    plantValueData
  );

  const {
    state: { template: templateData, formatters }
  } = usePerformanceContext();
  const templateConfig = readFragment(
    PerformanceTemplateFragment,
    templateData
  );

  if (!performancePlants || performancePlants.length === 0) {
    return [];
  }

  const result: Array<ChartSerie<PerformanceValuesByDate> | false> = [];

  // Process each plant
  performancePlants.forEach((performancePlantData) => {
    const performancePlant = readFragment(
      PerformanceChartFragment,
      performancePlantData
    );
    const plantId = performancePlant.plant.id;
    const colorHex =
      performancePlant.plant.colorHex || "var(--color-yellow-300)";

    // Add series for each metric based on template settings
    if (templateConfig?.producedElectricity) {
      result.push({
        render: renderLine,
        renderProps: {
          dataKey: `${plantId}_producedElectricity`,
          name: `${performancePlant.plant.name} - ${t("performance.chart.fields.pYield")}`,
          yAxisId: "kWh",
          unit: "kWh",
          stroke: colorHex,
          strokeOpacity: legendHiddenItems.includes(
            `${plantId}_producedElectricity`
          )
            ? 0
            : 0.5
        }
      });
    }

    if (templateConfig?.producedElectricityTarget) {
      result.push({
        render: renderLine,
        renderProps: {
          dataKey: `${plantId}_producedElectricityTarget`,
          name: `${performancePlant.plant.name} - ${t("performance.chart.fields.pYieldTarget")}`,
          yAxisId: "kWh",
          unit: "kWh",
          strokeDasharray: "3 2",
          stroke: colorHex,
          strokeOpacity: legendHiddenItems.includes(
            `${plantId}_producedElectricityTarget`
          )
            ? 0
            : 1
        }
      });
    }

    if (templateConfig?.generatedRevenue) {
      result.push({
        render: renderLine,
        renderProps: {
          dataKey: `${plantId}_generatedRevenue`,
          name: `${performancePlant.plant.name} - ${t("performance.chart.fields.revenue")}`,
          yAxisId: "CHF",
          unit: "CHF",
          stroke: colorHex,
          strokeOpacity: legendHiddenItems.includes(
            `${plantId}_generatedRevenue`
          )
            ? 0
            : 1
        }
      });
    }

    if (templateConfig?.generatedRevenueTarget) {
      result.push({
        render: renderLine,
        renderProps: {
          dataKey: `${plantId}_generatedRevenueTarget`,
          name: `${performancePlant.plant.name} - ${t("performance.chart.fields.revenueTarget")}`,
          yAxisId: "CHF",
          unit: "CHF",
          strokeDasharray: "3 5",
          stroke: colorHex,
          strokeOpacity: legendHiddenItems.includes(
            `${plantId}_generatedRevenueTarget`
          )
            ? 0
            : 0.5
        }
      });
    }

    if (templateConfig?.selfConsumptionRate) {
      result.push({
        render: renderBar,
        renderProps: {
          dataKey: `${plantId}_selfConsumptionRate`,
          name: `${performancePlant.plant.name} - Consumption`,
          yAxisId: "consumption",
          unit: "%",
          xAxisId: "secondary", // have to use second axis to not overflow bars with Y-axis
          children:
            formatters.monthsDiff <= 3 ? null : (
              <LabelList
                dataKey={`${plantId}_selfConsumptionRate`}
                position="centerTop"
                content={(props) =>
                  renderBarLabel({
                    ...props,
                    value: (
                      <span
                        className={
                          legendHiddenItems.includes(
                            `${plantId}_selfConsumptionRate`
                          )
                            ? "opacity-0"
                            : "opacity-50"
                        }
                      >
                        {props?.value
                          ? typeof props?.value === "number"
                            ? `${props.value.toFixed(0)}%`
                            : `${parseInt(props.value, 10).toFixed(0)}%`
                          : ""}
                      </span>
                    )
                  })
                }
              />
            ),
          fillOpacity: legendHiddenItems.includes(
            `${plantId}_selfConsumptionRate`
          )
            ? 0
            : 0.4,
          fill: colorHex
        }
      });
    }
  });

  return result;
};
