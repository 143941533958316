import { useUpdateReadStatus } from "@/app/notifications/hooks/useUpdateReadStatus";
import { NotificationsBadgeQuery } from "@/app/notifications/NotificationsBadge";
import { NotificationsDetailDialog } from "@/app/notifications/NotificationsDetailDialog";
import { NotificationsNavItemQuery } from "@/app/notifications/NotificationsNavItem";
import { useSession } from "@/lib/auth";
import { FragmentOf, graphql, readFragment } from "@/lib/data/graphql";
import { useTranslation } from "@/lib/i18n";
import { formatDate, formatDateTime } from "@/lib/utils/date";
import { downloadExternalFile } from "@/lib/utils/downloadExternalFile";
import { toaster } from "@/ui/feedback/toaster";
import { Checkbox } from "@/ui/form/checkbox";
import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownMenu
} from "@/ui/form/dropdown";
import { LoadingSpinner } from "@/ui/loading-spinner";
import { TableCell, TableRow } from "@/ui/table/table";
import { EllipsisHorizontalIcon } from "@heroicons/react/16/solid";
import {
  ArrowDownTrayIcon,
  CheckCircleIcon
} from "@heroicons/react/24/outline";
import clsx from "clsx";
import { MouseEventHandler, useState } from "react";

export const NotificationItemFragment = graphql(`
  fragment NotificationItem on Notification {
    id
    text
    createdAt
    read
    document {
      id
      name
      url
      type
    }
  }
`);

type NotificationItemProps = {
  data: FragmentOf<typeof NotificationItemFragment>;
  onCheck: (id: number, checked: boolean) => void;
  checked: boolean;
};

export const NotificationItem: React.FC<NotificationItemProps> = ({
  data,
  onCheck,
  checked
}) => {
  const { t } = useTranslation();

  const { id, text, createdAt, document, read } = readFragment(
    NotificationItemFragment,
    data
  );
  const [isDetailDialog, setIsDetailDialog] = useState(false);

  const session = useSession();
  const ability = session?.ability;
  const ableToDoActionsWithNotification = ability?.can("read", "notifications"); // TODO(BE): condition if could read this notification?

  const { updateReadStatus, loading } = useUpdateReadStatus();
  const markAsRead = (opts?: { hideSuccessToast: boolean }) => {
    updateReadStatus({
      variables: {
        input: {
          notifications: [
            {
              id,
              read: true
            }
          ]
        }
      },
      onCompleted: () => {
        !opts?.hideSuccessToast &&
          toaster.success({
            title: t(
              "notifications.listing.actions.markAsRead.success.description"
            )
          });
      },
      onError: () => {
        toaster.error({
          title: t("notifications.listing.actions.markAsRead.error.description")
        });
      },
      refetchQueries: [NotificationsBadgeQuery, NotificationsNavItemQuery]
    });
  };
  const handleUpdateReadStatus: MouseEventHandler<HTMLButtonElement> = (e) => {
    // to stop bubbling up the click event to the TableRow, which trigger open detail in modal
    e.stopPropagation();
    markAsRead();
  };

  const handleTriggerDownload: MouseEventHandler<HTMLButtonElement> = (e) => {
    // to stop bubbling up the click event to the TableRow, which trigger open detail in modal
    e.stopPropagation();

    handleDownloadPdf();
  };

  const handleDownloadPdf = (opts?: {
    onSuccess?: VoidFunction;
    onError?: (error: unknown) => void;
  }) => {
    downloadExternalFile({
      url: document.url,
      fileName: document.name + ".pdf",
      onSuccess: () => {
        opts?.onSuccess?.();
      },
      onError: (error) => {
        opts?.onError?.(error);
        toaster.error({
          title: t("notifications.listing.actions.download.error.description")
        });
      }
    });
  };

  const handleCloseDialog = () => {
    setIsDetailDialog(false);
  };

  const handleOpenDetailDialog = () => {
    setIsDetailDialog(true);
    markAsRead({ hideSuccessToast: true });
  };

  const classByReadState = read ? "font-bold" : "opacity-70";

  return (
    <TableRow
      className={clsx(
        "border-b border-zinc-200 dark:border-zinc-700",
        "hover:bg-zinc-100 dark:hover:bg-zinc-800 transition-colors",
        { "bg-zinc-100/45 dark:bg-zinc-800/25 ": read }
      )}
      onClick={handleOpenDetailDialog}
    >
      <TableCell
        className={clsx("cursor-pointer", "max-w-10", classByReadState)}
      >
        <Checkbox
          checked={checked}
          onChange={(checked) => onCheck(id, checked)}
          onClick={(e) => e.stopPropagation()}
        />
      </TableCell>
      <TableCell className={clsx("cursor-pointer", classByReadState)}>
        <span title={formatDateTime(createdAt)}>{formatDate(createdAt)}</span>
      </TableCell>

      <TableCell
        className={clsx("cursor-pointer", "truncate", classByReadState)}
      >
        {text}
      </TableCell>

      <TableCell className="cursor-pointer">
        <Dropdown>
          <DropdownButton
            plain
            aria-label={t("users.listing.actions.label")}
            disabled={!ableToDoActionsWithNotification}
            className={clsx({
              "cursor-not-allowed": !ableToDoActionsWithNotification
            })}
            // to stop bubbling up the click event to the TableRow, which trigger open detail in modal
            onClick={
              ((e) =>
                e.stopPropagation()) as MouseEventHandler<HTMLButtonElement>
            }
          >
            <EllipsisHorizontalIcon />
          </DropdownButton>
          <DropdownMenu>
            {ability?.can("read", "documents") &&
            ability?.can("read", "notifications") ? (
              <DropdownItem onClick={handleTriggerDownload}>
                <ArrowDownTrayIcon />
                {t("notifications.listing.actions.download.label", {
                  documentType: t(
                    `notifications.listing.actions.download.documentTypes.${document.type}`
                  )
                })}
              </DropdownItem>
            ) : null}
            {ability?.can("updateStatus", "notifications") ? (
              <DropdownItem
                onClick={handleUpdateReadStatus}
                className={clsx({
                  "pointer-events-none opacity-50": loading
                })}
              >
                {loading ? (
                  <div className="pr-2">
                    <LoadingSpinner size="sm" />
                  </div>
                ) : (
                  <CheckCircleIcon />
                )}
                {t("notifications.listing.actions.markAsRead.label")}
              </DropdownItem>
            ) : null}
          </DropdownMenu>
        </Dropdown>

        {ability?.can("updateStatus", "notifications") ? (
          <NotificationsDetailDialog
            handleDownloadPdf={handleDownloadPdf}
            createdAt={createdAt}
            text={text}
            isOpen={isDetailDialog}
            documentType={document.type}
            onClose={handleCloseDialog}
          />
        ) : null}
      </TableCell>
    </TableRow>
  );
};
