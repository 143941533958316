import { Line as RechartsLine, LineProps as RechartsLineProps } from "recharts";

// Why wrapping of components is just impossible to get right with messed up types
// https://github.com/recharts/recharts/issues/412
export type LineProps = Omit<RechartsLineProps, "ref">;
export const Line = RechartsLine;
export const defaultProps: LineProps = {
  stroke: "var(--color-content-secondary)",
  strokeWidth: 2,
  dot: false,
  type: "monotone",
  isAnimationActive: false
};

export const renderLine = (props: LineProps) => (
  <RechartsLine {...defaultProps} {...props} key={props.key} />
);
