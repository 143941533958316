import { UserItem, UserItemFragment } from "@/app/user-management/UserItem";
import { GraphqlErrorAlert } from "@/lib/api/GraphqlErrorAlert";
import { FragmentOf, readFragment } from "@/lib/data/graphql";
import { useTranslation } from "@/lib/i18n";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow
} from "@/ui/table/table";
import { TableLoader } from "@/ui/table/TableLoader";
import { ApolloError } from "@apollo/client";
import { FC } from "react";

type UserTableProps = {
  users?: FragmentOf<typeof UserItemFragment>[];
  loading: boolean;
  error?: ApolloError;
};

export const UsersTable: FC<UserTableProps> = ({ users, loading, error }) => {
  const { t } = useTranslation();

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableHeader>{t("users.table.name")}</TableHeader>
          <TableHeader>{t("users.table.email")}</TableHeader>
          <TableHeader>{t("users.table.roles")}</TableHeader>
          <TableHeader>{t("users.table.actions")}</TableHeader>
        </TableRow>
      </TableHead>
      <TableBody>
        {error ? (
          <TableRow>
            <TableCell colSpan={5}>
              <GraphqlErrorAlert error={error} />
            </TableCell>
          </TableRow>
        ) : null}
        {users ? (
          users.map((user) => {
            const { id } = readFragment(UserItemFragment, user);
            return <UserItem key={id} data={user} />;
          })
        ) : loading ? (
          <TableLoader colSpan={5} />
        ) : null}
      </TableBody>
    </Table>
  );
};
