import { graphql, readFragment } from "@/lib/data/graphql";
import { usePaginatedQuery } from "@/lib/data/hooks/usePaginatedQuery";
import { useTranslation } from "@/lib/i18n";
import { Divider } from "@/ui/display/divider";
import { LoadingSpinner } from "@/ui/loading-spinner";
import { Text } from "@/ui/typography/text";
import { useAtom } from "jotai";
import { useEffect } from "react";
import { paginationAtom } from "./atoms/technicalLogMessagesListAtoms";
import {
  TechnicalLogMessaagesListFilterWhereInput,
  TechnicalLogMessagesListFilter
} from "./TechnicalLogMessagesListFilter";
import {
  TechnicalLogMessagesListItem,
  TechnicalLogMessagesListItemFragment
} from "./TechnicalLogMessagesListItem";

export const TechnicalLogMessagesListQuery = graphql(
  `
    query TechnicalLogMessagesList(
      $where: TechnicalLogMessagesWhereInput
      $skip: Int
      $take: Int
      $orderBy: TechnicalLogMessagesOrderByInput
    ) {
      technicalLogMessages(
        where: $where
        skip: $skip
        take: $take
        orderBy: $orderBy
      ) {
        items {
          ...TechnicalLogMessagesListItem
        }
        totalCount
      }
    }
  `,
  [TechnicalLogMessagesListItemFragment]
);

export type TechnicalLogMessagesListProps = {
  filter?: TechnicalLogMessaagesListFilterWhereInput;
};

export const TechnicalLogMessagesList: React.FC<
  TechnicalLogMessagesListProps
> = ({ filter }) => {
  const { t } = useTranslation();
  const [, setPagination] = useAtom(paginationAtom);

  const {
    queryResult: { data, loading },
    currentPage,
    totalPages,
    setWhere
  } = usePaginatedQuery({
    query: TechnicalLogMessagesListQuery,
    extractCountFromQuery: (data) => data?.technicalLogMessages.totalCount,
    initialVariables: {
      where: filter
    }
  });

  useEffect(() => {
    setPagination({ currentPage, totalPages, loading });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, totalPages, loading]);

  if (
    !loading &&
    (!data?.technicalLogMessages ||
      data?.technicalLogMessages.items.length === 0)
  ) {
    return <Text>{t("common.listing.noData")}</Text>;
  }

  return (
    <>
      <div className="sticky max-sm:h-0 top-0 pt-0 sm:pt-1 z-50 bg-bg-primary">
        <TechnicalLogMessagesListFilter
          onFilterChange={setWhere}
          defaultFilter={filter}
        />
        <Divider className="hidden sm:block mt-3" />
      </div>
      <div className="flex flex-col">
        <div className="flex flex-col gap-2 my-2.5">
          {loading ? (
            <div className="self-center">
              <LoadingSpinner />
            </div>
          ) : null}

          {data?.technicalLogMessages.items.map((item) => {
            const data = readFragment(
              TechnicalLogMessagesListItemFragment,
              item
            );

            return <TechnicalLogMessagesListItem key={data.id} data={item} />;
          })}
        </div>
      </div>
    </>
  );
};
