import clsx from "clsx";
import React from "react";
import { Button } from "../button/button";

export const styles = {
  base: "bg-bg-primary text-content-primary border-border-primary",
  info: "bg-bg-info text-content-info border-content-info",
  success: "bg-bg-success text-content-success border-content-success",
  warning: "bg-bg-warning text-content-warning border-content-warning",
  error: "bg-bg-danger text-content-danger border-content-danger"
};

export type AlertProps = {
  title: string;
  type?: "base" | "info" | "success" | "warning" | "error";
  icon?: React.ReactNode;
  description?: string;
  onClose?: () => void;
  onAction?: () => void;
  closeLabel?: string;
  actionLabel?: string;
  className?: string;
};

export const Alert: React.FC<AlertProps> = ({
  type = "base",
  title,
  description,
  icon,
  onAction,
  onClose,
  actionLabel,
  closeLabel,
  className
}) => {
  return (
    <div
      role="alert"
      className={clsx(
        "[--gutter:theme(spacing.3)] sm:[--gutter:theme(spacing.4)]",
        "flex w-full content-start items-center justify-start gap-[--gutter]",
        className,
        styles[type],
        "p-[--gutter] text-start",
        "border forced-colors:outline shadow-lg rounded-lg sm:rounded-2xl"
      )}
    >
      <div>{icon ? <div className="size-6 sm:size-8">{icon}</div> : null}</div>
      <div>
        <h3 className="font-medium">{title}</h3>
        {description ? (
          <div className="text-xs text-content-secondary">{description}</div>
        ) : null}
      </div>
      <div className="flex gap-2 ml-auto">
        {onClose ? (
          <Button onClick={onClose} plain>
            {closeLabel}
          </Button>
        ) : null}
        {onAction ? <Button onClick={onAction}>{actionLabel}</Button> : null}
      </div>
    </div>
  );
};
