import { graphql, readFragment } from "@/lib/data/graphql";
import { useTranslation } from "@/lib/i18n";
import { useQuery } from "@apollo/client";
import { formatISO } from "date-fns";
import { TechnicalLogMessagesDrawer } from "../technical-log-messages/TechnicalLogMessagesDrawer";
import { formatDrawerTitle } from "./messages-log/utils";
import { PerformanceChartContainer } from "./PerformanceChartContainer";
import { usePerformanceContext } from "./state/PerformanceProvider";
import { AddPerformanceTemplateDrawer } from "./template-management/AddPerformanceTemplateDrawer";
import { UpdatePerformanceTemplateDrawer } from "./template-management/UpdatePerformanceTemplateDrawer";

// TODO: for future optimization, we should load only data which is really needed - that means add to filter other flags, revenue, target, etc.
export const PerformanceTemplateFragment = graphql(`
  fragment PerformanceTemplate on PerformanceTemplate {
    id
    name
    generatedRevenue
    generatedRevenueTarget
    producedElectricity
    producedElectricityTarget
    selfConsumptionRate
    plants {
      id
      name
    }
  }
`);

export const PerformanceTemplateQuery = graphql(
  `
    query PerformanceTemplate($id: Int!) {
      performanceTemplate(performanceTemplateId: $id) {
        ...PerformanceTemplate
      }
    }
  `,
  [PerformanceTemplateFragment]
);

export type PerformanceTemplateProps = {
  templateId: string;
};

export const PerformanceTemplate: React.FC<PerformanceTemplateProps> = ({
  templateId
}) => {
  const { t } = useTranslation();
  const { dispatch, state } = usePerformanceContext();

  const { loading } = useQuery(PerformanceTemplateQuery, {
    variables: {
      id: parseInt(templateId, 10)
    },
    onCompleted: (data) => {
      dispatch({ type: "SET_TEMPLATE", payload: data.performanceTemplate });
    }
  });

  const template = readFragment(PerformanceTemplateFragment, state.template);

  return (
    <>
      <PerformanceChartContainer loading={loading} />

      <TechnicalLogMessagesDrawer
        title={t("performance.messagesLog.drawer.title", {
          dateRange: formatDrawerTitle(
            state.messagesLogFilter.startDate,
            state.messagesLogFilter.endDate,
            state.formatters.aggregation
          )
        })}
        isOpen={state.messagesLogDrawer.open}
        onClose={() => {
          dispatch({ type: "OPEN_MESSAGES_LOG_DRAWER", payload: false });
        }}
        filter={{
          plantIds: template?.plants.map((plant) => plant.id) ?? [],
          type: null,
          startDate: formatISO(state.messagesLogFilter.startDate),
          endDate: formatISO(state.messagesLogFilter.endDate)
        }}
      />

      <AddPerformanceTemplateDrawer
        isOpen={state.addTemplateDrawer.open}
        onClose={() => {
          dispatch({ type: "OPEN_ADD_TEMPLATE_DRAWER", payload: false });
        }}
      />

      <UpdatePerformanceTemplateDrawer
        templateId={templateId}
        isOpen={state.updateTemplateDrawer.open}
        onClose={() => {
          dispatch({ type: "OPEN_UPDATE_TEMPLATE_DRAWER", payload: false });
        }}
      />
    </>
  );
};
