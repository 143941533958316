import { cn } from "../utils";

export type ProgressProps = {
  value?: number;
  max?: number;
  color?: keyof typeof styles;
  className?: string;
};

export const Progress: React.FC<ProgressProps> = ({
  value,
  color = "base",
  max = 100,
  className
}) => {
  return (
    <progress
      className={cn(
        "daisyui-progress [&::-webkit-progress-bar]::bg-bg-primary",
        styles[color],
        className
      )}
      value={value}
      max={max}
    ></progress>
  );
};

const styles = {
  base: ["[&::-webkit-progress-value]:bg-content-primary"],
  primary: ["[&::-webkit-progress-value]:bg-yellow-300"],
  success: ["[&::-webkit-progress-value]:bg-success"],
  error: ["[&::-webkit-progress-value]:bg-error"],
  warning: ["[&::-webkit-progress-value]:bg-warning"],
  info: ["[&::-webkit-progress-value]:bg-info"]
};
