import { AddUserDialogBtn } from "@/app/user-management/AddUserDialogBtn";
import { UserItemFragment } from "@/app/user-management/UserItem";
import { UsersTable } from "@/app/user-management/UsersTable";
import { graphql } from "@/lib/data/graphql";
import { useTranslation } from "@/lib/i18n";
import { Divider } from "@/ui/display/divider";
import { PageWrapper } from "@/ui/layouts/page-wrapper";
import { TableFilterWrapper } from "@/ui/table/TableFilterWrapper";
import { Heading } from "@/ui/typography/heading";
import { useQuery } from "@apollo/client";
import { FC } from "react";

// TODO: add pagination, filtering, sorting
// export const UsersPageQuery = graphql(
//     `
//     query UsersPage(
//       $where: UsersWhereInput
//       $skip: Int
//       $take: Int
//       $orderBy: UsersOrderByInput
//     ) {
//       users(where: $where, skip: $skip, take: $take, orderBy: $orderBy) {
//         items {
//           ...UserItemFragment
//         }
//         totalCount
//       }
//     }
//   `,
//   [UserItemFragment]
// );

export const UsersPageQuery = graphql(
  `
    query UsersPage {
      users {
        ...UserItem
      }
    }
  `,
  [UserItemFragment]
);
export const UsersPage: FC = () => {
  const { t } = useTranslation();
  const { data, loading, error } = useQuery(UsersPageQuery);

  return (
    <PageWrapper>
      <div className="flex justify-between gap-4">
        <Heading>{t("users.title")}</Heading>
        <TableFilterWrapper>
          <AddUserDialogBtn />
        </TableFilterWrapper>
      </div>

      <Divider />

      {/* TODO(BE): filters */}

      <UsersTable users={data?.users} loading={loading} error={error} />
    </PageWrapper>
  );
};
