import clsx from "clsx";
import { FC } from "react";

export type LoadingSpinnerProps = {
  size?: "sm" | "lg";
};

export const LoadingSpinner: FC<LoadingSpinnerProps> = ({ size = "lg" }) => {
  const styles = {
    sizes: {
      sm: ["daisyui-loading-sm"],
      lg: ["daisyui-loading-lg"]
    }
  };

  const classes = clsx(
    "daisyui-loading daisyui-loading-spinner",
    styles.sizes[size]
  );

  return <div className={classes}></div>;
};
