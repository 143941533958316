import { useSession } from "@/lib/auth";

export const FullName: React.FC = () => {
  const { me } = useSession();
  return (
    <>
      {me?.firstName} {me?.lastName}
    </>
  );
};
