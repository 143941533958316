import { useConfirmDialog } from "@/app/layouts/ConfirmDialogProvider";
import { useTranslation } from "@/lib/i18n";
import { downloadExternalFile } from "@/lib/utils/downloadExternalFile";
import { Button } from "@/ui/button/button";
import { toaster } from "@/ui/feedback/toaster";
import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownMenu
} from "@/ui/form/dropdown";
import {
  ArrowDownTrayIcon,
  EllipsisHorizontalIcon,
  EnvelopeIcon
} from "@heroicons/react/16/solid";
import clsx from "clsx";

type Props = {
  onDelete: VoidFunction;
  onRegenerate?: VoidFunction;
  documentName?: string;
  documentUrl?: string;
  disabled?: boolean;
  regenerateLoading?: boolean;
};

export const ProjectActions: React.FC<Props> = ({
  documentName,
  documentUrl,
  disabled,
  onDelete,
  onRegenerate,
  regenerateLoading
}) => {
  const { t } = useTranslation();
  const confirmDialog = useConfirmDialog();

  const handleDownloadPdf = () => {
    if (!documentUrl) {
      return;
    }

    downloadExternalFile({
      url: documentUrl,
      fileName: documentName + ".pdf",
      onError: () => {
        toaster.error({
          title: t("documents.listing.actions.download.error.description")
        });
      }
    });
  };

  const handleDelete = () => {
    confirmDialog.open({
      title: t("project.actions.deleteBill.confirmDialog.title"),
      description: t("project.actions.deleteBill.confirmDialog.description"),
      onConfirm: onDelete
    });
  };

  return documentUrl ? (
    <Dropdown>
      <DropdownButton
        plain
        aria-label={t("project.actions.label")}
        disabled={disabled}
        className={clsx({ "cursor-not-allowed": disabled })}
      >
        <EllipsisHorizontalIcon />
      </DropdownButton>
      {documentUrl ? (
        <DropdownMenu>
          <DropdownItem onClick={handleDownloadPdf}>
            <ArrowDownTrayIcon />
            {t("project.actions.browseDocument.label")}
          </DropdownItem>
          <DropdownItem onClick={handleDelete}>
            <EnvelopeIcon />
            {t("project.actions.deleteBill.label")}
          </DropdownItem>
        </DropdownMenu>
      ) : null}
    </Dropdown>
  ) : disabled ? (
    <Button
      onClick={onRegenerate}
      loading={regenerateLoading}
      className="self-center"
      size="sm"
    >
      {t("project.actions.regenerate.label")}
    </Button>
  ) : null;
};
