import { ChevronLeft, ChevronRight } from "@carbon/icons-react";
import { FC, ReactNode, useState } from "react";

export const Carousel: FC<{ slides: ReactNode[][]; className?: string }> = ({
  slides,
  className = ""
}) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev === slides.length - 1 ? 0 : prev + 1));
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev === 0 ? slides.length - 1 : prev - 1));
  };

  const columns = slides[0] ? slides[0].length : 0;

  return (
    <div className={`relative ${className}`}>
      {/* Slides */}
      <div className={`grid xl:grid-cols-${columns} gap-x-4 gap-y-6`}>
        {slides[currentSlide]!.map((item, index) => (
          <div key={index}>{item}</div>
        ))}
      </div>

      {/* Navigation Arrows */}
      <button
        onClick={prevSlide}
        className="absolute left-0 top-1/2 -translate-y-1/2 -translate-x-4 p-2 hover:bg-bg-secondary rounded-full"
      >
        <ChevronLeft size={24} />
      </button>
      <button
        onClick={nextSlide}
        className="absolute right-0 top-1/2 -translate-y-1/2 translate-x-4 p-2 hover:bg-bg-secondary rounded-full"
      >
        <ChevronRight size={24} />
      </button>

      {/* Navigation Dots */}
      <div className="flex justify-center gap-2 mt-4">
        {slides.map((_, index) => (
          <button
            key={index}
            onClick={() => setCurrentSlide(index)}
            className={`size-2 rounded-full ${
              currentSlide === index ? "bg-content-primary" : "bg-bg-secondary"
            }`}
          />
        ))}
      </div>
    </div>
  );
};
