import { PerformanceLegend } from "@/app/performance/PerformanceLegend";
import { PerformanceTemplateFragment } from "@/app/performance/PerformanceTemplate";
import { PerformanceTooltip } from "@/app/performance/PerformanceTooltip";
import { usePerformanceContext } from "@/app/performance/state/PerformanceProvider";
import { generateDateTicks } from "@/app/performance/utils/generateTicks";
import {
  PerformanceValuesByDate,
  transformPerformanceData
} from "@/app/performance/utils/transformPerformanceData";
import { useChartSeries } from "@/app/performance/utils/useChartSeries";
import { useChartYAxis } from "@/app/performance/utils/useChartYAxis";
import { FragmentOf, graphql, readFragment } from "@/lib/data/graphql";
import { getTheme } from "@/lib/utils/getTheme";
import { ChartSerie } from "@/ui/display/charts/chart";
import { ComposedChart } from "@/ui/display/charts/composed-chart";
import { renderXAxis } from "@/ui/display/charts/x-axis";
import { renderYAxis } from "@/ui/display/charts/y-axis";
import { LoadingSpinner } from "@/ui/loading-spinner";
import { useMeasure } from "@uidotdev/usehooks";
import { formatISO } from "date-fns";
import React, { useEffect, useMemo, useState } from "react";
import { XAxis } from "recharts";
import { MessageLogAxisTick } from "./messages-log/MessageLogAxisTick";
import { useMessageLogAxisQuery } from "./messages-log/useMessageLogAxisQuery";
import { generateTicks } from "./messages-log/utils";
import {
  AggregationType,
  formatTooltipLabel,
  formatTooltipValue,
  getFormatters
} from "./utils/format-utilities";

export const PerformanceChartFragment = graphql(`
  fragment PerformanceChart on PerformancePlant {
    id
    plant {
      id
      name
      colorHex
    }
    values {
      id
      date
      pYield
      pYieldTarget
      revenue
      revenueTarget
      pOwnConsumptionPercentage
    }
  }
`);

export type PerformanceChartProps = {
  data: FragmentOf<typeof PerformanceChartFragment>[];
  loading?: boolean;
  onMessageLogTickClick?: (date: string) => void;
};

type FormatConfigurationType = {
  aggregation: AggregationType;
  formatXAxis: (value: Date) => string;
  ticks?: string[];
};

const breakpoints = getTheme().screens;
const MAX_DESKTOP_HEIGHT = 600;

export const PerformanceChart: React.FC<PerformanceChartProps> = ({
  data,
  loading,
  onMessageLogTickClick
}) => {
  const { state } = usePerformanceContext();
  const { startDate, endDate } = state.filters;

  // use whole space for mobile, but have max for desktop, runs only on first render, doesn't update on resize
  const [ref, { height, width }] = useMeasure();
  const [isMobile] = useState(
    window?.matchMedia(`(max-width: ${breakpoints["lg"]})`).matches
  );
  const computedHeight =
    !isMobile && height && height >= MAX_DESKTOP_HEIGHT
      ? MAX_DESKTOP_HEIGHT
      : height;

  const [legendHiddenItems, setLegendHiddenItems] = useState<string[]>([]);

  const template = readFragment(PerformanceTemplateFragment, state.template);
  const plantIds = template?.plants.map((plant) => plant.id) ?? [];
  const { messages } = useMessageLogAxisQuery({
    plantIds,
    startDate: formatISO(startDate),
    endDate: formatISO(endDate)
  });

  // TODO: should be done in BE
  const chartData = useMemo(() => transformPerformanceData(data) ?? [], [data]);
  const series = useChartSeries(data, legendHiddenItems);
  const yAxisConfig = useChartYAxis();

  const [cachedConfig, setCachedConfig] =
    useState<FormatConfigurationType | null>(null);

  useEffect(() => {
    if (!loading && chartData) {
      const format = getFormatters(startDate, endDate);
      setCachedConfig({
        ...format,
        ticks:
          format.monthsDiff <= 1
            ? generateDateTicks(startDate, endDate, chartData)
            : undefined
      });
    }
  }, [loading, chartData, startDate, endDate]);

  const computedConfig: FormatConfigurationType =
    cachedConfig || getFormatters(startDate, endDate);

  return (
    <div ref={ref} className="relative h-full">
      {loading ? (
        <div className="absolute w-full flex mt-14 items-center justify-center">
          <LoadingSpinner />
        </div>
      ) : null}
      <div className={loading ? "opacity-40" : ""}>
        <ComposedChart<PerformanceValuesByDate>
          data={chartData}
          series={
            series.filter(Boolean) as ChartSerie<PerformanceValuesByDate>[]
          }
          xAxisKey="date"
          xAxisProps={{
            tickFormatter: computedConfig.formatXAxis,
            ticks: computedConfig?.ticks,
            interval: "equidistantPreserveStart",
            scale: "point",
            allowDuplicatedCategory: true
          }}
          tooltipProps={{
            labelFormatter: (label) =>
              formatTooltipLabel(label, computedConfig.aggregation),
            valueFormatter: (value) => formatTooltipValue(value)
          }}
          tooltip={<PerformanceTooltip />}
          legend={
            <PerformanceLegend
              setLegendHiddenItems={setLegendHiddenItems}
              legendHiddenItems={legendHiddenItems}
            />
          }
          legendWidth={isMobile ? width : undefined}
          height={computedHeight}
          margin={{
            top: 20,
            right: yAxisConfig.some((c) => c.orientation === "right") ? -24 : 0,
            bottom: 0,
            left: yAxisConfig.some((c) => c.orientation === "left") ? -24 : 0
          }}
        >
          {yAxisConfig.map((config) =>
            renderYAxis({
              ...config,
              key: config.yAxisId,
              tick: { fontSize: 12, fill: "var(--color-content-primary)" }
            })
          )}

          {/* Secondary X-Axis for consumption bar inside of Y-axis, otherwise with scale "point" it's overflow */}
          <XAxis
            dataKey="date"
            scale="band"
            xAxisId="secondary"
            orientation="top"
            interval="equidistantPreserveStart"
            hide
          />

          {/* Message Log Axis */}
          {renderXAxis({
            dataKey: "date",
            xAxisId: "log",
            ticks: generateTicks(messages, state.formatters.aggregation),
            interval: 0,
            allowDuplicatedCategory: true,
            tick: (
              <MessageLogAxisTick
                onClick={(date) => {
                  onMessageLogTickClick?.(date);
                }}
                className="max-md:hidden"
              />
            ),
            strokeOpacity: 0
          })}
        </ComposedChart>
      </div>
    </div>
  );
};
