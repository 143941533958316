import * as Headless from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import { FC } from "react";
import { Progress } from "../feedback/progress";
import { cn } from "../utils";

export type ActionDialogHeaderProps = {
  className?: string;
  onClose?: VoidFunction;
  title?: string;
  loading?: boolean;
  hideCloseButton?: boolean;
};

export const ActionDialogHeader: FC<ActionDialogHeaderProps> = ({
  className,
  title,
  loading,
  onClose,
  hideCloseButton
}) => {
  return (
    <div
      className={cn(
        "sticky top-0 z-10 w-full p-4",
        "rounded-t-lg",
        "bg-bg-primary border-b",
        loading ? "border-transparent" : "border-border-primary",
        className
      )}
    >
      <Headless.DialogTitle className="text-base/6 font-semibold text-content-primary">
        {title ? (
          title
        ) : (
          <div className="flex justify-center">
            <button
              className="w-1/3 h-1 rounded bg-content-primary opacity-40 hover:opacity-100"
              onClick={onClose}
            />
          </div>
        )}
        {onClose && !hideCloseButton ? (
          <button
            onClick={onClose}
            className="absolute top-0 right-0 flex items-center justify-center h-full px-2.5"
          >
            <XMarkIcon
              className={clsx(
                // Icon styles
                "size-6 opacity-60 hover:opacity-100",
                "dark:opacity-80 dark:hover:opacity-100"
              )}
            />
          </button>
        ) : null}
        {loading ? (
          <div className="absolute -bottom-[1px] inset-x-0">
            <Progress className="block rounded-none h-[2px]" />
          </div>
        ) : null}
      </Headless.DialogTitle>
    </div>
  );
};
