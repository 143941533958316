import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownLabel,
  DropdownMenu
} from "@/ui/form/dropdown";
import { TabNavigationItem } from "@/ui/tab-navigation/TabNavigationItem";
import { CheckIcon } from "@heroicons/react/20/solid";
import { Bars3Icon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import React, { ReactNode, useCallback, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

export type TabItem = { id: string; title: string };

export type TabNavigationProps = {
  items: TabItem[];
  maxTabsPerLine?: number;
  endSlot: ReactNode;
  urlParamsKey?: string;
  className?: string;
};

/**
 * Renders a responsive tab navigation:
 * - Desktop: Displays a list of tabs.
 * - Mobile: Displays a dropdown.
 * Tab items must be passed as props (`items`) to ensure responsive rendering and keep simplicity of component.
 * Additional content can be passed as children, such as buttons or other elements.
 * */
export const TabNavigation: React.FC<TabNavigationProps> = ({
  items,
  endSlot,
  maxTabsPerLine = 5,
  urlParamsKey = "tab",
  className
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const currentTab = searchParams.get(urlParamsKey) || "";

  const getSearchParamsArgs = useCallback(
    (tabId: string) => {
      const updatedParams = new URLSearchParams(searchParams);
      updatedParams.set(urlParamsKey, tabId);
      return `?${updatedParams.toString()}`;
    },
    [searchParams, urlParamsKey]
  );

  useEffect(() => {
    if (
      ((currentTab && !items.find((item) => item.id === currentTab)) ||
        !currentTab) &&
      items[0]
    ) {
      setSearchParams({ tab: items[0].id });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const preferDropdown = items.length > maxTabsPerLine;
  const currentTitle = items.find((item) => item.id === currentTab)?.title;

  return (
    <div
      className={clsx(
        className,
        "flex items-center justify-between gap-2",
        "pt-0 pb-2 md:px-1 md:pb-0",
        "border-b border-border-primary"
      )}
    >
      <div className="flex items-center gap-2">
        <div className={clsx(preferDropdown ? "hidden" : "hidden md:flex")}>
          {items.map((item) => (
            <TabNavigationItem
              key={item.id}
              current={item.id === currentTab}
              href={getSearchParamsArgs(item.id)}
              title={item.title}
            />
          ))}
        </div>

        <div
          className={clsx({
            "md:hidden flex items-center gap-1": !preferDropdown
          })}
        >
          <Dropdown>
            <div className="md:my-2x">
              <DropdownButton outline title={currentTitle}>
                <Bars3Icon />
                <span
                  data-slot="dropdown-button-title"
                  className={clsx("text-base font-normal ml-1", "truncate")}
                >
                  {currentTitle}
                </span>
              </DropdownButton>
              <DropdownMenu anchor="bottom start" className="mx-2">
                {items.map((item) => (
                  <DropdownItem
                    key={item.id}
                    href={getSearchParamsArgs(item.id)}
                  >
                    <div className="flex items-center gap-2 max-w-60 sm:max-w-80">
                      {currentTab === item.id ? (
                        <CheckIcon className="size-5" />
                      ) : null}
                      <DropdownLabel className="truncate">
                        {item.title}
                      </DropdownLabel>
                      {/* {item.title} */}
                    </div>
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </div>
          </Dropdown>
        </div>
      </div>
      {endSlot}
    </div>
  );
};
