import { PerformanceValuesByDate } from "@/app/performance/utils/transformPerformanceData";
import {
  differenceInHours,
  eachHourOfInterval,
  endOfDay,
  formatISO,
  startOfDay
} from "date-fns";

/**
 * Generates array of 24 hour intervals for a given date
 */
export function generate24HourTicks(inputDate: Date): string[] {
  const start = startOfDay(inputDate);
  const end = endOfDay(inputDate);

  return eachHourOfInterval({ start, end }).map((date) => formatISO(date));
}

/**
 * Generates array of daily intervals between two dates
 */
// TODO: sync with real data from backend if change of time summer => winter is handled properly
export function generateDateTicks(
  startDate: Date,
  endDate: Date,
  data: PerformanceValuesByDate[]
): string[] {
  const start = startOfDay(startDate);
  const end = endOfDay(endDate);
  const hoursDiff = differenceInHours(end, start);

  // Get dates within the range
  const filteredDates = data.filter((item) => {
    const date = new Date(item.date);
    return date >= start && date <= end;
  });

  // For single day view, return all data points
  if (hoursDiff <= 24) {
    return generate24HourTicks(start);
  }

  // Group by days and take first item of each day
  const dailyTicks = filteredDates.reduce((acc, item) => {
    const day = formatISO(startOfDay(new Date(item.date)));
    if (!acc.has(day)) {
      acc.set(day, item.date);
    }
    return acc;
  }, new Map<string, string>());

  return Array.from(dailyTicks.values()).sort();
}
