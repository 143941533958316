import { PerformancePlantsField } from "@/app/performance/template-management/PerformancePlantsField";
import { useTranslation } from "@/lib/i18n";
import { Accordion } from "@/ui/display/accordion";
import { Divider } from "@/ui/display/divider";
import { Alert } from "@/ui/feedback/alert";
import { CheckboxControlled } from "@/ui/form/CheckboxControlled";
import { Field, Label } from "@/ui/form/fieldset";
import { FormFieldCard } from "@/ui/form/formFieldCard";
import { InputControlled } from "@/ui/form/InputControlled";
import { SwitchControlled } from "@/ui/form/SwitchControlled";
import * as Headless from "@headlessui/react";
import { UseFormReturn } from "react-hook-form";

export type PerformanceTemplateFormValues = {
  name: string;
  generatedRevenue: boolean;
  generatedRevenueTarget: boolean;
  producedElectricity: boolean;
  producedElectricityTarget: boolean;
  selfConsumptionRate: boolean;
  plants: string[];
};

type PerformanceTemplateFormProps = {
  form: UseFormReturn<PerformanceTemplateFormValues>;
  onSubmit: (values: PerformanceTemplateFormValues) => void;
};

export const PerformanceTemplateForm: React.FC<
  PerformanceTemplateFormProps
> = ({ onSubmit, form }) => {
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors }
  } = form;

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">
      <InputControlled
        label={t("performanceTemplates.form.fields.name.label")}
        id="name"
        name="name"
        control={control}
        rules={{
          maxLength: {
            value: 50,
            message: t("performanceTemplates.form.fields.name.maxLength", {
              length: 50
            })
          }
        }}
      />

      <Divider />
      <Field className="flex flex-col gap-2">
        <Label className="mb-2">
          {t("performanceTemplates.form.fields.charts.label")}
        </Label>

        <FormFieldCard>
          <Accordion
            maxHeightClassName="max-h-[60px]"
            trigger={
              <Headless.Field className="flex items-center gap-3">
                <SwitchControlled
                  id="generatedRevenue"
                  name="generatedRevenue"
                  defaultChecked={true}
                  control={control}
                  optional
                />
                <Label htmlFor="generatedRevenue" className="cursor-pointer">
                  {t("performanceTemplates.form.fields.generatedRevenue.label")}
                </Label>
              </Headless.Field>
            }
          >
            <CheckboxControlled
              label={t(
                "performanceTemplates.form.fields.generatedRevenueTarget.label"
              )}
              id="generatedRevenueTarget"
              name="generatedRevenueTarget"
              control={control}
              optional
              className="ml-11 mt-2" // size-8 of switch box + gap 3
            />
          </Accordion>
        </FormFieldCard>

        <FormFieldCard>
          <Accordion
            maxHeightClassName="max-h-[60px]"
            trigger={
              <Headless.Field className="flex items-center gap-3">
                <SwitchControlled
                  id="producedElectricity"
                  name="producedElectricity"
                  defaultChecked={true}
                  control={control}
                  optional
                />
                <Label htmlFor="producedElectricity" className="cursor-pointer">
                  {t(
                    "performanceTemplates.form.fields.producedElectricity.label"
                  )}
                </Label>
              </Headless.Field>
            }
          >
            <CheckboxControlled
              label={t(
                "performanceTemplates.form.fields.producedElectricityTarget.label"
              )}
              id="producedElectricityTarget"
              name="producedElectricityTarget"
              control={control}
              optional
              className="ml-11 mt-2" // size-8 of switch box + gap 3
            />
          </Accordion>
        </FormFieldCard>

        <FormFieldCard>
          <Headless.Field className="flex items-center gap-3">
            <SwitchControlled
              id="selfConsumptionRate"
              name="selfConsumptionRate"
              defaultChecked={true}
              control={control}
              optional
            />
            <Label htmlFor="selfConsumptionRate" className="cursor-pointer">
              {t("performanceTemplates.form.fields.selfConsumptionRate.label")}
            </Label>
          </Headless.Field>
        </FormFieldCard>
      </Field>

      <Divider />

      <PerformancePlantsField
        id="plants"
        name="plants"
        control={control}
        setValue={setValue}
        value={watch("plants")}
      />
      {errors.root?.message ? (
        <Alert type="error" title={errors.root?.message} />
      ) : null}

      <div className="h-10"></div>
    </form>
  );
};
