import { graphql } from "@/lib/data/graphql";
import { formatISO } from "date-fns";

type PerformanceValues = {
  __typename: "PerformanceValues";
  id: number;
  date: string;
  revenue: number; // CHF
  revenueTarget: number; // CHF
  pYield: number; // kWh
  pYieldTarget: number; // kWh
  pOwnConsumptionPercentage: number; // 0 at night, 0-100% during the day
};

export const performanceValuesFactory = (
  startDate: Date,
  endDate: Date | null,
  aggregationType: ReturnType<typeof graphql.scalar<"AggregationType">>,
  indexOffset = 1
): PerformanceValues[] => {
  const items: PerformanceValues[] = [];
  const start = new Date(startDate);
  const end = endDate ? new Date(endDate) : new Date();

  let idCounter = indexOffset * 10000;
  const electricityPriceCHF = 0.2;

  const getOffsetFactor = (indexOffset: number) => {
    const offsetSeed = Math.sin(indexOffset * 12345) * 10000;
    return 1 + (offsetSeed % 100) / 1000;
  };

  const getInterval = () => {
    switch (aggregationType) {
      case "FIVE_MINUTES":
        return 60 * 5;
      case "FIFTEEN_MINUTES":
        return 60 * 15;
      case "HOUR":
      case "DAY":
      case "MONTH":
        return 60 * 60;
      default:
        return 60 * 15;
    }
  };

  const getAggregationHours = () => {
    switch (aggregationType) {
      case "DAY":
        return 19;
      case "MONTH": {
        const year = start.getFullYear();
        const month = start.getMonth();
        const daysInMonth = new Date(year, month + 1, 0).getDate();
        return 19 * daysInMonth;
      }
      default:
        return 1;
    }
  };

  const getSunlightHours = (date: Date) => {
    const latitude = 47.22;

    const dayOfYear = Math.floor(
      (new Date(date).setHours(12) -
        new Date(date.getFullYear(), 0, 1).setHours(12)) /
        86400000
    );

    const declination =
      -23.45 * Math.cos((2 * Math.PI * (dayOfYear + 10)) / 365.25);

    const latitudeRad = latitude * (Math.PI / 180);
    const declinationRad = declination * (Math.PI / 180);
    const dayLength =
      (24 * Math.acos(-Math.tan(latitudeRad) * Math.tan(declinationRad))) /
      Math.PI;

    const seasonalFactor =
      0.4 +
      (0.5 * (1 + Math.cos(((dayOfYear - 172) * 2 * Math.PI) / 365.25))) / 2;

    const midDay = 12.5;
    const sunrise = midDay - dayLength / 2;
    const sunset = midDay + dayLength / 2;

    return {
      sunrise: Math.max(3, sunrise),
      sunset: Math.min(22, sunset),
      seasonalFactor
    };
  };

  const getOwnConsumptionPercentage = (
    hour: number,
    baseYield: number,
    seasonalFactor: number
  ) => {
    if (baseYield === 0) return 0;

    const timeOfDayFactor = (() => {
      if (hour < 6) {
        return 0.3 + ((hour - 3) / 3) * 0.3;
      }
      if (hour <= 8) {
        return 0.9;
      }
      if (hour <= 9) {
        return 0.9 - (hour - 8) * 0.5;
      }
      if (hour <= 16) {
        return 0.4;
      }
      if (hour <= 17) {
        return 0.4 + (hour - 16) * 0.5;
      }
      if (hour <= 21) {
        return 0.9;
      }
      return 0.9 - (hour - 21) * 0.4;
    })();

    const baseConsumption = 30 + timeOfDayFactor * 70;
    const seasonalConsumptionFactor = Math.min(
      1,
      Math.max(0.3, 1.2 - seasonalFactor)
    );
    const randomFactor = 1 + (Math.random() * 0.1 - 0.05);

    return Math.min(
      100,
      baseConsumption * seasonalConsumptionFactor * randomFactor
    );
  };

  const getWeatherFactor = () => 1;

  const getYieldForTime = (hour: number, sunrise: number, sunset: number) => {
    const dayLength = sunset - sunrise;
    const normalizedTime = (hour - sunrise) / dayLength;
    return (
      Math.sin(normalizedTime * Math.PI) *
      Math.pow(Math.sin(normalizedTime * Math.PI), 0.5)
    );
  };

  const shouldAggregate = (current: Date) => {
    if (aggregationType === "DAY") {
      return current.getDate() !== lastAggregationDate.getDate();
    }
    if (aggregationType === "MONTH") {
      return current.getMonth() !== lastAggregationDate.getMonth();
    }
    return false;
  };

  const intervalSeconds = getInterval();
  const aggregationHours = getAggregationHours();
  let aggregatedValues = {
    revenue: 0,
    revenueTarget: 0,
    pYield: 0,
    pYieldTarget: 0,
    totalConsumed: 0,
    count: 0
  };
  let lastAggregationDate = new Date(start);
  const offsetFactor = getOffsetFactor(indexOffset);

  for (
    let current = new Date(start);
    current <= end;
    current = new Date(current.getTime() + intervalSeconds * 1000)
  ) {
    if (current > end) break;

    const hour = current.getHours() + current.getMinutes() / 60;
    const { sunrise, sunset, seasonalFactor } = getSunlightHours(current);
    const weatherFactor = getWeatherFactor();

    let baseYield = 0;
    let baseRevenue = 0;
    let ownConsumptionPercentage = 0;

    if (hour >= sunrise && hour <= sunset) {
      baseYield =
        getYieldForTime(hour, sunrise, sunset) *
        500 *
        seasonalFactor *
        offsetFactor;
      baseYield *= (0.9 + Math.random() * 0.1) * weatherFactor;
      baseRevenue = baseYield * electricityPriceCHF;
      ownConsumptionPercentage =
        getOwnConsumptionPercentage(hour, baseYield, seasonalFactor) *
        offsetFactor;
    }

    const targetYield = baseYield * (1 + Math.random() * 0.2) * offsetFactor;
    const targetRevenue =
      baseRevenue * (1 + Math.random() * 0.1) * offsetFactor;

    if (aggregationType === "DAY" || aggregationType === "MONTH") {
      aggregatedValues.revenue += baseRevenue;
      aggregatedValues.revenueTarget += targetRevenue;
      aggregatedValues.pYield += baseYield;
      aggregatedValues.pYieldTarget += targetYield;

      const seasonalConsumptionFactor =
        baseYield > 0 ? Math.min(1, Math.max(0.3, 1.2 - seasonalFactor)) : 0;

      aggregatedValues.totalConsumed += baseYield * seasonalConsumptionFactor;
      aggregatedValues.count++;

      if (shouldAggregate(current) || current >= end) {
        const avgFactor = aggregatedValues.count / aggregationHours;
        const totalYield = aggregatedValues.pYield * avgFactor;
        const totalConsumed = aggregatedValues.totalConsumed * avgFactor;

        const aggregatedPercentage =
          totalYield > 0 ? (totalConsumed / totalYield) * 100 : 0;

        items.push({
          __typename: "PerformanceValues",
          id: Math.floor(Math.random() * 1_000_000) + idCounter++,
          date: formatISO(lastAggregationDate),
          revenue: Number((aggregatedValues.revenue * avgFactor).toFixed(2)),
          revenueTarget: Number(
            Math.max(0, aggregatedValues.revenueTarget * avgFactor).toFixed(2)
          ),
          pYield: Number(totalYield.toFixed(2)),
          pYieldTarget: Number(
            (aggregatedValues.pYieldTarget * avgFactor).toFixed(2)
          ),
          pOwnConsumptionPercentage: Number(aggregatedPercentage.toFixed(2))
        });

        aggregatedValues = {
          revenue: 0,
          revenueTarget: 0,
          pYield: 0,
          pYieldTarget: 0,
          totalConsumed: 0,
          count: 0
        };
        lastAggregationDate = new Date(current);
      }
    } else {
      items.push({
        __typename: "PerformanceValues",
        id: Math.floor(Math.random() * 1_000_000) + idCounter++,
        date: formatISO(current),
        revenue: Number(baseRevenue.toFixed(2)),
        revenueTarget: Number(Math.max(0, targetRevenue).toFixed(2)),
        pYield: Number(baseYield.toFixed(2)),
        pYieldTarget: Number(targetYield.toFixed(2)),
        pOwnConsumptionPercentage: Number(ownConsumptionPercentage.toFixed(2))
      });
    }
  }

  const allItems = items;

  // Only filter for non-aggregated data types
  if (!["FIVE_MINUTES", "FIFTEEN_MINUTES", "HOUR"].includes(aggregationType)) {
    return allItems;
  }

  // Group items by day
  const itemsByDay = allItems.reduce((acc, item) => {
    const day = new Date(item.date).toISOString().split("T")[0];
    if (!day) return acc;

    if (!acc.has(day)) {
      acc.set(day, []);
    }
    acc.get(day)!.push(item);
    return acc;
  }, new Map<string, PerformanceValues[]>());

  const result: PerformanceValues[] = [];

  // Process each day separately
  itemsByDay.forEach((dayItems) => {
    // Find items with non-zero values
    const activeItems = dayItems.filter(
      (item) =>
        item.pYield > 0 ||
        item.revenue > 0 ||
        item.pOwnConsumptionPercentage > 0
    );

    if (
      activeItems &&
      activeItems[0]?.date &&
      activeItems[activeItems.length - 1]?.date
    ) {
      // Get first and last active item indices
      const firstActiveTime = new Date(activeItems[0].date);
      const lastActiveTime = new Date(
        activeItems[activeItems.length - 1]!.date
      );

      // Include 1 hour before and after
      const bufferBefore = new Date(firstActiveTime);
      bufferBefore.setHours(firstActiveTime.getHours() - 1);

      const bufferAfter = new Date(lastActiveTime);
      bufferAfter.setHours(lastActiveTime.getHours() + 1);

      // Filter items within buffer period
      const dayResult = dayItems.filter((item) => {
        const itemDate = new Date(item.date);
        return itemDate >= bufferBefore && itemDate <= bufferAfter;
      });

      result.push(...dayResult);
    }
  });

  return result.sort(
    (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
  );
};
