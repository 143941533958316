import { ResponsiveRadialBar } from "@nivo/radial-bar";

export type GaugeChartProps = {
  /**
   * Use 0 - 100 value.
   */
  value: number;
  /**
   * @default 100
   */
  maxValue?: number;
  /**
   * Color of the gauge chart. Use css variable eg. `var(--color-content-success)`.
   */
  color?: string;
  /**
   * Width of the gauge.
   *
   * @default "medium"
   */
  thickness?: "slim" | "medium" | "chonky";
  /**
   *
   */
  shape?: "halfcircle" | "crescent" | "narrow";
  /**
   * You can override `shape` with custom values for start and end angle.
   */
  startAngle?: number;
  /**
   * You can override `shape` with custom values for start and end angle.
   */
  endAngle?: number;
  /**
   * Center slot is placed in exact center of the chart. The gauge chart is wrapping
   * "around" it.
   */
  centerSlot?: React.ReactNode;
};

export const GaugeChart: React.FC<GaugeChartProps> = ({
  value,
  maxValue = 100,
  color = "var(--color-yellow-300)",
  thickness = "medium",
  shape = "halfcircle",
  centerSlot,
  ...props
}) => {
  return (
    <div className="h-full relative">
      <ResponsiveRadialBar
        // Hardcoded chart data to support only single track gauge chart.
        data={[
          {
            id: "id",
            data: [
              {
                x: "x",
                y: value,
                color: color
              }
            ]
          }
        ]}
        maxValue={maxValue}
        {...styles.arcShape[shape]}
        {...(props.startAngle && { startAngle: props.startAngle })}
        {...(props.endAngle && { endAngle: props.endAngle })}
        enableTracks={true}
        tracksColor="var(--color-bg-secondary)"
        margin={{ top: 0, bottom: 0 }}
        innerRadius={styles.innerRadius[thickness]}
        cornerRadius={999}
        circularAxisOuter={null}
        colors={(dat) => dat.data.color}
        enableRadialGrid={false}
        enableCircularGrid={false}
        radialAxisStart={null}
        tooltip={() => null}
      />
      {centerSlot ? (
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          {centerSlot}
        </div>
      ) : null}
    </div>
  );
};

const styles = {
  innerRadius: {
    slim: 0.9,
    medium: 0.65,
    chonky: 0.4
  },
  arcShape: {
    halfcircle: {
      startAngle: -90,
      endAngle: 90
    },
    crescent: {
      startAngle: -135,
      endAngle: 135
    },
    narrow: {
      startAngle: -45,
      endAngle: 45
    }
  }
};
