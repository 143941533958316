import { FragmentOf, graphql, readFragment } from "@/lib/data/graphql";
import { Divider } from "@/ui/display/divider";
import { Error, Information, WarningAlt } from "@carbon/icons-react";
import { format } from "date-fns";
import { PerformancePlantBadge } from "../performance/PerformancePlantBadge";

export const TechnicalLogMessagesListItemFragment = graphql(`
  fragment TechnicalLogMessagesListItem on TechnicalLogMessage {
    id
    message
    type
    plant {
      id
      name
      colorHex
    }
    createdAt
  }
`);

type Props = {
  data: FragmentOf<typeof TechnicalLogMessagesListItemFragment>;
};

export const TechnicalLogMessagesListItem: React.FC<Props> = ({ data }) => {
  const message = readFragment(TechnicalLogMessagesListItemFragment, data);

  const getIconByType = () => {
    switch (message.type) {
      case "WARNING":
        return <WarningAlt className="size-5" />;
      case "ERROR":
        return <Error className="size-5" />;
      case "INFO":
      default:
        return <Information className="size-5" />;
    }
  };

  return (
    <div className="flex flex-col rounded-md border border-border-primary p-2 gap-2">
      <div className="flex items-center gap-2">
        <div className="">{getIconByType()}</div>
        <div className="text-sm font-semibold text-nowrap">
          {format(message.createdAt, "dd.MM.yyyy, HH:mm")}
        </div>
        <PerformancePlantBadge
          colorHex={message.plant.colorHex ?? ""}
          name={message.plant.name}
          className="max-w-max"
        />
      </div>
      <Divider />
      <div className="text-sm text-content-secondary">{message.message}</div>
    </div>
  );
};
