import { ConfigureIbansDialog } from "@/app/settings/ConfigureIbansDialog";
import { FragmentOf, graphql, readFragment } from "@/lib/data/graphql";
import { useTranslation } from "@/lib/i18n";
import { validateBIC, validateIBAN } from "@/lib/utils/bankDetailsValidation";
import { Button } from "@/ui/button/button";
import { Divider } from "@/ui/display/divider";
import { toaster } from "@/ui/feedback/toaster";
import { CheckboxControlled } from "@/ui/form/CheckboxControlled";
import { InputControlled } from "@/ui/form/InputControlled";
import { SwitchControlled } from "@/ui/form/SwitchControlled";
import { Heading } from "@/ui/typography/heading";
import { useMutation } from "@apollo/client";
import React, { useState } from "react";
import { useForm } from "react-hook-form";

export type PaymentSettingsFormValues = {
  street: string;
  streetNumber: string;
  postalCode: string;
  city: string;
  country: string;
  legalEntity: boolean;
  bankName: string;
  bic: string;
  iban: string;
  enableIndividualIban: boolean;
  companyName: string;
  companyUid: string;
  companyVatApplicable: boolean;
};

export const PaymentSettingsFormFragment = graphql(`
  fragment PaymentSettingsForm on User {
    id
    uid
    paymentDetails {
      id
      street
      streetNumber
      postalCode
      city
      country
      legalEntity
      companyName
      companyUid
      companyVatApplicable
      bankName
      bic
      iban
      enableIndividualIban
      plantIbans {
        plant {
          id
          name
        }
        iban
      }
    }
  }
`);

export const UpdatePaymentSettingsFormMutation = graphql(`
  mutation UpdatePaymentSettingsForm(
    $userId: String!
    $input: UpdateUserPaymentDetailsInput!
  ) {
    updateUserPaymentDetails(
      userId: $userId
      updateUserPaymentDetailsInput: $input
    ) {
      id
      street
      streetNumber
      postalCode
      city
      country
      legalEntity
      companyName
      companyUid
      companyVatApplicable
      bankName
      bic
      iban
      enableIndividualIban
      plantIbans {
        plant {
          id
          name
        }
        iban
      }
    }
  }
`);

type PaymentSettingsFormProps = {
  data: FragmentOf<typeof PaymentSettingsFormFragment>;
};

export const PaymentSettingsForm: React.FC<PaymentSettingsFormProps> = ({
  data
}) => {
  const { t } = useTranslation();
  const [isConfigureDialogOpen, setIsConfigureDialogOpen] = useState(false);

  const { id, uid, paymentDetails } = readFragment(
    PaymentSettingsFormFragment,
    data
  );

  const { control, handleSubmit, watch } = useForm<PaymentSettingsFormValues>({
    defaultValues: {
      street: paymentDetails?.street || "",
      streetNumber: paymentDetails?.streetNumber || "",
      postalCode: paymentDetails?.postalCode || "",
      city: paymentDetails?.city || "",
      country: paymentDetails?.country || "",
      legalEntity: paymentDetails?.legalEntity || false,
      companyName: paymentDetails?.companyName || "",
      companyUid: paymentDetails?.companyUid || "",
      companyVatApplicable: paymentDetails?.companyVatApplicable || false,
      bankName: paymentDetails?.bankName || "",
      bic: paymentDetails?.bic || "",
      iban: paymentDetails?.iban || "",
      enableIndividualIban: paymentDetails?.enableIndividualIban || false
    }
  });

  const legalEntity = watch("legalEntity");
  const enableIndividualIbanEnabled = watch("enableIndividualIban");

  const [updatePaymentSettingsForm, { loading }] = useMutation(
    UpdatePaymentSettingsFormMutation
  );

  const handleUpdatePaymentSettings = (formData: PaymentSettingsFormValues) => {
    updatePaymentSettingsForm({
      variables: {
        userId: uid, // TODO(#262): should be id
        input: {
          street: formData.street,
          streetNumber: formData.streetNumber,
          postalCode: formData.postalCode,
          city: formData.city,
          country: formData.country,
          legalEntity: formData.legalEntity,
          companyName: formData.companyName ?? null,
          companyUid: formData.companyUid ?? null,
          companyVatApplicable: formData.companyVatApplicable ?? false,
          bankName: formData.bankName,
          bic: formData.bic,
          iban: formData.iban,
          enableIndividualIban: formData.enableIndividualIban ?? false
        }
      },
      onCompleted: () => {
        toaster.success({
          title: t("settings.payment.actions.save.success")
        });
      },
      onError: () => {
        toaster.error({
          title: t("settings.payment.actions.save.error")
        });
      }
    });
  };

  return (
    <>
      <form
        onSubmit={handleSubmit(handleUpdatePaymentSettings)}
        className="flex flex-col gap-6 max-w-lg"
      >
        <Heading level={2}>{t("settings.payment.title.address")}</Heading>
        <Divider />

        <div className="flex flex-col gap-6">
          <div className="flex gap-2 items-center">
            <SwitchControlled
              id="legalEntity"
              name="legalEntity"
              control={control}
              defaultChecked={false}
              optional
            />
            <label
              htmlFor="legalEntity"
              className="text-sm/6 text-content-secondary"
            >
              {t("settings.payment.fields.legalEntity.label")}
            </label>
          </div>

          {legalEntity ? (
            <div className="flex flex-col gap-6 sm:ml-4">
              <div className="flex flex-col sm:flex-row gap-6">
                <InputControlled
                  label={t("settings.payment.fields.companyName.label")}
                  id="companyName"
                  name="companyName"
                  control={control}
                  optional
                />
                <InputControlled
                  label={t("settings.payment.fields.companyUid.label")}
                  id="companyUid"
                  name="companyUid"
                  control={control}
                />
              </div>
              <CheckboxControlled
                label={t("settings.payment.fields.companyVatApplicable.label")}
                name="companyVatApplicable"
                id="companyVatApplicable"
                control={control}
                optional
              />
            </div>
          ) : null}

          <div className="flex flex-col sm:flex-row gap-6">
            <InputControlled
              label={t("settings.payment.fields.street.label")}
              id="street"
              name="street"
              className="flex-1"
              autoComplete="street-address"
              control={control}
            />
            <InputControlled
              label={t("settings.payment.fields.streetNumber.label")}
              id="streetNumber"
              name="streetNumber"
              autoComplete="street-number"
              control={control}
            />
          </div>
          <div className="flex flex-col sm:flex-row gap-6">
            <InputControlled
              label={t("settings.payment.fields.postalCode.label")}
              id="postalCode"
              name="postalCode"
              autoComplete="postal-code"
              control={control}
            />
            <InputControlled
              label={t("settings.payment.fields.city.label")}
              id="city"
              name="city"
              autoComplete="home city"
              className="flex-1"
              control={control}
            />
          </div>

          {/* TODO: should be selectbox or remove completely if only Swiss is supported? */}
          <InputControlled
            label={t("settings.payment.fields.country.label")}
            id="country"
            name="country"
            className="self-start"
            autoComplete="country-name"
            control={control}
          />
        </div>

        <Heading level={2}>{t("settings.payment.title.bankDetails")}</Heading>
        <Divider />

        <div className="flex flex-col gap-6">
          <InputControlled
            label={t("settings.payment.fields.bankName.label")}
            id="bankName"
            name="bankName"
            control={control}
          />
          <InputControlled
            label={t("settings.payment.fields.bic.label")}
            id="bic"
            name="bic"
            control={control}
            hideCounter
            rules={{
              validate: (bic) =>
                validateBIC(bic) ||
                t("settings.payment.fields.bic.validation.invalidFormat")
            }}
            placeholder={t("settings.payment.fields.bic.placeholder")}
          />

          <div className="flex gap-2">
            <InputControlled
              label={t("settings.payment.fields.iban.label")}
              id="iban"
              name="iban"
              control={control}
              hideCounter
              className="flex-1"
              optional
              rules={{
                validate: (iban) =>
                  !iban ||
                  validateIBAN(iban) ||
                  t("settings.payment.fields.iban.validation.invalidFormat")
              }}
              placeholder={t("settings.payment.fields.iban.placeholder")}
            />
            <Button
              outline
              className="self-start mt-[32px] sm:mt-[33px]"
              onClick={() => setIsConfigureDialogOpen(true)}
            >
              {t("settings.payment.actions.configure")}
            </Button>
          </div>
        </div>
        <Button
          type="submit"
          className="self-start min-w-32 mt-2 sm:mt-6"
          loading={loading}
        >
          {t("settings.payment.actions.save.label")}
        </Button>
      </form>
      <ConfigureIbansDialog
        isOpen={isConfigureDialogOpen}
        enableIndividualIbanEnabled={enableIndividualIbanEnabled}
        onClose={() => setIsConfigureDialogOpen(false)}
        paymentSettingsFormControl={control}
        userId={id.toString()}
        userUid={uid}
        plantIbans={
          paymentDetails?.plantIbans?.map((plantIban) => ({
            plantId: plantIban.plant.id,
            plantName: plantIban.plant.name,
            iban: plantIban.iban
          })) ?? []
        }
      />
    </>
  );
};
