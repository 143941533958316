import {
  addMilliseconds,
  differenceInMilliseconds,
  formatISO,
  parseISO
} from "date-fns";

interface TechnicalLogMessage {
  __typename: "TechnicalLogMessage";
  id: number;
  createdAt: string;
  plant: {
    id: number;
    name: string;
  };
}

/**
 * Updates the createdAt property of an array of TechnicalLogMessage objects.
 * For the first 7 items, it updates the date part to today while preserving the original time.
 *
 * @param items Array of TechnicalLogMessage objects to update
 * @returns A new array with updated createdAt timestamps
 */
export function updateTechnicalLogMessages(
  items: TechnicalLogMessage[],
  startDate: string,
  endDate: string
): TechnicalLogMessage[] {
  // Parse string dates into Date objects
  const startDateTime = parseISO(startDate);
  const endDateTime = parseISO(endDate);
  // Calculate the total time span in milliseconds using date-fns
  const timeSpan = differenceInMilliseconds(endDateTime, startDateTime);
  return items.map((item) => {
    // Generate a random position in the timespan (between 0 and 1)
    const randomPosition = Math.random();
    // Generate a new timestamp randomly within the range using date-fns
    const newTimestamp = addMilliseconds(
      startDateTime,
      timeSpan * randomPosition
    );
    // Generate a new random UUID for the id
    const newId = generateRandomNumber();
    // Return a new object with updated createdAt and id
    return {
      ...item,
      id: newId,
      createdAt: formatISO(newTimestamp)
    };
  });
}

function generateRandomNumber() {
  const min = 1000000000; // 10-digit minimum number
  const max = 9999999999; // 10-digit maximum number
  return Math.floor(Math.random() * (max - min + 1)) + min;
}
