import clsx from "clsx";
import { Dispatch, FC, SetStateAction, useCallback } from "react";
import type { LegendProps as RechartsLegendProps } from "recharts";
import { Payload } from "recharts/types/component/DefaultLegendContent";

type Props = RechartsLegendProps & {
  /**
   * Formatter for the label of the legend item.
   */
  labelFormatter?: (
    name?: string,
    payload?: RechartsLegendProps["payload"]
  ) => string;
  setLegendHiddenItems: Dispatch<SetStateAction<string[]>>;
  legendHiddenItems: string[];
  className?: string;
};

type ItemType = Payload & {
  payload: { strokeOpacity?: string | number };
};

export const PerformanceLegend: FC<Props> = ({
  payload,
  labelFormatter,
  setLegendHiddenItems,
  legendHiddenItems,
  className
}) => {
  const onToggleItem = useCallback(
    (item: ItemType) => {
      if (!payload) {
        return;
      }

      setLegendHiddenItems((prev) =>
        typeof item?.dataKey === "string"
          ? prev.includes(item.dataKey)
            ? prev.filter((key) => key !== item.dataKey)
            : [...prev, item.dataKey]
          : prev
      );
    },
    [payload, setLegendHiddenItems]
  );

  if (!payload) {
    return null;
  }

  return (
    <ul className={clsx("flex flex-wrap gap-3 md:px-12", className)}>
      {payload.map((item, i) => (
        <li key={i} className="flex items-center">
          <button
            type="button"
            className={`w-full text-left transition-opacity hover:opacity-80 ${
              legendHiddenItems.includes(item.dataKey as string)
                ? "opacity-40"
                : ""
            }`}
            onClick={() => onToggleItem(item as ItemType)}
          >
            <div className="flex items-center justify-center">
              {item.type === "line" ? (
                <IconWrapper
                  color={item.color}
                  strokeDasharray={item?.payload?.strokeDasharray}
                  // for items with no fully opacity, if they are turn off, use full opacity here, otherwise it will be hard to see
                  strokeOpacity={
                    legendHiddenItems.includes(item.dataKey as string)
                      ? 1
                      : (item as ItemType)?.payload?.strokeOpacity
                  }
                />
              ) : (
                <span
                  className="block size-3 mr-2"
                  style={{ backgroundColor: item.color }}
                />
              )}
              <span className="text-sm text-content-secondary font-medium">
                {labelFormatter
                  ? labelFormatter(item.value, [item])
                  : item.value}
              </span>
            </div>
          </button>
        </li>
      ))}
    </ul>
  );
};

type IconWrapperProps = {
  color?: string;
  strokeDasharray?: string | number;
  strokeOpacity?: string | number;
};

const IconWrapper: React.FC<IconWrapperProps> = ({
  color,
  strokeDasharray,
  strokeOpacity
}) => {
  return (
    <div
      className="size-3.5 flex items-center justify-center mr-2"
      style={{ opacity: strokeOpacity }}
    >
      <svg width="100%" height="5">
        <line
          x1="0"
          y1="3"
          x2="100%"
          y2="2"
          stroke={color}
          strokeWidth={5}
          strokeDasharray={strokeDasharray}
        />
      </svg>
    </div>
  );
};
