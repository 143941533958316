import { NotificationsNavItem } from "@/app/notifications/NotificationsNavItem";
import { secondFactorFlowAtom } from "@/app/users/atoms/mfaAtoms";
import { LoginPage } from "@/app/users/LoginPage";
import { EnrollSecondFactorPage } from "@/app/users/multi-factor-authentication/EnrollSecondFactorPage";
import { VerifySecondFactorPage } from "@/app/users/multi-factor-authentication/VerifySecondFactorPage";
import { UserAvatar } from "@/app/users/UserAvatar";
import { UserDropdown } from "@/app/users/UserDropdown";
import { useSession, useSignOut } from "@/lib/auth";
import { useTranslation } from "@/lib/i18n";
import { isNative } from "@/lib/utils/isNative";
import { openBrowserForNative } from "@/lib/utils/openBrowserForNative";
import { Button } from "@/ui/button/button";
import { ToasterContainer } from "@/ui/feedback/toaster";
import {
  Sidebar,
  SidebarBody,
  SidebarDivider,
  SidebarFooter,
  SidebarHeader,
  SidebarItem,
  SidebarLabel,
  SidebarSection,
  SidebarSpacer
} from "@/ui/layouts/sidebar";
import { SidebarLayout } from "@/ui/layouts/sidebar-layout";
import { LoadingSpinner } from "@/ui/loading-spinner";
import { Logo } from "@/ui/Logo";
import { Navbar, NavbarSection, NavbarSpacer } from "@/ui/navbar/navbar";
import {
  ChevronRight,
  DocumentMultiple_02,
  EnergyRenewable,
  Flash,
  GroupPresentation,
  Home,
  Money,
  Notification,
  ShoppingCart,
  UserMultiple
} from "@carbon/icons-react";
import {
  ArrowRightEndOnRectangleIcon,
  ShieldCheckIcon
} from "@heroicons/react/20/solid";
import { useAtom } from "jotai";
import { Outlet, useLocation } from "react-router-dom";
import { NotificationsBadge } from "../notifications/NotificationsBadge";
import { FullName } from "../users/FullName";

export const AppLayout: React.FC = () => {
  const { t } = useTranslation();
  const session = useSession();
  const [signOut] = useSignOut();
  const location = useLocation();
  const { pathname } = location;

  const [flow] = useAtom(secondFactorFlowAtom);

  if (session.loading) {
    return (
      <div className="flex mt-14 items-center justify-center">
        <LoadingSpinner />
      </div>
    );
  }

  // User is not signed in
  if (!session.isSignedIn()) {
    return flow === "second_factor" ? (
      <VerifySecondFactorPage />
    ) : (
      <LoginPage />
    );
  }
  // Skip enrolling if MFA is disabled
  if (import.meta.env.VITE_MFA_DISABLED !== "true") {
    // In case user refreshes the app during enrolling
    if (flow === null && !session.isUserEnrolled(session.firebaseUser)) {
      return <EnrollSecondFactorPage />;
    }

    if (flow === "enroll") {
      return <EnrollSecondFactorPage />;
    }
  }

  const { ability } = session;

  return (
    <div data-testid="authenticated-app-layout">
      <SidebarLayout
        navbar={
          <Navbar>
            <NavbarSpacer />
            <NavbarSection>
              {ability?.can("read", "notifications") && (
                <NotificationsNavItem />
              )}
              <UserDropdown />
            </NavbarSection>
          </Navbar>
        }
        sidebar={
          <Sidebar>
            <SidebarHeader>
              <Logo className="px-2" />
            </SidebarHeader>
            <SidebarBody>
              <SidebarSection>
                {ability?.can("read", "dashboard") && (
                  <SidebarItem href="/" current={pathname === "/"}>
                    <Home data-slot="icon" />
                    <SidebarLabel>{t("navigation.dashboard")}</SidebarLabel>
                  </SidebarItem>
                )}
                {ability?.can("read", "performance") && (
                  <SidebarItem
                    href="/performance"
                    current={pathname === "/performance"}
                  >
                    <Flash data-slot="icon" />
                    <SidebarLabel>{t("navigation.performance")}</SidebarLabel>
                  </SidebarItem>
                )}
                {ability?.can("read", "my-impact") && (
                  <SidebarItem
                    href="/my-impact"
                    current={pathname === "/my-impact"}
                  >
                    <EnergyRenewable data-slot="icon" />
                    <SidebarLabel>{t("navigation.myImpact")}</SidebarLabel>
                  </SidebarItem>
                )}
                {ability?.can("read", "resources") && (
                  <SidebarItem
                    href="/permissions"
                    current={pathname.startsWith("/permissions")}
                  >
                    <ShieldCheckIcon />
                    <SidebarLabel>{t("navigation.permissions")}</SidebarLabel>
                  </SidebarItem>
                )}
                {ability?.can("read", "documents") && (
                  <SidebarItem
                    href="/documents"
                    current={pathname.startsWith("/documents")}
                  >
                    <DocumentMultiple_02 data-slot="icon" />
                    <SidebarLabel>{t("navigation.documents")}</SidebarLabel>
                  </SidebarItem>
                )}
                {ability?.can("read", "users") && (
                  <SidebarItem
                    href="/users"
                    current={pathname.startsWith("/users")}
                  >
                    <UserMultiple data-slot="icon" />
                    <SidebarLabel>{t("navigation.users")}</SidebarLabel>
                  </SidebarItem>
                )}
                {ability?.can("read", "dashboard") && (
                  <SidebarItem
                    href={import.meta.env.VITE_ESHOP_LINK}
                    target="_blank"
                    {...(isNative() && {
                      onClick: () => {
                        openBrowserForNative(import.meta.env.VITE_ESHOP_LINK);
                      }
                    })}
                  >
                    <ShoppingCart data-slot="icon" />
                    <SidebarLabel>{t("navigation.eshop")}</SidebarLabel>
                  </SidebarItem>
                )}
                {ability?.can("read", "community-impact") && (
                  <SidebarItem
                    href="/community-impact"
                    current={pathname === "/community-impact"}
                  >
                    <GroupPresentation data-slot="icon" />
                    <SidebarLabel>
                      {t("navigation.communityImpact")}
                    </SidebarLabel>
                  </SidebarItem>
                )}
                {ability?.can("read", "project-overview") && (
                  <SidebarItem
                    href="/project-overview"
                    current={pathname === "/project-overview"}
                  >
                    <Money data-slot="icon" />
                    <SidebarLabel>
                      {t("navigation.projectOverview")}
                    </SidebarLabel>
                  </SidebarItem>
                )}
              </SidebarSection>

              {/* Bottom section only visible on mobile */}
              <SidebarSpacer className="lg:hidden" />
              <SidebarSection className="lg:hidden">
                <SidebarItem
                  href="/settings"
                  current={pathname.startsWith("/settings")}
                >
                  <UserAvatar className="!size-8" />
                  <SidebarLabel>
                    <FullName />
                  </SidebarLabel>
                  <div className="ml-auto">
                    <ChevronRight />
                  </div>
                </SidebarItem>
                <SidebarDivider />
                {ability?.can("read", "notifications") && (
                  <SidebarItem
                    href="/notifications"
                    current={pathname.startsWith("/notifications")}
                  >
                    <Notification data-slot="icon" />
                    <SidebarLabel>{t("navigation.notifications")}</SidebarLabel>
                    <NotificationsBadge />
                  </SidebarItem>
                )}
              </SidebarSection>
            </SidebarBody>
            <SidebarFooter className="lg:hidden mt-auto">
              <Button
                className="w-full !justify-start !gap-x-3"
                onClick={signOut}
                aria-label={t("menu.signOut")}
                color="light/white"
                size="lg"
              >
                <ArrowRightEndOnRectangleIcon className="!size-7" />
                <span>{t("menu.signOut")}</span>
              </Button>
            </SidebarFooter>
          </Sidebar>
        }
      >
        <Outlet />
      </SidebarLayout>

      <ToasterContainer />
    </div>
  );
};
