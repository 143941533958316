import { Button } from "@/ui/button/button";
import { ArrowRight } from "@carbon/icons-react";
import { FC } from "react";

export const CardHeaderAction: FC<{
  href: string;
  label?: string;
}> = ({ href, label }) => {
  return (
    <Button href={href} plain className="text-sm sm:text-base">
      {label && <b>{label}</b>}
      <ArrowRight data-slot="icon" />
    </Button>
  );
};
