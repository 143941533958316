import { Text } from "@/ui/typography/text";
import clsx from "clsx";
import { FC, PropsWithChildren } from "react";

export const Section: FC<PropsWithChildren<{ className?: string }>> = ({
  children,
  className
}) => {
  return <div className={clsx("space-y-2", className)}>{children}</div>;
};

export const SectionHeader: FC<
  PropsWithChildren<{ icon?: React.ReactElement }>
> = ({ icon, children }) => {
  return (
    <div className="flex items-center gap-1">
      {icon}
      <Text className="text-sm font-semibold">{children}</Text>
    </div>
  );
};

export const SectionBody: FC<PropsWithChildren> = ({ children }) => {
  return <div className="pt-2 flex flex-col items-start">{children}</div>;
};
