import { useAddResource } from "@/app/permissions/hooks/useAddPermission";
import { useUpdateRole } from "@/app/permissions/hooks/useUpdateRole";
import { useTranslation } from "@/lib/i18n";
import { Button } from "@/ui/button/button";
import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogTitle
} from "@/ui/feedback/dialog";
import { toaster } from "@/ui/feedback/toaster";
import { InputControlled } from "@/ui/form/InputControlled";
import { LoadingSpinner } from "@/ui/loading-spinner";
import { PlusIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import { useState } from "react";
import { useForm } from "react-hook-form";

type FormData = {
  name: string;
};
type Props = {
  subjectActionMap: Record<string, Set<string>>;
  selectedRoleId?: string;
};

export const AddPermissionDialogBtn: React.FC<Props> = ({
  subjectActionMap,
  selectedRoleId
}) => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);
  const { addResource, loading: loadingAddResource } = useAddResource();
  const { updateRole, loading: loadingUpdateRole } = useUpdateRole();

  const { control, reset, handleSubmit } = useForm<FormData>({
    defaultValues: { name: "" }
  });
  const loading = loadingAddResource || loadingUpdateRole;

  const handleAddPermission = async (formData: FormData) => {
    try {
      await addResource({
        variables: { name: formData.name },
        onError: (error) => {
          throw error;
        }
      });

      const resources = Object.entries(subjectActionMap).flatMap(
        ([sub, acts]) => Array.from(acts).map((act) => `${sub}:${act}`)
      );

      if (selectedRoleId) {
        updateRole({
          variables: {
            roleId: parseInt(selectedRoleId, 10),
            input: {
              resourceNames: [...resources, formData.name]
            }
          },
          onCompleted: () => {
            reset();
            setIsOpen(false);
          },
          onError: (error) => {
            throw error;
          }
        });
      }
      // ignoring caught error itself as it's logged already in errorLink of apollo client
    } catch {
      toaster.error({
        title: t("permissions.addPermission.error.description")
      });
    }
  };

  return (
    <>
      <Button
        outline
        className="ml-auto mt-auto"
        onClick={() => setIsOpen(true)}
      >
        {t("permissions.addPermission.newPermission")}
        <PlusIcon />
      </Button>
      <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
        <DialogTitle>{t("permissions.addPermission.title")}</DialogTitle>
        <form onSubmit={handleSubmit(handleAddPermission)}>
          <DialogBody>
            <InputControlled
              id="name"
              name="name"
              control={control}
              label={t("permissions.addPermission.fields.permissionName.label")}
              placeholder={t(
                "permissions.addPermission.fields.permissionName.placeholder"
              )}
            />
          </DialogBody>
          <DialogActions>
            <Button plain onClick={() => setIsOpen(false)}>
              {t("permissions.addPermission.actions.cancel")}
            </Button>
            <Button
              disabled={loading}
              className={clsx({ "pointer-events-none opacity-50": loading })}
              type="submit"
            >
              {loading ? (
                <div className="pr-2">
                  <LoadingSpinner size="sm" />
                </div>
              ) : null}
              {t("permissions.addPermission.actions.add")}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};
