import { PerformanceChartFragment } from "@/app/performance/PerformanceChart";
import { FragmentOf, readFragment } from "@/lib/data/graphql";
import { downloadFile } from "@/lib/utils/downloadFile";

export const generateCsv = (
  data: FragmentOf<typeof PerformanceChartFragment>[]
): string => {
  const performancePlants = readFragment(PerformanceChartFragment, data);

  const headers = [
    "Date",
    "Plant",
    "Yield",
    "Yield Target",
    "Revenue",
    "Revenue Target",
    "Self Consumption"
  ].join(",");

  const rows = performancePlants.flatMap((plantPerformance) =>
    plantPerformance.values.map((measurement) => ({
      date: measurement.date,
      plantName: plantPerformance.plant.name,
      pYield: measurement.pYield?.toString() ?? "",
      pYieldTarget: measurement.pYieldTarget?.toString() ?? "",
      revenue: measurement.revenue?.toString() ?? "",
      revenueTarget: measurement.revenueTarget?.toString() ?? "",
      pOwnConsumptionPercentage:
        measurement.pOwnConsumptionPercentage?.toString() ?? ""
    }))
  );

  const csvContent = rows
    .map((row) =>
      [
        row.date,
        row.plantName,
        row.pYield,
        row.pYieldTarget,
        row.revenue,
        row.revenueTarget,
        row.pOwnConsumptionPercentage
      ].join(",")
    )
    .join("\n");

  return `${headers}\n${csvContent}`;
};

export const downloadCsv = (
  data: FragmentOf<typeof PerformanceChartFragment>[]
) => {
  const csv = generateCsv(data);
  const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  downloadFile({ blob, fileName: "performance-data.csv" });
};
