import {
  DescriptionDetails,
  DescriptionList,
  DescriptionTerm
} from "@/ui/display/description-list";
import clsx from "clsx";
import React, { FC, ReactNode } from "react";
import type { TooltipProps as RechartsTooltipProps } from "recharts";

export type TooltipProps = Pick<
  RechartsTooltipProps<string, string>,
  | "active"
  | "payload"
  | "label"
  | "labelFormatter"
  | "isAnimationActive"
  | "cursor"
> & {
  /**
   * Formatter for the label of the serie.
   */
  nameFormatter?: (
    name?: string,
    payload?: RechartsTooltipProps<string, string>["payload"]
  ) => string;
  /**
   * Formatter for the value of the serie.
   */
  valueFormatter?: (
    value?: string,
    payload?: RechartsTooltipProps<string, string>["payload"]
  ) => string;
  className?: string;
};

/**
 * Custom tooltip component for Recharts
 */
export const Tooltip: React.FC<TooltipProps> = ({
  labelFormatter,
  nameFormatter,
  valueFormatter,
  className,
  ...props
}) => {
  // These props are provided by Recharts
  const { active, payload, label } = props;

  if (active && payload && payload.length) {
    return (
      <TooltipContent>
        <div
          className={clsx(
            className,
            "pt-3 py-3",
            "border-b border-border-primary text-content-primary font-semibold"
          )}
        >
          {labelFormatter ? labelFormatter(label, payload) : label}
        </div>
        <DescriptionList>
          {payload.map((item, i) => {
            return (
              <React.Fragment key={i}>
                <DescriptionTerm className="text-xs">
                  {nameFormatter
                    ? nameFormatter(item.name, payload)
                    : item.name}
                </DescriptionTerm>
                <DescriptionDetails>
                  {valueFormatter
                    ? valueFormatter(item.value, payload)
                    : item.value}
                  {item.unit && (item.unit === "%" ? "%" : ` ${item.unit}`)}
                </DescriptionDetails>
              </React.Fragment>
            );
          })}
        </DescriptionList>
      </TooltipContent>
    );
  }

  return null;
};

export const TooltipContent: FC<{
  children?: ReactNode;
  className?: string;
}> = ({ children, className }) => {
  return (
    <div
      className={clsx(
        "min-w-fit md:min-w-60 px-3",
        "bg-bg-primary border border-border-primary shadow-sm rounded-lg",
        className
      )}
    >
      {children}
    </div>
  );
};
