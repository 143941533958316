import { formatDateTime } from "@/lib/utils/date";
import { EnvelopeIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import { FC } from "react";

export type MessageLogAxisTickProps = {
  onClick?: (date: string) => void;
  className?: string;
} & { x?: number; y?: number; payload?: { value: string }; index?: number };

export const MessageLogAxisTick: FC<MessageLogAxisTickProps> = ({
  onClick,
  className,
  ...props
}) => {
  const { x, y, payload } = props;

  const handleOnClick = () => {
    if (onClick && payload?.value) {
      onClick(payload.value);
    }
  };

  return (
    <foreignObject
      x={x}
      y={y}
      width="0"
      height="0"
      className="overflow-visible z-50"
    >
      <div
        className={clsx(
          "w-fit",
          "-translate-x-1/2 translate-y-1x",
          "bg-bg-tertiary rounded-full",
          "[--offset:50px]",
          "-translate-y-[--offset]",
          className
        )}
      >
        <button
          title={formatDateTime(payload?.value ?? "")}
          onClick={handleOnClick}
          className="p-1 rounded-full bg-bg-tertiary cursor-pointer hover:bg-bg-hover ring-1 ring-border-primary transition-colors"
        >
          <EnvelopeIcon className="rounded-full size-4 stroke-content-primary" />
        </button>
      </div>
    </foreignObject>
  );
};
