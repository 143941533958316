import { FragmentOf, graphql, readFragment } from "@/lib/data/graphql";
import { useTranslation } from "@/lib/i18n";
import { Card, CardBody, CardHeader } from "@/ui/display/card";
import { Map, MapProps } from "@/ui/map/Map";
import { MapProvider } from "@/ui/map/MapProvider";
import { FC, useState } from "react";
import { PlantsMapDetail } from "./PlantsMapDetail";

export const PlantsMapCardFragment = graphql(`
  fragment PlantsMapCard on Query {
    plants {
      id
      location {
        lat
        lng
      }
    }
  }
`);

export const PlantsMapCard: FC<{
  data?: FragmentOf<typeof PlantsMapCardFragment>;
}> = ({ data }) => {
  const { t } = useTranslation();

  const { plants } = data
    ? readFragment(PlantsMapCardFragment, data)
    : { plants: [] };

  const [selectedPlantId, setSelectedPlantId] = useState<number>();

  const markers: MapProps["markers"] = plants.map(({ id, location }) => ({
    id: id.toString(),
    position: location
  }));

  const onMarkerClick: MapProps["onMarkerClick"] = (marker) => {
    const plant = plants.find((p) => p.id === parseInt(marker.id));
    if (plant) {
      setSelectedPlantId(plant.id);
    }
  };

  return (
    <>
      <Card className="bg-bg-white">
        <CardHeader
          title={t("dashboard.cards.plantsMap.title")}
          headingLevel={2}
          className="sm:pb-0"
        />
        <CardBody className="!p-0 rounded-b-2xl !overflow-hidden">
          <MapProvider>
            <Map
              id="plants-map"
              markers={markers}
              onMarkerClick={onMarkerClick}
            />
          </MapProvider>
        </CardBody>
      </Card>
      <PlantsMapDetail
        open={!!selectedPlantId}
        onClose={() => setSelectedPlantId(undefined)}
        plantId={selectedPlantId}
      />
    </>
  );
};
