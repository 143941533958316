import { useSession } from "@/lib/auth";
import { useTranslation } from "@/lib/i18n";
import { Button } from "@/ui/button/button";
import { NavbarSection } from "@/ui/navbar/navbar";
import { Cog6ToothIcon, PlusIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";

type PerformanceTemplateActionsProps = {
  loading?: boolean;
  onUpdate: () => void;
  onAdd: () => void;
};

export const PerformanceTemplateActions: React.FC<
  PerformanceTemplateActionsProps
> = ({ loading, onUpdate, onAdd }) => {
  const { t } = useTranslation();

  const { ability } = useSession();
  const ableTo = {
    create: ability?.can("create", "performance") ?? false,
    update: ability?.can("update", "performance") ?? false
  };

  return (
    <NavbarSection className="flex justify-between flex-1">
      <Button
        plain
        title={t("performanceTemplates.form.actions.add.label")}
        onClick={onAdd}
        disabled={!ableTo.create}
        className={clsx("max-md:ml-auto", {
          "cursor-not-allowed": !ableTo.create
        })}
      >
        <PlusIcon />
      </Button>
      <Button
        plain
        title={t("performanceTemplates.form.actions.update.label")}
        onClick={onUpdate}
        disabled={loading || !ableTo.update}
        className={clsx("max-md:hidden", {
          "cursor-not-allowed": !ableTo.create
        })}
      >
        <Cog6ToothIcon className="size-10" />
      </Button>
    </NavbarSection>
  );
};
