import { toaster } from "@/ui/feedback/toaster";
import { onError } from "@apollo/client/link/error";
import { GraphQLFormattedError } from "graphql";
import i18next from "i18next";
import { logger } from "../logger";

/**
 * These error codes should be mapped to `error.graphQLErrors[0].extensions.code`.
 * They are used to determine the type of error that occurred on the server.
 * Server must set them manually and send it over to the client.
 */
const SYNTAX_ERROR_CODES = ["SyntaxError"];
const RESOLVER_ERROR_CODES = ["ResolverError"];
export const SERVER_ERROR_CODES = [
  "InternalServerError",
  "UnknownException",
  "Forbidden"
];

// Log any GraphQL errors, protocol errors, or network error that occurred
export const graphqlErrorLink = onError((error) => {
  const { graphQLErrors, networkError, operation, forward } = error;

  if (!import.meta.env.PROD) {
    console.error("errorLink", { graphQLErrors, networkError });
  }

  /**
   * GraphQL errors
   *
   * These are errors related to the server-side execution of a GraphQL operation. They include:
   * - Syntax errors (e.g., a query was malformed)
   * - Validation errors (e.g., a query included a schema field that doesn't exist)
   * - Resolver errors (e.g., an error occurred while attempting to populate a query field)
   */
  if (graphQLErrors) {
    const parsedErrors: ParsedGraphqlError[] = [];

    for (const error of graphQLErrors) {
      parsedErrors.push(parseGraphQLError(error));
    }

    // Notify the user about the error
    // Beware: it will show multiple toasts if there are multiple errors
    for (const parsedError of parsedErrors) {
      if (parsedError.hasToast) {
        toaster.error({
          title: parsedError.message
        });
      }
    }

    // And retry the operation if any of errors are retryable
    if (parsedErrors.some((error) => error.retry)) {
      return forward(operation);
    }
  }

  /**
   * Network errors
   *
   * These are errors encountered while attempting to communicate with your GraphQL server,
   * usually resulting in a 4xx or 5xx response status code (and no data).
   *
   * Network errors are handled by RetryLink and after all retries are exhausted, they are handled here.
   */
  if (networkError) {
    logger.error(networkError);

    // Show a generic error toast to the user
    toaster.error(
      {
        title: i18next.t("error.graphql.network.title"),
        description: i18next.t("error.graphql.generic.description"),
        actionLabel: i18next.t("common.reload"),
        onAction: () => {
          window.location.reload();
        }
      },
      {
        autoClose: false,
        closeOnClick: true
      }
    );
  }
});

export type ParsedGraphqlError =
  | {
      code: string;
      message: string;
      hasToast: boolean;
      retry?: boolean;
    }
  | {
      code?: never;
      message?: never;
      hasToast: false;
      retry?: boolean;
    };

export function parseGraphQLError(
  error: GraphQLFormattedError
): ParsedGraphqlError {
  // We want to handle only syntax and resolver errors.
  // Validation errors should bubble down and be handled in the form ideally.
  const code = error?.extensions?.code as string | undefined;

  if (code) {
    // Server error codes should notify the user about the error
    if (SERVER_ERROR_CODES.includes(code)) {
      return {
        code,
        message:
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          getErrorMessage(error) ?? i18next.t(`error.graphql.${code}.title`),
        hasToast: true
      };
    }

    // Syntax errors should be silent
    if (SYNTAX_ERROR_CODES.includes(code)) {
      logger.error(error);
      return {
        code,
        message:
          getErrorMessage(error) ?? i18next.t(`error.graphql.generic.title`),
        hasToast: false
      };
    }

    // Resolver errors should be silent too
    if (RESOLVER_ERROR_CODES.includes(code)) {
      logger.error(error);
      return {
        code,
        message:
          getErrorMessage(error) ?? i18next.t(`error.graphql.generic.title`),
        hasToast: false
      };
    }

    // If we reach this point, it means that we are dealing with unsupported error.
    logger.error(error);
    return {
      code,
      message:
        getErrorMessage(error) ?? i18next.t(`error.graphql.generic.title`),
      hasToast: false
    };
  } else {
    // If `code` is undefined, that means we are dealing with unknown error or there is something
    // wrong with the code. In any case, we log the error.
    logger.error(error);
    return {
      code: "Unknown Error",
      message: error.message ?? "Unknown Error",
      hasToast: true
    };
  }
}

function getErrorMessage(error?: GraphQLFormattedError) {
  return (error?.extensions?.message as string | undefined) ?? error?.message;
}
