import { AuthError, useSignInWithGoogle } from "@/lib/auth";
import { useTranslation } from "@/lib/i18n";
import { Button } from "@/ui/button/button";
import { GoogleIcon } from "@/ui/icons/GoogleIcon";
import { UserCredential } from "firebase/auth";
import { useAtom } from "jotai";
import { disabledAtom } from "./atoms/loginAtoms";

export type GoogleLoginButtonProps = {
  disabled?: boolean;
  onError: (error: AuthError) => void;
  onSuccess: (result: UserCredential) => void;
};

export const GoogleLoginButton = (props: GoogleLoginButtonProps) => {
  const { onError, onSuccess, disabled } = props;
  const { t } = useTranslation();
  const [, setDisabled] = useAtom(disabledAtom);

  const [singIn, , loading] = useSignInWithGoogle();

  const handleClick = async () => {
    setDisabled(true);
    try {
      const result = await singIn();
      onSuccess(result);
    } catch (error) {
      onError(error as AuthError);
    } finally {
      setDisabled(false);
    }
  };

  return (
    <Button
      onClick={handleClick}
      disabled={loading || disabled}
      size="lg"
      className="w-full"
      outline
    >
      <GoogleIcon />
      <span className="text-base">{t("login.signInWithGoogle")}</span>
    </Button>
  );
};
