import daisyui from "daisyui";
import { Config } from "tailwindcss";
import defaultTheme from "tailwindcss/defaultTheme";
import { twSafeAreaPlugin } from "./src/ui/tw-safe-area-plugin";

const config: Config = {
  content: ["./index.html", "./src/**/*.{ts,tsx}"],
  theme: {
    extend: {
      colors: {
        yellow: {
          300: "rgb(from var(--color-yellow-300) r g b / <alpha-value>)"
        },
        "content-primary":
          "rgb(from var(--color-content-primary) r g b / <alpha-value>)",
        "content-secondary":
          "rgb(from var(--color-content-secondary) r g b / <alpha-value>)",
        "bg-primary": "rgb(from var(--color-bg-primary) r g b / <alpha-value>)",
        "bg-secondary":
          "rgb(from var(--color-bg-secondary) r g b / <alpha-value>)",
        "bg-tertiary":
          "rgb(from var(--color-bg-tertiary) r g b / <alpha-value>)",
        "bg-hover": "var(--color-bg-hover)",
        "bg-white": "var(--color-bg-white)",
        "bg-black": "var(--color-bg-black)",
        "content-info":
          "rgb(from var(--color-content-info) r g b / <alpha-value>)",
        "bg-info": "rgb(from var(--color-bg-info) r g b / <alpha-value>)",
        "border-info":
          "rgb(from var(--color-border-info) r g b / <alpha-value>)",
        "content-success":
          "rgb(from var(--color-content-success) r g b / <alpha-value>)",
        "bg-success": "rgb(from var(--color-bg-success) r g b / <alpha-value>)",
        "border-success":
          "rgb(from var(--color-border-success) r g b / <alpha-value>)",
        "content-warning":
          "rgb(from var(--color-content-warning) r g b / <alpha-value>)",
        "bg-warning": "rgb(from var(--color-bg-warning) r g b / <alpha-value>)",
        "border-warning":
          "rgb(from var(--color-border-warning) r g b / <alpha-value>)",
        "content-danger":
          "rgb(from var(--color-content-danger) r g b / <alpha-value>)",
        "bg-danger": "rgb(from var(--color-bg-danger) r g b / <alpha-value>)",
        "border-danger":
          "rgb(from var(--color-border-danger) r g b / <alpha-value>)",
        "border-primary":
          "rgb(from var(--color-border-primary) r g b / <alpha-value>)"
      },
      fontFamily: {
        sans: ["test", "serif", ...defaultTheme.fontFamily.sans]
      },
      fontSize: {
        "display-lg": [
          "3.5rem",
          { lineHeight: "normal", letterSpacing: "0.05em", fontWeight: "400" }
        ],
        "display-md": [
          "3rem",
          { lineHeight: "normal", letterSpacing: "0.05em", fontWeight: "400" }
        ],
        "display-sm": [
          "2.5rem",
          { lineHeight: "normal", letterSpacing: "0.05em", fontWeight: "400" }
        ],
        "heading-lg": ["1.5rem", { lineHeight: "normal", fontWeight: "400" }],
        "heading-md": ["1.25rem", { lineHeight: "normal", fontWeight: "400" }],
        "heading-sm": ["0.875rem", { lineHeight: "1.25rem", fontWeight: "400" }]
      }
    }
  },
  darkMode: "selector",
  plugins: [daisyui, twSafeAreaPlugin],
  daisyui: {
    prefix: "daisyui-",
    themes: false,
    base: false,
    styled: true,
    utils: true,
    logs: false
  }
};

export default config;
