import { useConfirmDialog } from "@/app/layouts/ConfirmDialogProvider";
import {
  secondFactorFlowAtom,
  secondFactorStepAtom
} from "@/app/users/atoms/mfaAtoms";
import { Auth, AuthError, revokeAccessToken } from "firebase/auth";
import { useAtom } from "jotai";
import { useCallback, useState } from "react";
import { useSession } from "./useSession";

export type SignOutHook = [
  () => Promise<boolean>,
  boolean,
  AuthError | Error | undefined
];

export function useSignOut(auth: Auth): SignOutHook {
  const [error, setError] = useState<AuthError>();
  const [loading, setLoading] = useState<boolean>(false);
  const { accessToken } = useSession();
  const globalConfirmDialog = useConfirmDialog();

  const [, setFlow] = useAtom(secondFactorFlowAtom);
  const [, setStep] = useAtom(secondFactorStepAtom);

  // TODO: reset apollo client cache
  const signOut = useCallback(async () => {
    setLoading(true);
    setError(undefined);

    try {
      await auth.signOut();
      if (accessToken) {
        await revokeAccessToken(auth, accessToken);
      }
      // Reset MFA
      setFlow(null);
      setStep(null);

      globalConfirmDialog.close();

      return true;
    } catch (err) {
      setError(err as AuthError);
      return false;
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  return [signOut, loading, error];
}
