import { getTheme } from "@/lib/utils/getTheme";
import React, { PropsWithChildren, useEffect, useState } from "react";

const breakpoints = getTheme().screens;

/**
 * A responsive container component that conditionally wraps children in a container element based on screen width.
 *
 * @component
 * @param {React.ReactElement} wrapper - The container element to wrap children in when viewport is below breakpoint
 * @param {string} breakpoint - The breakpoint width to determine mobile view. Defaults to theme's "sm" breakpoint
 * @param {React.ReactNode} children - The child elements to render
 *
 * @example
 * ```tsx
 * <ResponsiveWrapper wrapper={<div className="mobile-wrapper" />} breakpoint="768px">
 *   <Content />
 * </ResponsiveWrapper>
 * ```
 *
 * @returns On mobile viewports (below breakpoint), renders children wrapped in the container element.
 * On larger viewports, renders children directly without the container.
 */
export type ResponsiveWrapperProps = PropsWithChildren<{
  wrapper: React.ReactElement;
  breakpoint?: (typeof breakpoints)[keyof typeof breakpoints];
}>;

export const ResponsiveWrapper: React.FC<ResponsiveWrapperProps> = ({
  wrapper,
  breakpoint = breakpoints["sm"],
  children
}) => {
  const [isMobile, setIsMobile] = useState(
    window?.matchMedia(`(max-width: ${breakpoint})`).matches
  );

  useEffect(() => {
    let timeoutId: number | null = null;

    const handleResize = () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      timeoutId = setTimeout(() => {
        setIsMobile(window?.matchMedia(`(max-width: ${breakpoint})`).matches);
      }, 120) as unknown as number;
    };

    window.addEventListener("resize", handleResize);

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      window.removeEventListener("resize", handleResize);
    };
  }, [breakpoint]);

  return isMobile ? React.cloneElement(wrapper, {}, children) : children;
};
