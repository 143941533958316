import clsx from "clsx";
import React from "react";

export const DescriptionList = ({
  className,
  ...props
}: { className?: string } & React.ComponentPropsWithoutRef<"dl">) => {
  return (
    <dl
      {...props}
      className={clsx(
        className,
        "grid grid-cols-1 text-base/6 sm:grid-cols-[min(50%,theme(spacing.80))_auto] sm:text-sm/6"
      )}
    />
  );
};

export const DescriptionTerm = ({
  className,
  ...props
}: { className?: string } & React.ComponentPropsWithoutRef<"dt">) => {
  return (
    <dt
      {...props}
      className={clsx(
        className,
        "col-start-1 pt-3 sm:py-3",
        "border-t border-border-primary text-content-secondary first:border-none sm:border-t"
      )}
    />
  );
};

export const DescriptionDetails = ({
  className,
  ...props
}: { className?: string } & React.ComponentPropsWithoutRef<"dd">) => {
  return (
    <dd
      {...props}
      className={clsx(
        className,
        "pb-3 pt-1 sm:py-3",
        "text-content-primary sm:border-t sm:border-border-primary sm:[&:nth-child(2)]:border-none"
      )}
    />
  );
};
