const createMarkerSvg = (color: string) => {
  const svg = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="12" fill="${color}" fill-opacity="0.25"/>
    <circle cx="12" cy="12" r="6" fill="${color}"/>
  </svg>`;
  return `data:image/svg+xml;base64,${btoa(svg)}`;
};

export const getMapMarker = (theme: string): string | undefined => {
  return theme === "dark"
    ? createMarkerSvg("#ffffff")
    : createMarkerSvg("#000000");
};
