import { useTranslation } from "@/lib/i18n";
import { Select } from "@/ui/form/select";
import {
  Pagination,
  PaginationGap,
  PaginationList,
  PaginationNext,
  PaginationPage,
  PaginationPrevious
} from "@/ui/pagination";
import clsx from "clsx";
import { useCallback } from "react";
import { useSearchParams } from "react-router-dom";

export const ITEMS_PER_PAGE_OPTIONS = [10, 20, 50] as const;

export type TablePaginationProps = {
  currentPage: number;
  totalPages: number;
  /**
   * Number of items per page. If not provided, the items per page selector will not be displayed.
   */
  itemsPerPage?: number;
  loading: boolean;
};

export const TablePagination: React.FC<TablePaginationProps> = ({
  currentPage,
  totalPages,
  itemsPerPage,
  loading
}) => {
  const { t } = useTranslation("common");
  const [searchParams, setSearchParams] = useSearchParams();

  const getSearchParamsArgs = useCallback(
    (newPage: number) => {
      const updatedParams = new URLSearchParams(searchParams);
      updatedParams.set("page", newPage.toString());
      return `?${updatedParams.toString()}`;
    },
    [searchParams]
  );

  const handleItemsPerPageChange = useCallback(
    (newTake: string) => {
      setSearchParams((prevSearchParams) => {
        const updatedParams = new URLSearchParams(prevSearchParams);
        updatedParams.set("take", newTake);
        return updatedParams.toString();
      });
    },
    [setSearchParams]
  );

  const disabledClass = clsx({
    "pointer-events-none opacity-50": loading
  });

  return (
    <Pagination role="navigation" aria-label={t("pagination.navigationLabel")}>
      <PaginationPrevious
        href={getSearchParamsArgs(currentPage - 1)}
        className={clsx(
          { "pointer-events-none opacity-50": currentPage - 1 === 0 },
          disabledClass
        )}
        aria-label={t("pagination.previousPage")}
      />

      <div className="flex items-center justify-center">
        <PaginationList>
          {currentPage > 2 && (
            <PaginationPage
              href={getSearchParamsArgs(1)}
              aria-label={t("pagination.goToPage", { page: 1 })}
              className={disabledClass}
            >
              {1}
            </PaginationPage>
          )}

          {currentPage > 3 && <PaginationGap />}

          {currentPage > 1 && (
            <PaginationPage
              href={getSearchParamsArgs(currentPage - 1)}
              aria-label={t("pagination.goToPage", { page: currentPage - 1 })}
              className={disabledClass}
            >
              {currentPage - 1}
            </PaginationPage>
          )}

          <PaginationPage
            href={getSearchParamsArgs(currentPage)}
            aria-label={t("pagination.currentPage", { page: currentPage })}
            aria-current="true"
            current
            className={disabledClass}
          >
            {currentPage}
          </PaginationPage>

          {currentPage < totalPages && (
            <PaginationPage
              href={getSearchParamsArgs(currentPage + 1)}
              aria-label={t("pagination.goToPage", { page: currentPage + 1 })}
              className={disabledClass}
            >
              {currentPage + 1}
            </PaginationPage>
          )}

          {currentPage < totalPages - 2 && <PaginationGap />}

          {currentPage < totalPages - 1 && (
            <PaginationPage
              href={getSearchParamsArgs(totalPages)}
              aria-label={t("pagination.goToPage", { page: totalPages })}
              className={disabledClass}
            >
              {totalPages}
            </PaginationPage>
          )}
        </PaginationList>
        {itemsPerPage ? (
          <Select
            name="take"
            defaultValue={itemsPerPage}
            className={clsx("w-20 ml-4", disabledClass)}
            onChange={(e) => {
              const newValue = parseInt(e.target?.value, 10);
              if (newValue > 0) {
                handleItemsPerPageChange(e.target.value);
              }
            }}
            aria-label={t("pagination.itemsPerPage")}
            disabled={loading}
          >
            {ITEMS_PER_PAGE_OPTIONS.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </Select>
        ) : null}
      </div>

      <PaginationNext
        href={getSearchParamsArgs(currentPage + 1)}
        className={clsx(
          { "pointer-events-none opacity-50": currentPage + 1 > totalPages },
          disabledClass
        )}
        aria-label={t("pagination.nextPage")}
      />
    </Pagination>
  );
};
