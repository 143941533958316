import { graphql } from "@/lib/data/graphql";
import {
  differenceInDays,
  differenceInHours,
  differenceInMonths,
  differenceInYears,
  format
} from "date-fns";

export type AggregationType = ReturnType<
  typeof graphql.scalar<"AggregationType">
>;

export const TIME_SCALE = {
  HOURS: "HOURS",
  DAYS: "DAYS",
  MONTHS: "MONTHS",
  YEARS: "YEARS"
} as const;
export type TimeScaleType = (typeof TIME_SCALE)[keyof typeof TIME_SCALE];

type TimeRange = {
  aggregation: AggregationType;
  timeScale: TimeScaleType;
  formatXAxis: string | ((monthsDiff: number) => string);
};

export function getFormatters(
  startDate: Date,
  endDate: Date
): {
  aggregation: AggregationType;
  formatXAxis: (date: Date) => string;
  daysDiff: number;
  monthsDiff: number;
  timeScale: TimeScaleType;
} {
  const hoursDiff = differenceInHours(endDate, startDate);
  const daysDiff = differenceInDays(endDate, startDate);
  const monthsDiff = differenceInMonths(endDate, startDate);
  const showYear = differenceInYears(endDate, startDate) > 0;

  let range: TimeRange;

  switch (true) {
    case hoursDiff <= 6:
      range = {
        aggregation: "FIVE_MINUTES",
        timeScale: TIME_SCALE.HOURS,
        formatXAxis: "HH:00"
      };
      break;

    case daysDiff < 1:
      range = {
        aggregation: "FIFTEEN_MINUTES",
        timeScale: TIME_SCALE.HOURS,
        formatXAxis: "HH:00"
      };
      break;

    case daysDiff <= 14:
      range = {
        aggregation: "HOUR",
        timeScale: TIME_SCALE.DAYS,
        formatXAxis: showYear ? "dd.MM.yyyy" : "dd.MM"
      };
      break;

    case monthsDiff <= 3:
      range = {
        aggregation: "DAY",
        timeScale: TIME_SCALE.DAYS,
        formatXAxis: showYear ? "dd.MM.yyyy" : "dd.MM"
      };
      break;

    default:
      range = {
        aggregation: "MONTH",
        timeScale: TIME_SCALE.MONTHS,
        formatXAxis: showYear ? "MMMM yyyy" : "MMMM"
      };
      break;
  }

  return {
    aggregation: range.aggregation,
    timeScale: range.timeScale,
    daysDiff: daysDiff,
    monthsDiff: monthsDiff,
    formatXAxis: (date: Date) =>
      typeof range.formatXAxis === "function"
        ? range.formatXAxis(monthsDiff)
        : format(date, range.formatXAxis)
  };
}

export function formatTooltipLabel(
  label: string | number,
  aggregation: AggregationType
) {
  if (!label) return "";

  return label
    ? format(
        new Date(label),
        aggregation === "FIFTEEN_MINUTES" ||
          aggregation === "FIVE_MINUTES" ||
          aggregation === "HOUR"
          ? "dd.M.yyyy HH:mm"
          : aggregation === "MONTH"
            ? "MMMM yyyy"
            : "dd.MM.yyyy"
      )
    : "";
}

export function formatTooltipValue(value: string | undefined) {
  return value ? `${parseFloat(value).toFixed(2)}` : "";
}
