import { graphql } from "@/lib/data/graphql";
import { useMutation } from "@apollo/client";

export const AccountFormFragment = graphql(`
  fragment AccountForm on User {
    id
    email
    uid
    firstName
    lastName
    additionalName
  }
`);

const UpdateAccountMutation = graphql(
  `
    mutation UpdateAccount($userId: String!, $input: UpdateUserInput!) {
      updateUser(userId: $userId, updateUserInput: $input) {
        ...AccountForm
      }
    }
  `,
  [AccountFormFragment]
);

export const useUpdateAccount = () => {
  const [updateAccount, { loading }] = useMutation(UpdateAccountMutation);

  return {
    updateAccount,
    loading
  };
};
