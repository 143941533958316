import React, { ReactElement } from "react";
import {
  LineChart as RechartsLineChart,
  Tooltip as RechartsTooltip,
  ResponsiveContainer
} from "recharts";
import { BaseChartProps } from "./chart";
import { defaultProps, Line, type LineProps } from "./line";
import { Tick } from "./tick";
import { Tooltip, TooltipProps } from "./tooltip";
import { XAxis, type XAxisProps } from "./x-axis";
import { YAxis, type YAxisProps } from "./y-axis";

// TODO replace with composed-chart.tsx
export type LineChartSeries<TData extends Record<string, unknown>> = {
  /**
   * The key of the data object that contains the data to be displayed.
   */
  dataKey: Extract<keyof TData, string>;
  name: string;
  /**
   * Render props allows to change the appearance of the chart series.
   */
  renderProps?: LineProps;
  /**
   * Render can be used to render a custom chart series. That can be Line, Bar, Area, etc.
   */
  render?: (props: LineProps) => ReactElement<LineProps>;
};

export type LineChartProps<TData extends Record<string, unknown>> = {
  /**
   * Data to be plotted in the chart
   */
  data: TData[];
  /**
   * Data key for X axis
   */
  xAxisKey: keyof TData;
  xAxisProps?: Omit<XAxisProps, "dataKey" | "tick">;
  yAxisProps?: Omit<YAxisProps, "dataKey" | "tick">;
  /**
   * Visual representation of the data. It only uses line charts.
   */
  series: LineChartSeries<TData>[];
  /**
   * Tooltip
   *
   * - if false or undefined, no tooltip will be rendered
   * - if true, tooltip will be rendered with tooltip props provided
   * - if React element, custom tooltip will be rendered
   *
   * @default true
   */
  tooltip?: boolean | React.ReactElement;
  tooltipProps?: TooltipProps;
  /**
   * Height of the chart in pixels or percentage.
   */
  height?: number | string;
  margin?: {
    top?: number;
    right?: number;
    bottom?: number;
    left?: number;
  };
} & BaseChartProps & { children?: React.ReactNode };

export const LineChart = <TData extends Record<string, unknown>>({
  data,
  height = "100%",
  xAxisKey,
  series,
  className,
  tooltip = true,
  yAxisProps,
  xAxisProps,
  ...props
}: LineChartProps<TData>) => {
  return (
    <ResponsiveContainer className={className} height={height}>
      <RechartsLineChart
        data={data}
        margin={{ top: 0, right: 0, left: 0, bottom: 20, ...props.margin }}
      >
        {series.map(({ render, renderProps, ...serie }, i) => {
          if (render) {
            return render({
              ...defaultProps,
              ...renderProps,
              // TODO id will be probably way better
              key: i,
              dataKey: serie.dataKey,
              name: serie.name
            });
          } else {
            return (
              <Line
                {...defaultProps}
                {...renderProps}
                key={i}
                dataKey={serie.dataKey}
                name={serie.name}
              />
            );
          }
        })}

        <YAxis
          stroke="var(--color-border-primary)"
          {...yAxisProps}
          tick={<Tick axis="y" formatter={yAxisProps?.tickFormatter} />}
        />

        <XAxis
          stroke="var(--color-border-primary)"
          {...xAxisProps}
          tick={<Tick axis="x" formatter={xAxisProps?.tickFormatter} />}
          dataKey={xAxisKey as string}
        />

        {tooltip && (
          <RechartsTooltip
            isAnimationActive={false}
            {...props.tooltipProps}
            content={
              tooltip === true ? <Tooltip {...props.tooltipProps} /> : tooltip
            }
          />
        )}

        {props.children}
      </RechartsLineChart>
    </ResponsiveContainer>
  );
};
