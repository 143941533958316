import { ActionDialog } from "@/ui/action-dialog/ActionDialog";
import { ActionDialogBody } from "@/ui/action-dialog/ActionDialogBody";
import { ActionDialogFooter } from "@/ui/action-dialog/ActionDialogFooter";
import { ActionDialogHeader } from "@/ui/action-dialog/ActionDialogHeader";
import React, { PropsWithChildren, useState } from "react";

export type ActionDialogToggleProps = {
  title: string;
  renderActions?: (closeModal: VoidFunction) => React.ReactNode;
  renderTriggerButton: (openModal: VoidFunction) => React.ReactNode;
  bodyClassName?: string;
};

export const ActionDialogToggle: React.FC<
  PropsWithChildren<ActionDialogToggleProps>
> = ({
  children,
  title,
  renderActions,
  renderTriggerButton,
  bodyClassName
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  return (
    <>
      <div className="flex flex-col items-end">
        {renderTriggerButton(openModal)}
      </div>
      <ActionDialog open={isOpen} onClose={closeModal}>
        <ActionDialogHeader title={title} onClose={closeModal} />
        <ActionDialogBody className={bodyClassName}>
          {children}
        </ActionDialogBody>
        {renderActions ? (
          <ActionDialogFooter>{renderActions(closeModal)}</ActionDialogFooter>
        ) : null}
      </ActionDialog>
    </>
  );
};
