import { useTranslation } from "@/lib/i18n";
import { formatDate } from "@/lib/utils/date";
import { Button } from "@/ui/button/button";
import { Progress } from "@/ui/feedback/progress";
import { Text } from "@/ui/typography/text";
import {
  ExclamationCircleIcon,
  InformationCircleIcon
} from "@heroicons/react/24/outline";
import clsx from "clsx";

type Props = {
  approvedPercentage: number;
  provisionallyApprovedPercentage: number;
  provisionallyApprovedLimit: number;
  conditionsAreMet: boolean;
  onOpenPaymentDateDialog: VoidFunction;
  paymentDate?: string | null;
};

export const ProjectOverviewProgress: React.FC<Props> = ({
  approvedPercentage,
  provisionallyApprovedPercentage,
  provisionallyApprovedLimit,
  conditionsAreMet,
  onOpenPaymentDateDialog,
  paymentDate
}) => {
  const { t } = useTranslation();

  return (
    <div className="bg-bg-primary p-4 rounded-lg border border-border-primary flex flex-col items-center gap-6 sticky top-4">
      <div className="w-full">
        <Text className="font-medium flex justify-between gap-2">
          <span>{t("projectOverview.progress.approvedPercentage")}</span>
          <span className="font-bold">{approvedPercentage.toFixed(2)}%</span>
        </Text>
        <Progress
          color={
            approvedPercentage === 100
              ? "success"
              : approvedPercentage >= 65
                ? "warning"
                : "error"
          }
          value={approvedPercentage}
        />
      </div>
      <div className="w-full">
        <Text className="font-medium flex justify-between gap-2">
          <span>
            {t("projectOverview.progress.provisionallyApprovedPercentage")}
          </span>
          <span className="font-bold">
            {provisionallyApprovedPercentage.toFixed(2)}%
          </span>
        </Text>
        <Progress
          color={
            provisionallyApprovedPercentage >= provisionallyApprovedLimit
              ? "error"
              : "success"
          }
          value={provisionallyApprovedPercentage}
        />
      </div>
      <Button
        className={clsx("self-center", {
          "!cursor-not-allowed": !conditionsAreMet || Boolean(paymentDate)
        })}
        disabled={!conditionsAreMet || Boolean(paymentDate)}
        onClick={onOpenPaymentDateDialog}
      >
        {t("projectOverview.actions.sendPayment.label")}
      </Button>

      {!conditionsAreMet && !paymentDate ? (
        <div className="flex gap-2">
          <div>
            <ExclamationCircleIcon className="size-7 !text-content-danger" />
          </div>
          <Text className="inline-flex text-xs !text-content-danger">
            {t("projectOverview.progress.conditionsNotMet", {
              provisionallyApprovedLimit: provisionallyApprovedLimit.toFixed()
            })}
          </Text>
        </div>
      ) : paymentDate ? (
        <div className="flex gap-2">
          <div>
            <InformationCircleIcon className="size-7" />
          </div>
          <Text className="inline-flex text-xs">
            {t("projectOverview.progress.paymentSent", {
              paymentDate: formatDate(paymentDate)
            })}
          </Text>
        </div>
      ) : null}
    </div>
  );
};
