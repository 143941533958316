import {
  ProjectData,
  ProjectStatusType
} from "@/app/project-overview/hooks/useProjectData";
import { ProjectItem } from "@/app/project-overview/ProjectItem";
import { useTranslation } from "@/lib/i18n";
import {
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow
} from "@/ui/table/table";

type Props = {
  data?: ProjectData[];
  onStatusChange: (projectId: string, status: ProjectStatusType) => void;
  disabled?: boolean;
  year: number;
  quarter: number;
};

export const ProjectsTable: React.FC<Props> = ({
  data,
  disabled = false,
  onStatusChange,
  year,
  quarter
}) => {
  const { t } = useTranslation();

  return (
    <Table dense>
      <TableHead>
        <TableRow>
          <TableHeader className="w-full">
            {t("projectOverview.fields.name")}
          </TableHeader>
          <TableHeader>{t("projectOverview.fields.status")}</TableHeader>
          <TableHeader>
            <span className="sr-only">
              {t("projectOverview.fields.actions")}
            </span>
          </TableHeader>
        </TableRow>
      </TableHead>
      <TableBody className="divide-y divide-gray-200">
        {data &&
          data.map((projectData) => (
            <ProjectItem
              key={projectData.id}
              data={projectData}
              onStatusChange={onStatusChange}
              disabled={disabled}
              year={year}
              quarter={quarter}
            />
          ))}
      </TableBody>
    </Table>
  );
};
