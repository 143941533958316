import {
  CloseButton,
  Dialog,
  DialogBackdrop,
  DialogPanel
} from "@headlessui/react";
import { Bars3CenterLeftIcon, XMarkIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import React, { FC, PropsWithChildren, useState } from "react";
import { NavbarItem } from "../navbar/navbar";

export const SidebarLayout = ({
  navbar,
  sidebar,
  footer,
  children
}: React.PropsWithChildren<{
  navbar: React.ReactNode;
  sidebar: React.ReactNode;
  footer?: React.ReactNode;
}>) => {
  const [showSidebar, setShowSidebar] = useState(false);

  return (
    <div
      className={clsx(
        "[--sidebar-width:_theme(spacing.64)] [--header-height:_theme(spacing.16)]",
        "relative isolate flex min-h-svh max-h-svh w-full",
        "bg-bg-secondary"
      )}
    >
      {/* Sidebar on desktop */}
      <div
        className={clsx(
          "fixed inset-y-0 left-0 w-[--sidebar-width] max-lg:hidden",
          "pl-8 pr-3 py-6",
          "[&_[data-slot=sidebar-header]]:h-[--header-height]"
        )}
      >
        {sidebar}
      </div>

      {/* Sidebar on mobile */}
      <MobileSidebar open={showSidebar} onClose={() => setShowSidebar(false)}>
        {sidebar}
      </MobileSidebar>

      {/* Content */}
      <div
        className={clsx(
          "flex flex-col grow w-full gap-y-2 lg:gap-y-3",
          "lg:pt-0 lg:pl-[--sidebar-width] lg:pr-8 lg:pb-6",
          "pt-[calc(theme(spacing.2)_+_env(safe-area-inset-top))]",
          "pb-[calc(theme(spacing.0)_+_env(safe-area-inset-bottom))]",
          "max-sm:rounded-2xl"
        )}
      >
        {/* Navbar on desktop */}
        <div className="max-lg:hidden sticky top-0 z-40 h-[--header-height] mt-6">
          {navbar}
        </div>

        {/* Navbar on mobile */}
        <MobileHeader onClick={() => setShowSidebar(true)}>
          {navbar}
        </MobileHeader>

        <main
          className={clsx(
            "flex flex-col flex-1 overflow-y-auto lg:min-w-0",
            "max-lg:mx-2 max-lg:mb-2",
            "px-safe",
            "rounded-2xl",
            "bg-[linear-gradient(135deg,_var(--color-bg-primary)_65%,_#EDEBB9_100%)]",
            "dark:bg-[linear-gradient(135deg,_var(--color-bg-primary)_65%,_#413617_100%)]"
          )}
        >
          <div className="grow">
            <div className="mx-auto max-w-screen-2xl h-full">{children}</div>
          </div>
        </main>
      </div>

      {/* Footer on mobile */}
      <MobileFooter>{footer}</MobileFooter>
    </div>
  );
};

const MobileSidebar = ({
  open,
  onClose,
  children
}: React.PropsWithChildren<{
  open: boolean;
  onClose: () => void;
}>) => {
  return (
    <Dialog open={open} onClose={onClose} className="lg:hidden">
      <DialogBackdrop
        transition
        className={clsx(
          "max-sm:hidden fixed inset-0 sfy-bg-backdrop",
          "transition data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
        )}
      />
      <DialogPanel
        transition
        className={clsx(
          "fixed inset-0 w-full sm:max-w-80",
          "py-safe",
          "bg-[linear-gradient(180deg,_#FFFFFF_-20.59%,_#F6F4CA_100%)]",
          "dark:bg-[linear-gradient(180deg,_var(--color-bg-primary)_0%,_var(--color-gradient-primary)_100%)]",
          "transition duration-300 ease-in-out data-[closed]:-translate-x-full"
        )}
      >
        <div className="flex flex-col h-full gap-y-4 p-5 pb-3">
          <div className="ml-auto">
            <CloseButton as={NavbarItem} aria-label="Close navigation">
              <XMarkIcon className="!fill-content-primary !size-8" />
            </CloseButton>
          </div>
          <div
            className={clsx(
              "flex flex-col grow min-h-0 px-safe",
              "[&_[data-slot=sidebar-header]]:hidden"
            )}
          >
            {children}
          </div>
        </div>
      </DialogPanel>
    </Dialog>
  );
};

const MobileHeader: FC<PropsWithChildren<{ onClick: () => void }>> = ({
  onClick,
  children
}) => {
  return (
    <header
      className={clsx(
        "sticky top-0 w-full lg:hidden flex items-center z-[101] h-[--header-height]",
        "px-safe"
      )}
    >
      <div className="flex flex-1 px-2 mx-2 bg-bg-primary rounded-2xl">
        <div className="py-2.5">
          <NavbarItem onClick={onClick} aria-label="Open navigation">
            <Bars3CenterLeftIcon />
          </NavbarItem>
        </div>
        <div className="min-w-0 flex-1">{children}</div>
      </div>
    </header>
  );
};

const MobileFooter: FC<PropsWithChildren> = ({ children }) => {
  return (
    <footer
      className={clsx(
        "lg:hidden fixed bottom-0 flex-shrink-0 w-full",
        "pb-safe px-safe",
        "bg-bg-secondary"
      )}
    >
      {children ? (
        <div className="max-w-6xl mx-auto p-4 lg:p-6">{children}</div>
      ) : null}
    </footer>
  );
};
