import { FIFTEEN_MINUTES_IN_MS } from "@/constants";
import { graphql } from "@/lib/data/graphql";
import { Card, CardBody, CardHeader } from "@/ui/display/card";
import { LoadingSpinner } from "@/ui/loading-spinner";
import { useQuery } from "@apollo/client";
import { Lightning, Time } from "@carbon/icons-react";
import { format, formatISO, startOfDay } from "date-fns";
import { t } from "i18next";
import { FC, useMemo } from "react";
import {
  ElectricityProductionChart,
  ElectricityProductionChartFragment
} from "./ElectricityProductionChart";

export const ElectricityProductionCardQuery = graphql(
  `
    query ElectricityProductionCard($where: PortfolioPerformanceWhereInput!) {
      portfolioPerformance(where: $where) {
        ...ElectricityProductionChart
      }
    }
  `,
  [ElectricityProductionChartFragment]
);

export const ElectricityProductionCard: FC = () => {
  const { data, loading, previousData } = useQuery(
    ElectricityProductionCardQuery,
    {
      variables: {
        where: {
          aggregation: "FIFTEEN_MINUTES",
          startDate: formatISO(startOfDay(new Date())),
          endDate: null
        }
      },
      pollInterval: FIFTEEN_MINUTES_IN_MS
    }
  );

  const chartData = useMemo(() => {
    return (
      data?.portfolioPerformance ?? previousData?.portfolioPerformance ?? []
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Card className="flex flex-col h-full w-full bg-bg-white" fancyBackground>
      <CardHeader
        title={t("dashboard.cards.electricityProduction.title")}
        end={loading ? <LoadingSpinner size="sm" /> : null}
      />
      <CardBody className="flex flex-col gap-6 grow">
        <div className="flex items-center text-xs sm:text-sm mb-2 gap-4">
          <div className="flex items-center gap-2">
            <Time />
            <span className="font-bold">
              <span>{t("common.today")}</span>
              <span className="max-xl:hidden">
                , {format(new Date(), "dd.MM.yyyy")}
              </span>
            </span>
          </div>
          <div className="flex items-center gap-2">
            <Lightning />
            <span className="font-bold max-xl:hidden">
              {t("common.units.kiloWattHours")} ({t("common.units.kWh")})
            </span>
            <span className="font-bold xl:hidden">{t("common.units.kWh")}</span>
          </div>
        </div>
        <div className="h-[300px] lg:h-[400px] w-full lg:mt-auto">
          <ElectricityProductionChart data={chartData} />
        </div>
      </CardBody>
    </Card>
  );
};
