export const DEMO_ACCOUNT = {
  email: import.meta.env.VITE_DEMO_EMAIL,
  password: import.meta.env.VITE_DEMO_PASSWORD
};

export const DEFAULT_HOUSEHOLD_CONSUMPTION = 2500;

export const FIVE_MINUTES_IN_MS = 5 * 60 * 1000;
export const FIFTEEN_MINUTES_IN_MS = 15 * 60 * 1000;
export const DAY_IN_MS = 24 * 60 * 60 * 1000;
export const MONTH_IN_MS = 30 * 24 * 60 * 60 * 1000;
