import { FragmentOf, graphql, readFragment } from "@/lib/data/graphql";
import { useTranslation } from "@/lib/i18n";
import { Card, CardBody, CardHeader } from "@/ui/display/card";
import { Count } from "@/ui/display/count";
import { Progress } from "@/ui/feedback/progress";
import { LoadingSpinner } from "@/ui/loading-spinner";
import { FC } from "react";

export const Co2EquivalentsCardFragment = graphql(`
  fragment Co2EquivalentsCard on MyImpactCalculation {
    equivalents {
      key
      value
      category
    }
    yieldPerYear
  }
`);

export const Co2EquivalentsCard: FC<{
  data?: FragmentOf<typeof Co2EquivalentsCardFragment>;
  loading?: boolean;
}> = ({ data, loading }) => {
  const { t } = useTranslation();

  const values = readFragment(Co2EquivalentsCardFragment, data);

  return (
    <Card className="bg-bg-white">
      <CardHeader
        title={t("myImpact.cards.myImpactCalculator.cards.co2Equivalent.title")}
        headingLevel={2}
        end={loading ? <LoadingSpinner size="sm" /> : null}
      />
      <CardBody>
        <div className="grid 2xl:grid-cols-2 gap-8 min-h-[250px]">
          <div className="flex flex-col my-auto text-sm">
            <p
              dangerouslySetInnerHTML={{
                __html: t(
                  "myImpact.cards.myImpactCalculator.cards.co2Equivalent.text",
                  {
                    yieldPerYear: values?.yieldPerYear
                  }
                )
              }}
            ></p>
          </div>
          <div className="flex flex-col gap-y-2 my-auto">
            {values?.equivalents
              .filter((e) => e.category === "FOSSILE_FUELS")
              .map((item) => {
                return (
                  <div key={item.key} className="flex flex-col gap-2.5">
                    <label
                      className="text-xs font-semibold"
                      aria-label={item.key as string}
                    >
                      {/* @ts-expect-error dynamic translation keys are pain with TS */}
                      {t(`enums.co2Equivalents.${item.key}`)}
                    </label>
                    <Progress color="primary" value={item.value as number} />
                    <Count
                      value={item.value as number}
                      className="ml-auto text-xs font-semibold"
                    />
                  </div>
                );
              })}
          </div>
        </div>
      </CardBody>
    </Card>
  );
};
