import { animate, motion, useMotionValue, useTransform } from "motion/react";
import { FC, useEffect } from "react";

export type CountProps = {
  value: number | string;
  start?: number | string;
  duration?: number;
  className?: string;
};

export const Count: FC<CountProps> = ({
  start = 0,
  value,
  duration = 2,
  className
}) => {
  const count = useMotionValue(Number(start));
  const rounded = useTransform(() => Math.round(count.get()));

  useEffect(() => {
    const controls = animate(count, Number(value), { duration });
    return () => controls.stop();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return <motion.span className={className}>{rounded}</motion.span>;
};
