import { sendPasswordResetEmail, useSession } from "@/lib/auth";
import { useTranslation } from "@/lib/i18n";
import { Logo } from "@/ui/Logo";
import { Button } from "@/ui/button/button";
import { Input, InputGroup } from "@/ui/form/input";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export const ForgotPasswordPage = () => {
  const session = useSession();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<boolean>(false);
  const [disabled, setDisabled] = useState(false);
  const { t } = useTranslation();

  // The base URL to which the user will be redirected after resetting the password
  const continueUrl = import.meta.env.VITE_APP_URL;

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setLoading(true);
    setError(null);
    setSuccess(false);
    setDisabled(true);

    try {
      await sendPasswordResetEmail(session.auth, email, {
        url: continueUrl
      });
      setSuccess(true);
      setTimeout(() => {
        navigate("/");
      }, 3000);
    } catch (err) {
      setError(t("forgotPassword.error"));
      setLoading(false);
      setDisabled(false);
    }
  };

  const buttonStatus = success ? "success" : loading ? "loading" : "ready";

  return (
    <div className="flex min-h-screen items-center justify-center bg-bg-secondary p-4">
      <div className="w-full max-w-md bg-bg-primary rounded-lg p-8 border border-border-primary">
        <div className="flex justify-center mb-6">
          <Logo className="px-2" />
        </div>
        <h1 className="text-xl font-bold text-content-primary mb-4 text-center">
          {t("forgotPassword.title")}
        </h1>
        <p className="text-base text-content-secondary mb-8 text-center leading-relaxed">
          {t("forgotPassword.description")}
        </p>
        <form onSubmit={handleSubmit} className="space-y-6">
          <InputGroup>
            <Input
              type="email"
              placeholder={t("forgotPassword.placeholder")}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              disabled={disabled}
              required
            />
            {success ? <CheckCircleIcon className="text-green-500" /> : null}
          </InputGroup>
          {error && <p className="text-red-500 text-center">{error}</p>}
          <div className="flex justify-center mt-6">
            <Button
              type="submit"
              loading={loading}
              size="lg"
              className="w-full"
              outline
            >
              {t(`forgotPassword.button.${buttonStatus}`)}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};
