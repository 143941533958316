import clsx from "clsx";
import { PropsWithChildren } from "react";

export const FormFieldCard: React.FC<
  PropsWithChildren<{ className?: string }>
> = ({ children, className }) => {
  return (
    <div
      className={clsx(
        className,
        "p-2.5 rounded-lg shadow-sm",
        "bg-bg-primary",
        "border border-border-primary"
      )}
    >
      {children}
    </div>
  );
};
