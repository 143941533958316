import { FragmentOf, graphql, readFragment } from "@/lib/data/graphql";
import { Card, CardBody, CardHeader } from "@/ui/display/card";
import { GaugeChart } from "@/ui/display/charts/gauge-chart";
import { Count } from "@/ui/display/count";
import { LoadingSpinner } from "@/ui/loading-spinner";
import { Flash } from "@carbon/icons-react";
import { t } from "i18next";
import { FC } from "react";

export const FootprintCardFragment = graphql(`
  fragment FootprintCard on MyImpactCalculation {
    tier {
      level
      value
      maxValue
    }
    panelsAmount
  }
`);

export const FootprintCard: FC<{
  data?: FragmentOf<typeof FootprintCardFragment>;
  loading?: boolean;
}> = ({ data, loading }) => {
  const values = readFragment(FootprintCardFragment, data);

  return (
    <Card className="bg-bg-white">
      <CardHeader
        title={t("myImpact.cards.myImpactCalculator.cards.footprint.title")}
        headingLevel={2}
        end={loading ? <LoadingSpinner size="sm" /> : null}
      />
      <CardBody>
        <div className="flex max-2xl:flex-col gap-4 2xl:gap-8">
          <div className="text-sm my-auto">
            <p
              dangerouslySetInnerHTML={{
                __html: t(
                  "myImpact.cards.myImpactCalculator.cards.footprint.text",
                  {
                    amount: values?.panelsAmount,
                    value: values?.tier.value,
                    level: values?.tier.level
                  }
                )
              }}
            ></p>
          </div>
          <div className="min-h-[250px] h-[250px] w-full">
            <GaugeChart
              value={values?.tier.value ?? 0}
              maxValue={values?.tier.maxValue}
              shape="crescent"
              centerSlot={
                <div className="flex flex-col items-center gap-1 text-content-primary ">
                  <div className="rounded-full bg-bg-secondary p-1">
                    <Flash size={24} />
                  </div>
                  <Count
                    className="text-3xl leading-none"
                    value={values?.tier.value ?? 0}
                  />
                  <span>%</span>
                </div>
              }
            />
          </div>
        </div>
      </CardBody>
    </Card>
  );
};
