import { Button } from "@/ui/button/button";
import { DATE_QUICK_SELECTORS, TimeScaleKeyType } from "@/ui/date/date-utils";
import clsx from "clsx";
import React from "react";

export type DateQuickPresetsProps = {
  activeSelector: TimeScaleKeyType;
  onChange: (value: TimeScaleKeyType) => void;
};

export const DateQuickPresets: React.FC<DateQuickPresetsProps> = ({
  activeSelector,
  onChange
}) => {
  return (
    <div className="flex gap-x-2">
      {DATE_QUICK_SELECTORS.map(({ label, key }) => (
        <Button
          key={key}
          outline
          className={clsx(
            "!font-semibold",
            activeSelector === key
              ? "!text-content-primary"
              : "!text-content-secondary"
          )}
          onClick={() => onChange(key)}
          size="base"
        >
          {label}
        </Button>
      ))}
    </div>
  );
};
