import { DocumentItemFragment } from "@/app/documents/DocumentItem";
import { DocumentsFilters } from "@/app/documents/DocumentsFilters";
import { DocumentsTable } from "@/app/documents/DocumentsTable";
import { graphql } from "@/lib/data/graphql";
import { usePaginatedQuery } from "@/lib/data/hooks/usePaginatedQuery";
import { useTranslation } from "@/lib/i18n";
import { Divider } from "@/ui/display/divider";
import { PageWrapper } from "@/ui/layouts/page-wrapper";
import { TablePagination } from "@/ui/table/TablePagination";
import { Heading } from "@/ui/typography/heading";

export const DocumentsPageQuery = graphql(
  `
    query DocumentsPage(
      $where: DocumentsWhereInput
      $skip: Int
      $take: Int
      $orderBy: DocumentsOrderByInput
    ) {
      documents(where: $where, skip: $skip, take: $take, orderBy: $orderBy) {
        items {
          ...DocumentItem
        }
        totalCount
      }
    }
  `,
  [DocumentItemFragment]
);

export const DocumentsPage = () => {
  const { t } = useTranslation();

  const {
    queryResult: { data, loading },
    currentPage,
    totalPages,
    itemsPerPage,
    setWhere,
    orderBy,
    setOrderBy
  } = usePaginatedQuery({
    query: DocumentsPageQuery,
    extractCountFromQuery: (data) => data?.documents.totalCount
  });

  return (
    <PageWrapper>
      <Heading>{t("documents.listing.title")}</Heading>
      <Divider />

      <DocumentsFilters setWhere={setWhere} />

      <DocumentsTable
        documents={data?.documents.items}
        loading={loading}
        setOrderBy={setOrderBy}
        orderBy={orderBy}
      />

      <TablePagination
        currentPage={currentPage}
        totalPages={totalPages}
        itemsPerPage={itemsPerPage}
        loading={loading}
      />
    </PageWrapper>
  );
};
