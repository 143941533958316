import { FragmentOf, graphql, readFragment } from "@/lib/data/graphql";
import { useTranslation } from "@/lib/i18n";
import { Card, CardBody, CardHeader } from "@/ui/display/card";
import { Progress } from "@/ui/feedback/progress";
import { Text } from "@/ui/typography/text";
import { FC } from "react";

export const SwissSolarExpansionCardFragment = graphql(`
  fragment SwissSolarExpansionCard on Query {
    communitySwissSolarExpansion {
      currentValue
      goalValue
    }
  }
`);

export const SwissSolarExpansionCard: FC<{
  data?: FragmentOf<typeof SwissSolarExpansionCardFragment>;
}> = ({ data }) => {
  const { t } = useTranslation();
  const values = readFragment(SwissSolarExpansionCardFragment, data);

  if (!values?.communitySwissSolarExpansion) return null;

  const { currentValue, goalValue } = values.communitySwissSolarExpansion;
  const progress = (currentValue / (currentValue + goalValue)) * 100;

  return (
    <Card className="bg-bg-white" fancyBackground>
      <CardHeader
        title={t("communityImpact.cards.swissSolarExpansion.title")}
        headingLevel={2}
      />
      <CardBody>
        <div className="space-y-6">
          <div className="flex justify-between items-center mt-10">
            <div className="text-center">
              <Text>
                {t("communityImpact.cards.swissSolarExpansion.labels.current")}
              </Text>
              <Text className="!text-2xl font-bold">{currentValue}</Text>
            </div>
            <div className="text-center">
              <Text>
                {t("communityImpact.cards.swissSolarExpansion.labels.goal")}
              </Text>
              <Text className="!text-2xl font-bold">{goalValue}</Text>
            </div>
          </div>
          <div className="pb-10">
            <Progress className="h-10" color="primary" value={progress} />
          </div>
        </div>
      </CardBody>
    </Card>
  );
};
