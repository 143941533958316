import { useConfirmDialog } from "@/app/layouts/ConfirmDialogProvider";
import { AccountForm } from "@/app/settings/AccountForm";
import { ChangeEmail, ChangeEmailFragment } from "@/app/settings/ChangeEmail";
import { AccountFormFragment } from "@/app/settings/hooks/useUpdateAccount";
import { sendPasswordResetEmail, useSession } from "@/lib/auth";
import { graphql } from "@/lib/data/graphql";
import { useTranslation } from "@/lib/i18n";
import { Button } from "@/ui/button/button";
import { Divider } from "@/ui/display/divider";
import { Alert } from "@/ui/feedback/alert";
import { toaster } from "@/ui/feedback/toaster";
import { SubPageWrapper } from "@/ui/layouts/sub-page-wrapper";
import { LoadingSpinner } from "@/ui/loading-spinner";
import { Heading } from "@/ui/typography/heading";
import { useQuery } from "@apollo/client";

export const AccountSettingsPageQuery = graphql(
  `
    query AccountSettingsPage {
      me {
        email
        ...AccountForm
        ...ChangeEmail
      }
    }
  `,
  [AccountFormFragment, ChangeEmailFragment]
);

export const AccountSettings = () => {
  const { t } = useTranslation();
  const { auth } = useSession();
  const confirmDialog = useConfirmDialog();

  const { data, loading } = useQuery(AccountSettingsPageQuery);

  const handleResetPassword = async () => {
    confirmDialog.open({
      title: t("settings.account.actions.resetPassword.confirmDialog.title"),
      description: t(
        "settings.account.actions.resetPassword.confirmDialog.description"
      ),
      onConfirm: async () => {
        if (data?.me?.email) {
          await sendPasswordResetEmail(auth, data?.me.email);
          toaster.success({
            title: t("settings.account.actions.resetPassword.success")
          });
        }
      }
    });
  };

  if (loading) {
    return (
      <div className="flex mt-14 items-center justify-center grow">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <SubPageWrapper>
      {data?.me ? (
        <>
          <Heading level={2}>{t("settings.account.title.account")}</Heading>
          <Divider />

          <ChangeEmail data={data.me} />

          <Button outline className="self-start" onClick={handleResetPassword}>
            {t("settings.account.actions.resetPassword.label")}
          </Button>

          <Heading level={2}>{t("settings.account.title.personal")}</Heading>
          <Divider />

          <AccountForm data={data.me} />
        </>
      ) : (
        <Alert type="error" title={t("error.generic.description")} />
      )}
    </SubPageWrapper>
  );
};
