import { PerformancePlantBadge } from "@/app/performance/PerformancePlantBadge";
import {
  Checkbox,
  CheckboxField,
  CheckboxGroup as CheckboxUIGroup
} from "@/ui/form/checkbox";
import { Label } from "@/ui/form/fieldset";
import { SelectOption } from "@/ui/form/form";
import {
  FormFieldController,
  FormFieldProps
} from "@/ui/form/FormFieldController";
import { FieldPath, FieldValues } from "react-hook-form";

type Props<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = FormFieldProps<TFieldValues, TName> & {
  options: (SelectOption & { color: string })[];
};

export const PlantCheckboxGroup = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  id,
  control,
  name,
  optional,
  options
}: Props<TFieldValues, TName>) => {
  if (!options || options.length === 0) {
    return null;
  }

  return (
    <FormFieldController
      id={id}
      control={control}
      name={name}
      optional={optional}
      render={({ field }) => (
        <CheckboxUIGroup className="max-h-[30vh] overflow-y-auto gap-2 flex flex-col !space-y-0">
          {options.map((option) => (
            <CheckboxField
              key={option.value}
              className="flex items-center gap-2 ml-2"
            >
              <Checkbox
                name={field.name}
                value={option.value}
                checked={field.value?.includes(option.value)}
                onChange={(isChecked) => {
                  let updatedValues: string[] = field.value ?? [];

                  if (isChecked) {
                    updatedValues = [...updatedValues, option.value];
                  } else {
                    updatedValues = updatedValues.filter(
                      (item) => item !== option.value
                    );
                  }

                  // Update the form value
                  field.onChange(updatedValues);
                }}
              />

              <Label>
                <PerformancePlantBadge
                  colorHex={option.color}
                  name={option.label}
                />
              </Label>
            </CheckboxField>
          ))}
        </CheckboxUIGroup>
      )}
    />
  );
};
