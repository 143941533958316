import { FragmentOf, graphql, readFragment } from "@/lib/data/graphql";
import { useTranslation } from "@/lib/i18n";
import { Card, CardBody, CardHeader } from "@/ui/display/card";
import { Carousel } from "@/ui/display/carousel";
import { KeyFigure } from "@/ui/display/key-figure";
import {
  Building,
  Events,
  Flash,
  Money,
  Power,
  SolarPanel
} from "@carbon/icons-react";
import { FC } from "react";

export const CommunityKeyFiguresCardFragment = graphql(`
  fragment CommunityKeyFiguresCard on Query {
    communityKeyFigures {
      panelsOwned
      totalPanelOwners
      totalRoofOwners
      crowdInvestmentVolume
      installedPower
      solarPowerProduction
    }
  }
`);

export const CommunityKeyFiguresCard: FC<{
  data?: FragmentOf<typeof CommunityKeyFiguresCardFragment>;
}> = ({ data }) => {
  const { t } = useTranslation();

  if (!data) return null;

  const { communityKeyFigures } = readFragment(
    CommunityKeyFiguresCardFragment,
    data
  );

  const slides = [
    [
      <KeyFigure
        key="panels"
        label={t("communityImpact.cards.communityKeyFigures.panelsOwned")}
        value={communityKeyFigures?.panelsOwned}
        Icon={SolarPanel}
      />,
      <KeyFigure
        key="owners"
        label={t("communityImpact.cards.communityKeyFigures.totalPanelOwners")}
        value={communityKeyFigures?.totalPanelOwners}
        Icon={Events}
      />,
      <KeyFigure
        key="investment"
        label={t(
          "communityImpact.cards.communityKeyFigures.crowdInvestmentVolume"
        )}
        value={communityKeyFigures?.crowdInvestmentVolume}
        Icon={Money}
      />
    ],
    [
      <KeyFigure
        key="roofOwners"
        label={t("communityImpact.cards.communityKeyFigures.totalRoofOwners")}
        value={communityKeyFigures?.totalRoofOwners}
        Icon={Building}
      />,
      <KeyFigure
        key="power"
        label={t("communityImpact.cards.communityKeyFigures.installedPower")}
        value={communityKeyFigures?.installedPower}
        Icon={Power}
      />,
      <KeyFigure
        key="production"
        label={t(
          "communityImpact.cards.communityKeyFigures.solarPowerProduction"
        )}
        value={communityKeyFigures?.solarPowerProduction}
        Icon={Flash}
      />
    ]
  ];

  return (
    <Card className="bg-bg-white">
      <CardHeader
        title={t("communityImpact.cards.communityKeyFigures.title")}
        headingLevel={2}
      />
      <CardBody>
        <Carousel slides={slides} />
      </CardBody>
    </Card>
  );
};
