import { FragmentOf, graphql, readFragment } from "@/lib/data/graphql";
import { useTranslation } from "@/lib/i18n";
import { Button } from "@/ui/button/button";
import { Divider } from "@/ui/display/divider";
import { toaster } from "@/ui/feedback/toaster";
import { Description, Label } from "@/ui/form/fieldset";
import { InputControlled } from "@/ui/form/InputControlled";
import { SwitchField } from "@/ui/form/switch";
import { SwitchControlled } from "@/ui/form/SwitchControlled";
import { Link } from "@/ui/link";
import { Text } from "@/ui/typography/text";
import { useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";

export const NotificationSettingsFormFragment = graphql(`
  fragment NotificationSettingsForm on User {
    id
    uid
    email
    notificationSettings {
      id
      additionalRecipients
      notifyByEmail
      notifyByPush
      notifyWhenGoalReached
      receiveInvoicesByMail
      subscribeToNewsletter
    }
  }
`);

const UpdateNotificationSettingsMutation = graphql(`
  mutation UpdateNotificationSettings(
    $userId: String!
    $input: UpdateUserNotificationSettingsInput!
  ) {
    updateUserNotificationSettings(
      userId: $userId
      updateUserNotificationSettingsInput: $input
    ) {
      id
      additionalRecipients
      notifyByEmail
      notifyByPush
      notifyWhenGoalReached
      receiveInvoicesByMail
      subscribeToNewsletter
    }
  }
`);

type NotificationSettingsFormValues = {
  additionalRecipients: string;
  notifyByEmail: boolean;
  notifyByPush: boolean;
  notifyWhenGoalReached: boolean;
  receiveInvoicesByMail: boolean;
  subscribeToNewsletter: boolean;
};

type NotificationSettingsFormProps = {
  data: FragmentOf<typeof NotificationSettingsFormFragment>;
};

export const NotificationSettingsForm: React.FC<
  NotificationSettingsFormProps
> = ({ data }) => {
  const { t } = useTranslation();

  const { email, uid, notificationSettings } = readFragment(
    NotificationSettingsFormFragment,
    data
  );

  const { control, handleSubmit } = useForm<NotificationSettingsFormValues>({
    defaultValues: {
      additionalRecipients: notificationSettings?.additionalRecipients || "",
      notifyByEmail: notificationSettings?.notifyByEmail || false,
      notifyByPush: notificationSettings?.notifyByPush || false,
      notifyWhenGoalReached:
        notificationSettings?.notifyWhenGoalReached || false,
      receiveInvoicesByMail:
        notificationSettings?.receiveInvoicesByMail || false,
      subscribeToNewsletter:
        notificationSettings?.subscribeToNewsletter || false
    }
  });

  const [updateSettings, { loading }] = useMutation(
    UpdateNotificationSettingsMutation
  );
  const handleUpdateSettings = (formData: NotificationSettingsFormValues) => {
    if (uid) {
      updateSettings({
        variables: {
          userId: uid, // TODO(#262): should be id
          input: {
            additionalRecipients: formData.additionalRecipients,
            notifyByEmail: formData.notifyByEmail,
            notifyByPush: formData.notifyByPush,
            notifyWhenGoalReached: formData.notifyWhenGoalReached,
            receiveInvoicesByMail: formData.receiveInvoicesByMail,
            subscribeToNewsletter: formData.subscribeToNewsletter
          }
        },
        onCompleted: () => {
          toaster.success({
            title: t("settings.notifications.actions.save.success")
          });
        },
        onError: () => {
          toaster.error({
            title: t("settings.notifications.actions.save.error")
          });
        }
      });
    }
  };

  return (
    <div className="flex flex-col gap-6 max-w-4xl">
      <Text>
        {t("settings.notifications.primaryEmailInfo")} <b>{email}</b>
      </Text>

      <form
        onSubmit={handleSubmit(handleUpdateSettings)}
        className="flex flex-col gap-6"
      >
        <div className="flex flex-col gap-2">
          <Text className="font-medium">
            {t("settings.notifications.addMoreRecipients")}
          </Text>
          <InputControlled
            hintText={t(
              "settings.notifications.fields.additionalRecipients.hintText"
            )}
            id="additionalRecipients"
            name="additionalRecipients"
            control={control}
            className="w-full max-w-96"
            rules={{
              validate: (value) =>
                validateAdditionalRecipients(value) ||
                t(
                  "settings.notifications.fields.additionalRecipients.invalidFormat"
                )
            }}
            optional
          />
        </div>

        <Divider />

        <div className="flex flex-col gap-6">
          <div className="flex flex-col gap-2">
            <Text className="font-medium">
              {t("settings.notifications.notifyBy.label")}
            </Text>

            <SwitchField className="flex gap-2 justify-between ml-10">
              <Label htmlFor="notifyByEmail" className="text-content-secondary">
                {t("settings.notifications.notifyBy.email.label")}
              </Label>
              <SwitchControlled
                id="notifyByEmail"
                name="notifyByEmail"
                defaultChecked={true}
                control={control}
                optional
              />
            </SwitchField>
            <SwitchField className="flex gap-2 justify-between ml-10">
              <Label htmlFor="notifyByPush" className="text-content-secondary">
                {t("settings.notifications.notifyBy.push.label")}
              </Label>
              <SwitchControlled
                id="notifyByPush"
                name="notifyByPush"
                defaultChecked={true}
                control={control}
                optional
              />
            </SwitchField>
          </div>

          <SwitchField>
            <Label htmlFor="notifyWhenGoalReached">
              {t("settings.notifications.fields.notifyWhenGoalReached.label")}
            </Label>
            <SwitchControlled
              id="notifyWhenGoalReached"
              name="notifyWhenGoalReached"
              defaultChecked={true}
              control={control}
              optional
            />
          </SwitchField>

          <SwitchField>
            <Label htmlFor="receiveInvoicesByMail" className="font-medium">
              {t("settings.notifications.fields.receiveInvoicesByMail.label")}
            </Label>
            <SwitchControlled
              id="receiveInvoicesByMail"
              name="receiveInvoicesByMail"
              defaultChecked={true}
              control={control}
              optional
            />
          </SwitchField>

          <SwitchField className="flex gap-2 justify-between items-center">
            <Label htmlFor="subscribeToNewsletter" className="font-medium">
              {t("settings.notifications.fields.subscribeToNewsletter.label")}
            </Label>
            <Description>
              {t(
                "settings.notifications.fields.subscribeToNewsletter.hintText"
              )}
            </Description>
            <SwitchControlled
              id="subscribeToNewsletter"
              name="subscribeToNewsletter"
              defaultChecked={true}
              control={control}
              optional
            />
          </SwitchField>
        </div>

        <Divider />
        <Text>
          {t("settings.notifications.betaTestingInfo")}{" "}
          <Link
            className="opacity-60 hover:opacity-100"
            href={`mailto:${import.meta.env.VITE_SUPPORT_EMAIL}`}
            target="_blank"
          >
            {import.meta.env.VITE_SUPPORT_EMAIL}
          </Link>
        </Text>

        <Button
          type="submit"
          className="self-start min-w-32 mt-2 sm:mt-6"
          loading={loading}
        >
          {t("settings.notifications.actions.save.label")}
        </Button>
      </form>
    </div>
  );
};

function validateAdditionalRecipients(value: string): boolean | string {
  if (!value) {
    return true;
  }

  const emails = value.split(",").map((email) => email.trim());
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  return emails.every((email) => emailRegex.test(email));
}
