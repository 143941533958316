import { TimePeriod, TimePeriodListbox } from "@/app/impact/TimePeriodListbox";
import { graphql } from "@/lib/data/graphql";
import { useTranslation } from "@/lib/i18n";
import { Card, CardBody, CardHeader } from "@/ui/display/card";
import { useTheme } from "@/ui/Theme";
import { useQuery } from "@apollo/client";
import { DotLottieReact } from "@lottiefiles/dotlottie-react";
import clsx from "clsx";
import { FC, useState } from "react";

export const ProducedElectricityCardQuery = graphql(`
  query ProducedElectricityCard($period: TimePeriod!) {
    myImpactProduction(period: $period)
  }
`);

export const ProducedElectricityCard: FC = () => {
  const { t } = useTranslation();
  const { theme } = useTheme();
  const [period, setPeriod] = useState<TimePeriod>("TOTAL");

  const { data, loading } = useQuery(ProducedElectricityCardQuery, {
    variables: { period }
  });

  const producedElectricity = data?.myImpactProduction ?? 0;

  return (
    <Card fancyBackground className="relative h-[400px] sm:h-[300px]">
      <CardHeader
        title={t("myImpact.cards.producedElectricity.title")}
        end={<TimePeriodListbox value={period} onChange={setPeriod} />}
        border={false}
        headingLevel={1}
        wrapperClassName="max-sm:flex-col max-sm:items-start"
      />
      <CardBody>
        <div className="absolute left-[-110px] bottom-[-10px] sm:top-0 sm:left-1/3 sm:transform sm:-translate-x-1/3">
          <DotLottieReact
            className="w-[400px] h-[200px] sm:w-[600px] sm:h-[300px]"
            src={
              theme === "light"
                ? "/graphics/solar_inverter.lottie"
                : "/graphics/solar_inverter_dark.lottie"
            }
            loop
            autoplay
          />
        </div>
        <div className="absolute top-1/2 transform -translate-y-1/2 right-2 sm:right-[calc(50%-300px)]">
          <div
            className={clsx(
              "relative size-[190px] sm:size-[270px]",
              "bg-[radial-gradient(circle,_rgba(249,225,43,0.5)_0%,_#ffffff00_70%)]",
              "dark:bg-[radial-gradient(circle,_#6B4F04_0%,_#ffffff00_70%)]"
            )}
          >
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
              <div className="flex items-baseline">
                <div className="text-[3.75rem] sm:text-[5rem] font-normal">
                  {loading ? "..." : producedElectricity.toFixed(0)}
                </div>
                <div className="text-xl font-normal">{t("units.kWh")}</div>
              </div>
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};
