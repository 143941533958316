import { Button } from "@/ui/button/button";
import { getIntervalText, TimeScaleKeyType } from "@/ui/date/date-utils";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/16/solid";
import clsx from "clsx";
import React from "react";

export type DateNavigationProps = {
  startDate: Date;
  endDate: Date;
  quickSelectorKey: TimeScaleKeyType;
  onNavigate: (direction: "back" | "forward") => void;
  disabled?: boolean;
  className?: string;
};

export const DateNavigation: React.FC<DateNavigationProps> = ({
  startDate,
  endDate,
  quickSelectorKey,
  onNavigate,
  disabled,
  className
}) => (
  <div className={clsx("flex items-center gap-x-2", className)}>
    <Button outline onClick={() => onNavigate("back")} disabled={disabled}>
      <ChevronLeftIcon className="size-4" />
    </Button>
    <span
      className={clsx([
        "text-sm/6 text-content-primary font-bold text-center",
        "min-w-[120px] sm:border border-border-primary rounded-xl",
        "py-[calc(theme(spacing[2.5])-1px)] sm:px-[calc(theme(spacing.3)-1px)] sm:py-[calc(theme(spacing[1.5])-1px)]"
      ])}
    >
      {getIntervalText(startDate, endDate, quickSelectorKey)}
    </span>
    <Button outline onClick={() => onNavigate("forward")} disabled={disabled}>
      <ChevronRightIcon className="size-4" />
    </Button>
  </div>
);
