import { graphql } from "@/lib/data/graphql";
import { useTranslation } from "@/lib/i18n";
import { Button } from "@/ui/button/button";
import {
  Dialog,
  DialogActions,
  DialogDescription,
  DialogTitle
} from "@/ui/feedback/dialog";
import { toaster } from "@/ui/feedback/toaster";
import { useMutation } from "@apollo/client";

const ShareDocumentMutation = graphql(`
  mutation ShareDocument($input: SendEmailInput!) {
    sendEmail(emailInput: $input)
  }
`);

type DocumentShareDialogProps = {
  email: string;
  subject: string;
  text: string;
  isOpen: boolean;
  onClose: VoidFunction;
};

export const DocumentShareDialog: React.FC<DocumentShareDialogProps> = ({
  email,
  subject,
  text,
  onClose,
  isOpen
}) => {
  const { t } = useTranslation();
  const [triggerSending, { loading }] = useMutation(ShareDocumentMutation);

  const handleSending = () => {
    triggerSending({
      variables: {
        input: {
          to: email,
          subject,
          text
        }
      },
      onCompleted: () => {
        toaster.success({
          title: t("documents.listing.actions.share.success.description")
        });
        onClose();
      },
      onError: () => {
        toaster.error({
          title: t("documents.listing.actions.share.error.description")
        });
      }
    });
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>
        {t("documents.listing.actions.share.dialog.title")}
      </DialogTitle>
      <DialogDescription>
        <span>{t("documents.listing.actions.share.dialog.description")}</span>
        <br />
        <span className="text-content-primary font-semibold">{email}</span>
      </DialogDescription>
      <DialogActions>
        <Button plain onClick={onClose}>
          {t("documents.listing.actions.share.dialog.cancel")}
        </Button>
        <Button loading={loading} onClick={handleSending}>
          {t("documents.listing.actions.share.dialog.send")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
