import { FragmentOf, graphql, readFragment } from "@/lib/data/graphql";
import { Card, CardBody, CardHeader } from "@/ui/display/card";
import { Carousel } from "@/ui/display/carousel";
import { KeyFigure } from "@/ui/display/key-figure";
import {
  ChartPie,
  EnergyRenewable,
  Money,
  Need,
  Plug,
  SolarPanel
} from "@carbon/icons-react";
import { t } from "i18next";
import { FC } from "react";
import { CardHeaderAction } from "./CardHeaderAction";

export const PortfolioKeyFiguresCardFragment = graphql(`
  fragment PortfolioKeyFiguresCard on PortfolioKeyFigures {
    totalElectricityProduction
    panelsOwned
    investedSolarPlants
    investmentVolume
    portfolioResidualValue
    payoutsSinceBeginning
  }
`);

export const PortfolioKeyFiguresCard: FC<{
  data?: FragmentOf<typeof PortfolioKeyFiguresCardFragment>;
}> = ({ data }) => {
  const performance = readFragment(PortfolioKeyFiguresCardFragment, data);

  const slides = [
    [
      <KeyFigure
        key="electricity"
        label={t(
          "dashboard.cards.portfolioKeyFigures.totalElectricityProduction"
        )}
        value={performance?.totalElectricityProduction}
        Icon={Plug}
      />,
      <KeyFigure
        key="panels"
        label={t("dashboard.cards.portfolioKeyFigures.panelsOwned")}
        value={performance?.panelsOwned}
        Icon={SolarPanel}
      />,
      <KeyFigure
        key="plants"
        label={t("dashboard.cards.portfolioKeyFigures.investedSolarPlants")}
        value={performance?.investedSolarPlants}
        Icon={EnergyRenewable}
      />
    ],
    [
      <KeyFigure
        key="investment"
        label={t("dashboard.cards.portfolioKeyFigures.investmentVolume")}
        value={performance?.investmentVolume}
        Icon={Money}
      />,
      <KeyFigure
        key="residual"
        label={t("dashboard.cards.portfolioKeyFigures.portfolioResidualValue")}
        value={performance?.portfolioResidualValue}
        Icon={ChartPie}
      />,
      <KeyFigure
        key="payouts"
        label={t("dashboard.cards.portfolioKeyFigures.payoutsSinceBeginning")}
        value={performance?.payoutsSinceBeginning}
        Icon={Need}
      />
    ]
  ];

  return (
    <Card className="bg-bg-white">
      <CardHeader
        title={t("dashboard.cards.portfolioKeyFigures.title")}
        headingLevel={2}
        end={
          <CardHeaderAction
            href="/performance"
            label={t("dashboard.cards.portfolioKeyFigures.details")}
          />
        }
      />
      <CardBody>
        <Carousel slides={slides} />
      </CardBody>
    </Card>
  );
};
