import {
  createContext,
  Dispatch,
  PropsWithChildren,
  useContext,
  useReducer
} from "react";
import { Action, reducer } from "./reducer";
import { initialState, State } from "./state";

export const PerformanceContext = createContext<State | null>(null);
export const PerformanceDispatchContext =
  createContext<Dispatch<Action> | null>(null);

export const PerformanceProvider: React.FC<PropsWithChildren> = ({
  children
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <PerformanceContext.Provider value={state}>
      <PerformanceDispatchContext.Provider value={dispatch}>
        {children}
      </PerformanceDispatchContext.Provider>
    </PerformanceContext.Provider>
  );
};

export const usePerformanceContext = () => {
  const state = useContext(PerformanceContext);
  const dispatch = useContext(PerformanceDispatchContext);
  if (state === null || dispatch === null) {
    throw new Error(
      "usePerformanceContext must be used within a PerformanceProvider"
    );
  }
  return { state, dispatch };
};
