import { useTranslation } from "@/lib/i18n";
import { Field, Label } from "@/ui/form/fieldset";
import { Select } from "@/ui/form/select";
import { TableFilterWrapper } from "@/ui/table/TableFilterWrapper";
import React from "react";

const QUARTERS = [1, 2, 3, 4] as const;
const earliestYear = 1970;
const currentYear = new Date().getFullYear();
const YEARS = Array.from(
  { length: currentYear - earliestYear + 2 },
  (_, i) => earliestYear + i
);

export type FiltersFields = {
  year: number;
  quarter: number;
};

type Props = {
  filters: FiltersFields;
  setFilters: React.Dispatch<React.SetStateAction<FiltersFields>>;
};

export const ProjectOverviewFilters: React.FC<Props> = ({
  filters,
  setFilters
}) => {
  const { t } = useTranslation();

  return (
    <TableFilterWrapper>
      <Field className="min-w-36">
        <Label>{t("projectOverview.filters.year.label")}</Label>
        <Select
          name="year"
          value={filters.year}
          onChange={(e) =>
            setFilters((prev) => ({
              ...prev,
              year: parseInt(e.target.value)
            }))
          }
        >
          {YEARS.map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </Select>
      </Field>

      <Field className="min-w-36">
        <Label>{t("projectOverview.filters.quarter.label")}</Label>
        <Select
          name="quarter"
          value={filters.quarter}
          onChange={(e) =>
            setFilters((prev) => ({
              ...prev,
              quarter: parseInt(e.target.value)
            }))
          }
        >
          {QUARTERS.map((quarter) => {
            const q = `Q${quarter}`;
            return (
              <option key={quarter} value={quarter}>
                {q}
              </option>
            );
          })}
        </Select>
      </Field>
    </TableFilterWrapper>
  );
};
