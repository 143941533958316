import { useUpdateAccount } from "@/app/settings/hooks/useUpdateAccount";
import { FragmentOf, graphql, readFragment } from "@/lib/data/graphql";
import { useTranslation } from "@/lib/i18n";
import { Button } from "@/ui/button/button";
import { Dialog, DialogActions, DialogTitle } from "@/ui/feedback/dialog";
import { toaster } from "@/ui/feedback/toaster";
import { InputControlled } from "@/ui/form/InputControlled";
import { Text } from "@/ui/typography/text";
import React, { useState } from "react";
import { useForm } from "react-hook-form";

export const ChangeEmailFragment = graphql(`
  fragment ChangeEmail on User {
    id
    email
    uid
  }
`);

type ChangeEmailFormValues = {
  email: string;
};
type ChangeEmailProps = {
  data: FragmentOf<typeof ChangeEmailFragment>;
};
export const ChangeEmail: React.FC<ChangeEmailProps> = ({ data }) => {
  const [isChangeDialogOpen, setIsChangeDialogOpen] = useState(false);
  const { t } = useTranslation();

  const { email, uid } = readFragment(ChangeEmailFragment, data);

  const { control, handleSubmit } = useForm<ChangeEmailFormValues>({
    defaultValues: {
      email: email || ""
    }
  });
  const { updateAccount, loading } = useUpdateAccount();

  const updateEmail = (formData: ChangeEmailFormValues) => {
    if (uid) {
      updateAccount({
        variables: {
          userId: uid, // TODO(#262): should be id
          input: {
            email: formData.email
          }
        },
        onCompleted: () => {
          toaster.success({
            title: t("settings.account.changeEmail.actions.submit.success")
          });
          setIsChangeDialogOpen(false);
        },
        onError: () => {
          toaster.error({
            title: t("settings.account.changeEmail.actions.submit.error")
          });
        }
      });
    }
  };

  return (
    <div className="flex flex-col sm:flex-row gap-6">
      <InputControlled
        label={t("settings.account.fields.email.label")}
        id="email"
        type="email"
        name="email"
        autoComplete="email"
        control={control}
        disabled={true}
        hintText={t("settings.account.fields.email.hintText")}
        className="min-w-72"
      />
      <Button
        outline
        className="self-start sm:self-end sm:mb-5"
        onClick={() => setIsChangeDialogOpen(true)}
      >
        {t("settings.account.actions.changeEmail")}
      </Button>
      <Dialog
        open={isChangeDialogOpen}
        onClose={() => setIsChangeDialogOpen(true)}
      >
        <DialogTitle>
          {t("documents.listing.actions.share.dialog.title")}
        </DialogTitle>
        <form onSubmit={handleSubmit(updateEmail)}>
          <InputControlled
            label={t("settings.account.changeEmail.fields.newEmail.label")}
            id="email"
            type="email"
            name="email"
            autoComplete="email"
            control={control}
          />

          <Text className="mt-2">
            {t("settings.account.changeEmail.description")}
          </Text>

          <DialogActions>
            <Button plain onClick={() => setIsChangeDialogOpen(false)}>
              {t("settings.account.changeEmail.actions.cancel")}
            </Button>
            <Button loading={loading} type="submit">
              {t("settings.account.changeEmail.actions.submit.label")}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};
