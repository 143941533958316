import { ArrowLeft } from "@carbon/icons-react";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";

export type PageHeadingProps = {
  title: string;
  className?: string;
  back?: boolean;
};

export const PageHeading: React.FC<PageHeadingProps> = ({
  title,
  className,
  back
}) => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div className={clsx("flex items-center", className)}>
      {back ? (
        <button
          onClick={handleBack}
          className="rounded-full p-2 hover:bg-bg-secondary"
        >
          <ArrowLeft className="text-content-secondary size-6 sm:size-8" />
        </button>
      ) : null}
      <div className={clsx("relative", back ? "ml-2" : "sm:ml-4")}>
        <h1
          className={clsx(
            "text-heading-lg md:text-display-md lg:text-display-lg text-content-primary",
            "[--dot-size:0.75rem]",
            {
              "after:hidden md:after:block after:absolute after:-right-[calc(var(--dot-size)_+_0.25rem)] after:top-2 md:after:top-4 after:bg-yellow-300 after:rounded-full after:content-[''] after:size-[--dot-size]":
                title
            }
          )}
        >
          {title}
        </h1>
      </div>
    </div>
  );
};
