import clsx from "clsx";
import { Bar as RechartsBar, BarProps as RechartsBarProps } from "recharts";

export type BarProps = Omit<RechartsBarProps, "ref">;
export const defaultProps: Partial<BarProps> = {
  stroke: "transparent",
  fill: "var(--color-content-secondary)",
  fillOpacity: 1,
  type: "monotone",
  isAnimationActive: false,
  maxBarSize: 30
  // barCategoryGap: "5%" // TODO: not working
};

export const renderBar = (props: BarProps) => (
  <RechartsBar {...defaultProps} {...props} key={props.key}>
    {props.children}
  </RechartsBar>
);

type RenderBarLabelProps = {
  valueFormatter?: (value: number) => string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} & Record<string, any>;

export const renderBarLabel = ({
  x,
  y,
  width,
  height,
  value,
  valueFormatter
}: RenderBarLabelProps) => {
  if (value >= 0 && value <= 1) {
    return;
  }

  return (
    <foreignObject
      x={x}
      y={y}
      width={width}
      height={height}
      className="overflow-visible"
    >
      <div
        className={clsx(
          "text-xs -translate-x-1/2 -translate-y-full text-content-primary"
        )}
      >
        <div>{valueFormatter ? valueFormatter(value) : value}</div>
      </div>
    </foreignObject>
  );
};
