import { Checkbox } from "@/ui/form/checkbox";
import { Label } from "@/ui/form/fieldset";
import clsx from "clsx";
import { FieldPath, FieldValues } from "react-hook-form";
import { FormFieldController, FormFieldProps } from "./FormFieldController";

type CheckboxControlledProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = { className?: string; defaultChecked?: boolean } & FormFieldProps<
  TFieldValues,
  TName
>;

export const CheckboxControlled = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  id,
  control,
  label,
  name,
  optional,
  rules,
  className,
  defaultChecked,
  ...restProps
}: CheckboxControlledProps<TFieldValues, TName>) => {
  return (
    <FormFieldController
      id={id}
      control={control}
      name={name}
      optional={optional}
      rules={rules}
      render={({ field, error }) => (
        <div className={clsx(className, "flex gap-2 items-center")}>
          <Checkbox
            id={id}
            checked={field?.value}
            defaultChecked={defaultChecked}
            aria-invalid={error?.message ? "true" : "false"}
            {...field}
            {...restProps}
            name={field?.name}
          />
          <Label htmlFor={id} className="cursor-pointer">
            {label}
          </Label>
        </div>
      )}
    />
  );
};
