import clsx from "clsx";

type PerformancePlantBadgeProps = {
  colorHex: string;
  name: string;
  className?: string;
};

export const PerformancePlantBadge: React.FC<PerformancePlantBadgeProps> = ({
  colorHex,
  name,
  className
}) => {
  return (
    <div
      className={clsx(
        "[--bg-brightness:1]",
        "[--label-brightness:0.4]",
        "p-1.5 leading-none rounded-md text-sm",
        "truncate",
        className
      )}
      style={{
        backgroundColor: colorHex,
        filter: "brightness(var(--bg-brightness))"
      }}
    >
      <span
        style={{
          color: colorHex,
          filter: "brightness(var(--label-brightness))"
        }}
      >
        {name}
      </span>
    </div>
  );
};
