import { ConfirmDialog } from "@/ui/feedback/confirm-dialog";
import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useMemo,
  useState
} from "react";

type ConfirmDialogContent = {
  title: string;
  description: string;
  onClose?: () => void;
  onConfirm: () => void;
  closeOnOutsideClick?: boolean;
};

const ConfirmDialogContext = createContext<{
  open: (content: ConfirmDialogContent) => void;
  close: () => void;
} | null>(null);

export const ConfirmDialogProvider: React.FC<PropsWithChildren> = ({
  children
}) => {
  const [dialogContent, setDialogContent] =
    useState<ConfirmDialogContent | null>(null);

  const open = (content: ConfirmDialogContent) => {
    setDialogContent(content);
  };

  const close = () => {
    setDialogContent(null);
  };

  const handleCancel = () => {
    dialogContent?.onClose?.();
    close();
  };

  const handleConfirm = () => {
    dialogContent?.onConfirm();
    close();
  };

  const contextValue = useMemo(() => ({ open, close }), []);

  return (
    <ConfirmDialogContext.Provider value={contextValue}>
      {children}

      {dialogContent ? (
        <ConfirmDialog
          isOpen
          title={dialogContent.title}
          description={dialogContent.description}
          onClose={handleCancel}
          onConfirm={handleConfirm}
          closeOnOutsideClick={dialogContent.closeOnOutsideClick}
        />
      ) : null}
    </ConfirmDialogContext.Provider>
  );
};

export const useConfirmDialog = () => {
  const context = useContext(ConfirmDialogContext);
  if (!context) {
    throw new Error(
      "useConfirmDialog must be used within a ConfirmDialogProvider"
    );
  }
  return context;
};
