import {
  Checkbox,
  CheckboxField,
  CheckboxGroup as CheckboxUIGroup
} from "@/ui/form/checkbox";
import { Label } from "@/ui/form/fieldset";
import { SelectOption } from "@/ui/form/form";
import { FieldPath, FieldValues } from "react-hook-form";
import { FormFieldController, FormFieldProps } from "./FormFieldController";

type Props<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = FormFieldProps<TFieldValues, TName> & { options: SelectOption[] };

export const CheckboxGroupControlled = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  id,
  label,
  control,
  name,
  options,
  optional
}: Props<TFieldValues, TName>) => {
  if (!options) {
    return null;
  }

  return (
    <FormFieldController
      id={id}
      label={label}
      control={control}
      name={name}
      optional={optional}
      render={({ field }) => (
        <CheckboxUIGroup>
          {options.map((option) => (
            <CheckboxField key={option.value}>
              <Checkbox
                name={field.name}
                value={option.value}
                checked={field.value?.includes(option.value)}
                onChange={(isChecked) => {
                  let updatedValues: string[] = field.value ?? [];

                  if (isChecked) {
                    updatedValues = [...updatedValues, option.value];
                  } else {
                    updatedValues = updatedValues.filter(
                      (item) => item !== option.value
                    );
                  }

                  // Update the form value
                  field.onChange(updatedValues);
                }}
              />
              <Label className="cursor-pointer">{option.label}</Label>
            </CheckboxField>
          ))}
        </CheckboxUIGroup>
      )}
    />
  );
};
