import plugin from "tailwindcss/plugin";

export const twSafeAreaPlugin = plugin(function ({ addUtilities }) {
  const utilities = {
    // Padding utilities
    ".pt-safe": {
      paddingTop: "env(safe-area-inset-top)"
    },
    ".pb-safe": {
      paddingBottom: "env(safe-area-inset-bottom)"
    },
    ".pl-safe": {
      paddingLeft: "env(safe-area-inset-left)"
    },
    ".pr-safe": {
      paddingRight: "env(safe-area-inset-right)"
    },
    ".px-safe": {
      paddingLeft: "env(safe-area-inset-left)",
      paddingRight: "env(safe-area-inset-right)"
    },
    ".py-safe": {
      paddingTop: "env(safe-area-inset-top)",
      paddingBottom: "env(safe-area-inset-bottom)"
    },
    ".p-safe": {
      paddingTop: "env(safe-area-inset-top)",
      paddingBottom: "env(safe-area-inset-bottom)",
      paddingLeft: "env(safe-area-inset-left)",
      paddingRight: "env(safe-area-inset-right)"
    },

    // Position-based utilities
    ".t-safe": {
      top: "env(safe-area-inset-top)"
    },
    ".b-safe": {
      bottom: "env(safe-area-inset-bottom)"
    },
    ".l-safe": {
      left: "env(safe-area-inset-left)"
    },
    ".r-safe": {
      right: "env(safe-area-inset-right)"
    },
    ".x-safe": {
      left: "env(safe-area-inset-left)",
      right: "env(safe-area-inset-right)"
    },
    ".y-safe": {
      top: "env(safe-area-inset-top)",
      bottom: "env(safe-area-inset-bottom)"
    },
    ".inset-safe": {
      top: "env(safe-area-inset-top)",
      bottom: "env(safe-area-inset-bottom)",
      left: "env(safe-area-inset-left)",
      right: "env(safe-area-inset-right)"
    }
  };

  addUtilities(utilities);
});
