/* eslint-disable react/jsx-no-literals */
/* eslint-disable no-restricted-imports */
import { useTheme } from "@/ui/Theme";
import { Alert, AlertProps } from "@/ui/feedback/alert";
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon
} from "@heroicons/react/24/outline";
import clsx from "clsx";
import { Id, Slide, toast, ToastContainer, ToastOptions } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const ToasterContainer: React.FC = () => {
  const { theme } = useTheme();

  return (
    <>
      <ToastContainer
        limit={3}
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={true}
        pauseOnFocusLoss={true}
        draggable={true}
        pauseOnHover={true}
        closeButton={false}
        aria-live="assertive"
        theme={theme}
        transition={Slide}
        icon={false}
        className={clsx(
          "max-lg:px-4 !w-full md:max-w-md lg:max-w-lg",
          // Override toastify CSS variables with our theme, more at https://fkhadra.github.io/react-toastify/how-to-style#override-css-variables
          "[--toastify-color-progress-info:theme(colors[content-info])]",
          "[--toastify-color-progress-success:theme(colors[content-success])]",
          "[--toastify-color-progress-warning:theme(colors[content-warning])]",
          "[--toastify-color-progress-error:theme(colors[content-danger])]",
          "[--toastify-color-progress-bgo:0.4]"
        )}
        toastClassName={clsx(
          "!p-0 mb-2 rounded-lg sm:rounded-2xl",
          "[--toastify-color-light:transparent] [--toastify-color-dark:transparent] !min-h-fit !w-full"
        )}
        bodyClassName={clsx("!p-0 !m-0")}
      />
      {/* Unfortunately, toastify's `progressClassName` is not helping us to change height of the progress bar, so we need to override it with our own CSS. */}
      <style>
        {`
          .Toastify__progress-bar--wrp {
            height: 0.125rem;
          }
        `}
      </style>
    </>
  );
};

export const toaster = (
  alertProps: AlertProps,
  toastOptions: ToastOptions
): Id => toast(<Alert {...alertProps} />, toastOptions);

toaster.success = (alertProps: AlertProps, toastOptions?: ToastOptions): Id =>
  toast.success(
    <Alert
      {...alertProps}
      icon={
        <CheckCircleIcon className="size-6 sm:size-8 text-content-success" />
      }
    />,
    {
      ...toastOptions
    }
  );

toaster.info = (alertProps: AlertProps, toastOptions?: ToastOptions): Id =>
  toast.info(
    <Alert
      {...alertProps}
      icon={
        <InformationCircleIcon className="size-6 sm:size-8 text-content-info" />
      }
    />,
    {
      ...toastOptions
    }
  );

toaster.warning = (alertProps: AlertProps, toastOptions?: ToastOptions): Id =>
  toast.warning(
    <Alert
      {...alertProps}
      icon={
        <ExclamationTriangleIcon className="size-6 sm:size-8 text-content-warning" />
      }
    />,
    {
      ...toastOptions
    }
  );

toaster.error = (alertProps: AlertProps, toastOptions?: ToastOptions): Id =>
  toast.error(
    <Alert
      {...alertProps}
      icon={
        <ExclamationCircleIcon className="size-6 sm:size-8 text-content-danger" />
      }
    />,
    {
      ...toastOptions
    }
  );
