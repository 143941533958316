import { TouchTarget } from "@/ui/button/button";
import { Link } from "@/ui/link";
import clsx from "clsx";
import { motion } from "motion/react";
import React from "react";

type TabNavigationItemProps = {
  href: string;
  current: boolean;
  title: string;
  className?: string;
};

export const TabNavigationItem: React.FC<TabNavigationItemProps> = ({
  className,
  href,
  title,
  current
}) => {
  return (
    <span className="relative">
      {current ? (
        <motion.span
          layoutId="current-indicator"
          className={clsx(
            "absolute z-20 inset-x-0 -bottom-[1px] h-0.5 rounded-full bg-zinc-950 dark:bg-white"
          )}
        />
      ) : null}
      <Link
        href={href}
        className={clsx(
          "relative flex min-w-0 items-center gap-3 rounded-t-lg px-4 py-4 text-left text-base/6 font-medium",
          "text-zinc-950 dark:text-white text-sm",
          "hover:bg-zinc-950/[2.5%] hover:text-zinc-950 dark:hover:bg-white/5 dark:hover:text-white",
          "focus:outline-none focus:bg-zinc-950/[2.5%] focus:text-zinc-950 dark:focus:bg-white/5 dark:focus:text-white",
          "forced-color-adjust-none forced-colors:hover:bg-[Highlight] forced-colors:hover:text-[HighlightText]",
          "forced-colors:focus:bg-[Highlight] forced-colors:focus:text-[HighlightText]",
          "transition-colors ease",
          { "opacity-60": !current },
          "hover:opacity-100 focus:opacity-100",
          className
        )}
        data-current={current ? "true" : undefined}
        title={title}
      >
        <TouchTarget>
          <span
            data-slot="item-title"
            className={clsx(
              "block text-center",
              "text-ellipsis overflow-hidden whitespace-nowrap",
              "max-w-[200px]"
            )}
          >
            {title}
          </span>
        </TouchTarget>
      </Link>
    </span>
  );
};
