import React, { PropsWithChildren } from "react";
import { cn } from "../utils";

export interface NativeToolbarProps {
  /**
   * Optional CSS class to apply to the toolbar
   */
  className?: string;
}

export interface NativeToolbarBodyProps {
  /**
   * Optional CSS class to apply to the content container
   */
  className?: string;
  children: React.ReactNode;
}

/**
 * A toolbar component inspired by Ionic's toolbar, positioned at the bottom of the screen.
 * Respects safe area insets for modern mobile browsers.
 *
 * @example
 * ```tsx
 * <NativeToolbar>
 *   <NativeToolbarBody>
 *     <button>Action 1</button>
 *     <button>Action 2</button>
 *   </NativeToolbarBody>
 * </NativeToolbar>
 * ```
 *
 * @example With elements in different slots
 * ```tsx
 * <NativeToolbar>
 *   <NativeToolbarBody>
 *     <div data-slot="start">
 *       <button>Back</button>
 *     </div>
 *     <div>Title</div>
 *     <div data-slot="end">
 *       <button>Menu</button>
 *     </div>
 *   </NativeToolbarBody>
 * </NativeToolbar>
 */
export const NativeToolbar: React.FC<PropsWithChildren<NativeToolbarProps>> = ({
  children,
  className
}) => {
  return (
    <div
      className={cn(
        "fixed bottom-0 left-0 right-0 z-50",
        "bg-bg-primary border-t border-border-primary",
        "pb-safe",
        "shadow-lg",
        className
      )}
    >
      {children}
    </div>
  );
};

/**
 * The inner content container for the NativeToolbar.
 * Handles the layout of toolbar items based on their data-slot attributes.
 */
export const NativeToolbarBody: React.FC<NativeToolbarBodyProps> = ({
  className,
  children
}) => {
  return (
    <div
      className={cn(
        "flex items-center justify-between px-4 h-14",
        "[&>[data-slot=start]]:flex [&>[data-slot=start]]:items-center",
        "[&>[data-slot=end]]:flex [&>[data-slot=end]]:items-center",
        "[&>:not([data-slot])]:flex [&>:not([data-slot])]:items-center [&>:not([data-slot])]:justify-center [&>:not([data-slot])]:flex-1",
        className
      )}
    >
      {children}
    </div>
  );
};
