import { FC, PropsWithChildren } from "react";
import { cn } from "../utils";

export const PageWrapper: FC<PropsWithChildren<{ className?: string }>> = ({
  className,
  children
}) => {
  return (
    <div
      className={cn(
        "flex flex-col gap-4 sm:gap-8 h-full",
        "p-4 md:p-4 lg:p-6",
        className
      )}
    >
      {children}
    </div>
  );
};
