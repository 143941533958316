import {
  YAxis as RechartsYAxis,
  YAxisProps as RechartsYAxisProps
} from "recharts";

export type YAxisProps = Omit<RechartsYAxisProps, "ref">;
export const YAxis = RechartsYAxis;
export const defaultProps: Partial<YAxisProps> = {
  stroke: "var(--color-content-secondary)"
};

export const renderYAxis = (props: YAxisProps) => (
  <RechartsYAxis {...defaultProps} {...props} key={props.key}>
    {props.children}
  </RechartsYAxis>
);
