import { graphql } from "@/lib/data/graphql";
import { useTranslation } from "@/lib/i18n";
import { Listbox, ListboxLabel, ListboxOption } from "@/ui/form/listbox";
import clsx from "clsx";
import { FC } from "react";

export type TechnicalLogMessageType = ReturnType<
  typeof graphql.scalar<"TechnicalLogMessageType">
>;

type Props = {
  value?: TechnicalLogMessageType | null;
  onChange: (value: TechnicalLogMessageType) => void;
  className?: string;
};

export const TechnicalLogMessagesTypesListbox: FC<Props> = ({
  value,
  onChange,
  className
}) => {
  const { t } = useTranslation();
  const types: TechnicalLogMessageType[] = [
    "ERROR",
    "INFO",
    "WARNING"
  ] as const;

  const options = types.map((type) => ({
    label: t(`enums.technicalLogMessageType.${type}`) as string,
    value: type
  }));

  return (
    <Listbox
      value={value ?? "all"}
      onChange={(e: TechnicalLogMessageType) => onChange(e)}
      className={clsx("w-full", className)}
    >
      <ListboxOption key="all" value="all">
        <ListboxLabel>
          {t("technicalLogMessages.technicalLogMessagesTypesListbox.all")}
        </ListboxLabel>
      </ListboxOption>
      {options.map((option) => {
        return (
          <ListboxOption<TechnicalLogMessageType>
            key={option.value}
            value={option.value}
          >
            <ListboxLabel>{option.label}</ListboxLabel>
          </ListboxOption>
        );
      })}
    </Listbox>
  );
};
