import { FragmentOf, graphql, readFragment } from "@/lib/data/graphql";
import { useTranslation } from "@/lib/i18n";
import { Avatar } from "@/ui/avatar";
import { Card, CardBody, CardHeader } from "@/ui/display/card";
import { FC } from "react";
import { Link } from "react-router-dom";
import { CardHeaderAction } from "./CardHeaderAction";

export const PlantItemCardFragment = graphql(`
  fragment PlantItemCard on Plant {
    id
    name
    photoUrl
    todayPerformance {
      pYield
      pConsumption
      pOwnConsumption
      pOwnConsumptionPercentage
    }
  }
`);

export const PlantItemCard: FC<{
  data?: FragmentOf<typeof PlantItemCardFragment>;
}> = ({ data }) => {
  const { t } = useTranslation();

  const plant = readFragment(PlantItemCardFragment, data);

  if (!plant || !plant.todayPerformance) return null;

  const { todayPerformance } = plant;

  const plantHref = `plants/${plant.id}`;

  return (
    <Link to={plantHref}>
      <Card className="bg-bg-white hover:bg-bg-hover transition-colors h-full">
        <CardHeader
          title={plant.name}
          end={<CardHeaderAction href={plantHref} />}
        />
        <CardBody>
          <div className="flex gap-4 items-start sm:gap-6">
            <Avatar src={plant.photoUrl} className="size-20 sm:size-32" />
            <div className="space-y-1 sm:space-y-2">
              <PlantPerfromance
                label={t("dashboard.cards.performance.production")}
                value={todayPerformance.pYield.toFixed(2)}
                units={t("common.units.kWh")}
              />
              <PlantPerfromance
                label={t("dashboard.cards.performance.consumption")}
                value={todayPerformance.pConsumption.toFixed(2)}
                units={t("common.units.kWh")}
              />
              <PlantPerfromance
                label={t("dashboard.cards.performance.ownConsumption")}
                value={todayPerformance.pOwnConsumption.toFixed(2)}
                units={`${t("common.units.kWh")} (${todayPerformance.pOwnConsumptionPercentage}%)`}
              />
            </div>
          </div>
        </CardBody>
      </Card>
    </Link>
  );
};

const PlantPerfromance: FC<{
  label: string;
  units: string;
  value: React.ReactNode;
}> = ({ label, value, units }) => {
  return (
    <div className="space-y-1 text-sm">
      <div className="text-content-secondary">{label}</div>
      <div className="font-medium">
        {value} {units}
      </div>
    </div>
  );
};
