import { Input, UiInputProps } from "@/ui/form/input";
import { FieldPath, FieldValues } from "react-hook-form";
import { FormFieldController, FormFieldProps } from "./FormFieldController";

type InputFieldProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = UiInputProps & FormFieldProps<TFieldValues, TName>;

export const InputControlled = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  id,
  label,
  control,
  name,
  optional,
  rules,
  hintText,
  className,
  hideCounter,
  ...restProps
}: InputFieldProps<TFieldValues, TName>) => {
  return (
    <FormFieldController
      id={id}
      label={label}
      control={control}
      name={name}
      optional={optional}
      rules={rules}
      hintText={hintText}
      className={className}
      hideCounter={hideCounter}
      render={({ field, error }) => (
        <Input
          id={id}
          invalid={!!error}
          aria-invalid={error?.message ? "true" : "false"}
          {...field}
          {...restProps}
          name={field?.name}
        />
      )}
    />
  );
};
