import { FragmentOf, graphql, readFragment } from "@/lib/data/graphql";
import { TabNavigation } from "@/ui/tab-navigation/TabNavigation";
import clsx from "clsx";
import { useEffect, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { PerformanceTemplateActions } from "./PerformanceTemplateActions";
import { usePerformanceContext } from "./state/PerformanceProvider";

export const PerformanceTemplatesNavigationFragment = graphql(`
  fragment PerformanceTemplatesNavigation on PerformanceTemplate {
    id
    name
  }
`);

type PerformanceTemplatesNavigationProps = {
  data?: FragmentOf<typeof PerformanceTemplatesNavigationFragment>[];
  loading?: boolean;
};

export const URL_PERF_TEMPLATE_KEY = "templateId";

export const PerformanceTemplatesNavigation: React.FC<
  PerformanceTemplatesNavigationProps
> = ({ loading, data }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { dispatch } = usePerformanceContext();
  const activeTemplateId = searchParams.get(URL_PERF_TEMPLATE_KEY);

  const navigationItems = useMemo(
    () =>
      data?.map((navigationItem) => {
        const navItem = readFragment(
          PerformanceTemplatesNavigationFragment,
          navigationItem
        );

        return {
          id: navItem.id.toString(),
          title: navItem.name
        };
      }) || [],
    [data]
  );

  // setup default tab
  useEffect(() => {
    if (!activeTemplateId && navigationItems[0]) {
      setSearchParams({ [URL_PERF_TEMPLATE_KEY]: navigationItems[0].id });
    }
  }, [activeTemplateId, navigationItems, setSearchParams]);

  return (
    <div className="max-md:p-2 max-md:pb-0">
      <TabNavigation
        urlParamsKey={URL_PERF_TEMPLATE_KEY}
        items={navigationItems}
        endSlot={
          <PerformanceTemplateActions
            loading={loading}
            onAdd={() =>
              dispatch({ type: "OPEN_ADD_TEMPLATE_DRAWER", payload: true })
            }
            onUpdate={() =>
              dispatch({ type: "OPEN_UPDATE_TEMPLATE_DRAWER", payload: true })
            }
          />
        }
        className={clsx(
          "[&_[data-slot=item-title]]:max-w-[90px]",
          "[&_[data-slot=dropdown-button-title]]:max-w-[200px] sm:[&_[data-slot=dropdown-button-title]]:max-w-[400px]"
        )}
      />
    </div>
  );
};
