import { ApprovalStatusSegments } from "@/app/project-overview/ApprovalStatusSegment";
import {
  ProjectData,
  ProjectItemFragment,
  ProjectStatusType
} from "@/app/project-overview/hooks/useProjectData";
import { readFragment } from "@/lib/data/graphql";
import { useTranslation } from "@/lib/i18n";
import { Badge } from "@/ui/display/badge";

type Props = {
  projectData: ProjectData;
  disabled?: boolean;
  onChange: (plantId: string, status: ProjectStatusType) => void;
};

export const ProjectStatus: React.FC<Props> = ({
  projectData,
  disabled = false,
  onChange
}) => {
  const { t } = useTranslation();

  const item = readFragment(ProjectItemFragment, projectData);

  return (
    <div className="w-full flex gap-2">
      {disabled && item.documentUrl ? (
        <Badge>{t(`project.status.${item.status}`)}</Badge>
      ) : (
        <ApprovalStatusSegments
          layoutId={item.id}
          value={item.status}
          onChange={(status) => onChange(item.plantId, status)}
        />
      )}
    </div>
  );
};
