import {
  Co2EquivalentsCard,
  Co2EquivalentsCardFragment
} from "@/app/impact/calculator/Co2EquivalentsCard";
import {
  FootprintCard,
  FootprintCardFragment
} from "@/app/impact/calculator/FootprintCard";
import {
  ImpactCard,
  ImpactCardFragment
} from "@/app/impact/calculator/ImpactCard";
import { DEFAULT_HOUSEHOLD_CONSUMPTION } from "@/constants";
import { graphql } from "@/lib/data/graphql";
import { useTranslation } from "@/lib/i18n";
import { Button } from "@/ui/button/button";
import { Card, CardBody, CardHeader } from "@/ui/display/card";
import { useLazyQuery } from "@apollo/client";
import { FC, useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { CalculateForm } from "./CalculateForm";

export const MyImpactCalculatorCardQuery = graphql(
  `
    query MyImpactCalculatorCard($consumption: Int!) {
      myImpactCalculation(consumption: $consumption) {
        ...FootprintCard
        ...Co2EquivalentsCard
        ...ImpactCard
      }
    }
  `,
  [FootprintCardFragment, Co2EquivalentsCardFragment, ImpactCardFragment]
);

export const MyImpactCalculatorCard: FC = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [hasCalculated, setHasCalculated] = useState(true);
  const [consumption, setConsumption] = useState<number | undefined>(
    DEFAULT_HOUSEHOLD_CONSUMPTION
  );
  const scrollToRef = useRef<HTMLDivElement>(null);

  const [getMyImpactCalculate, { data, loading }] = useLazyQuery(
    MyImpactCalculatorCardQuery,
    {
      fetchPolicy: "no-cache",
      onCompleted: () => {
        setHasCalculated(true);
      }
    }
  );

  useEffect(() => {
    // Anytime we set `hasCalculated` from outside, we want calculator card to scroll into view
    if (searchParams.get("hasCalculated")) {
      scrollIntoView();
    }

    if (searchParams.get("hasCalculated") === "false") {
      setHasCalculated(false);
    } else {
      getMyImpactCalculate({ variables: { consumption: consumption ?? 0 } });
      setSearchParams("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCalculate = async (value?: number) => {
    setConsumption(value);
    await getMyImpactCalculate({ variables: { consumption: value ?? 0 } });
    scrollIntoView();
  };

  const handleRecalculate = () => {
    setHasCalculated(false);
    scrollIntoView();
  };

  const scrollIntoView = () => {
    setTimeout(() => {
      scrollToRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "start"
      });
    }, 0);
  };

  return (
    <Card className="!border-none">
      <div ref={scrollToRef}></div>
      <CardHeader
        className="!px-0 sm:!p-3"
        title={
          hasCalculated
            ? t("myImpact.cards.myImpactCalculator.calculatedTitle", {
                value: consumption
              })
            : t("myImpact.cards.myImpactCalculator.title")
        }
        end={
          hasCalculated ? (
            <Button outline onClick={handleRecalculate} disabled={loading}>
              {t("myImpact.cards.myImpactCalculator.recalculate")}
            </Button>
          ) : null
        }
        headingLevel={1}
        border={false}
      />
      {hasCalculated ? (
        <CardBody className="!p-0">
          <div className="grid md:grid-cols-3 gap-6">
            <FootprintCard data={data?.myImpactCalculation} loading={loading} />
            <Co2EquivalentsCard
              data={data?.myImpactCalculation}
              loading={loading}
            />
            <ImpactCard data={data?.myImpactCalculation} loading={loading} />
          </div>
        </CardBody>
      ) : (
        <CardBody className="!p-0">
          <CalculateForm
            onSubmit={(formData) => handleCalculate(formData.value)}
            initialValues={{ value: consumption }}
            loading={loading}
          />
        </CardBody>
      )}
    </Card>
  );
};
