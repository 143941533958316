// Because of type issues in Recharts v2, we cannot do React wrapper for XAxis.
// In v3, this should solved, in the meantime we just re-export the component from
// here, which serves as placeholder for future wrapper. Same with XAxis.
// export { XAxis, type XAxisProps } from "recharts";
import {
  XAxis as RechartsXAxis,
  XAxisProps as RechartsXAxisProps
} from "recharts";

export type XAxisProps = Omit<RechartsXAxisProps, "ref">;
export const XAxis = RechartsXAxis;
export const defaultProps: Partial<XAxisProps> = {
  stroke: "var(--color-content-secondary)"
};

export const renderXAxis = (props: XAxisProps) => (
  <RechartsXAxis {...defaultProps} {...props} key={props.key}>
    {props.children}
  </RechartsXAxis>
);
