import { useTranslation } from "@/lib/i18n";
import { Button } from "@/ui/button/button";
import { NativeToolbar, NativeToolbarBody } from "@/ui/layouts/native-toolbar";
import {
  AdjustmentsVerticalIcon,
  EnvelopeIcon
} from "@heroicons/react/24/outline";
import React from "react";
import { usePerformanceContext } from "./state/PerformanceProvider";

export type PerformanceChartToolbarProps = {
  endSlot?: React.ReactNode;
  className?: string;
};

export const PerformanceChartToolbar: React.FC<
  PerformanceChartToolbarProps
> = ({ endSlot, className }) => {
  const { t } = useTranslation();
  const { dispatch } = usePerformanceContext();

  const handleOpenLog = () => {
    dispatch({ type: "OPEN_MESSAGES_LOG_DRAWER", payload: true });
  };

  const handleEditTemplateDialogOpen = () => {
    dispatch({ type: "OPEN_ADJUST_TEMPLATE_DIALOG", payload: true });
  };

  return (
    <NativeToolbar className={className}>
      <NativeToolbarBody className="">
        <div className="flex justify-center gap-2">
          <Button
            plain
            title={t("performanceTemplates.form.actions.openTechnicalLog")}
            onClick={handleOpenLog}
          >
            <EnvelopeIcon />
          </Button>
          <Button
            plain
            title={t("performanceTemplates.form.actions.update.label")}
            onClick={handleEditTemplateDialogOpen}
          >
            <AdjustmentsVerticalIcon />
          </Button>

          <div className="absolute right-2">{endSlot}</div>
        </div>
      </NativeToolbarBody>
    </NativeToolbar>
  );
};

export default PerformanceChartToolbar;
