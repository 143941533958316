import { useConfirmDialog } from "@/app/layouts/ConfirmDialogProvider";
import {
  ProjectData,
  ProjectItemFragment
} from "@/app/project-overview/hooks/useProjectData";
import { graphql } from "@/lib/data/graphql";
import { useTranslation } from "@/lib/i18n";
import { Button } from "@/ui/button/button";
import {
  Dialog,
  DialogActions,
  DialogDescription,
  DialogTitle
} from "@/ui/feedback/dialog";
import { toaster } from "@/ui/feedback/toaster";
import { DateInput } from "@/ui/form/DateInput";
import { Text } from "@/ui/typography/text";
import { useMutation } from "@apollo/client";
import { addDays, formatISO } from "date-fns";
import { useState } from "react";

const today = new Date();
const minDate = addDays(today, 6);
const defaultValue = minDate;

const SubmitProjectsOverviewMutation = graphql(
  `
    mutation SubmitProjectsOverview($input: ProjectsOverviewInput!) {
      submitProjectsOverview(projectOverviewInput: $input) {
        id
        quarter
        year
        provisionallyApprovedLimit
        paymentDate
        projects {
          ...ProjectItem
        }
      }
    }
  `,
  [ProjectItemFragment]
);

type Props = {
  isOpen: boolean;
  onClose: VoidFunction;
  projects: ProjectData[];
  quarter: number;
  year: number;
};

export const PaymentDateDialog: React.FC<Props> = ({
  quarter,
  year,
  projects,
  onClose,
  isOpen
}) => {
  const { t } = useTranslation();
  const confirmDialog = useConfirmDialog();

  const [paymentDate, setPaymentDate] = useState(defaultValue);

  const [submitProjectsOverview, { loading }] = useMutation(
    SubmitProjectsOverviewMutation
  );

  const handleSubmit = (paymentDate: Date) => {
    submitProjectsOverview({
      variables: {
        input: {
          paymentDate: formatISO(paymentDate),
          quarter,
          year,
          projects: projects.map(({ id, plantId, status }) => ({
            id: parseInt(id, 10),
            plantId: parseInt(plantId, 10),
            status
          }))
        }
      },
      onCompleted: () => {
        toaster.success({
          title: t("projectOverview.paymentDialog.submit.success")
        });
        onClose();
      },
      onError: () => {
        toaster.error({
          title: t("projectOverview.paymentDialog.submit.error")
        });
      }
    });
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>{t("projectOverview.paymentDialog.title")}</DialogTitle>
      <DialogDescription>
        <Text className="mb-2">
          {t("projectOverview.paymentDialog.description", {
            quarter,
            year
          })}
        </Text>
        <DateInput
          minDate={minDate}
          value={paymentDate}
          onChange={(date) => date && setPaymentDate(date)}
        />
      </DialogDescription>
      <DialogActions>
        <Button plain onClick={onClose}>
          {t("projectOverview.paymentDialog.actions.cancel.label")}
        </Button>
        <Button
          loading={loading}
          onClick={() => {
            confirmDialog.open({
              title: t(
                "projectOverview.paymentDialog.actions.submit.confirmDialog.title"
              ),
              description: t(
                "projectOverview.paymentDialog.actions.submit.confirmDialog.description",
                {
                  quarter,
                  year
                }
              ),
              onConfirm: () => handleSubmit(paymentDate)
            });
          }}
        >
          {t("projectOverview.paymentDialog.actions.submit.label")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
