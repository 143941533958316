import { DocumentShareDialog } from "@/app/documents/DocumentShareDialog";
import { useSession } from "@/lib/auth";
import { FragmentOf, graphql, readFragment } from "@/lib/data/graphql";
import { useTranslation } from "@/lib/i18n";
import { formatDate } from "@/lib/utils/date";
import { downloadExternalFile } from "@/lib/utils/downloadExternalFile";
import { Badge } from "@/ui/display/badge";
import { toaster } from "@/ui/feedback/toaster";
import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownMenu
} from "@/ui/form/dropdown";
import { TableCell, TableRow } from "@/ui/table/table";
import { EllipsisHorizontalIcon } from "@heroicons/react/16/solid";
import { ArrowDownTrayIcon, EnvelopeIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import { useState } from "react";

export const DocumentItemFragment = graphql(`
  fragment DocumentItem on Document {
    id
    name
    plant {
      id
      name
    }
    type
    createdAt
    url
  }
`);

type DocumentItemProps = {
  data: FragmentOf<typeof DocumentItemFragment>;
};

export const DocumentItem: React.FC<DocumentItemProps> = ({ data }) => {
  const { t } = useTranslation();
  const { name, plant, type, createdAt, url } = readFragment(
    DocumentItemFragment,
    data
  );

  const [isShareDialogOpen, setIsShareDialogOpen] = useState(false);

  const session = useSession();
  const ability = session?.ability;
  const ableToDoAction = ability?.can("read", "documents"); // TODO(BE): condition if could read his documents?

  const handleDownloadPdf = () => {
    downloadExternalFile({
      url,
      fileName: name + ".pdf",
      onError: () => {
        toaster.error({
          title: t("documents.listing.actions.download.error.description")
        });
      }
    });
  };

  return (
    <TableRow className="border-b border-border-primary hover:bg-zinc-100 dark:hover:bg-zinc-800 transition-colors">
      <TableCell className="font-bold max-w-18 truncate">{name}</TableCell>
      <TableCell className="max-w-18 truncate">{plant?.name || "–"}</TableCell>
      <TableCell>
        <Badge color="zinc">
          {t(`documents.listing.fields.type.options.${type}`)}
        </Badge>
      </TableCell>
      <TableCell>{formatDate(createdAt)}</TableCell>
      <TableCell>
        <Dropdown>
          <DropdownButton
            plain
            aria-label={t("users.listing.actions.label")}
            disabled={!ableToDoAction}
            className={clsx({ "cursor-not-allowed": !ableToDoAction })}
          >
            <EllipsisHorizontalIcon />
          </DropdownButton>
          <DropdownMenu>
            {ability?.can("read", "documents") ? (
              <>
                <DropdownItem onClick={handleDownloadPdf}>
                  <ArrowDownTrayIcon />
                  {t("documents.listing.actions.download.label")}
                </DropdownItem>
                {session?.me?.email ? (
                  <DropdownItem onClick={() => setIsShareDialogOpen(true)}>
                    <EnvelopeIcon />
                    {t("documents.listing.actions.share.label")}
                  </DropdownItem>
                ) : null}
              </>
            ) : null}
          </DropdownMenu>
        </Dropdown>

        {session?.me?.email ? (
          <DocumentShareDialog
            email={session.me.email}
            subject={name}
            text={url}
            isOpen={isShareDialogOpen}
            onClose={() => setIsShareDialogOpen(false)}
          />
        ) : null}
      </TableCell>
    </TableRow>
  );
};
