import { ConfigureIbansDialogFormValues } from "@/app/settings/ConfigureIbansDialog";
import { useTranslation } from "@/lib/i18n";
import { validateIBAN } from "@/lib/utils/bankDetailsValidation";
import { Card } from "@/ui/display/card";
import { ErrorMessage, Field, Label } from "@/ui/form/fieldset";
import { Input } from "@/ui/form/input";
import { Text } from "@/ui/typography/text";
import clsx from "clsx";
import { Control, Controller, useFieldArray } from "react-hook-form";

type PlantIbansFieldProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<ConfigureIbansDialogFormValues, any>;
  disabled?: boolean;
};

export const PlantIbansField = ({
  control,
  disabled
}: PlantIbansFieldProps) => {
  const { t } = useTranslation();

  const { fields } = useFieldArray({
    control,
    name: "ibans"
  });

  return (
    <Card className="flex flex-col">
      <div className="hidden sm:flex items-center gap-2 py-4">
        <Text className="flex-1 text-content-secondary pl-4">
          {t("settings.payment.configureIbans.fields.plant.label")}
        </Text>
        <Text className="flex-1 text-content-secondary pr-4">
          {t("settings.payment.configureIbans.fields.iban.label")}
        </Text>
      </div>

      {fields.map(({ plantId, plantName }, index) => (
        <Field
          key={plantId}
          className={clsx(
            "flex flex-col sm:flex-row sm:items-center gap-2 py-3 border-t border-border-primary",
            { "border-t-0 sm:border-t": index === 0 }
          )}
        >
          <Label htmlFor={`${plantId}_iban`} className="flex-1 pl-4">
            {plantName}
          </Label>

          <div className="flex-1 pl-4 sm:pl-0 pr-4">
            <Controller
              control={control}
              name={`ibans.${index}.iban`}
              rules={{
                validate: (iban) =>
                  disabled ||
                  validateIBAN(iban) ||
                  t("settings.payment.fields.iban.validation.invalidFormat")
              }}
              render={({ field, fieldState }) => (
                <>
                  <Input
                    id={`${plantId}_iban`}
                    {...field}
                    placeholder={t("settings.payment.fields.iban.placeholder")}
                    autoComplete="off"
                    disabled={disabled}
                  />
                  {fieldState.error ? (
                    <ErrorMessage className="mt-1">
                      {fieldState.error.message}
                    </ErrorMessage>
                  ) : null}
                </>
              )}
            />
          </div>
        </Field>
      ))}
    </Card>
  );
};
